import { style } from "@angular/animations";
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";

@Component({
  selector: "app-shell",
  templateUrl: "./shell.component.html",
  styleUrls: ["./shell.component.scss"],
})
export class ShellComponent implements OnInit,AfterViewChecked {
  logins: boolean;
  user: string;
  rol: any[] = [];
  public destroyed = new Subject<any>();

  constructor(
    private authService: AuthService,
    private msalservice: MsalService,
    private router: Router,
    private messageService: MessageService,
    private changeDetectorRef:ChangeDetectorRef
  ) {
    //borrar
    // localStorage.setItem("token", "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJpbnRlcm5vIiwiZXhwIjoxNjczNzA5NTgxLCJpYXQiOjE2NzExMTc1ODF9.sFBxJAjVjqwFsrtEqaAwiSLQ9HpTyeQXKKNO8AtZcAoMCANzy5aF5lIaeIDei2Jyiftc2-SBz9PawrReR82O3g")
    //borrar
  }

  async rolesInit() {
    if (localStorage.getItem("token")) {
      let promesa = await this.authService.actual2();
      this.rol = promesa.rolenames;
    }
  }

  ngOnInit() {}

  ngAfterViewChecked() {
    this.user = localStorage.getItem("userName");
    this.rol = this.authService.queryRole()
      ? (JSON.parse(this.authService.queryRole()) as String[])
      : [];

    this.changeDetectorRef.detectChanges();
  }

  click() {
    var sidebar = document.querySelector("#sidebar");
    sidebar.classList.toggle("active");
  }

  login() {
    return (
      this.authService.isLoggedIn() ||
      this.msalservice.instance.getAllAccounts().length > 0
    );
  }

  logout() {
    if (
      this.authService.isLoggedIn() &&
      this.msalservice.instance.getAllAccounts().length == 0
    ) {
      this.messageService.add({
        key: "msg",
        severity: "success",
        summary: "Hecho!",
        detail: "Cerrando sesión... ",
      });
      localStorage.clear();
      this.router.navigate(["/login"]);
    } else {
      if (
        this.msalservice.instance.getAllAccounts().length > 0 &&
        this.authService.isLoggedIn()
      ) {
        this.msalservice.logout();
        localStorage.clear();
        this.router.navigate(["/login"]);
      }
    }
  }
}
