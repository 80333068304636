
  <div class="container min-width-500">
    <div class="header">
      <h2>Alertas<br /></h2>
    </div>
    <div class="card">
      <app-popup-alertas></app-popup-alertas>
    </div>
  </div>
  

  

  