import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import {
  AzureDate,
  Value,
} from "src/app/core/authentication/interfaces/groupAzure.interface.";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  progressBarValue = 100;
  keywords: any = [];
  formDatos = this.form.group({
    user: ["admin", Validators.required],
    pass: ["admin", Validators.required],
  });

  loginDisplay = false;
  username;
  constructor(
    private router: Router,
    private form: FormBuilder,
    private messageService: MessageService,
    private authServices: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    var home = document.getElementById("home");
    home.style.left = "0px";
    // document.getElementById("constr").style.display = "none";
  }

  ngOnInit(): void {
    document.getElementById("home").style.width = "100%";
    this.username = sessionStorage.getItem("usernameActive");
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.instance.logout();
    }

 /*  if (localStorage.getItem("token")) {
      this.authServices.actual().subscribe(
        (actual) => {
          if (actual) {
            this.router.navigate(["/home"]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    */
  }

  submit() {
    if (this.formDatos.invalid) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "error",
        detail: "Faltan completar campos",
      });
    } else {
      let user = this.formDatos.get("user").value;
      let pass = this.formDatos.get("pass").value;
      this.authServices.login({ password: pass, username: user }).subscribe(
        (auth) => {
          // const roles = [auth.userRoles] as string[];
          // const roles = ["ADMIN", "TRANSPORTER","PROGRAMMER"] as string[];
          // localStorage.setItem("rol", auth.userRole);
          // localStorage.setItem("rol", JSON.stringify(["ADMIN"]));
          localStorage.setItem("userName", user);
          localStorage.setItem("rol", JSON.stringify(auth.userRoles));
          localStorage.setItem("token", auth.token);
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Hecho!",
            detail: "Inicio de sesión exitoso! ",
          });
          var home = document.getElementById("home");
          home.style.left = "240px";
          home.style.width = "calc(100% - 240px)";

          setTimeout(() => {
            if (auth.userRoles.indexOf("TRANSPORTER") !== -1) {
              this.redirectTo("tablero-transportista");
            } else {
              this.redirectTo("home");
            }
          }, 500);
        },
        (authError) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: "Usuario o contraseña incorrectos",
          });
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: "Proceso de autenticacion fallido",
          });
        }
      );
    }
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  loginAzure() {
    sessionStorage.removeItem("msal.interaction.status");
    this.msalService.loginPopup().subscribe(async (res) => {
      let dateAzure: AzureDate = await this.authServices.getGroupAdAzure(
        res.uniqueId,
        res.accessToken
      );

      let groupsAzure: Value[] = dateAzure.value;

      let groups: string[] = [];

      let groupsPae: string[] = [];

      if (groupsAzure.length > 0) {
        for (let i = 0; i < groupsAzure.length; i++) {
          groups.push(groupsAzure[i].displayName);
        }
      }

      if (groups.length > 0) {
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].indexOf("AZURE_Candoit") != -1) {
            groupsPae.push(groups[i]);
          }
        }
      }

      if (groupsPae.length > 0) {
        let user = {
          name: res.account.name,
          username: res.account.username,
          adGroups: groupsPae,
          password: "SECRET",
        };
        this.authServices.loginAzure(user).subscribe(
          (auth) => {
            localStorage.setItem("userName", user.name);
            localStorage.setItem("rol", JSON.stringify(auth.userRoles));
            localStorage.setItem("token", auth.token);

            var home = document.getElementById("home");
            home.style.left = "240px";
            home.style.width = "calc(100% - 250px)";
            this.router.navigate(["/home"]);
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: "Usuario o contraseña incorrectos",
            });
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: "Proceso de autenticacion fallido",
            });
          }
        );
      } else {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail:
            "El usuario no tiene roles asignados, comuníquese con el administrador",
        });
      }
    });
  }

  logoutAzure() {
    sessionStorage.clear();
    this.msalService.logout();
  }
}
