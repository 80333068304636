<div class="row text-center m-auto">
  <div class="col-4">
    <button
    pButton
    pRipple
    icon="pi pi-download"
    iconPos="right"
    type="button"
    label="Descargar Solicitud"
    class="p-button-secondary p-button"
    (click)="descargarPdfSsta()"
  ></button>
  </div>
  <div class="col-4">
    <p-button
      label="Generar nueva Solicitud"
      styleClass="p-button-secondary p-button"
      [routerLink]="['/solicitudes']"
    ></p-button>
  </div>
  <div class="col-4" style="padding-right: 10%">
    <p-button
      label="Historial"
      styleClass="p-button-secondary p-button-lg"
      [routerLink]="['/solicitudes-asignadas']"
    ></p-button>
  </div>

</div>

<div class="flex justify-content-end mt-5">
  <p-button
  label="Volver"
  styleClass="p-button-text"
  [routerLink]="['/solicitudes']"
></p-button>
</div>