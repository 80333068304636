import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { UserService } from "src/app/core/services/user/user.service";
import { User } from "src/app/shared/models/user/user";
import { TransportistaService } from "../../transportista/services/transportista.service";
@Component({
  selector: 'app-update-programador',
  templateUrl: './update-programador.component.html',
  styleUrls: ['./update-programador.component.scss']
})
export class UpdateProgramadorComponent implements OnInit {

  form: FormGroup;

  user: User = new User();

  spinner = true;

  id;

  userEncontrado:User = new User();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private transportistaService: TransportistaService,
    private userService: UserService
  ) {
    this.cargarUser();
  }

  ngOnInit(): void {
  }

  cargarUser() {
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id;
    this.userService.getByUserId(id).subscribe((user: User) => {
      this.userEncontrado = user;
      this.form = new FormGroup({
        nombre: new FormControl(user.username, [Validators.required]),
        razonSocial: new FormControl(user.companyName, [Validators.required]),
        cuit: new FormControl(user.taxPayerId, [Validators.required]),
        direccion: new FormControl(user.address, [Validators.required]),
        telefono: new FormControl(user.phone, [Validators.required]),
        email: new FormControl(user.emails[0], [Validators.required]),
        usuario: new FormControl(user.username, [Validators.required]),
        // contraseña: new FormControl(user.password, [Validators.required]),
      });
      this.spinner = false;
    });
  }

  modificarProveedor() {
    this.user.address = this.form.get("direccion").value;
    this.user.companyName = this.form.get("razonSocial").value;
    this.user.email = this.form.get("email").value;
    // this.user.password = this.form.get("contraseña").value;
    this.user.phone = this.form.get("telefono").value;
    this.user.taxPayerId = this.form.get("cuit").value;
    this.user.rolenames = this.userEncontrado.rolenames;
    this.user.id = this.id;

    this.userService.editUser(this.user).subscribe(
      (resp) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Creado",
          detail: "¡El programador ha sido modificado con exito!",
        });
        this.router.navigate(["/programadores"]);
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail:(err.error)? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
