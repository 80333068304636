import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertasComponent } from './pages/alertas/alertas.component';
import { PopupAlertasComponent } from './shared/popup-alertas/popup-alertas.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SocialLoginModule } from 'angularx-social-login';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';



@NgModule({
  declarations: [
    AlertasComponent,
    PopupAlertasComponent
  ],
  imports: [
    CommonModule,
    PrimengModule,
    BrowserModule, HttpClientModule,FormsModule, AppRoutingModule, BrowserAnimationsModule, SocialLoginModule,MatCarouselModule.forRoot()
  ],
  exports: [PopupAlertasComponent]
})
export class AlertasModule { }
