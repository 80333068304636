<div class="container">
  <form [formGroup]="form" (ngSubmit)="crear()" class="form">
    <div class="row col-12 mt-5 p-0">
      <div class="col">
        <label for="name" class="padding-2">Nombre </label><br />
        <input type="text" formControlName="nombre" pInputText />
        <span
          class="danger"
          *ngIf="
            form.get('nombre').hasError('required') &&
            form.get('nombre').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>

      <div class="col">
        <label for="name" class="padding-2">Apellido </label><br />
        <input type="text" formControlName="apellido" pInputText />
        <span
          class="danger"
          *ngIf="
            form.get('apellido').hasError('required') &&
            form.get('apellido').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>
    </div>
    <div class="row col-12 mt-5 p-0">
      <div class="col">
        <label for="name" class="padding-2">Dirección </label><br />
        <input type="text" formControlName="direccion" pInputText />
        <span
          class="danger"
          *ngIf="
            form.get('direccion').hasError('required') &&
            form.get('direccion').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>

      <div class="col">
        <label for="name" class="padding-2">Telefono </label><br />
        <input type="text" formControlName="telefono" pInputText />
        <span
          class="danger"
          *ngIf="
            form.get('telefono').hasError('required') &&
            form.get('telefono').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>
    </div>
    <div class="row col-12 mt-5 p-0">
      <div class="col">
        <label for="name" class="padding-2">Tipo </label>
        <p-dropdown
          [options]="tipos"
          formControlName="tipo"
          placeholder="Seleccionar tipo"
          optionLabel="name"
          [showClear]="true"
        ></p-dropdown>

        <span
          class="danger"
          *ngIf="
            form.get('tipo').hasError('required') && form.get('tipo').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>

      <div class="col">
        <label for="fecha" class="padding-2">Fecha de Nacimiento</label><br />
        <p-calendar
          appendTo="body"
          formControlName="fecha"
          [showIcon]="true"
          inputId="icon"
          class="pl-5"
          [yearNavigator]="true"
          yearRange="1970:2050"
          dateFormat="dd/mm/yy"
        >
        </p-calendar>
        <div>
          <span
            class="danger"
            *ngIf="
              form.get('fecha').hasError('required') &&
              form.get('fecha').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
    </div>
    <div class="row col-12 mt-5 p-0">
      <div class="col">
        <label for="name" class="padding-2">Nº </label><br />
        <input type="text" formControlName="numero" pInputText />
        <span
          class="danger"
          *ngIf="
            form.get('numero').hasError('required') &&
            form.get('numero').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>
      <div class="col"></div>
    </div>
    <div class="row col-12">
      <div class="col">
        <p-button
          type="submit"
          [disabled]="form.invalid"
          label="Confirmar"
          class="p-button-primary p-button-text"
        ></p-button>
      </div>
    </div>
  </form>
</div>
