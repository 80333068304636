import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "src/app/shared/models/user/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  getAll( query:any = [], rol = "",) {

    let queryBuid = "";

    query.forEach((q) => {
      if (q.col == "includeInactives") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "rol") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    if(rol){
      queryBuid = queryBuid + "rol" + "=" + rol + "&";
    }

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/usuarios/filtrar?${queryBuid}`, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getByRol(rol: String) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/usuarios/filtrar?rol=${rol}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  filterByRol(rol: String, page, size, query?:any) {
    let headers = new HttpHeaders();

    let queryBuid = "";

    query.forEach((q) => {
      if (q.col == "includeInactives") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "userId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "rol") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "asc") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    if(rol){
        queryBuid = queryBuid + "rol" + "=" + rol + "&";
    }

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/usuarios/filtrar/${page}/${size}?${queryBuid}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  createUser(user: User) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/usuarios/nuevo`, user, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  edit(user: User) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/usuarios/nuevo`, user, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  editUser(user: User) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/usuarios/guardar`, user, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  deleteUser(id) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/usuarios/borrar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getByUserId(id: string): Observable<User> {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get<User>(`${environment.apiUrl}/usuarios/buscar/${id}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deshabilitarUser(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/usuarios/desactivar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  habilitarUser(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/usuarios/activar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
