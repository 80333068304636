import { Component, Input, OnInit } from '@angular/core';
import { ListSstaComponent } from '../../pages/list-ssta/list-ssta.component';
import { MessageService } from 'primeng/api';
import { TemplatesService } from 'src/app/core/services/documentos/templates/templates.service';
import { PdfService } from 'src/app/core/services/documentos/pdf.service';
import { SolicitudAsignada } from 'src/app/shared/models/sst/solicitudAsignada';

@Component({
  selector: 'app-popup-ssta-generada',
  templateUrl: './popup-ssta-generada.component.html',
  styleUrls: ['./popup-ssta-generada.component.scss']
})
export class PopupSstaGeneradaComponent implements OnInit {

  @Input()
  sstaGenerate:SolicitudAsignada;

  constructor(private messageService:MessageService,private templateService:TemplatesService,private pdfService:PdfService) { }

  ngOnInit(): void {
  }

  descargarPdfSsta() {
    this.pdfService
      .descargarSsta(this.templateService.sendTemplateSsta(this.sstaGenerate))
      .subscribe(
        (data) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Descargado",
            detail: "SSTA Descargada",
          });
          var downloadURL = URL.createObjectURL(data);
          var link = document.createElement("a");
          link.href = downloadURL;
          link.download = `SSTA-${this.sstaGenerate.id}.pdf`;
          link.click();
        },
        (error) => {
          console.log(error);
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      );
  }

}
