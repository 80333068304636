import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { TrailersService } from "src/app/core/services/transporte/trailers/trailers.service";
import { SstService } from "src/app/services/sst/sst.service";
import { User } from "src/app/shared/models/user/user";
import { Tractor } from "src/app/shared/models/vehiculos/tractor";
import { Trailer } from "src/app/shared/models/vehiculos/trailers";

@Component({
  selector: "app-popup-new-semirremolques-acoplados",
  templateUrl: "./popup-new-semirremolques-acoplados.component.html",
  styleUrls: ["./popup-new-semirremolques-acoplados.component.scss"],
})
export class PopupNewSemirremolquesAcopladosComponent implements OnInit {
  nuevoTrailer: Trailer = new Trailer();

  form: FormGroup;
  marcas = [
    { name: "CHEVROLET", code: "CHEVROLET" },
    { name: "FORD", code: "FORD" },
    { name: "KIA", code: "KIA" },
    { name: "MAZDA", code: "MAZDA" },
    { name: "MERCEDES BENZ", code: "MERCEDES_BENZ" },
    { name: "NISSAN", code: "NISSAN" },
    { name: "RENAULT", code: "RENAULT" },
    { name: "VOLKSWAGEN", code: "VOLKSWAGEN" },
    { name: "VOLVO", code: "VOLVO" },
    { name: "OTRO", code: "OTHER" },
  ];

  hiddenPopUpNewTractor = true;

  
  userActual: User;
  roles: string[] = [];
  idUserActual = "";

  @Output()
  eventHiddenPopUpSemirremolquesAcoplados: EventEmitter<Boolean> =
    new EventEmitter();

  constructor(
    private messageService: MessageService,
    private trailerService: TrailersService,
    private authService:AuthService
  ) {}

  async rolesInit() {
    this.userActual = await this.authService.actual2();
    this.idUserActual = (await this.authService.actual2()).id;
    this.roles = (await this.authService.actual2()).rolenames;
  }

  ngOnInit(): void {
    this.rolesInit();
    this.form = new FormGroup({
      patente: new FormControl("", [Validators.required]),
      marca: new FormControl("", [Validators.required]),
      modelo: new FormControl("", [Validators.required]),
      length: new FormControl("", [Validators.required]),
      height: new FormControl("", [Validators.required]),
      width: new FormControl("", [Validators.required]),
      details: new FormControl("", [Validators.required]),
    });
  }

  showOtherBrand = true;

  otherBrand(event) {
    if (event.value == "OTHER" && !this.form.get("otherBrand")) {
      this.addControlOtherBrand();
    } else {
      if (this.form.get("otherBrand")) {
        this.removeControlOtherBrand();
      }
    }
  }

  addControlOtherBrand() {
    this.form.addControl(
      "otherBrand",
      new FormControl("", [Validators.required])
    );
    this.showOtherBrand = false;
  }

  removeControlOtherBrand() {
    this.form.removeControl("otherBrand");
    this.showOtherBrand = true;
  }

  crear(): void {
    this.nuevoTrailer.state = "ACTIVE";
    if (this.form.get("marca").value == "OTHER") {
      this.nuevoTrailer.brand = this.form.get("otherBrand").value;
    } else {
      this.nuevoTrailer.brand = this.form.get("marca").value;
    }
    this.nuevoTrailer.model = this.form.get("patente").value;
    this.nuevoTrailer.patent = this.form.get("modelo").value;
    this.nuevoTrailer.patent = this.form.get("patente").value;
    this.nuevoTrailer.length = this.form.get("length").value;
    this.nuevoTrailer.height = this.form.get("height").value;
    this.nuevoTrailer.width = this.form.get("width").value;
    this.nuevoTrailer.details = this.form.get("details").value;
    this.nuevoTrailer.transporterId = this.userActual.id;

    this.trailerService.createTrailer(this.nuevoTrailer).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Confirmado",
          detail: "El semirremolques / acoplados se ha creado con exito",
        });
        this.eventHiddenPopUpSemirremolquesAcoplados.emit(
          this.hiddenPopUpNewTractor
        );
        this.form.reset();
        this.removeControlOtherBrand();
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
