import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { concat, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-graphic-average-time-ssta',
  templateUrl: './graphic-average-time-ssta.component.html',
  styleUrls: ['./graphic-average-time-ssta.component.scss']
})
export class GraphicAverageTimeSstaComponent implements OnInit, OnDestroy {

  @Input()
  mesesTransportistas = [];

  @Input()
  alerta: any = null;

  spinnerGraphicAverageTimeSsta = true;

  chart2: any;
  xaxisChart2 = [];
  graphic2: any = null;
  dataChart2: any = [];
  maxYaxisChart2: any;
  labelChart2: any = [];
  enableSeriesIndex: any = [];

  constructor() {
    this.chart2 = {
      series: [],
      chart: {
        type: 'line',
        height: 350,
        stacked: true,
        zoom: {
          enabled: true,
        },
        toolbar: {
          tools: {
            zoomin: true,
            zoomout: true,
            selection: true,
            pan: true,
            reset: true
          },
        },
      },
      labels: [],
      stroke: {
        width: [0, 4],
        colors: ['#fff'],
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return val || ""
        },
        style: {
          fontSize: '1em',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: undefined
        },
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      fill: {
        opacity: 1
      },
      xaxis: {
        categories: this.xaxisChart2,
        labels: {
          // rotateAlways: true,
          // rotate: -45,
          formatter: function (value) {
            return value;
          },
          tickPlacement: 'on'
        }
      },
      yaxis:
      {
        seriesName: "Power",
        min: 0,
        max: this.maxYaxisChart2,
        title: {
          text: ""
        },
        labels: {
          formatter: function (value) {
            return value;
          }
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        x: {
          show: true,
          format: 'dd MMM',
          formatter: "n",
        },
        y: {
          formatter: function (val, opts) {
            return val ? val : val
          },
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          formatter: "c",
          title: 'Size: '
        },
      },

      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'left'
      }
    };
  }
  ngOnChanges(changes: SimpleChanges): void {

    // this.ngOnInit()
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    of({})
      .pipe(
        tap({
          next: (data) => {
            this.graphicInsert();
          },
          error: (error) => { },
          complete: () => {
            this.spinnerGraphicAverageTimeSsta = false;
          },
        })
      )
      .subscribe();
  }

  graphicInsert() {
    const chartElement = document.querySelector("#chart2")

    if (this.graphic2) {
      this.graphic2.destroy();
      this.xaxisChart2 = [];
      this.dataChart2 = [];
      this.graphic2.destroy()
    }

    this.mesesTransportistas.forEach((mes, index) => {
      const split = mes.mes.split(" ")
      this.xaxisChart2.push(split[0].substring(0, 3).toUpperCase() + " " + split[1])
    })

    if (chartElement) {
      const sstaData = {
        name: 'SSTa',
        group: 'meses',
        type: "column",
        data: [],
        color: this.getRandomColor()
      }

      const alertas = {
        name: 'Alertas',
        type: "column",
        group: 'meses',
        data: [],
        color: this.getRandomColor()
      }

      const tiempoPromedio = {
        name: 'Tiempo promedio',
        type: "line",
        group: 'meses',
        data: [],
        color: this.getRandomColor()
      }

      const transportistas = []
      const alertasTransportistas = []
      const tiempoTransportistas = []

      this.mesesTransportistas.forEach((mes, index) => {
        if (mes.transportistas.length <= 0) {
          alertas.data.push(mes.alerta > 0 &&
            parseFloat(mes.cantidad) > parseFloat(this.alerta)
            ? parseFloat(mes.cantidad) - parseFloat(this.alerta)
            : 0)
          sstaData.data.push(mes.cantidad)
          tiempoPromedio.data.push(mes.tiempoPromedio)
        } else {
          mes.transportistas.forEach((transportista, index) => {
            let find = transportistas.find((element) => { return element.group == transportista.username })
            if (!find) {
              let color = this.getRandomColor();
              transportistas.push({
                name: 'SSTa ' + transportista.username,
                group: transportista.username,
                type: "column",
                data: [],
                color: color
              })
              alertasTransportistas.push({
                name: 'Alertas ' + transportista.username,
                group: transportista.username,
                type: "column",
                data: [],
                color: this.getRandomColor()
              })
              tiempoTransportistas.push({
                name: 'Tiempo promedio ' + transportista.username,
                group: transportista.username,
                type: "line",
                data: [],
                color: color
              })
            }
          })
        }
      })

      this.mesesTransportistas.forEach((mes, index) => {
        const mesFind = this.mesesTransportistas.find((mesSearch) => { return mesSearch.mes.includes(mes.mes) })
        const indexMes = this.mesesTransportistas.indexOf(mesFind)
        mes.transportistas.forEach((transportista, index) => {
          let findGroupSsta = transportistas.find((element) => { return element.group.includes(transportista.username) })
          let findGroupTiempo = tiempoTransportistas.find((element) => { return element.group.includes(transportista.username) })
          let findGroupAlertas = alertasTransportistas.find((element) => { return element.group.includes(transportista.username) })

          findGroupSsta.data[indexMes] = transportista.cantidad || null
          findGroupTiempo.data[indexMes] = transportista.tiempoPromedio || null
          findGroupAlertas.data[indexMes] =
            transportista.alerta > 0 &&
              parseFloat(transportista.cantidad) > parseFloat(this.alerta.value)
              ? parseFloat(transportista.cantidad) - parseFloat(this.alerta)
              : null
        })
      })

      if (transportistas.length > 0) {
        let maxDataSsta = 0;
        let maxTiempo = 0;
        let maxAlertas = 0;

        const allDataTransportistas = transportistas
          .map(t => t.data) // Obtiene todos los arrays data
          .reduce((acc, val) => acc.concat(val), []) // Aplana el array
          .filter(Number.isFinite);
        const allDataTiempo = tiempoTransportistas
          .map(t => t.data) // Obtiene todos los arrays data
          .reduce((acc, val) => acc.concat(val), []) // Aplana el array
          .filter(Number.isFinite);
        const allDataAlerta = alertasTransportistas
          .map(t => t.data) // Obtiene todos los arrays data
          .reduce((acc, val) => acc.concat(val), []) // Aplana el array
          .filter(Number.isFinite);

          of({})
          .pipe(
            tap({
              next: (data) => {
                maxDataSsta =
                allDataTransportistas.length > 0
                    ? Math.max(...allDataTransportistas)
                    : 0;
                maxTiempo =
                  allDataTiempo.length > 0 ? Math.max(...allDataTiempo) : 0;
                maxAlertas =
                  allDataAlerta.length > 0 ? Math.max(...allDataAlerta) : 0;

                this.maxYaxisChart2 =
                  Math.max(maxDataSsta, maxTiempo, maxAlertas) + 50;
              },
              error: (error) => { },
            }),
            concatMap(() =>
              concat(
                of(
                  this.dataChart2.push(...transportistas),
                  this.dataChart2.push(...alertasTransportistas),
                  this.dataChart2.push(...tiempoTransportistas)
                )
              )
            ),
            concatMap(() =>
              concat(
                of(
                  (this.chart2.yaxis = [
                    {
                      seriesName: [...this.getSeriesName(tiempoTransportistas)],
                      opposite: true,
                      min: 0,
                      max: maxTiempo + 5,
                      title: {
                        text: "Tiempo",
                      },
                      labels: {
                        formatter: function (value) {
                          return parseInt(value);
                        },
                        colors: "#CCCCCC",
                      },
                    },
                    {
                      seriesName: [
                        ...this.getSeriesName(transportistas),
                        ...this.getSeriesName(alertasTransportistas),
                      ],
                      min: 0,
                      max: maxDataSsta + (maxAlertas || 0) + 100,
                      title: {
                        text: "SSTa",
                      },
                      labels: {
                        formatter: function (value) {
                          return parseInt(value);
                        },
                      },
                    },
                  ])
                )
              )
            )
          )
          .subscribe();

        if (this.dataChart2.length > 3) {
          this.chart2.dataLabels.enabledOnSeries = this.getIndexTimeGraphic();
        }
      } else {
        const allDataSsta = sstaData.data.length > 0 ? Math.max(...sstaData.data) : 0;
        const allDataTiempo = tiempoPromedio.data.length > 0 ? Math.max(...tiempoPromedio.data) : 0;
        const allDataAlertas = alertas.data.length > 0 ? Math.max(...alertas.data) : 0;

        this.maxYaxisChart2 = Math.max(allDataSsta, allDataTiempo, allDataAlertas) + 50

        this.dataChart2.push(sstaData)
        this.dataChart2.push(alertas)
        this.dataChart2.push(tiempoPromedio)

        this.chart2.yaxis = [
          {
            seriesName: [...this.getSeriesName([tiempoPromedio])],
            opposite: true,
            min: 0,
            max: allDataTiempo + 5,
            title: {
              text: "Tiempo"
            },
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
              colors: '#CCCCCC',

            },
          },
          {
            seriesName: [...this.getSeriesName([sstaData]), ...this.getSeriesName([alertas])],
            min: 0,
            max: allDataSsta + allDataAlertas + 10,
            title: {
              text: "SSTa"
            },
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
              colors: '#CCCCCC',
            },
          }
        ]
      }

      this.chart2.labels = this.xaxisChart2
      this.chart2.series = this.dataChart2


      this.graphic2 = new ApexCharts(chartElement, this.chart2);
      this.graphic2.render();
    }
  }

  getSeriesName(serie: any[]) {
    const seriesName = [];

    serie.forEach((element) => {
      seriesName.push(element.name);
    });

    return seriesName;
  }

  getIndexTimeGraphic() {
    const indices = this.dataChart2
      .map((item, index) => item.name.includes('Tiempo') ? index : -1)
      .filter(index => index !== -1);
    return indices
  }

  getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 3; i++) {
      // Limita los valores de los colores a una gama oscura (0-7)
      const randomValue = Math.floor(Math.random() * 8);
      color += letters[randomValue] + letters[randomValue]; // Duplícalo para asegurar que sea un color medio a oscuro
    }
    return color;
  }
}