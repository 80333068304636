import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AlertasService {
  constructor(private http: HttpClient) {}

  filterAlertByIdUser(id: String, page, size) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/alertas/filtrar/${page}/${size}?userReceiver=${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteAlerta(id) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/alertas/borrar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
