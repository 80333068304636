import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(private http: HttpClient) {}

  uploadFiles(data: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/files/upload`, data, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  uploadTransport(data:any){
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/transporte/upload`, data, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getFile(file: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/files/${file}`, {
        headers: headers,
        responseType: "blob",
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteFile(id: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/files/delete/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
