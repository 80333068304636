import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-ssta-aceptada',
  templateUrl: './popup-ssta-aceptada.component.html',
  styleUrls: ['./popup-ssta-aceptada.component.scss']
})
export class PopupSstaAceptadaComponent implements OnInit {

  hiddenPopUpSstaAceptada = true;

  @Output()
  eventHiddenPopUp:EventEmitter<Boolean> =new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  cerrarPopUp(){
    this.eventHiddenPopUp.emit(this.hiddenPopUpSstaAceptada);
  }

}
