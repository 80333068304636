export class User {
  id:string;
  username:string;
  password:string;
  address:string;
  phone:string;
  companyName:string
  taxPayerId:string;
  email:string;
  emails:string[] = [];
  rolename:string;
  rolenames:string[] = [];
  state:string;
  azure:boolean;
}

