import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListTransportistaComponent } from './list-transportista/list-transportista.component';
import { AltaTransportistaComponent } from './alta-transportista/alta-transportista.component';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLoginModule } from 'angularx-social-login';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { EditTransportistaComponent } from './edit-transportista/edit-transportista.component';
import { DetailsTransportistaComponent } from './details-transportista/details-transportista.component';

@NgModule({
  declarations: [ListTransportistaComponent, AltaTransportistaComponent, EditTransportistaComponent, DetailsTransportistaComponent],
  imports: [
    CommonModule,
    PrimengModule,
    BrowserModule, HttpClientModule,FormsModule, AppRoutingModule, BrowserAnimationsModule, SocialLoginModule,MatCarouselModule.forRoot()
  ],
  exports : [
    ListTransportistaComponent,AltaTransportistaComponent,EditTransportistaComponent,DetailsTransportistaComponent
  ]
})
export class TransportistaModule { }
