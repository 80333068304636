import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { SocialLoginModule } from "angularx-social-login";
import { AppRoutingModule } from "src/app/app-routing.module";
import { PrimengModule } from "src/app/shared/primeng/primeng.module";
import { PopupNewSemirremolquesAcopladosComponent } from "./shared/popup-new-semirremolques-acoplados/popup-new-semirremolques-acoplados.component";
import { PopupNewTractorComponent } from "./shared/popup-new-tractor/popup-new-tractor.component";
import { PopupNewConductorComponent } from "./shared/popup-new-conductor/popup-new-conductor.component";
import { InformacionDeTransporteComponent } from "./pages/informacion-de-transporte/informacion-de-transporte.component";
import { DocumentacionModule } from "../documentacion/documentacion.module";
import { TractoresComponent } from './pages/tractores/tractores.component';
import { ConductoresComponent } from './pages/conductores/conductores.component';
import { AcopladosComponent } from './pages/acoplados/acoplados.component';
import { ListTransportComponent } from './pages/list-transport/list-transport.component';

@NgModule({
  declarations: [
    PopupNewSemirremolquesAcopladosComponent,
    PopupNewTractorComponent,
    PopupNewConductorComponent,
    InformacionDeTransporteComponent,
    TractoresComponent,
    ConductoresComponent,
    AcopladosComponent,
    ListTransportComponent,
  ],
  imports: [
    CommonModule,
    PrimengModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    DocumentacionModule,
    MatCarouselModule.forRoot(),
  ],
  exports: [
    PopupNewSemirremolquesAcopladosComponent,
    PopupNewTractorComponent,
    PopupNewConductorComponent,
    InformacionDeTransporteComponent,
  ],
})
export class VehiculosModule {}
