<li class="nav-item text-left">
    <a href="/alertas">
        <i class="bi bi-bell"></i>
        <span class="links_name">Alertas</span>
    </a>
</li>
<li class="nav-item text-left">
    <a href="/solicitudes">
        <i class="bi bi-file-earmark-text"></i>
        <span class="links_name">SSTs</span>
    </a>
    <span class="tooltip"></span>
</li>
<li class="nav-item text-left">
    <a href="/solicitudes-asignadas">
        <i class="bi bi-file-check"></i>
        <span class="links_name">SSTAs</span>
    </a>
    <span class="tooltip"></span>
</li>
<li class="nav-item text-left">
    <a href="/transportistas">
        <i class="bi bi-truck"></i>
        <span class="links_name">Transportistas</span>
    </a>
    <span class="tooltip"></span>
</li>
<!-- <li class="nav-item text-left">
    <a href="/reportes">
        <i class="bi bi-file-text"></i>
        <span class="links_name">Reportes</span>
    </a>
    <span class="tooltip"></span>
</li> -->
