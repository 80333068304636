<div class="container">
  <div class="row text-center">
    <div class="col-3">
      <button
        pButton
        pRipple
        type="button"
        label="Ver Documentación"
        class="p-button p-button-secondary p-button-text p-3"
        routerLink="/document/ver/{{type | lowercase}}/{{car}}"
      ></button>
    </div>
    <div class="col-3">
      <button
        pButton
        pRipple
        type="button"
        label="Agregar Documentación"
        class="p-button p-button-secondary p-button-text"
        routerLink="/document/agregar/{{type | lowercase}}/{{car}}"
      ></button>
    </div>
    <div class="col-3">
      <button
        pButton
        pRipple
        type="button"
        label="Editar Documentación"
        class="p-button p-button-secondary p-button-text"
        routerLink="/document/actualizar/{{type | lowercase}}/{{car}}"
      ></button>
    </div>
  </div>
</div>
