<div class="container min-width-500">
    <div class="header">
      <h2>INFORMACION DE TRANSPORTE<br /></h2>
    </div>
    <div class="card">
      <div class="row text-center">
        <div class="col">
          <p-button type="button" label="Tractores" routerLink="/transporte/tractores" 
            iconPos="right" class="p-button p-button-text">
          </p-button>
        </div>
        <div class="col">
          <p-button type="button" label="Acoplados" routerLink="/transporte/acoplados"
            iconPos="right" class="p-button p-button-text">
          </p-button>
        </div>
        <div class="col">
          <p-button type="button" label="Conductores" routerLink="/transporte/conductores" 
            iconPos="right" class="p-button p-button-text">
          </p-button>
        </div>
      </div>
    </div>
  </div>