<p-dialog *ngIf="showPopUpCambiarRol" class="mb-5" header="Cambiar Rol"
  [style]="{ 'background-color': 'white', 'min-width': '50vw' }" [(visible)]="showPopUpCambiarRol"
  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <app-pop-up-cambiar-rol [userChange]="userChange"
    (eventHiddenPopUp)="cerrarPopUpCambiarRol($event)"></app-pop-up-cambiar-rol>
</p-dialog>

<div class="container min-width-500">
  <div class="header">
    <h2>Roles</h2>
  </div>
  <div class="card filters">
    <div class="row col-12 p-0">
      <div class="col">
        <h1 class="title">Filtros</h1>
      </div>
    </div>
    <div class="row p-0">
      <div class="col-6">
        <label class="">Usuario </label><br />
        <p-dropdown [style]="{ width: '100%' }" class="" appendTo="body" [options]="users"
          (onChange)="filter($event.value, 'userId')" placeholder="-" [showClear]="true" [filter]="true"
          optionValue="id" filterBy="username,companyName">
          <ng-template let-option pTemplate="item">
            <span [class]="'user -' + option.id">{{option.username}} - {{option.companyName}}</span>
          </ng-template>
          <ng-template let-option pTemplate="selectedItem">
            {{option.username}} - {{option.companyName}}
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-6">
        <label class="">Roles </label><br />
        <p-dropdown  [filter]="true" [style]="{ width: '100%' }" class="" appendTo="body" [options]="roles"
          (onChange)="filter($event.value, 'rol')" (onClear)="filter($event.value, 'rol')" placeholder="-"
          [showClear]="rol">
          <ng-template let-option pTemplate="item">
            <span [class]="'customer-badge rol-' + option.value">{{
              option.label
              }}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>

  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>

  <div class="card" *ngIf="!spinner">
    <div class="">
      <p-table #myTab [value]="usuarios" [scrollable]="true" scrollHeight="400px"
        styleClass="p-datatable p-datatable-striped" [tableStyle]="{ 'min-width': '50rem' }" [rowHover]="true"
        dataKey="id" (onLazyLoad)="customSort($event)"       [customSort]="true"
        [lazy]="true">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="nombre">
              Nombre <p-sortIcon field="nombre"></p-sortIcon>
            </th>
            <th pSortableColumn="razonSocial">
              Razón Social<p-sortIcon field="razonSocial"></p-sortIcon>
            </th>
            <th pSortableColumn="cuit">
              CUIT<p-sortIcon field="cuit"></p-sortIcon>
            </th>
            <th>Roles</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
          <!--<tr>
              <th *ngFor="let col of cols">
                <input
                  type="text"
                  *ngIf="col.field == 'nombre'"
                  class="form-control"
                  placeholder=""
                  (input)="
                    myTab.filter(
                      $event.target.value,
                      col.field,
                      col.filterMatchMode
                    )
                  "
                />
  
                <input
                  type="text"
                  *ngIf="col.field == 'razonSocial'"
                  class="form-control"
                  placeholder=""
                  (input)="
                    myTab.filter(
                      $event.target.value,
                      col.field,
                      col.filterMatchMode
                    )
                  "
                />
  
                <input
                  type="text"
                  *ngIf="col.field == 'cuit'"
                  class="form-control"
                  placeholder=""
                  (input)="
                    myTab.filter(
                      $event.target.value,
                      col.field,
                      col.filterMatchMode
                    )
                  "
                />
                <p-columnFilter
                  *ngIf="col.field == 'estadoId'"
                  field="estadoId"
                  matchMode="equals"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <p-dropdown
                      class="p-dropdown p-dropdown-trigger"
                      appendTo="body"
                      [ngModel]="value"
                      [options]="statuses"
                      (onChange)="filter($event.value)"
                      placeholder="-"
                      [showClear]="true"
                    >
                      <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{
                          option.label
                        }}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th></th>
            </tr>-->
        </ng-template>

        <ng-template pTemplate="body" let-usuario>
          <tr>
            <td>{{ usuario.username }}</td>
            <td>{{ usuario.companyName }}</td>
            <td>{{formatearCUIT( usuario.taxPayerId) }}</td>
            <td>
              <div *ngFor="let rol of usuario.rolenames">
                <p *ngIf="rol == 'PROGRAMMER'">PROGRAMADOR</p>
                <p *ngIf="rol == 'INTERNAL'">INTERNO</p>
                <p *ngIf="rol == 'SUPPLIER'">PROVEEDOR</p>
                <p *ngIf="rol == 'MANAGER'">GERENTE</p>
                <p *ngIf="rol == 'ADMIN'">ADMINISTRADOR</p>
                <p *ngIf="rol == 'TRANSPORTER'">TRANSPORTISTA</p>
                <p *ngIf="rol == 'PLANNING_LEADER'">Lider</p>
                <p *ngIf="rol == 'VISUALIZER'">Visualizador</p>
              </div>
            </td>
            <td *ngIf="usuario.state == 'ACTIVE'" class="text-success">
              {{ usuario.state }}
            </td>
            <td *ngIf="usuario.state != 'ACTIVE'" class="text-danger">
              {{ usuario.state }}
            </td>

            <td colspan="1">
              <p-button label="" icon="pi pi-pencil" styleClass="p-button-sm" pTooltip="Editar roles"
                (click)="showDialogCambiarRol(usuario)"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator [rows]="size" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]"
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-toast></p-toast>