import { Component, Input, OnInit, Output,EventEmitter } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";

@Component({
  selector: "app-popup-proponer-nueva-fecha",
  templateUrl: "./popup-proponer-nueva-fecha.component.html",
  styleUrls: ["./popup-proponer-nueva-fecha.component.scss"],
})
export class PopupProponerNuevaFechaComponent implements OnInit {
  applicationDate: Date = new Date();
  form: FormGroup;
  minDateValue = new Date();

  @Input()
  idSstSelected = [];

  hiddenPopUpProponerFecha = true;

  @Output()
  eventHiddenPopUp:EventEmitter<Boolean> =new EventEmitter();

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private solicitudesAsignadasService: SolicudesAsignadasService,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      fecha: new FormControl("", [Validators.required]),
    });
  }

  cerrarPopUp(){
    this.eventHiddenPopUp.emit(this.hiddenPopUpProponerFecha);
  }

  submit(): void {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea proponer la fecha?",
      header: "Confirmar propuesta",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.posponerFecha();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  posponerFecha() {
    let fechasNuevas = {
      assignedRequestIds: this.idSstSelected,
      programmingDate: this.form.get("fecha").value,
    };

    this.solicitudesAsignadasService.posponerFechaSstas(fechasNuevas).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Confirmado",
          detail: "Nuevas fechas enviadas al programador",
        });
        this.cerrarPopUp();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
