<p-toast position="top-right" life="40000" key="msg"></p-toast>

<div class="container" *ngIf="progressBarValue == 100">
  <div class="field col-12 md:col-12 text-center">
    <h2>Sistema de optimización logística<br /></h2>
  </div>
  <p-card class="card-container card-login">
    <form [formGroup]="form" class="ui large form" (ngSubmit)="confirmar()">
      <div class="p-fluid grid formgrid">
        <div class="col-12 md:col-12 text-center">
          <img src="./assets/img/logo.png" id="img" alt="" />
        </div>
        <div class="col-12 md:col-12 text-center">
          <label for="">Usuario</label>

          <div class="">
            <input
              type="text"
              formControlName="mail"
              pInputText
              placeholder="nombre de usuario"
            />
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-user"
              styleClass="p-button-warn"
            ></button>
          </div>
          <div
            *ngIf="
              form.get('mail').hasError('required') &&
              form.get('mail').touched
            "
            class="text-center"
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </div>
        </div>
        <div class="field col-12 md:col-12 mt-3 text-center">
          <button
            [disabled]="form.invalid"
            pButton
            pRipple
            type="submit"
            class="btn"
            icon="pi pi-check "
            label="Recuperar Contraseña"
          ></button>
        </div>
        <div class="field col-12 md:col-12 mt-5 text-center">
          <p>
            ¿No tienes una cuenta? Haz click 
            <a routerLink="/registro"><span>Registrarse</span></a>
          </p>
        </div>
        <div class="field col-12 md:col-12 mt-2 text-center">
          <p>
            ¿Ya tienes una cuenta?
            <a routerLink="/login"><span>Iniciar Sesión</span></a>
          </p>
        </div>
      </div>
    </form>
  </p-card>
</div>

<div class="container" *ngIf="progressBarValue != 100">
  <p-card>
    <span class="text-center">Procesando...</span>
    <p-progressBar [value]="progressBarValue"></p-progressBar>
  </p-card>
</div>
