import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from "primeng/api";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { ConductoresService } from "src/app/core/services/transporte/conductores/conductores.service";
import { TractoresService } from "src/app/core/services/transporte/tractores/tractores.service";
import { TrailersService } from "src/app/core/services/transporte/trailers/trailers.service";
import { AuthService } from "../../../../core/authentication/auth.service";
import { InformSsta } from "src/app/shared/models/modelsForm/informSsta";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-pop-up-curso-ssta",
  templateUrl: "./pop-up-curso-ssta.component.html",
  styleUrls: ["./pop-up-curso-ssta.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PopUpCursoSstaComponent implements OnInit, AfterContentInit {
  @Input()
  ssta: any;

  @Output()
  eventEmitterSstaInProgress: EventEmitter<any> = new EventEmitter();

  @Output()
  eventHiddenPopUp: EventEmitter<number> = new EventEmitter();

  form: FormGroup;

  tractores: any = [];
  semirremolqueAcoplados: any = [];
  conductores: any = [];

  choferSelecteds = [];
  trailerSelecteds = [];
  tractorSelecteds = [];

  rol: string;

  userActual: User;
  roles: string[] = [];
  idUserActual = "";

  query: any[] = [];

  spinner = true;

  constructor(
    private solicitudesAsignadasService: SolicudesAsignadasService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private conductoresService: ConductoresService,
    private tractorService: TractoresService,
    private trailerService: TrailersService,
    private authService: AuthService
  ) {
    this.rol = this.authService.queryRole();
  }

  async rolesInit() {
    this.userActual = await this.authService.actual2();
    this.idUserActual = (await this.authService.actual2()).id;
    this.roles = (await this.authService.actual2()).rolenames;
  }

  async parametros() {
    return new Promise((resolve, reject) => {
      if (this.idUserActual) {
        if (this.hasRole("TRANSPORTER")) {
          let findTransporter = this.query.find(
            (d) => d.col == "transporterId"
          );
          if (findTransporter) {
            let index = this.query.indexOf(findTransporter);
            this.query.splice(index, 1);
          }
          this.query.push({ col: "transporterId", value: this.idUserActual });
        }
      }

      resolve("");
    });
  }

  ngAfterContentInit(): void {}

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      transports: new FormArray([]),
    });
    await this.rolesInit();
    await this.parametros();
    await this.getConductores();
    await this.getTractores();
    await this.getTrailers();
    this.startForm();
    this.spinner = false;
  }

  async getConductores() {
    let conductoresGet = await this.conductoresService.getAllAsync(this.query);

    for (let i = 0; i < conductoresGet.length; i++) {
      this.conductores.push({
        label: conductoresGet[i].fullName,
        value: conductoresGet[i].id,
        document: conductoresGet[i].documentNumber,
        phone: conductoresGet[i].phone,
      });
    }
  }

  async getTractores() {
    let tractores = await this.tractorService.getAllAsync(this.query);
    for (let i = 0; i < tractores.length; i++) {
      this.tractores.push({
        label: tractores[i].patent + " - " + tractores[i].brand,
        value: tractores[i].id,
      });
    }
  }

  async getTrailers() {
    let trailers = await this.trailerService.getAllAsync(this.query);

    for (let i = 0; i < trailers.length; i++) {
      this.semirremolqueAcoplados.push({
        label: trailers[i].model + " - " + trailers[i].brand,
        value: trailers[i].id,
        patent: trailers[i].patent,
      });
    }
  }

  startForm() {
    let transports = this.form.get("transports") as FormArray;
    for (let i = 0; i < this.ssta.transports.length; i++) {
      if (this.ssta.transports[i].driver?.id) {
        this.mostrarDatosChofer(
          { value: this.ssta.transports[i].driver?.id },
          i
        );
      }
      if (this.ssta.transports[i].trailer?.id) {
        this.mostrarDatoTrailer(
          { value: this.ssta.transports[i].trailer?.id },
          i
        );
      }
      if (this.ssta.transports[i].tractor?.id) {
        this.mostrarDatoTractor(
          { value: this.ssta.transports[i].tractor?.id },
          i
        );
      }

      transports.push(
        new FormGroup({
          driverId: new FormControl(this.ssta.transports[i].driver?.id, [
            Validators.required,
          ]),
          tractorId: new FormControl(this.ssta.transports[i].tractor?.id, [
            Validators.required,
          ]),
          trailerId: new FormControl(this.ssta.transports[i].trailer?.id, []),
        })
      );
    }
  }

  crearNewInform() {
    return new FormGroup({
      driverId: new FormControl("", [Validators.required]),
      tractorId: new FormControl("", [Validators.required]),
      trailerId: new FormControl("", []),
    });
  }

  addNewInform() {
    let newInform = this.crearNewInform();
    this.transports.push(newInform);
  }

  get transports(): FormArray {
    return this.form.get("transports") as FormArray;
  }

  deleteInform(idx: number) {
    this.choferSelecteds[idx] = null;
    this.trailerSelecteds[idx] = null;
    this.tractorSelecteds[idx] = null;
    this.transports.removeAt(idx);
  }

  mostrarDatosChofer(event, indice) {
    if (event.value && event.value > 0) {
      this.conductoresService.getById(event.value).subscribe((data) => {
        this.choferSelecteds[indice] = data;
      });
    }
    this.choferSelecteds[indice] = null;
  }

  mostrarDatoTrailer(event, indice) {
    if (event.value && event.value > 0) {
      this.trailerService.getById(event.value).subscribe((data) => {
        this.trailerSelecteds[indice] = data;
      });
    }
    this.trailerSelecteds[indice] = null;
  }

  mostrarDatoTractor(event, indice) {
    if (event.value && event.value > 0) {
      this.tractorService.getById(event.value).subscribe((data) => {
        this.tractorSelecteds[indice] = data;
      });
    }
    this.tractorSelecteds[indice] = null;
  }

  cerrarPopUp(tipoDeCambio: number) {
    this.eventHiddenPopUp.emit(tipoDeCambio);
  }

  confirmPutInCourse() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea poner en curso la SSTA?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.putInCourse();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  putInCourse() {
    let informSsta: InformSsta = new InformSsta();

    informSsta.ids = [this.ssta?.id];
    this.transports.controls.forEach((t: FormGroup) => {
      informSsta.transports.push({
        driverId: t.get("driverId").value,
        id: 0,
        tractorId: t.get("tractorId").value,
        trailerId: t.get("trailerId").value,
      });
    });

    this.solicitudesAsignadasService
      .ponerEnCurso(this.ssta.id, informSsta)
      .subscribe(
        (res) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "En curso",
            detail: "La SSTA ha sido puesta en curso con exito",
          });
          this.cerrarPopUp(2);
        },
        (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      );
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
