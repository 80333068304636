// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://prod.pae.logistica.back.candoit.com.ar',
  frontUrl: 'https://transporte.pan-energy.com',
  
  client_secret : 'tn1234',

  // azure
  clientId: "ef2ec94b-2e18-4fd1-a722-48c3d60dacf3",
  redirectUri: 'https://transporte.pan-energy.com/home',
  authority:"https://login.microsoftonline.com/8cb3c63f-2956-4d28-b216-acb701237c25",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
