<div class="container min-width-500">
  <form [formGroup]="form" (ngSubmit)="crear()" class="form">
    <div class="row col-12 mt-5 p-0">
      <div class="col">
        <label for="name" class="padding-2">Patente </label>
        <input
          [style]="{ width: '100%' }"
          type="text"
          formControlName="patente"
          pInputText
        />
        <span
          class="danger"
          *ngIf="
            form.get('patente').hasError('required') &&
            form.get('patente').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>

      <div class="col">
        <label for="name" class="padding-2">Marca </label><br />
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="marcas"
          formControlName="marca"
          placeholder="Seleccionar marca"
          optionLabel="name"
          optionValue="code"
          [showClear]="true"
          (onChange)="otherBrand($event)"
        ></p-dropdown>

        <span
          class="danger"
          *ngIf="
            form.get('marca').hasError('required') && form.get('marca').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>
    </div>
    <div
      class="row col-12 mt-5 p-0"
      [hidden]="showOtherBrand"
      *ngIf="this.form.get('otherBrand')"
    >
      <div class="col">
        <label for="name" class="padding-2"
          >Escriba el nombre de la marca
        </label>
        <input
          type="text"
          formControlName="otherBrand"
          class="form-control"
          pInputText
        />
        <span
          class="danger"
          *ngIf="
            form.get('otherBrand').hasError('required') &&
            form.get('otherBrand').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>
    </div>
    <div class="row col-12 mt-5 p-0">
      <div class="col">
        <label for="name" class="padding-2">Modelo </label>
        <input
          [style]="{ width: '100%' }"
          type="text"
          formControlName="modelo"
          pInputText
        />
        <span
          class="danger"
          *ngIf="
            form.get('modelo').hasError('required') &&
            form.get('modelo').touched
          "
        >
          <br />
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <p-button
          type="submit"
          [disabled]="form.invalid"
          label="Confirmar"
          class="p-button-primary p-button-text"
        ></p-button>
      </div>
    </div>
  </form>
</div>
