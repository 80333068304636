<div class="container-fluid ">
    <div class="card text-center" *ngIf="spinner">
        <span>
            <p-progressSpinner></p-progressSpinner>
        </span>
        <br />
        <span class="text-center">Cargando...</span>
    </div>
    <form [formGroup]="form" *ngIf="form && !spinner" class="row m-0 mt-2 card" style="background-color: #fff;">
        <div class="row">
            <div class="col-4">
                <h4>Tablero general</h4>
            </div>
            <div class="col-8 text-end">
                <p-selectButton [options]="justifyOptions" [(ngModel)]="value" optionLabel="label" optionValue="value"
                    [ngModelOptions]="{standalone: true}">
                    <ng-template let-item>
                        <span>{{item.label}}</span>
                        <i [class]="item.icon"></i>
                    </ng-template>
                </p-selectButton>
            </div>
        </div>
        <div class="row p-3">
            <div class="col-sm-12">
                <div class=" row">
                    <div class="col-3" *ngIf="value == 1">
                        <label class="">Programadores </label><br />
                        <p-multiSelect [options]="programadoresFilter" [style]="{ width: '100%' }"
                            [maxSelectedLabels]="2" [selectedItemsLabel]="'{0} seleccionados'"
                            defaultLabel="-- Seleccione --" optionLabel="username" optionValue="id"
                            class="multiselect-custom" appendTo="body" formControlName="programmerIds">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.id">{{
                                    option.username || option.companyName
                                    }}</span>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                    <div class="col-3" *ngIf="value == 2">
                        <label class="">Transportista </label><br />
                        <p-multiSelect [options]="transportistaFilter" [style]="{ width: '100%' }"
                            [maxSelectedLabels]="2" [selectedItemsLabel]="'{0} seleccionados'"
                            defaultLabel="-- Seleccione --" optionLabel="username" optionValue="id"
                            class="multiselect-custom" appendTo="body" formControlName="transporterIds">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.id">{{
                                    option.username || option.companyName
                                    }}</span>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                    <div class="col-3" *ngIf="value == 1">
                        <label class="">Estado SST</label><br />
                        <p-multiSelect formControlName="estadoSst" optionValue="value" [style]="{ width: '100%' }"
                            class="" appendTo="body" [options]="statusesSst" placeholder="-">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.value">{{
                                    option.label
                                    }}</span>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                    <div class="col-3" *ngIf="value == 2">
                        <label class="">Estado SSTa</label><br />
                        <p-multiSelect formControlName="estadoSsta" optionValue="value" [style]="{ width: '100%' }"
                            class="" appendTo="body" [options]="statusesSsta" placeholder="-">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.value">{{
                                    option.label
                                    }}</span>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                    <div class="col-3">
                        <label class="">Fecha desde </label><br />
                        <p-calendar (onClearClick)="clearDate()" showButtonBar="true" [style]="{ width: '100%' }" dateFormat="dd/mm/yy" class="" appendTo="body"
                            formControlName="startDate" inputId="basic" [yearNavigator]="true"
                            yearRange="1970:2050"></p-calendar>
                        <!-- 
                        <p-calendar [style]="{ width: '100%' }" formControlName="dateRange" showButtonBar="true"
                            dateFormat="dd/mm/yy" class="" appendTo="body" [yearNavigator]="true" selectionMode="range"
                            [readonlyInput]="true" inputId="range" yearRange="1970:2050"></p-calendar> -->
                    </div>
                    <div class="col-3">
                        <label class="">Fecha hasta </label><br />
                        <p-calendar (onClearClick)="clearDate()" showButtonBar="true" [readonlyInput]="true" [style]="{ width: '100%' }" dateFormat="dd/mm/yy" class="" appendTo="body"
                            formControlName="endDate" inputId="basic" [yearNavigator]="true"
                            yearRange="1970:2050"></p-calendar>
                        <!-- 
                        <p-calendar [style]="{ width: '100%' }" formControlName="dateRange" showButtonBar="true"
                            dateFormat="dd/mm/yy" class="" appendTo="body" [yearNavigator]="true" selectionMode="range"
                            [readonlyInput]="true" inputId="range" yearRange="1970:2050"></p-calendar> -->
                    </div>
                    <div class="col-3">
                        <label class="">Alerta </label><br />
                        <input [style]="{ width: '100%' }" type="text" pInputText appendTo="body"
                            formControlName="alert" />
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="card filters m-1" [ngClass]="{
        'd-none' : spinner
      }">
        <div class="text-center" *ngIf="loadingData">
            <span>
                <p-progressSpinner></p-progressSpinner>
            </span>
            <br />
            <span class="text-center">Cargando...</span>
        </div>
        <p-tabView [ngClass]="{
            'd-none' : value === 1 || loadingData
          }">
            <p-tabPanel header="Promedios">
                <div class="row p-0">
                    <div class="col-12">
                        <!-- <div id="chart2">
                        </div> -->
                        <app-graphic-average-time-ssta *ngIf="mesesTransportistas.length > 0 && 
                        !loadingData;else sinData" [mesesTransportistas]="mesesTransportistas"
                            [alerta]="this.form.get('alert').value"></app-graphic-average-time-ssta>
                        <ng-template #sinData>
                            <p-messages class="w-100"
                                [value]="[{ severity: 'info', detail: 'No hay datos para mostrar' }]"
                                [enableService]="false" [closable]="false">
                            </p-messages>
                        </ng-template>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Aceptadas/Rechazadas">
                <div class="row p-0">
                    <div class="col-12">
                        <app-graphic-ssta-rejected-accepted
                            *ngIf="transportistas.length > 0 && !loadingData;else sinData"
                            [transportistas]="transportistas"></app-graphic-ssta-rejected-accepted>

                        <ng-template #sinData>
                            <p-messages class="w-100"
                                [value]="[{ severity: 'info', detail: 'No hay datos para mostrar' }]"
                                [enableService]="false" [closable]="false">
                            </p-messages>
                        </ng-template>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Tablero">
                <div class="row p-0">
                    <div class="col-12" *ngIf="sstsDataTransportistas">
                        <app-tablero-transportistas [sstsDataTransportistas]="sstsDataTransportistas"
                            (eventEmitterUserFilterSearch)="searchLocalStorageSsta($event)">
                        </app-tablero-transportistas>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <p-tabView [ngClass]="{
            'd-none' : value === 2  || loadingData
          }">
            <p-tabPanel header="Promedios">
                <div class="row p-0">
                    <div class="col-12">
                        <!-- <div id="chart1">
                        </div> -->
                        <app-graphic-average-time-sst *ngIf="mesesProgramadores.length > 0 && !loadingData;else sinData"
                            [mesesProgramadores]="mesesProgramadores"
                            [alerta]="this.form.get('alert').value"></app-graphic-average-time-sst>
                        <ng-template #sinData>
                            <p-messages class="w-100"
                                [value]="[{ severity: 'info', detail: 'No hay datos para mostrar' }]"
                                [enableService]="false" [closable]="false">
                            </p-messages>
                        </ng-template>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Tablero">
                <div class="row p-0">
                    <div class="col-12" *ngIf="sstsDataProgrammer">
                        <app-tablero-programadores [sstsDataProgrammer]="sstsDataProgrammer"
                            (eventEmitterUserFilterSearch)="searchLocalStorageSst($event)">
                        </app-tablero-programadores>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>