import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SocialLoginModule } from 'angularx-social-login';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { PopupHabilitacionesComponent } from './popup-habilitaciones/popup-habilitaciones.component';



@NgModule({
  declarations: [PopupHabilitacionesComponent],
  imports: [
    CommonModule,
    PrimengModule,
    BrowserModule, HttpClientModule,FormsModule, AppRoutingModule, BrowserAnimationsModule, SocialLoginModule,MatCarouselModule.forRoot()
  ],
  exports: [PopupHabilitacionesComponent],

})
export class HabilitacionesModule { }
