<p-dialog
  header="Agregar tractor"
  [(visible)]="displayNewTractor"
  [style]="{
    'background-color': 'white',
    'min-width': '50vw',
    'min-height': '40vw'
  }"
>
  <app-popup-new-tractor
    (eventHiddenPopUpTractor)="cerrarPopUpNewTractor($event)"
  ></app-popup-new-tractor>
</p-dialog>
<p-dialog
  header="Agregar Semirremolques / Acoplados"
  [(visible)]="displayNewSemirremolquesAcoplados"
>
  <app-popup-new-semirremolques-acoplados
    (eventHiddenPopUpSemirremolquesAcoplados)="
      cerrarPopUpNewSemirremolquesAcoplados($event)
    "
  ></app-popup-new-semirremolques-acoplados>
</p-dialog>
<p-dialog header="Agregar Conductor" [(visible)]="displayNewConductor">
  <app-popup-new-conductor
    (eventHiddenPopUpConductor)="cerrarPopUpNewConductores($event)"
  ></app-popup-new-conductor>
</p-dialog>
<p-dialog
  [header]="'Documentación De ' + type"
  [style]="{ 'background-color': 'white', 'min-width': '50vw' }"
  [(visible)]="displayDocumentacion"
>
  <app-popup-documentacion [type]="type" [car]="car"></app-popup-documentacion>
</p-dialog>

<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container min-width-500" *ngIf="!spinner">
  <div class="row mt-3 m-0">
    <div class="col">
      <h2>INFORMACION DE TRANSPORTE<br /></h2>
    </div>
    <div class="col text-end">
      <p-fileUpload mode="basic" [files]="uploadedFiles" chooseLabel="Cargar unidades"  accept=".xls, .xlsx" maxFileSize="1000000"  (onSelect)="cargarVehiculosExcel($event)"></p-fileUpload>
    </div>
  </div>
  <p-card>
    <div class="row">
      <div class="col">
        <h1 class="title">Tractores</h1>
        <button
          pButton
          pRipple
          type="button"
          label="Agregar"
          class="p-button-secondary p-button-text"
          (click)="showDialogNewTractor()"
        ></button>
      </div>
    </div>
    <p-card
      *ngFor="let tractor of tractores; index as i"
      [style]="{
        display: 'inline-flex',
        width: '23rem',
        height: '23rem',
        'margin-bottom': '2em'
      }"
    >
      <div class="row">
        <div class="col header">
          <h1 class="title">Tractor {{ i + 1 }}</h1>
          <div class="state">{{ tractor.state }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label
            >Patente: <span> {{ tractor.patent }} </span> </label
          ><br />
          <label
            >Marca: <span> {{ tractor.brand }} </span> </label
          ><br />
          <label
            >Modelo: <span> {{ tractor.model }} </span> </label
          ><br />
        </div>
      </div>
      <div class="buttons">
        <p-button
          label=""
          (click)="showDialogDocumentacion('Tractor', tractor.id)"
          pTooltip="Ver Documentación"
          icon="pi pi-file"
          styleClass="p-button-sm"
        >
        </p-button>
        <p-button
          *ngIf="tractor.state != 'ACTIVE'"
          label=""
          icon="pi pi-check-circle"
          styleClass="p-button-sm"
          pTooltip="Habilitar"
          (click)="habilitarTractor(tractor.id)"
        ></p-button>
        <p-button
          *ngIf="tractor.state == 'ACTIVE'"
          label=""
          icon="pi pi-minus-circle
        "
          styleClass="p-button-sm"
          pTooltip="Deshabilitar"
          (click)="deshabilitarTractor(tractor.id)"
        ></p-button>
      </div>
    </p-card>
    <div class="row">
      <div class="col">
        <p-divider></p-divider>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="title">Semirremolques / Acoplados</h1>
        <br />
        <button
          pButton
          pRipple
          type="button"
          label="Agregar"
          class="p-button-secondary p-button-text"
          (click)="showDialogNewSemirremolquesAcoplados()"
        ></button>
      </div>
    </div>
    <p-card
      *ngFor="let trailer of smirremolquesAcoplados; index as i"
      [style]="{
        display: 'inline-flex',
        width: '23rem',
        height: '23rem',
        'margin-bottom': '2em'
      }"
    >
      <div class="row">
        <div class="col header">
          <h1 class="title">Acoplado {{ i + 1 }}</h1>
          <div class="state">{{ trailer.state }}</div>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col m-0 p-0">
          <label
            >Patente: <span> {{ trailer.patent }} </span> </label
          ><br />
          <label
            >Marca: <span> {{ trailer.brand }} </span> </label
          ><br />
          <label
            >Modelo: <span> {{ trailer.model }} </span> </label
          ><br />
          <div class="row m-0 p-0">
            <div class="col m-0 p-0">
              <label
                >Alto: <span> {{ trailer.height }} </span> </label
              ><br />
              <label
                >Ancho: <span> {{ trailer.width }} </span> </label
              ><br />
            </div>
            <div class="col m-0 p-0">
              <label
                >Largo: <span> {{ trailer.length }} </span> </label
              ><br />
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <p-button
          label=""
          (click)="showDialogDocumentacion('Trailer', trailer.id)"
          pTooltip="Ver Documentación"
          icon="pi pi-file"
          styleClass="p-button-sm"
        >
        </p-button>
        <p-button
          *ngIf="trailer.state != 'ACTIVE'"
          label=""
          icon="pi pi-check-circle"
          styleClass="p-button-sm"
          pTooltip="Habilitar"
          (click)="habilitarTrailer(trailer.id)"
        ></p-button>
        <p-button
          *ngIf="trailer.state == 'ACTIVE'"
          label=""
          icon="pi pi-minus-circle
        "
          styleClass="p-button-sm"
          pTooltip="Deshabilitar"
          (click)="deshabilitarTrailer(trailer.id)"
        ></p-button>
      </div>
    </p-card>
    <div class="row">
      <div class="col">
        <p-divider></p-divider>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="title">Conductores</h1>
        <br />
        <button
          pButton
          pRipple
          type="button"
          label="Agregar"
          class="p-button-secondary p-button-text"
          (click)="showDialogNewConductor()"
        ></button>
      </div>
    </div>
    <p-card
      *ngFor="let conductor of conductores; index as i"
      [style]="{
        display: 'inline-flex',
        width: '23rem',
        height: '28rem',
        'margin-bottom': '2em'
      }"
    >
      <div class="row">
        <div class="col header">
          <h1 class="title">Conductor {{ i + 1 }}</h1>
          <div class="state">{{ conductor.state }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label
            >Nombre: <span> {{ conductor.fullName }} </span> </label
          ><br />
          <label
            >DNI: <span> {{ conductor.documentNumber }} </span> </label
          ><br />
          <label
            >Tipo: <span> {{ conductor.documentType }} </span> </label
          ><br />
          <label
            >Fecha de nacimiento:
            <span>
              {{ conductor.dateOfBirth | date : "dd/MM/YYYY" }}
            </span> </label
          ><br />
          <label
            >Dirección: <span> {{ conductor.address }} </span> </label
          ><br />
          <label
            >Telefono: <span> {{ conductor.phone }} </span> </label
          ><br />
        </div>
      </div>
      <div class="buttons">
        <p-button
          label=""
          (click)="showDialogDocumentacion('Conductor', conductor.id)"
          pTooltip="Ver Documentación"
          icon="pi pi-file"
          styleClass="p-button-sm"
        >
        </p-button>
        <p-button
          *ngIf="conductor.state != 'ACTIVE'"
          label=""
          icon="pi pi-check-circle"
          styleClass="p-button-sm"
          pTooltip="Habilitar"
          (click)="habilitarConductor(conductor.id)"
        ></p-button>
        <p-button
          *ngIf="conductor.state == 'ACTIVE'"
          label=""
          icon="pi pi-minus-circle
        "
          styleClass="p-button-sm"
          pTooltip="Deshabilitar"
          (click)="deshabilitarConductor(conductor.id)"
        ></p-button>
      </div>
    </p-card>
    <div class="row">
      <div class="col">
        <p-divider></p-divider>
      </div>
    </div>
  </p-card>
</div>

<p-confirmDialog
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
>
</p-confirmDialog>
<p-toast></p-toast>
