<div class="container-fluid min-width-500 p-0">
  <div class="header">
    <h2>Reportes</h2>
  </div>
  <p-card>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br />
      <span class="text-center">Cargando...</span>
    </div>
    <div *ngIf="!spinner">

    <div class="row mb-3">
      <div class="offset-6 col-6">
        <button
          class="p-button-lg float-right"
          pButton
          pRipple
          label="Volver"
          routerLink="/reportes"
        ></button>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <label class="pr-5">Transportista <span></span> </label
        ><br /><p-dropdown
          [options]="transportistas"
          [(ngModel)]="transporterId"
          placeholder="-- Seleccione --"
          optionLabel="name"
          optionValue="code"
          [showClear]="true"
          [style]="{ width: '100%' }"
          (onChange)="filtrosSolicitudAsignada()"
        ></p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4>Buscar por tiempo entre fechas: </h4>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-6">
        <label class="">Fecha desde:<span></span></label>
        <br />
        <p-calendar
          showButtonBar="true"
          (onClearClick)="filtrosSolicitudAsignada()"
          [(ngModel)]="startDateSolicitud"
          inputId="basic"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          (onSelect)="filtrosSolicitudAsignada()"
          showIcon="true"
          dateFormat="dd/mm/yy"
          [yearNavigator]="true"
          yearRange="1970:2050"
        >
          <ng-template pTemplate="header">
            <h4 class="text-center">Desde</h4>
          </ng-template>
        </p-calendar>
      </div>
      <div class="col-6">
        <label class="">Fecha hasta:<span></span></label>
        <br />
        <p-calendar
          showButtonBar="true"
          (onClearClick)="filtrosSolicitudAsignada()"
          [(ngModel)]="endDateSolicitud"
          inputId="basic"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          (onSelect)="filtrosSolicitudAsignada()"
          showIcon="true"
          [minDate]="minDateValue"
          dateFormat="dd/mm/yy"
          [yearNavigator]="true"
          yearRange="1970:2050"
        >
          <ng-template pTemplate="header">
            <h4 class="text-center">Hasta</h4>
          </ng-template>
        </p-calendar>
      </div>
    </div>

    <p-table
      *ngIf="!spinner"
      #myTab
      [value]="solicitudesAsignadas"
      [scrollable]="true"
      scrollHeight="300px"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable p-datatable-striped p-datatable-sm p-datatable-gridlines p-datatable-responsive-demo"
      [rowHover]="true"
      dataKey="id"
    >
      <ng-template pTemplate="caption">
        <p class="text-center">SSTAs</p>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            Nro SSTA
          </th>
          <th>
            Fecha Programacion
          </th>
          <th >
            Transportista
          </th>
          <th>
            Servicio Urgente
          </th>
          <th>Tiempo</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ssta>
        <tr class="p-selectable-row">
          <td class="palabra">
            {{ ssta.assignedRequest.id }}
          </td>
          <td class="palabra">
            {{ ssta.assignedRequest.programmingDate | date : "dd/MM/YYYY" }}
          </td>
          <td class="palabra">
            {{ ssta.assignedRequest.transporterName }}
          </td>
          <td class="palabra">
            <span *ngIf="ssta.assignedRequest.expressService">Si </span>
            <span *ngIf="!ssta.assignedRequest.expressService"> No </span>
          </td>
          <td class="palabra">
            {{ ssta.report }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" class="w-100">
        <tr>
          <td colspan="12">
            <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No se encontraron SSTAs' }]"
              [enableService]="false" [closable]="false">
            </p-messages>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      [rows]="sizeAsignadas"
      [totalRecords]="totalRecordsAsignadas"
      [rowsPerPageOptions]="[10, 20, 30]"
      (onPageChange)="paginateAsignadas($event)"
    ></p-paginator>
    <div class="d-flex justify-content-center flex-wrap mt-5"  *ngIf="!hasRole('VISUALIZER')">
      <button
        pButton
        pRipple
        type="button"
        label="Descargar Reporte"
        class="p-button-secondary"
        (click)="descargarReporteSsta()"
      ></button>
    </div>
  </div>

  </p-card>
</div>
