import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmationService,
  MessageService,
  ConfirmEventType,
} from "primeng/api";
import { Table } from "primeng/table";
import { EMPTY, concat, of } from "rxjs";
import { concatMap, debounceTime, switchMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { DespositosService } from "src/app/core/services/depositos/despositos.service";
import { PdfService } from "src/app/core/services/documentos/pdf.service";
import { TemplatesService } from "src/app/core/services/documentos/templates/templates.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import { SolicitudAsignada } from "src/app/shared/models/sst/solicitudAsignada";
import { Destino, Origen } from "src/app/shared/models/sst/solicitudes";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-list-ssta",
  templateUrl: "./list-ssta.component.html",
  styleUrls: ["./list-ssta.component.scss"],
})
export class ListSstaComponent implements OnInit, AfterViewInit {

  userActual: User;

  query: any[] = [];
  ordenes: any[] = [
    { label: "N°SSTA", value: "ID" },
    { label: "Solicitante", value: "REQUESTER" },
    { label: "Fecha de programación", value: "DATE" },
    { label: "Servicio Urgente", value: "SPECIAL" },
    { label: "Estado", value: "STATUS" },
  ];

  users: any[] = [];
  programadores: any[] = [];
  transportistas: any[] = [];
  solicitudes: PostSolicitud[] = [];
  selectedSst: PostSolicitud[] = [];
  idSstSelected: number[] = [];
  statuses: any[] = [
    { label: "Pendiente", value: "PENDING" },
    { label: "Aceptada", value: "ACCEPTED" },
    { label: "Rechazada", value: "REJECTED" },
    { label: "Cancelada", value: "CANCELLED" },
    { label: "En curso", value: "IN_PROGRESS" },
    { label: "Finalizada", value: "FINISHED" },
    { label: "Informada", value: "INFORMED" },
  ];
  origenes: Origen[] = [];
  destinos: Destino[] = [];
  rol: string;
  roles: string[] = [];

  cols: any[] = [];

  spinner = true;

  url: string;

  mode = "table";

  idUserActual = "";
  viewSstProgrammerId = "";

  form: FormGroup;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private messageService: MessageService,
    private solicitudesAsigndasServices: SolicudesAsignadasService,
    private route: ActivatedRoute,
    private router: Router,
    private pdfService: PdfService,
    private templateService: TemplatesService,
    private depositosService: DespositosService
  ) {
    this.rol = this.authService.queryRole();
    this.url = route.snapshot.url[0].path;
  }
  ngAfterViewInit(): void {
  }


  ngOnInit() {
    this.rolesInit();

    if (this.hasRole("PLANNING_LEADER") || this.hasRole("PROGRAMMER") || this.hasRole('ADMIN')) {
      this.statuses.push(
        { label: "Pendiente de asignación", value: "ASSIGNMENT_PENDING" },
        { label: "Pendiente de autorización", value: "AUTHORIZATION_PENDING" }
      );
    }
  }


  rolesInit() {
    this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.userActual = data;
          this.idUserActual = data.id;
          this.roles = data.rolenames;
        },
        complete: () => {
        }
      }),
      // concatMap(() => concat(of(this.parametros()))),
      concatMap(() => this.getDestinos()),
      concatMap(() => this.getOrigenes()),
      concatMap(() => this.getTransportistas()),
      concatMap(() => this.getProgrammers()),
      concatMap(() => concat(of(this.startFormFilter()))),
      concatMap(() => concat(of(this.getSolicitudesAsignadas(JSON.parse(localStorage.getItem('querySsta')) || this.query))))
    ).subscribe();


  }

  parametros() {
    if (this.idUserActual) {
      this.procesarRol("PROGRAMMER", "programmerId", this.programmerId || this.idUserActual);
      this.procesarRol("TRANSPORTER", "transporterId", this.transporterId || this.idUserActual);
      this.procesarRol("PLANNING_LEADER", "expressService", true);

    }
  }

  private procesarRol(rol: string, col: string, value: any): void {
    if (this.hasRole(rol)) {
      const findCol = this.query.find((d) => d.col === col);
      if (!findCol) {
        // const index = this.query.indexOf(findCol);
        // this.query.splice(index, 1);
        this.query.push({ col, value });
      }

    }
  }

  getSolicitudesAsignadas(query: any = []) {
    this.parametros()
    localStorage.setItem('querySsta', JSON.stringify(query));

    this.first = ((JSON.parse(localStorage.getItem('pageSsta')) || this.page) - 1) * this.size;

    this.solicitudesAsigndasServices.getAllSstaFiltrar(this.query, (JSON.parse(localStorage.getItem('pageSsta')) || this.page), this.size)
      .pipe(
        switchMap((data) => {
          this.solicitudes = data.list;
          this.totalRecords = data.totalElements;
          this.page = data.currentPage;
          this.spinner = false;
          return EMPTY;  // Puedes devolver EMPTY u otro observable vacío si no necesitas emitir nuevos valores.
        })
      )
      .subscribe();
  }

  getOrigenes() {
    return this.depositosService.getOrigenes().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.origenes.push({ description: d.name, id: d.id });
          });

          this.origenes.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getDestinos() {
    return this.depositosService.getDestinos().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.destinos.push({ description: d.name, id: d.id });
          });

          this.destinos.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getTransportistas() {
    return this.userService.getByRol("transportista").pipe(
      tap({
        next: (res) => {
          res.forEach((e) => {
            if (e.id != this.idUserActual) {
              this.transportistas.push(e);
            }
          });
        },
        complete: () => {
        }
      })
    );
  }


  getProgrammers() {
    return this.userService.getByRol("PROGRAMMER").pipe(
      tap({
        next: (res) => {
          res.forEach((e) => {
            if (e.id != this.idUserActual) {
              this.programadores.push(e);
            }
          });
        },
        complete: () => {
        }
      })
    );
  }

  startFormFilter() {
    let queryBack = JSON.parse(localStorage.getItem('querySsta'))

    if (queryBack) {
      this.query = queryBack
    }

    let transporterId = this.searchFilter('transporterId');
    let programmerId = this.searchFilter('programmerId');
    let originId = this.searchFilter('originId');
    let destinationId = this.searchFilter('destinationId');
    let startProgrammingDate = this.searchFilter('startProgrammingDate');
    let endProgrammingDate = this.searchFilter('endProgrammingDate');
    let startCreationDate = this.searchFilter('startCreationDate');
    let endCreationDate = this.searchFilter('endCreationDate');
    let statuses = this.searchFilter('statuses');
    let esAe = this.searchFilter('esAe');
    let purchaseOrderNumber = this.searchFilter('purchaseOrderNumber');
    let sort = this.searchFilter('sort');
    let requestId = this.searchFilter('requestId');
    let assignedRequestId = this.searchFilter('assignedRequestId');
    let asc = this.searchFilter('asc');

    this.form = new FormGroup({
      transportista: new FormControl(transporterId.value != this.idUserActual ? transporterId.value : ""),
      programadores: new FormControl(programmerId.value != this.idUserActual ? programmerId.value : ""),
      origen: new FormControl(originId.value || ""),
      destino: new FormControl(destinationId.value || ""),
      fechaProgramacionInit: new FormControl(startProgrammingDate.value ? new Date(startProgrammingDate.value) : ""),
      fechaProgramacionEnd: new FormControl(startProgrammingDate.value ? new Date(endProgrammingDate.value) : ""),
      fechaSolicitudInit: new FormControl(startCreationDate.value ? new Date(startCreationDate.value) : ""),
      fechaSolicitudEnd: new FormControl(endCreationDate.value ? new Date(endCreationDate.value) : ""),
      estado: new FormControl(statuses.value || []),
      esAe: new FormControl(esAe.value || ""),
      oc: new FormControl(purchaseOrderNumber.value || ""),
      numeroSst: new FormControl(requestId.value || ""),
      numeroSsta: new FormControl(assignedRequestId.value || ""),
      ordenSsta: new FormControl(sort.value || ""),
      sort: new FormControl(sort.value || ""),
      asc: new FormControl(asc.value || false),
    })

    this.form.valueChanges.subscribe((selectedValue) => {
      // this.spinner = true;
    })

    this.setupFormControlSubscription('transportista', 'transporterId');
    this.setupFormControlSubscription('programadores', 'programmerId');
    this.setupFormControlSubscription('origen', 'originId');
    this.setupFormControlSubscription('destino', 'destinationId');
    this.setupFormControlSubscriptionDate('fechaProgramacionInit', 'startProgrammingDate', 'endProgrammingDate');
    this.setupFormControlSubscriptionDate('fechaProgramacionEnd', 'endProgrammingDate', 'startProgrammingDate');
    this.setupFormControlSubscriptionArray('estado', 'statuses');
    this.setupFormControlSubscription('esAe', 'esAe');
    this.setupFormControlSubscription('oc', 'purchaseOrderNumber');
    this.setupFormControlSubscription('numeroSst', 'requestId');
    this.setupFormControlSubscription('numeroSsta', 'assignedRequestId');
    this.setupFormControlSubscription('ordenSsta', 'sort');
    this.setupFormControlSubscription('sort', 'sort');
  }

  // Método para configurar suscripciones a cambios en un control del formulario
  private setupFormControlSubscription(controlName: string, filterName: string) {
    this.form.get(controlName).valueChanges
      .pipe(debounceTime(300))
      .subscribe({
        next: (selectedValue) => {
          this.deleteFilter(filterName);
          if (selectedValue) {
            this.query.push({ col: filterName, value: selectedValue });
          }
          this.page = 1;
          this.getSolicitudesAsignadas(this.query);
        }
      });
  }

  private setupFormControlSubscriptionDate(controlName: string, filterName: string, correspondingControl: string) {
    this.form.get(controlName).valueChanges
      .pipe(debounceTime(300))
      .subscribe({
        next: (selectedValue) => {
          this.deleteFilter(filterName);
          if (selectedValue) {
            this.query.push({ col: filterName, value: selectedValue });
          }

          if (this.searchFilter(filterName) && this.searchFilter(correspondingControl)) {
            this.page = 1;
            this.getSolicitudesAsignadas(this.query);
          }
        }
      });
  }

  // Método para configurar suscripciones a cambios en un control de array del formulario
  private setupFormControlSubscriptionArray(controlName: string, filterName: string) {
    this.form.get(controlName).valueChanges
      .pipe(debounceTime(300))
      .subscribe({
        next: (selectedValue) => {
          this.deleteFilter(filterName);
          if (selectedValue) {
            this.query.push({ col: filterName, value: selectedValue.length > 0 ? selectedValue : "" });
          }
          this.page = 1;
          this.getSolicitudesAsignadas(this.query);
        }
      });
  }

  deleteFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }
  }

  searchFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      return find;
    }
    return false;
  }

  changeAscDescOrder(order: any) {
    this.deleteFilter('asc')

    this.ordenesAscDescFilters = !this.ordenesAscDescFilters;
    this.ordenesAscDescIcon = this.ordenesAscDescFilters
      ? "pi pi-sort-amount-up"
      : "pi pi-sort-amount-down-alt";
    this.ordenesAscDescTooltip = this.ordenesAscDescFilters
      ? "Orden Ascendente"
      : "Orden Descendiente";
    this.query.push({ col: 'asc', value: this.ordenesAscDescFilters });

    this.form.get('asc').setValue(this.ordenesAscDescFilters)
    this.page = 1;
    this.getSolicitudesAsignadas(this.query);
  }

  /**
   *  Tablero transportista --INICIO
   */

  disableButtonAcceptSsta = false;
  disableButtonRejectSsta = false;
  disableButtonInformSsta = false;
  disableButtonNewDateSsta = false;

  showPopUpSstaAceptada = false;
  showPopUpProponerFecha = false;
  showPopUpEnCursoSsta = false;
  showPopUpFinalizarSsta = false;

  cerrarPopUpPosponerFecha(event) {
    this.showPopUpProponerFecha = !event;
    this.verificarExistPendientes();
  }

  cerrarPopUpEnCursoSsta(event) {
    this.showPopUpEnCursoSsta = false;
    this.sstaEnCurso = "";
    if (event == 2) {
      this.getSolicitudesAsignadas();
    }
  }

  cerrarPopUpSstaAceptada(event) {
    this.showPopUpSstaAceptada = !event;
  }

  cerrarPopUpFinalizarSsta(event) {
    this.showPopUpFinalizarSsta = false;
    this.sstaAFinalizar = "";
    if (event == 2) {
      this.getSolicitudesAsignadas();
    }
  }

  verificarExistPendientes() {
    let deniedAcceptSsta = false;
    let deniedRejectSsta = false;
    let deniedNewDateSsta = false;
    let deniedInformSsta = false;

    let CANCELLED = false;
    let DELETED = false;
    let FINISHED = false;
    let ACCEPTED = false;
    let REJECTED = false;
    let PENDING = false;
    let INFORMED = false;
    let INPROGRESS = false;

    this.idSstSelected = [];
    if (this.selectedSst.length > 0) {
      for (let sst of this.selectedSst) {
        this.idSstSelected.push(sst.id);
        if (sst.status == "CANCELLED") {
          CANCELLED = true;
        }
        if (sst.status == "DELETED") {
          DELETED = true;
        }
        if (sst.status == "FINISHED") {
          FINISHED = true;
        }
        if (sst.status == "ACCEPTED") {
          ACCEPTED = true;
        }
        if (sst.status == "REJECTED" || sst.status == 'EXPRESS_SERVICE_REJECTED') {
          REJECTED = true;
        }
        if (sst.status == "PENDING" || sst.status == "EXPRESS_SERVICE_ACCEPTED") {
          PENDING = true;
        }
        if (sst.status == "INFORMED") {
          INFORMED = true;
        }
        if (sst.status == "IN_PROGRESS") {
          INPROGRESS = true;
        }
      }
    } else {
      this.disableButtonAcceptSsta = deniedAcceptSsta;
      this.disableButtonRejectSsta = deniedRejectSsta;
      this.disableButtonNewDateSsta = deniedNewDateSsta;
      this.disableButtonInformSsta = deniedInformSsta;
    }

    if (
      ACCEPTED &&
      PENDING &&
      !INFORMED &&
      !REJECTED &&
      !CANCELLED &&
      !FINISHED &&
      !INPROGRESS
    ) {
      this.disableButtonAcceptSsta = true;
      this.disableButtonRejectSsta = true;
      this.disableButtonNewDateSsta = true;
      this.disableButtonInformSsta = true;
    } else if (
      PENDING &&
      !ACCEPTED &&
      !INFORMED &&
      !REJECTED &&
      !CANCELLED &&
      !FINISHED &&
      !INPROGRESS
    ) {
      this.disableButtonAcceptSsta = false;
      this.disableButtonRejectSsta = false;
      this.disableButtonNewDateSsta = false;
      this.disableButtonInformSsta = true;
    } else if (
      !PENDING &&
      ACCEPTED &&
      !INFORMED &&
      !REJECTED &&
      !CANCELLED &&
      !FINISHED &&
      !INPROGRESS
    ) {
      this.disableButtonAcceptSsta = true;
      this.disableButtonRejectSsta = true;
      this.disableButtonNewDateSsta = true;
      this.disableButtonInformSsta = false;
    } else if (INFORMED) {
      this.disableButtonAcceptSsta = true;
      this.disableButtonRejectSsta = true;
      this.disableButtonNewDateSsta = true;
      this.disableButtonInformSsta = true;
    } else if (REJECTED) {
      this.disableButtonAcceptSsta = true;
      this.disableButtonRejectSsta = true;
      this.disableButtonNewDateSsta = true;
      this.disableButtonInformSsta = true;
    } else if (CANCELLED) {
      this.disableButtonAcceptSsta = true;
      this.disableButtonRejectSsta = true;
      this.disableButtonNewDateSsta = true;
      this.disableButtonInformSsta = true;
    } else if (INPROGRESS) {
      this.disableButtonAcceptSsta = true;
      this.disableButtonRejectSsta = true;
      this.disableButtonNewDateSsta = true;
      this.disableButtonInformSsta = true;
    } else if (FINISHED) {
      this.disableButtonAcceptSsta = true;
      this.disableButtonRejectSsta = true;
      this.disableButtonNewDateSsta = true;
      this.disableButtonInformSsta = true;
    }
  }

  loadingAcceptSsta = false;
  showDialogAceptar(event: Event, idLiderSsta?: any) {
    event.preventDefault();

    if (idLiderSsta) {
      this.idSstSelected.push(idLiderSsta);
    }

    if (this.idSstSelected.length > 0) {
      this.loadingAcceptSsta = true;

      let mensaje = "¿Está aseguro que desea aceptar la ssta seleccionada?";

      this.confirmationService.confirm({
        message: mensaje,
        header: "Confirmar ",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        accept: () => {
          this.solicitudesAsigndasServices
            .acceptSstas(this.idSstSelected)
            .subscribe({
              next: (resp) => {
                this.messageService.add({
                  key: "msg",
                  severity: "success",
                  summary: "Aceptado",
                  detail: "",
                });
                this.getSolicitudesAsignadas();
                this.selectedSst = [];
                this.verificarExistPendientes();
                this.showPopUpSstaAceptada = true;
                if (idLiderSsta) {
                  this.idSstSelected = [];
                }
              },
              error: (err) => {
                this.messageService.add({
                  key: "msg",
                  severity: "error",
                  summary: "Error",
                  detail: err.error
                    ? err.error.message
                    : "Ups! ocurrio un error",
                });
              },
              complete: () => {
                this.loadingAcceptSsta = false;
              },
            });
        },
        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({
                key: "msg",
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              this.loadingAcceptSsta = false;

              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({
                key: "msg",
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              this.loadingAcceptSsta = false;
              break;
          }
        },
      });
    } else {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Seleccione mínimo una SSTA",
      });
    }
  }

  sstaAFinalizar = "";
  sstaEnCurso = "";

  showDialogFinalizarSsta(idSsta: any) {
    this.sstaAFinalizar = idSsta;
    if (this.sstaAFinalizar != "") {
      this.showPopUpFinalizarSsta = true;
    }
  }

  showDialogEnCursoSsta(ssta: any) {
    this.sstaEnCurso = ssta;
    if (this.sstaEnCurso != "") {
      this.showPopUpEnCursoSsta = true;
    }
  }

  showDialogProponer() {
    if (this.selectedSst.length <= 0) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Seleccione mínimo una SSTA",
      });
      return;
    }
    this.showPopUpProponerFecha = true;
  }

  informarSsta() {
    if (this.selectedSst.length <= 0) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Seleccione mínimo una SSTA",
      });
      return;
    }

    localStorage.setItem("sstReportSstaId", JSON.stringify(this.idSstSelected));
    localStorage.setItem("sstReportSsta", JSON.stringify(this.selectedSst));
    this.router.navigate(["/informar-ssta"]);
  }

  cancelarSstaInformada(ssta: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea cancelar la solicitud?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesAsigndasServices.cancelarSstaInformada(ssta).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "La SSTA informada ha sido cancelada con exito",
            });
            this.getSolicitudesAsignadas();
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error
                ? error.error.message
                : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }


  cancelarSstaEnCurso(ssta: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea cancelar la solicitud en curso?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesAsigndasServices.cancelarSstaEnCurso(ssta).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "La SSTA en curso ha sido cancelada con exito",
            });
            this.getSolicitudesAsignadas();
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error
                ? error.error.message
                : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  loadingRejectSsta = false;
  rechazar(event: Event, idLiderSsta?: any) {
    if (idLiderSsta) {
      this.idSstSelected.push(idLiderSsta);
    }

    if (this.idSstSelected.length > 0) {
      this.loadingRejectSsta = true;

      let mensaje = "¿Está aseguro que desea rechazar la ssta seleccionada?";

      this.confirmationService.confirm({
        message: mensaje,
        header: "Confirmar ",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Rechazar",
        rejectLabel: "Cancelar",
        accept: () => {
          this.solicitudesAsigndasServices
            .rejectSstas(this.idSstSelected)
            .subscribe({
              next: (resp) => {
                this.messageService.add({
                  key: "msg",
                  severity: "success",
                  summary: "Rechazado",
                  detail: "",
                });
                this.getSolicitudesAsignadas();
                this.selectedSst = [];
                this.verificarExistPendientes();
                if (idLiderSsta) {
                  this.idSstSelected = [];
                }
              },
              error: (err) => {
                this.messageService.add({
                  key: "msg",
                  severity: "error",
                  summary: "Error",
                  detail: err.error
                    ? err.error.message
                    : "Ups! ocurrio un error",
                });
              },
              complete: () => {
                this.loadingRejectSsta = false;
              },
            });
        },
        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({
                key: "msg",
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              this.loadingRejectSsta = false;
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({
                key: "msg",
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              this.loadingRejectSsta = false;
              break;
          }
        },
      });
    } else {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Seleccione mínimo una SSTA",
      });
    }
  }

  /**
   *  Tablero transportista --FIN
   */

  /**
   * Acciones de los botones de la tablas/card --INICIO
   */
  deleteSst(id) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la solicitud?",
      header: "Confirmar eliminar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesAsigndasServices.deleteSst(id).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "La solicitud ha sido eliminada con exito",
            });
            this.getSolicitudesAsignadas();
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error
                ? error.error.message
                : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  descargarPdfSsta(ssta: SolicitudAsignada) {
    this.pdfService
      .descargarSsta(this.templateService.sendTemplateSsta(ssta))
      .subscribe(
        (data) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Descargado",
            detail: "SSTA Descargada",
          });
          var downloadURL = URL.createObjectURL(data);
          var link = document.createElement("a");
          link.href = downloadURL;
          link.download = `SSTA-${ssta.id}.pdf`;
          link.click();
          // window.open(downloadURL);;
        },
        (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      );
  }
  /**
   * Acciones de los botones de la tablas/card --FIN
   */

  /**
   * Filtros de la tabla --INICIO
   */

  fechaSolicitudFilter: Date;
  fechaNecesidadFilter: Date;
  programmerId = "";
  transporterId = "";
  requirementDate = "";
  requirementDateStart = "";
  requirementDateStartDate = new Date();
  requirementDateEnd = "";
  destinationId = "";
  originId = "";
  supplierId = "";
  status = "";
  esAe = "";
  purchaseOrderNumber = "";
  requestId = "";

  resetDate(col) {
    let find = this.query.find((d) => d.col == col);
    let index = this.query.indexOf(find);
    this.query.splice(index, 1);
    this.getSolicitudesAsignadas();
  }

  //filtros de modo tarjeta
  ordenesAscDescFilters = false;
  ordenesAscDescIcon = "pi pi-sort-amount-down-alt";
  ordenesAscDescTooltip = "Orden Ascendente";
  ordenSst = "";
  statusFilter = [];

  clear(table: Table) {
    table.clear();
  }

  filterFechaSolicitud(event) { }

  filterFechaNecesidad(event) { }

  /**
   * Filtros de la tabla --FIN
   */

  //paginado --INICIO
  totalRecords = 0;
  page = 1;
  size = 10;
  first;

  paginate(event) {
    this.page = event.page + 1;
    localStorage.setItem('pageSsta', JSON.stringify(this.page));
    this.size = event.rows;
    this.getSolicitudesAsignadas();
  }
  //paginado --FIN

  customSort(event: any) {
    let find = "";

    find = this.query.find((d) => d.col == "sort");
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }

    switch (event.sortField) {
      case "id":
        this.query.push({ col: "sort", value: "ID" });
        break;
      case "programmingDate":
        this.query.push({ col: "sort", value: "DATE" });

        break;
      case "transporterName":
        this.query.push({ col: "sort", value: "REQUESTER" });

        break;
      case "expressService":
        this.query.push({ col: "sort", value: "SPECIAL" });
        break;
      default:
        break;
    }

      find = this.query.find((d) => d.col == "asc");
      if (find) {
        let index = this.query.indexOf(find);
        this.query.splice(index, 1);
      }

      let ordenAscendente = false;

      event.sortOrder == 1
        ? this.query.push({ col: "asc", value: ordenAscendente })
        : this.query.push({ col: "asc", value: !ordenAscendente });
    this.getSolicitudesAsignadas();
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
