<p-toast position="top-right" life="40000" key="msg"></p-toast>

<div class="container" *ngIf="progressBarValue == 100">
  <div class="field col-12 md:col-12 text-center">
    <h2>Sistema de optimización logística<br /></h2>
  </div>
  <p-card class="card-container card-login">
    <form [formGroup]="form" class="ui large form" (ngSubmit)="cambiarContrasenia()">
      <div class="p-fluid grid formgrid">
        <div class="col-12 md:col-12 text-center">
          <img src="./assets/img/logo.png" id="img" alt="" />
        </div>
        <div class="col-12 md:col-12 text-center">
            <label for="">Nueva Contraseña</label>
            <div class="">
              <input
                type="password"
                pInputText
                formControlName="pass"
                placeholder="********"
              />
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-lock"
                styleClass="p-button-warn"
              ></button>
            </div>
            <div
              *ngIf="
                form.get('pass').hasError('required') &&
                form.get('pass').touched
              "
              class="text-center"
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </div>
        </div>
        <div class="col-12 md:col-12 text-center">
            <label for="">Repite Contraseña</label>
            <div class="">
              <input
                type="password"
                pInputText
                formControlName="repitePassword"
                placeholder="********"
              />
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-lock"
                styleClass="p-button-warn"
              ></button>
            </div>
            <div
              *ngIf="
                form.get('repitePassword').hasError('required') &&
                form.get('repitePassword').touched
              "
              class="text-center"
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </div>
        </div>
        <div class="field col-12 md:col-12 mt-3 text-center">
          <button
            [disabled]="form.invalid"
            pButton
            pRipple
            type="submit"
            class="btn"
            icon="pi pi-check "
            label="Cambiar Contraseña"
          ></button>
        </div>
        <div class="field col-12 md:col-12 mt-5 text-center">
          <p>
            ¿No tienes una cuenta? Haz click 
            <a routerLink="/registro"><span>Registrarse</span></a>
          </p>
        </div>
        <div class="field col-12 md:col-12 mt-2 text-center">
          <p>
            ¿Ya tienes una cuenta?
            <a routerLink="/login"><span>Iniciar Sesión</span></a>
          </p>
        </div>
      </div>
    </form>
  </p-card>
</div>

<div class="container" *ngIf="progressBarValue != 100">
  <p-card>
    <span class="text-center">Procesando...</span>
    <p-progressBar [value]="progressBarValue"></p-progressBar>
  </p-card>
</div>
