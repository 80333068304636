import { HttpClient, HttpHeaders } from "@angular/common/http";
import { utf8Encode } from "@angular/compiler/src/util";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor(private http: HttpClient) {}

  descargarSst(data: any) {
    let headers = new HttpHeaders({
      "Content-Type": "text/html",
    });
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    return this.http
      .post(
        `${environment.apiUrl}/generar-pdf`,
        data,
        { headers: headers, responseType: "blob" }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  descargarSsta(data: any) {
    let headers = new HttpHeaders({
      "Content-Type": "text/html",
    });
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    return this.http
      .post(
        `${environment.apiUrl}/generar-pdf`,
        data,
        { headers: headers, responseType: "blob" }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
