export class InformSsta {
    ids?:any[] = [];
    informs?:any[] = [];
    id?:number;
    fechaRetiro?:string;
    transports: Transport[] = [];
}

export class Transport {
    driverId:        number;
    id:              number;
    // packageTypeList: string[];
    tractorId:       number;
    trailerId?:       number;
}
