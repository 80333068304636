import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as ApexCharts from 'apexcharts';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexPlotOptions,
  ApexDataLabels,
  ApexResponsive,
  ApexLegend
} from "ng-apexcharts";
import { MessageService } from "primeng/api";
import { concat, Observable, of, pipe } from "rxjs";
import { concatMap, debounceTime, finalize, switchMap, tap } from "rxjs/operators";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { LoadDataTableBoard, TabViewSstaTableBoard, TabViewSstTableBoard, TypeFilterLoadTableBoard } from "../../enum/table-board.enum";

@Component({
  selector: 'app-tablero-general',
  templateUrl: './tablero-general.component.html',
  styleUrls: ['./tablero-general.component.scss']
})

export class TableroGeneralComponent implements OnInit {

  spinner = true;

  loadingDataSst = false;
  loadingDataSsta = false;

  loadingDataEnumSst: LoadDataTableBoard = LoadDataTableBoard.NORMAL
  loadingDataEnumSsta: LoadDataTableBoard = LoadDataTableBoard.NORMAL

  tableViewSstActually: TabViewSstTableBoard = null;
  tableViewSstaActually: TabViewSstaTableBoard = null;

  spinnerSst = true;
  spinnerSsta = true;

  myMap2 = new Map<TabViewSstTableBoard, Set<any>>();
  myMap3 = new Map<TabViewSstaTableBoard, Set<any>>();

  /**
   * Spinner pestañas del tablero - SST
   */

  spinnerTableroProgramadorSst = true;
  spinnerGraficoTiempoSst = true;

  /**
   * Spinner pestañas del tablero - SSTA
   */

  spinnerTableroTransportistaSsta = true;
  spinnerGraficoTiempoSsta = true;
  spinnerRechazadaAceptadaSsta = true;

  value: number = 1;
  justifyOptions: any[] = [
    { icon: 'bi bi-file-earmark-text', value: 1, label: "SST" },
    { icon: 'bi bi-file-check', value: 2, label: "SSTA" },
  ];

  programadores = []

  transportistas = []

  mesesProgramadores = []

  mesesTransportistas = []

  sstsDataProgrammer = null;

  sstsDataTransportistas = null;

  statusesSsta: any[] = [
    { label: "Aceptada", value: "ACCEPTED" },
    { label: "Rechazada", value: "REJECTED" },
    { label: "Cancelada", value: "CANCELLED" },
    { label: "En curso", value: "IN_PROGRESS" },
    { label: "Finalizada", value: "FINISHED" },
    { label: "Informada", value: "INFORMED" },
  ];

  statusesSst: any[] = [
    { label: "Asignada", value: "ASSIGNED" },
    { label: "Rechazada", value: "REJECTED" },
    { label: "Cancelada", value: "CANCELLED" },
    { label: "Eliminada", value: "DELETED" },
    { label: "Finalizada", value: "FINISHED" },
    { label: "Guardada", value: "NEW" }
  ];

  programadoresFilter: any = []
  transportistaFilter: any = []

  @ViewChild('chart1', { static: false }) chartGraphic1: ElementRef;
  @ViewChild('chart2', { static: false }) chartGraphic2: ElementRef;
  @ViewChild('chart3', { static: false }) chartGraphic3: ElementRef;

  form: FormGroup;
  formSsta: FormGroup;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private solicitudesAsignadaService: SolicudesAsignadasService,
    private solicitudesService: SolcitudesService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {



  }

  ngOnInit(): void {
    this.getData(JSON.parse(localStorage.getItem("querySstTable")) || this.query).subscribe({
      complete: () => {
        // this.loadingData = false
      }
    })

    this.changeDetectorRef.detectChanges()
  }

  query: any[] = [];

  startFormSsta() {
    const queryBack = JSON.parse(localStorage.getItem("querySstTable"));
    if (queryBack) {
      this.query = queryBack;
    }

    // Extrae los valores de los filtros
    const transports = this.searchFilter("transporterIds");
    const alertSsta = this.searchFilter("alertSsta");
    const startDateSsta = this.searchFilter("startDateSsta");
    const endDateSsta = this.searchFilter("endDateSsta");
    const estadoSsta = this.searchFilter("estadoSsta");

    this.formSsta = new FormGroup({
      transporterIds: new FormControl(
        transports.value || ""
      ),
      alertSsta: new FormControl(alertSsta.value || ""),
      startDateSsta: new FormControl(
        startDateSsta.value ? new Date(startDateSsta.value) : ""
      ),
      endDateSsta: new FormControl(
        endDateSsta.value ? new Date(endDateSsta.value) : ""
      ),
      estadoSsta: new FormControl(
        estadoSsta.value || ""
      ),
    });

    this.setupFormControlSubscriptionDate("startDateSsta", "startDateSsta", "endDateSsta", [TabViewSstaTableBoard.GRAPHIC_TIME_SSTA,TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA, TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA],this.formSsta);
    this.setupFormControlSubscriptionDate("endDateSsta", "endDateSsta", "startDateSsta", [TabViewSstaTableBoard.GRAPHIC_TIME_SSTA,TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA, TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA],this.formSsta);
    this.setupFormControlSubscriptionArray("transporterIds", "transporterIds", [TabViewSstaTableBoard.GRAPHIC_TIME_SSTA,TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA],this.formSsta);
    this.setupFormControlSubscription("estadoSsta", "estadoSsta", [TabViewSstaTableBoard.GRAPHIC_TIME_SSTA,TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA],this.formSsta);
    this.setupFormControlSubscription("alertSsta", "alertSsta", [TabViewSstaTableBoard.GRAPHIC_TIME_SSTA], this.formSsta) 
  }

  startFormFilter() {
    const queryBack = JSON.parse(localStorage.getItem("querySstTable"));
    if (queryBack) {
      this.query = queryBack;
    }

    // Extrae los valores de los filtros
    const programmers = this.searchFilter("programmerIds");
    const alert = this.searchFilter("alert");
    const startDate = this.searchFilter("startDateSst");
    const endDate = this.searchFilter("endDateSst");
    const estadoSst = this.searchFilter("estadoSst");

    this.form = new FormGroup({
      programmerIds: new FormControl(
        programmers.value || ""
      ),
      alert: new FormControl(alert.value || ""),
      startDateSst: new FormControl(
        startDate.value ? new Date(startDate.value) : ""
      ),
      endDateSst: new FormControl(
        endDate.value ? new Date(endDate.value) : ""
      ),
      estadoSst: new FormControl(
        estadoSst.value || ""
      ),
    });

    this.setupFormControlSubscriptionDate("startDateSst", "startDateSst", "endDateSst", [TabViewSstTableBoard.GRAPHIC_TIME_SST, TabViewSstTableBoard.TABLE_PROGRAMMER_SST],this.form);
    this.setupFormControlSubscriptionDate("endDateSst", "endDateSst", "startDateSst", [TabViewSstTableBoard.GRAPHIC_TIME_SST,TabViewSstTableBoard.TABLE_PROGRAMMER_SST],this.form);
    this.setupFormControlSubscriptionArray("programmerIds", "programmerIds", [TabViewSstTableBoard.GRAPHIC_TIME_SST],this.form);
    this.setupFormControlSubscription("estadoSst", "estadoSst", [TabViewSstTableBoard.GRAPHIC_TIME_SST],this.form);
    this.setupFormControlSubscription("alert", "alert",[TabViewSstTableBoard.GRAPHIC_TIME_SST],this.form);
  }

  getData(query: any = []) {
    // localStorage.setItem("querySstTable", JSON.stringify(query));
    return of({
    }).pipe(
      tap({
        next: (data) => { },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }

      }),
      concatMap(() => this.getTransportistas()),
      concatMap(() => this.getProgramadores()),
      concatMap(() => concat(of(this.startFormFilter()))),
      concatMap(() => concat(of(this.startFormSsta()))),
      concatMap(() => concat(of(
        this.spinner = false
      ))),
      concatMap(() => of(this.changeViewSst(0))),
      // concatMap(() => of(this.changeViewSsta(0))),
      // concatMap(() => this.loadDataSst(JSON.parse(localStorage.getItem("querySstTable")) || this.query)),
    )
  }

  loadData() {
    if (this.value == 1 && !this.loadingDataSst) {
      this.loadingDataSst = true;
      return this.changeViewSst(this.tableViewSstActually || TabViewSstTableBoard.GRAPHIC_TIME_SST)
    } else if (this.value == 2 && !this.loadingDataSsta) {
      this.loadingDataSsta = true;
      return this.changeViewSsta(this.tableViewSstaActually || TabViewSstaTableBoard.GRAPHIC_TIME_SSTA)
    }
  }


  changeViewSst(tabViewTableBoard: TabViewSstTableBoard = TabViewSstTableBoard.GRAPHIC_TIME_SST, query: any = this.query) {
    this.tableViewSstActually = tabViewTableBoard;
    localStorage.setItem("querySstTable", JSON.stringify(query));
  
    const handleDataLoad = (spinner: boolean, mapKey: TabViewSstTableBoard, dataFunc: () => Observable<any>) => {
      if (spinner) {
        of({}).pipe(switchMap(dataFunc)).subscribe();
      } else if (this.myMap2.get(this.tableViewSstActually)?.has(mapKey)) {
        this.myMap2.get(this.tableViewSstActually).delete(mapKey);
        of({}).pipe(switchMap(dataFunc)).subscribe();
      }
    };
  
    switch (tabViewTableBoard) {
      case TabViewSstTableBoard.GRAPHIC_TIME_SST:
        handleDataLoad(this.spinnerGraficoTiempoSst, TabViewSstTableBoard.GRAPHIC_TIME_SST, () => this.dataTiempoProgramadoresTableroGeneralSst());
        break;
      case TabViewSstTableBoard.TABLE_PROGRAMMER_SST:
        handleDataLoad(this.spinnerTableroProgramadorSst, TabViewSstTableBoard.TABLE_PROGRAMMER_SST, () => this.tablaDataProgramadoresTableroGeneralSst());
        break;
      default:
        break;
    }
  }



  changeViewSsta(tabViewTableBoard: TabViewSstaTableBoard = TabViewSstaTableBoard.GRAPHIC_TIME_SSTA, query: any = this.query) {
    this.tableViewSstaActually = tabViewTableBoard;
    localStorage.setItem("querySstTable", JSON.stringify(query));
  
    const handleDataLoad = (spinner: boolean, mapKey: TabViewSstaTableBoard, dataFunc: () => Observable<any>) => {
      if (spinner) {
        of({}).pipe(switchMap(dataFunc)).subscribe();
      } else if (this.myMap3.get(this.tableViewSstaActually)?.has(mapKey)) {
        this.myMap3.get(this.tableViewSstaActually).delete(mapKey);
        of({}).pipe(switchMap(dataFunc)).subscribe();
      }
    };
  
    switch (tabViewTableBoard) {
      case TabViewSstaTableBoard.GRAPHIC_TIME_SSTA:
        handleDataLoad(this.spinnerGraficoTiempoSsta, TabViewSstaTableBoard.GRAPHIC_TIME_SSTA, () => this.dataTiempoTransportistasTableroGeneralSsta());
        break;
      case TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA:
        handleDataLoad(this.spinnerTableroTransportistaSsta, TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA, () => this.tablaDataTransportistasTableroGeneralSsta());
        break;
      case TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA:
        handleDataLoad(this.spinnerRechazadaAceptadaSsta, TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA, () => this.getGraficoDatarechazadasAceptadasTableroGeneralSsta());
        break;
      default:
        break;
    }
  }

  getTransportistas() {
    return this.userService.getByRol("transportista").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.transportistaFilter.push({ username: e.username, id: e.id });
            } else {
              this.transportistaFilter.push({
                username: (e.companyName || e.username),
                id: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }

  getProgramadores() {
    return this.userService.getByRol("PROGRAMADOR").pipe(tap({
      next: (data) => {
        data.forEach((e) => {
          if (e.azure) {
            this.programadoresFilter.push({ username: e.username, id: e.id });
          } else {
            this.programadoresFilter.push({ username: e.companyName, id: e.id });
          }
        });
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    })
    )
  }

  tablaDataTransportistasTableroGeneralSsta() {
    this.spinnerTableroTransportistaSsta = true;
    return this.solicitudesAsignadaService.dataTransportistasTableroGeneralSsta(this.query).pipe(
      tap({
        next: (data) => {
          this.sstsDataTransportistas = data;
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }, complete: () => {
          this.spinnerTableroTransportistaSsta = false;
          this.changeDetectorRef.detectChanges()
        }
      }),
      finalize(() => {

      })
    )
  }

  tablaDataProgramadoresTableroGeneralSst() {
    this.spinnerTableroProgramadorSst = true;
    return this.solicitudesService.dataProgramadoresTableroGeneralSst(this.query).pipe(tap({
      next: (data) => {
        this.sstsDataProgrammer = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        this.spinnerTableroProgramadorSst = false;
        this.loadingDataSst = false
        this.changeDetectorRef.detectChanges()
      }
    }),
      finalize(() => {
      })
    )
  }

  dataTiempoTransportistasTableroGeneralSsta() {
    this.spinnerGraficoTiempoSsta = true;

    return this.solicitudesAsignadaService.dataTiempoTransportistasTableroGeneralSsta(this.query).pipe(tap({
      next: (data) => {
        this.mesesTransportistas = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        this.spinnerGraficoTiempoSsta = false;
        // this.tiempoPromedioGraficoSsta()
      }
    }),
      finalize(() => {
      })
    )
  }

  dataTiempoProgramadoresTableroGeneralSst() {
    this.spinnerGraficoTiempoSst = true;
    return this.solicitudesService.dataTiempoProgramadoresTableroGeneralSst(this.query).pipe(tap({
      next: (data) => {
        this.mesesProgramadores = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        this.loadingDataSst = false
        this.spinnerGraficoTiempoSst = false;
        // this.tiempoPromedioGraficoSst()
      }
    }),
      finalize(() => {
      })
    )
  }


  //grafico ssta aceptada rechazadas -- INICIO
  getGraficoDatarechazadasAceptadasTableroGeneralSsta() {
    this.spinnerRechazadaAceptadaSsta = true;
    return this.solicitudesAsignadaService.rechazadasAceptadasTableroGeneralSsta(this.query).pipe(tap({
      next: (data) => {
        this.transportistas = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        this.spinnerRechazadaAceptadaSsta = false;
      }
    }),
      finalize(() => {
      })
    )
  }

  private setupFormControlSubscriptionArray(
    controlName: string,
    filterName: string,
    filterLoading?: any,
    form?:any
  ) {

    form.get(controlName).valueChanges.pipe(debounceTime(500)).subscribe({
      next: (selectedValue) => {
        this.deleteFilter(filterName);
        if (!selectedValue.includes(null)) {
          this.query.push({
            col: filterName,
            value: selectedValue.length > 0 ? selectedValue : "",
          });

          if (this.value == 1) {
            filterLoading.forEach(element => {
              this.myMap2.set(element, new Set(this.setFilterSst(filterName)))
            });

            this.changeViewSst(this.tableViewSstActually)
          } else {
            filterLoading.forEach(element => {
              this.myMap3.set(element, new Set(this.setFilterSsta(filterName)))
            });
            this.changeViewSsta(this.tableViewSstaActually)
          }
        }
      },
    });
  }

  // Método para configurar suscripciones a cambios en un control del formulario
  private setupFormControlSubscription(
    controlName: string,
    filterName: string,
    filterLoading?: any,
    form?:any
  ) {
    form.get(controlName).valueChanges.pipe(debounceTime(300)).subscribe({
      next: (selectedValue) => {
        this.deleteFilter(filterName);
        if (selectedValue) {
          this.query.push({ col: filterName, value: selectedValue });
        }
        if (this.value == 1) {
          filterLoading.forEach(element => {
            this.myMap2.set(element, new Set(this.setFilterSst(filterName)))
          });

          this.changeViewSst(this.tableViewSstActually)
        } else {
          filterLoading.forEach(element => {
            this.myMap3.set(element, new Set(this.setFilterSsta(filterName)))
          });
          this.changeViewSsta(this.tableViewSstaActually)
        }
        // this.reloadData(filterLoading)
      },
    });
  }

   // Método para configurar suscripciones a cambios en un control de fecha del formulario
   private setupFormControlSubscriptionDate(
    controlName: string,
    filterName: string,
    correspondingControl: string,
    filterLoading?:any,
    form?: any
  ) {
    form.get(controlName).valueChanges.pipe(debounceTime(500)).subscribe({
      next: (selectedValue) => {
        this.deleteFilter(filterName);
        if (selectedValue) {
          this.query.push({ col: filterName, value: selectedValue });
        }
        if (
          this.searchFilter(filterName) &&
          this.searchFilter(correspondingControl)
        ) {
          if (this.value == 1) {
            filterLoading.forEach(element => {
              this.myMap2.set(element, new Set(this.setFilterSst(filterName)))
            });

            this.changeViewSst(this.tableViewSstActually)
          } else {
            filterLoading.forEach(element => {
              this.myMap3.set(element, new Set(this.setFilterSsta(filterName)))
            });
            this.changeViewSsta(this.tableViewSstaActually)
          }
          // this.reloadData(filterLoading)
        }
      },
    });
  }

  setFilterSst(filterName) {
    const array = []
    switch (filterName) {
      case "alert":
        array.push(TabViewSstTableBoard.GRAPHIC_TIME_SST)
        break;
      case "programmerIds":
        array.push(TabViewSstTableBoard.GRAPHIC_TIME_SST)
        break;
      case "estadoSst":
        array.push(TabViewSstTableBoard.GRAPHIC_TIME_SST)
        break;
      case "startDateSst":
        array.push(TabViewSstTableBoard.GRAPHIC_TIME_SST, TabViewSstTableBoard.TABLE_PROGRAMMER_SST)
        break;
      case "endDateSst":
        array.push(TabViewSstTableBoard.GRAPHIC_TIME_SST, TabViewSstTableBoard.TABLE_PROGRAMMER_SST)
        break;
      default:
        break;
    }
    return [...array]
  }

  setFilterSsta(filterName) {
    const array = []
    switch (filterName) {
      case "alertSsta":
        array.push(TabViewSstaTableBoard.GRAPHIC_TIME_SSTA)
        break;
      case "transporterIds":
        array.push(TabViewSstaTableBoard.GRAPHIC_TIME_SSTA, TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA, TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA)
        break;
      case "estadoSsta":
        array.push(TabViewSstaTableBoard.GRAPHIC_TIME_SSTA, TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA, TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA)
        break;
        case "startDateSsta":
          array.push(TabViewSstaTableBoard.GRAPHIC_TIME_SSTA, TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA, TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA)
          break;
        case "endDateSsta":
          array.push(TabViewSstaTableBoard.GRAPHIC_TIME_SSTA, TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA, TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA)
          break;
      default:
        break;
    }
    return [...array]
  }

 

  clearDate(startDate:string,endDate:string,form:any) {
    form.get(startDate).setValue(null)
    form.get(endDate).setValue(null)
    this.deleteFilter(startDate);
    this.deleteFilter(endDate);

    if (!form.get(startDate).value && !form.get(endDate).value) {
      if(this.value == 1){
        this.myMap2.set(TabViewSstTableBoard.GRAPHIC_TIME_SST, new Set(this.setFilterSst(startDate)))
        this.myMap2.set(TabViewSstTableBoard.TABLE_PROGRAMMER_SST, new Set(this.setFilterSst(startDate)))
        this.changeViewSst(this.tableViewSstActually)
      }else{
        this.myMap3.set(TabViewSstaTableBoard.GRAPHIC_TIME_SSTA, new Set(this.setFilterSsta(startDate)))
        this.myMap3.set(TabViewSstaTableBoard.TABLE_TRANSPORT_SSTA, new Set(this.setFilterSsta(endDate)))
        this.myMap3.set(TabViewSstaTableBoard.GRAPHIC_REJECTED_ACCEPTED_SSTA, new Set(this.setFilterSsta(endDate)))
        this.changeViewSsta(this.tableViewSstaActually)
      }
    }
  }

  clearStatus(filter: string, filterLoading?: TypeFilterLoadTableBoard) {
    this.form.get(filter).setValue(null)

    // this.reloadData(filterLoading)
  }

  // Método para configurar suscripciones a cambios en un control de array del formulario


  deleteFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }
  }

  searchFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      return find;
    }
    return false;
  }

  searchLocalStorageSst(data: any) {
    const filter = [];

    if (data && data.user) {
      filter.push({ "col": "programmerId", "value": data.user })
    }

    if (data && data.status) {
      filter.push({ "col": "statuses", "value": [data.status] })
    }

    localStorage.setItem('querySst', JSON.stringify(filter))

    this.router.navigate(['/solicitudes'])
  }

  searchLocalStorageSsta(data: any) {
    const filter = [];

    if (data && data.user) {
      filter.push({ "col": "transporterId", "value": data.user })
    }

    if (data && data.status) {
      filter.push({ "col": "statuses", "value": [data.status] })
    }

    localStorage.setItem('querySsta', JSON.stringify(filter))

    this.router.navigate(['/solicitudes-asignadas'])
  }
}

