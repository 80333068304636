import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { DespositosService } from "src/app/core/services/depositos/despositos.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SstService } from "src/app/services/sst/sst.service";
import { Package } from "src/app/shared/models/sst/package";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { Destino, Origen } from "src/app/shared/models/sst/solicitudes";

@Component({
  selector: "app-dividir-sst",
  templateUrl: "./dividir-sst.component.html",
  styleUrls: ["./dividir-sst.component.scss"],
})
export class DividirSstComponent implements OnInit {
  alphabet: string[] = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  destinos: Destino[] = [{ description: "Cerro Dragón, Chubut", id: 1 }];
  sst: Solicitud;

  originalSst: Solicitud;

  sstDivididas: any[] = [];

  rol: string;
  roles: string[] = [];
  idSst: any;
  spinner = true;
  detalles = [];
  displayHabilitaciones = false;
  url: string;
  origenes: Origen[] = [];
  bultosSelected: any = {
    whole: [],
    split: [],
  };
  mostrarDividida = "d-none";

  form: FormGroup;

  constructor(
    private sstService: SstService,
    private depositosService: DespositosService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.authService.actual().subscribe((actual) => {
      this.roles = actual.rolesnames;
    });
    this.rol = this.authService.queryRole();
    this.depositosService.getAll().subscribe((res) => {
      res.list.forEach((d) => {
        this.origenes.push({ description: d.name, id: d.id });
      });
      this.origenes.push({ description: "Otro", id: 0 });
    });
  }

  ngOnInit(): void {
    this.url = this.router.url.split("/", 2)[1];
    let { id } = this.activatedRoute.snapshot.params;
    this.idSst = id;
    this.solcitudService.getById(id).subscribe((res) => {
      this.originalSst = res;
      this.sst = res;
      if (this.originalSst) {
        this.updateForm();

        this.disabledChecksSubdivide();
      }
      this.spinner = false;
    });
    this.habilitarDividirSolicitud();
    this.habilitarGuardarCambios();
    this.hiddenDividirSolicitud();
  }

  updateForm() {
    this.sortPackageSst();

    this.form = new FormGroup({
      subdivideArray: new FormArray([]),
    });

    for (let packageBulto of this.sst.packages) {
      this.subdivideArray.push(this.addPackage(packageBulto));
    }

    this.disabledChecksSubdivide();
  }

  addPackage(packageBulto: Package) {
    return new FormGroup({
      idPackage: new FormControl(packageBulto.id, [Validators.required]),
      checkSeparate: new FormControl(false),
      checkSubdivide: new FormControl(false),
      quantity: new FormControl(1, [
        Validators.min(1),
        Validators.max(parseInt(packageBulto.description)),
        Validators.required,
      ]),
    });
  }

  deletePackage(packageBulto: Package) {
    const indexAEliminar = this.subdivideArray.controls.findIndex(
      (control) => control.get("idPackage").value === packageBulto.id
    );
    this.subdivideArray.removeAt(indexAEliminar);
  }

  sortPackageSst() {
    // if (this.sst.packages.length >= 1) {
    //   this.sst.packages.sort((a, b) => a.id - b.id);
    // }
  }

  get subdivideArray() {
    return this.form.get("subdivideArray") as FormArray;
  }

  disabledDividirSolicitudButton = false;
  habilitarDividirSolicitud() {
    if (
      this.bultosSelected.whole.length <= 0 &&
      this.bultosSelected.split.length <= 0
    ) {
      this.disabledDividirSolicitudButton = true;
    } else if (this.form?.valid) {
      this.disabledDividirSolicitudButton = false;
    } else {
      this.disabledDividirSolicitudButton = true;
    }
  }

  disabledDividirGuardarCambiosButton = false;
  habilitarGuardarCambios() {
    if (this.sstDivididas.length <= 0) {
      this.disabledDividirGuardarCambiosButton = true;
    } else {
      this.disabledDividirGuardarCambiosButton = false;
    }
  }

  hiddenDividirSolicitud() {
    return this.mostrarDividida;
  }

  disabledChecksSubdivide() {
    for (let control of this.subdivideArray.controls) {
      control.get("quantity").disable();
    }
  }

  seleccionarBultoEntero(
    action: string,
    event: any,
    packageBulto: Package,
    index: any
  ) {
    let findBulto: Package = this.bultosSelected.whole.find(
      (bulto: Package) => bulto.id == packageBulto.id
    );

    let checkSeparate = this.getCheckSeparateAllPackageIndex(index);
    let checkSubdivide = this.getCheckSubDividePackageIndex(index);

    switch (action) {
      case "all":
        checkSeparate.value
          ? checkSubdivide.disable()
          : checkSubdivide.enable();

        if (findBulto && event.checked === false && !checkSeparate.value) {
          this.deleteBultoSelectedWhole(packageBulto, index);
        } else if (event.checked === true && !findBulto) {
          if (this.getLimitPackageWhole()) {
            this.addBultoWhole(packageBulto);
          } else {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "No puede seleccionar todos",
              detail: "No puede dejar la solicitud original sin bultos",
            });
            this.changeCheckSeparateValueFalse(index);
            checkSubdivide.enable();
            this.habilitarGuardarCambios();
          }
        }
        break;

      case "part":
        checkSubdivide.value ? checkSeparate.disable() : checkSeparate.enable();
        break;
    }

    this.habilitarDividirSolicitud();
    this.habilitarGuardarCambios();
  }

  seleccionarBultoDividiendo(
    action: string,
    event: any,
    packageBulto: Package,
    index: any
  ) {
    let findBulto: Package = this.bultosSelected.split.find(
      (bulto: Package) => bulto.id == packageBulto.id
    );

    let checkSeparate = this.getCheckSeparateAllPackageIndex(index);
    let checkSubdivide = this.getCheckSubDividePackageIndex(index);

    switch (action) {
      case "all":
        checkSeparate.value
          ? checkSubdivide.disable()
          : checkSubdivide.enable();

        if (findBulto && event.checked === false && !checkSeparate.value) {
          this.deleteBultoSelectedWhole(packageBulto, index);
        }
        break;
      case "part":
        checkSubdivide.value ? checkSeparate.disable() : checkSeparate.enable();
        if (findBulto && event.checked === false && !checkSubdivide.value) {
          this.deleteBultoSelectedSplit(findBulto, index);
          this.changeCheckSplitValueFalse(index);
        } else if (
          !findBulto &&
          event.checked === true &&
          this.validBultoSplitPackage(packageBulto, index) &&
          (this.getLimitSplit() || this.getLimitPackageWhole())
        ) {
          this.addBultoSplit(packageBulto);
        } else {
          checkSeparate.enable();
        }
        break;
    }

    this.habilitarDividirSolicitud();
    this.habilitarGuardarCambios();
  }

  addBultoWhole(packageNew: Package) {
    this.bultosSelected.whole.push(packageNew);
  }

  deleteBultoSelectedWhole(packageDeleted: any, index: any) {
    let exist = this.bultosSelected.whole.indexOf(packageDeleted);
    this.bultosSelected.whole.splice(exist, 1);
  }

  addBultoSplit(packageNew: Package) {
    this.bultosSelected.split.push(packageNew);
  }

  deleteBultoSelectedSplit(packageDeleted: any, index: any) {
    let exist = this.bultosSelected.split.indexOf(packageDeleted);
    this.bultosSelected.split.splice(exist, 1);
  }

  validBultoSplitPackage(packageBulto: Package, index: any) {
    if (parseInt(packageBulto.description) > 1) {
      this.subdivideArray.controls[index].get("quantity").enable();
      return true;
    } else {
      this.messageService.add({
        key: "msg",
        severity: "error",
        summary: "No se puede separar",
        detail: "La cantidad de bultos no se puede separar más",
      });
      this.subdivideArray.controls[index].get("checkSubdivide").setValue(false);
      this.subdivideArray.controls[index].get("checkSeparate").setValue(false);
      this.subdivideArray.controls[index].get("quantity").disable();
      return false;
    }
  }

  getCheckSubDividePackageIndex(index: any) {
    return this.subdivideArray.controls[index].get("checkSubdivide");
  }

  getCheckSeparateAllPackageIndex(index: any) {
    return this.subdivideArray.controls[index].get("checkSeparate");
  }

  changeCheckSeparateValueFalse(index: any) {
    this.subdivideArray.controls[index].get("checkSeparate").setValue(false);
  }

  changeCheckSplitValueFalse(index: any) {
    this.subdivideArray.controls[index].get("checkSubdivide").setValue(false);
    this.subdivideArray.controls[index].get("quantity").setValue(1);
    this.subdivideArray.controls[index].get("quantity").disable();
  }

  eliminarSolicitudDividida(sstDividida: Solicitud, indice: number) {
    this.habilitarDividirSolicitud();

    for (let i = 0; i < sstDividida.packages.length; i++) {
      let find = this.sst.packages.find((pack: Package) => {
        return pack.id == sstDividida.packages[i].id;
      });
      let exist = this.sst.packages.indexOf(find);
      for (let j = 0; j < this.sst.packages.length; j++) {
        if (
          this.sst.packages[j].id === sstDividida.packages[i].id &&
          exist != -1
        ) {
          this.sst.packages[j].divisor = null;
          this.sst.packages[j].description = String(
            parseInt(this.sst.packages[j].description) +
              parseInt(sstDividida.packages[i].divisor)
          );

          this.subdivideArray.controls.forEach((control, index) => {
            if (this.sst.packages[j].id === control.get("idPackage").value) {
              this.changeCheckSplitValueFalse(index);
            }
          });
        }
      }
      if (exist == -1) {
        this.sst.packages.push(sstDividida.packages[i]);
      }
    }

    let exist = this.sstDivididas.indexOf(sstDividida);
    if (exist != -1) {
      this.sstDivididas.splice(exist, 1);
    }

    if (this.sstDivididas.length <= 0) {
      this.mostrarDividida = "d-none";
    }
    this.habilitarDividirSolicitud();
    this.habilitarGuardarCambios();

    this.messageService.add({
      key: "msg",
      severity: "success",
      summary: "Eliminado",
      detail: `La división fue eliminada`,
    });

    this.updateForm()
  }

  dividirSolicitud() {
    if (!this.disabledDividirSolicitudButton) {
      let nuevaSolicitud: Solicitud;
      if (
        this.bultosSelected.whole.length > 0 ||
        this.bultosSelected.split.length > 0
      ) {
        nuevaSolicitud = JSON.parse(JSON.stringify(this.sst));
        nuevaSolicitud.packages = JSON.parse(
          JSON.stringify(
            this.bultosSelected.whole.concat(this.bultosSelected.split)
          )
        );

        for (let packageItem of nuevaSolicitud.packages) {
          for (let control of this.subdivideArray.controls) {
            if (control.get("idPackage").value == packageItem.id) {
              if (control.get("checkSubdivide").value == true) {
                packageItem.divisor = control.get("quantity").value;

                this.changeMaxQuantityValidator(
                  control.get("quantity"),
                  parseInt(packageItem.description) -
                    parseInt(packageItem.divisor)
                );
                packageItem.description = packageItem.divisor;
                control.get("checkSubdivide").setValue(false);
                control.get("checkSeparate").enable();
                control.get("quantity").disable();
              } else if (control.get("checkSeparate").value == true) {
                control.get("checkSeparate").setValue(false);
                let find = this.sst.packages.find((pack: Package) => {
                  return pack.id == packageItem.id;
                });
                this.deletePackage(packageItem);

                let exist = this.sst.packages.indexOf(find);
                this.sst.packages.splice(exist, 1);
              }
            }
          }
        }

        for (let packageItem of this.sst.packages) {
          for (let packageSplit of nuevaSolicitud.packages) {
            if (packageItem.id === packageSplit.id) {
              packageItem.description = String(
                parseInt(packageItem.description) -
                  parseInt(packageSplit.divisor)
              );
              if (parseInt(packageItem.description) <= 0) {
                let exist = this.sst.packages.indexOf(packageItem);
                this.deletePackage(packageItem);
                this.sst.packages.splice(exist, 1);
              }
            }
          }
        }

        this.sstDivididas.push(nuevaSolicitud);

        this.bultosSelected.whole = [];
        this.bultosSelected.split = [];
        this.mostrarDividida = "";
        // this.updateForm()
        this.habilitarDividirSolicitud();
        this.habilitarGuardarCambios();
      }
    }
  }

  guardarCambios() {
    if (!this.disabledDividirGuardarCambiosButton) {
      let bultos = [];
      for (let i = 0; i < this.sstDivididas.length; i++) {
        let sst = {sst : []};
        for (let j = 0; j < this.sstDivididas[i].packages.length; j++) {
          let bulto: any = this.sstDivididas[i].packages[j] || "";
          sst.sst.push({ packageId: bulto.id, divisor: bulto?.divisor || 0 });
        }
        if (sst.sst.length > 0) {
          bultos.push(sst);
        }
      }

      this.solcitudService.dividirSolicitud(this.sst.id, bultos).subscribe(
        (data) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Confirmado",
            detail: "¡La solicitud ha sido dividida con exito!",
          });
          this.router.navigate(["/solicitudes"]);
        },
        (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        }
      );
    }
  }

  changeMaxQuantityValidator(formControl: AbstractControl, quantity: any) {
    const currentValidations = formControl.validator;

    const newValidations = [currentValidations, Validators.max(quantity)];

    formControl.setValidators(newValidations);

    formControl.updateValueAndValidity();
  }

  getLimitPackageWhole() {
    let packageLength = 0;
    for (let sst of this.sstDivididas) {
      for (sst of sst.packages) {
        packageLength += 1;
      }
    }

    return !(this.bultosSelected.whole.length + 1 == this.sst.packages.length);
  }

  getLimitSplit() {
    let available = false;
    for (let packageItem of this.sst.packages) {
      if (parseInt(packageItem.description) > 1) {
        available = true;
        break;
      }
    }
    return available;
  }
}
