import { Component, OnInit } from "@angular/core";
import {
  MessageService,
  ConfirmationService,
  ConfirmEventType,
} from "primeng/api";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ExcelService } from "src/app/core/services/documentos/excel.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { User } from "src/app/shared/models/sst/solicitudes";

@Component({
  selector: "app-report-ssta-recepcion-hasta-asignacion",
  templateUrl: "./report-ssta-recepcion-hasta-asignacion.component.html",
  styleUrls: ["./report-ssta-recepcion-hasta-asignacion.component.scss"],
})
export class ReportSstaRecepcionHastaAsignacionComponent implements OnInit {
  reportSst: any[] = [];

  users: User[] = [];

  tiempoTotal: string = "";

  querySolicitudAsignada: any[] = [];

  cols: any[] = [];

  spinner = true;

  rol: string;

  statuses: any[];
  transportistas: User[] = [];
  ordenSSTA: any[];

  roles: string[] = [];

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private solicitudesAsignadas: SolicudesAsignadasService,
    private excelService: ExcelService
  ) {
  }

  ngOnInit(): void {
    this.statuses = [
      { label: "Pendiente", value: "PENDING" },
      { label: "Aceptada", value: "ACCEPTED" },
      { label: "Rechazada", value: "REJECTED" },
      { label: "Cancelada", value: "CANCELLED" },
      { label: "Eliminada", value: "DELETED" },
      { label: "Finalizada", value: "FINISHED" },
      { label: "Informada", value: "INFORMED" },
      { label: "En Curso", value: "IN_PROGRESS" },
    ];

    this.ordenSSTA = [
      { label: "Por Fecha", value: "DATE" },
      { label: "Por Número SSTA", value: "ID" },
    ];

    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });

  }

  getData() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.roles = data.rolenames
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
        }
      }),
      concatMap(() => this.getTransportistas()),
      concatMap(() => this.getReportSstaTiempoPromedioHastaSuAsignacion()),
      concatMap(() => this.getReportSstaTiempoDeRecepcionHastaSuAsignacion()),
    )
  }

  getTransportistas() {
    return this.userService.getByRol("transportista").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.transportistas.push({ name: e.username, code: e.id });
            } else {
              this.transportistas.push({
                name: (e.companyName || e.username),
                code: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }

  getReportSstaTiempoPromedioHastaSuAsignacion() {
    return this.solicitudesAsignadas
      .reportSstaTiempoPromedioHastaSuAsignacion(this.querySolicitudAsignada)
      .pipe(
        tap({
          next: (data) => {
            this.tiempoTotal = data;
          },
          error:
            (error) => {
              this.messageService.add({
                key: "msg",
                severity: "error",
                summary: "Error",
                detail: error.error ? error.error.message : "Ups! ocurrio un error",
              });
            }
        })
      )
  }

  getReportSstaTiempoDeRecepcionHastaSuAsignacion() {
    return this.solicitudesAsignadas
      .reportSstaTiempoDeRecepcionHastaSuAsignacion(this.querySolicitudAsignada)
      .pipe(tap({
        next: (data) => {
          this.users = data;

        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      }))
  }

  transportistaSolicitudAsignada = "";
  minDateValue = new Date();
  startDateSolicitud = "";
  endDateSolicitud = ""

  filtrosSolicitudAsignada() {
    this.querySolicitudAsignada = [];

    if (this.startDateSolicitud) {
      this.minDateValue = new Date(this.startDateSolicitud);
    }

    if (this.startDateSolicitud && this.endDateSolicitud) {
      this.querySolicitudAsignada.push({
        col: "startDate",
        value: this.startDateSolicitud,
      });
      this.querySolicitudAsignada.push({
        col: "endDate",
        value: this.endDateSolicitud,
      });
    }

    if (this.transportistaSolicitudAsignada) {
      this.querySolicitudAsignada.push({
        col: "transporterId",
        value: this.transportistaSolicitudAsignada,
      });
    }
    if (this.querySolicitudAsignada.length > 0) {
      this.spinner = true;
    }
    this.getReportSstaTiempoPromedioHastaSuAsignacion().subscribe();
    this.getReportSstaTiempoDeRecepcionHastaSuAsignacion().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  descargarExcel() {
    this.excelService.descargarTiempoSsta(this.querySolicitudAsignada).subscribe(
      (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = `reporte-tiempo-transportista-SSTA.xlsx`;
        link.click();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
