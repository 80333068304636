export class SolicitudAsignada {
    expressService: boolean;
    programmingDate: string;
    requests: any[] = [];
    transporter: number;
    transports = [];
    id?: Number;
    status: string;
    transportGuides:any[] = []
    finishDateTime:any;
    assignedDateTime:Date;
    pickupDate?:Date;
}