<div class="container-fluid min-width-500 p-0">
  <div class="header">
    <h2>Reportes</h2>
  </div>
  <p-card>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br />
      <span class="text-center">Cargando...</span>
    </div>
    <div *ngIf="!spinner">

      <div class="row">
        <div class="col-6">
          <label class="">Tiempo total: <span>{{tiempoTotal}}</span></label>
        </div>
        <div class="col-6">
          <button class="p-button-lg float-right" pButton pRipple label="Volver" routerLink="/reportes"></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="pr-5">Transportista <span></span> </label><br /><p-dropdown [filter]="true"
            [options]="transportistas" [(ngModel)]="transportistaSolicitudAsignada" placeholder="-- Seleccione --"
            optionLabel="name" optionValue="code" [showClear]="true" [style]="{ width: '100%' }"
            (onChange)="filtrosSolicitudAsignada()"></p-dropdown>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <label class="">Fecha desde:<span></span></label>
          <br />
          <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitudAsignada()" [(ngModel)]="startDateSolicitud"
            inputId="basic" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"
            (onSelect)="filtrosSolicitudAsignada()" showIcon="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
            yearRange="1970:2050">
            <ng-template pTemplate="header">
              <h4 class="text-center">Desde</h4>
            </ng-template>
          </p-calendar>
        </div>
        <div class="col-6">
          <label class="">Fecha hasta:<span></span></label>
          <br />
          <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitudAsignada()" [(ngModel)]="endDateSolicitud"
            inputId="basic" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"
            (onSelect)="filtrosSolicitudAsignada()" showIcon="true" [minDate]="minDateValue" dateFormat="dd/mm/yy"
            [yearNavigator]="true" yearRange="1970:2050">
            <ng-template pTemplate="header">
              <h4 class="text-center">Hasta</h4>
            </ng-template>
          </p-calendar>
        </div>
      </div>
      <p-table #myTab [value]="users" [scrollable]="true" scrollHeight="400px" styleClass="p-datatable-striped mt-5"
        [tableStyle]="{ 'min-width': '50rem' }" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th>
              Nombre
            </th>
            <th>
              Razón Social
            </th>
            <th>
              CUIT
            </th>
            <th>Estado</th>
            <th>Tiempo</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-users>
          <tr>
            <td class="palabra">{{ users.user.username }}</td>
            <td class="palabra">{{ users.user.companyName }}</td>
            <td class="palabra">{{ users.user.taxPayerId }}</td>
            <td *ngIf="users.user.state == 'ACTIVE'" class="text-success palabra">
              {{ users.user.state }}
            </td>
            <td *ngIf="users.user.state != 'ACTIVE'" class="text-danger palabra">
              {{ users.user.state }}
            </td>
            <td class="">{{ users.report }}</td>
          </tr>
        </ng-template>
      </p-table>
      <div class="d-flex justify-content-center flex-wrap mt-5" *ngIf="!hasRole('VISUALIZER')">
        <button pButton pRipple type="button" label="Descargar Reporte" class="p-button-secondary"
          (click)="descargarExcel()"></button>
      </div>
    </div>

  </p-card>
</div>