<div class="container min-width-500">
  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  <div *ngIf="!spinner && alertas && alertas.length > 0; else vacio">
    <div class="container">
      <div class="row text-center">
        <div class="col-sm-12 col-lg-6" *ngFor="let alerta of alertas">
          <p-card
            *ngIf="alerta.request"
            [style]="{
              width: '23rem',
              height: '23rem',
              'margin-bottom': '2em'
            }"
          >
            <ng-template pTemplate="header" class="">
              <div class="mt-5">
                <h1 class="title mt-5 pl-3 pr-3">
                  {{ alerta.type }} Nº {{ alerta.request.requestId }}
                </h1>
                <div class="state mt-5 pl-3 pr-3">
                  <p *ngIf="alerta.request.status == 'CANCELLED'">CANCELADA</p>
                  <p *ngIf="alerta.request.status == 'DELETED'">ELIMINADA</p>
                  <p *ngIf="alerta.request.status == 'FINISHED'">FINALIZADA</p>
                  <p *ngIf="alerta.request.status == 'ASSIGNED'">ASIGNADA</p>
                  <p *ngIf="alerta.request.status == 'REJECTED'">RECHAZADA</p>
                  <p *ngIf="alerta.request.status == 'PENDING'">PENDIENTE</p>
                  <p *ngIf="alerta.request.status == 'NEW_DATE_PROPOSED'">
                    Nueva Fecha Propuesta
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="title">
              <br />
              <h6 class="text-left">&nbsp;</h6>
            </ng-template>
            <ng-template pTemplate="content">
              <label
                >Fecha Solicitud:
                <span>
                  {{ alerta.request.creationDate | date : "dd/MM/YYYY" }}</span
                > </label
              ><br />
              <label
                >Solicitante:
                <span> {{ alerta.userRequestingName }}</span> </label
              ><br />
              <label
                >Fecha Necesidad del Transporte:
                <span>
                  {{
                    alerta.request.requirementDate | date : "dd/MM/YYYY"
                  }}</span
                > </label
              ><br />
              <label
                >Origen: <span> {{ alerta.request.originName }}</span> </label
              ><br />
              <label
                >Destino:
                <span> {{ alerta.request.destinationName }}</span> </label
              ><br />
            </ng-template>
            <ng-template pTemplate="footer">
              <div class="buttons">
                <p-button
                  label=""
                  routerLink="/details-{{ alerta.type | lowercase }}/{{
                    alerta.request.id
                  }}"
                  icon="pi pi-eye"
                  styleClass="p-button-sm"
                  pTooltip="Ver Detalles"
                >
                </p-button>
                <p-button
                  label=""
                  (click)="deleteSst(alerta)"
                  icon="pi pi-trash"
                  styleClass="p-button-sm"
                  pTooltip="Eliminar Alerta"
                >
                </p-button>
                <p-button
                  label=""
                  (click)="asignarSst(alerta)"
                  icon="bi bi-bus-front-fill"
                  styleClass="p-button-sm"
                  pTooltip="Asignar SST"
                >
                </p-button>
              </div>
            </ng-template>
          </p-card>
          <p-card
            *ngIf="alerta.assignedRequest"
            [style]="{
              width: '23rem',
              height: '23rem',
              'margin-bottom': '2em'
            }"
          >
            <ng-template pTemplate="header" class="">
              <div class="mt-5">
                <div class="row col-12 m-0 p-0">
                  <div class="col-6 m-0 p-0">
                    <h1
                      *ngIf="alerta.type == 'SST'"
                      class="title mt-5 pl-3 pr-3"
                    >
                      {{ alerta.type }} Nº {{ alerta.assignedRequest.id }}
                    </h1>
                    <h1
                      *ngIf="alerta.type == 'SSTA'"
                      class="title mt-5 pl-3 pr-3"
                    >
                      {{ alerta.type }} Nº {{ alerta.assignedRequest.id }}
                    </h1>
                    <h5
                      *ngIf="alerta.type == 'NEW_DATE_PROPOSED'"
                      class="title mt-5 pl-3 pr-3"
                    >
                      Fecha Propuesta
                    </h5>
                  </div>
                  <div class="col-6 m-0 p-0">
                    <div class="state mt-5 pl-3 pr-3">
                      <p *ngIf="alerta.assignedRequest.status == 'ACCEPTED'">
                        Aceptada
                      </p>
                      <p *ngIf="alerta.assignedRequest.status == 'CANCELLED'">
                        CANCELADA
                      </p>
                      <p *ngIf="alerta.assignedRequest.status == 'DELETED'">
                        ELIMINADA
                      </p>
                      <p *ngIf="alerta.assignedRequest.status == 'FINISHED'">
                        FINALIZADA
                      </p>
                      <p *ngIf="alerta.assignedRequest.status == 'ASSIGNED'">
                        ASIGNADA
                      </p>
                      <p *ngIf="alerta.assignedRequest.status == 'REJECTED'">
                        RECHAZADA
                      </p>
                      <p *ngIf="alerta.assignedRequest.status == 'PENDING'">
                        PENDIENTE
                      </p>
                      <p
                        *ngIf="
                          alerta.assignedRequest.status == 'ASSIGNMENT_PENDING'
                        "
                      >
                        PENDIENTE DE ASIGNACIÓN
                      </p>
                      <p
                        *ngIf="
                          alerta.assignedRequest.status ==
                          'AUTHORIZATION_PENDING'
                        "
                      >
                        PENDIENTE DE AUTORIZACIÓN
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="title">
              <h6 *ngIf="alerta.type == 'NEW_DATE_PROPOSED'" class="text-left">
                {{ alerta.date | date : "dd/MM/YYYY" }}
              </h6>
            </ng-template>
            <ng-template pTemplate="content">
              <label
                >Fecha Solicitud:
                <span>
                  {{
                    alerta.assignedRequest.creationDate | date : "dd/MM/YYYY"
                  }}</span
                > </label
              ><br />
              <label
                >Solicitante:
                <span> {{ alerta.userRequestingName }}</span> </label
              ><br />
              <label
                >Fecha Necesidad del Transporte:
                <span>
                  {{
                    alerta.assignedRequest.programmingDate | date : "dd/MM/YYYY"
                  }}</span
                > </label
              ><br />
              <label
                >Origen: <span> {{ alerta.origin }}</span> </label
              ><br />
              <label *ngIf="alerta.destinationName"
                >Destino: <span> {{ alerta.destinationName }}</span>
              </label>
              <label *ngIf="alerta.destination"
                >Destino: <span> {{ alerta.destination }}</span>
              </label>
              <button
                class="btn btn-success text-small"
                disabled
                *ngIf="alerta.message == 'NEW_DATE_PROPOSED_ACCEPTED'"
              >
                Fecha aceptada
              </button>
              <button
                class="btn btn-danger text-small"
                disabled
                *ngIf="alerta.message == 'NEW_DATE_PROPOSED_REJECTED'"
              >
                Fecha rechazada
              </button>
            </ng-template>
            <ng-template
              pTemplate="footer"
              class="pt-0"
              style="padding-top: 0px !important"
            >
              <div class="buttons p-0">
                <p-button
                  *ngIf="alerta.type != 'NEW_DATE_PROPOSED'"
                  label=""
                  (click)="deleteSst(alerta)"
                  icon="pi pi-trash"
                  styleClass="p-button-sm"
                  pTooltip="Eliminar Alerta"
                >
                </p-button>

                <p-button
                  *ngIf="
                    alerta.type == 'NEW_DATE_PROPOSED' || alerta.type == 'SSTA'
                  "
                  label=""
                  routerLink="/details-ssta/{{ alerta.assignedRequest.id }}"
                  icon="pi pi-eye"
                  styleClass="p-button-sm"
                  pTooltip="Ver Detalles"
                >
                </p-button>

                <p-button
                  *ngIf="alerta.type == 'NEW_DATE_PROPOSED'"
                  label=""
                  icon="pi pi-times"
                  styleClass="p-button-sm"
                  pTooltip="Rechazar Propuesta"
                  (click)="rechazarALerta(alerta)"
                >
                </p-button>
                <p-button
                  *ngIf="alerta.type == 'NEW_DATE_PROPOSED'"
                  label=""
                  icon="pi pi-check-circle"
                  styleClass="p-button-sm"
                  pTooltip="Aceptar Propuesta"
                  (click)="aceptarAlerta(alerta)"
                >
                </p-button>

                <p-button
                  *ngIf="
                    alerta.assignedRequest.status == 'AUTHORIZATION_PENDING'
                  "
                  label=""
                  icon="pi pi-times"
                  styleClass="p-button-sm"
                  pTooltip="Rechazar Servicio Urgente"
                  (click)="rechazarExpressServiceLider(alerta)"
                >
                </p-button>
                <p-button
                  *ngIf="
                    alerta.assignedRequest.status == 'AUTHORIZATION_PENDING'
                  "
                  label=""
                  icon="pi pi-check-circle"
                  styleClass="p-button-sm"
                  pTooltip="Aceptar Servicio Urgente"
                  (click)="aceptarExpressServiceLider(alerta)"
                >
                </p-button>
                <p-button
                  [loading]="loadingRejectSsta"
                  *ngIf="
                    hasRole('TRANSPORTER') &&
                    alerta.assignedRequest.status == 'PENDING'
                  "
                  label=""
                  icon="pi pi-times"
                  styleClass="p-button-sm"
                  pTooltip="Rechazar SSTA"
                  (click)="loadingRejectSsta ? '' :rechazarSstaTransportista(alerta,$event)"
                >
                </p-button>
                <p-button
                [loading]="loadingAcceptSsta"
                  *ngIf="
                    hasRole('TRANSPORTER') &&
                    alerta.assignedRequest.status == 'PENDING'
                  "
                  label=""
                  icon="pi pi-check-circle"
                  styleClass="p-button-sm"
                  pTooltip="Aceptar SSTA"
                  (click)="loadingAcceptSsta ? '' : aceptarSstaTransportista(alerta,$event)"
                >
                </p-button>

                <p-button
                  *ngIf="alerta.assignedRequest.status === 'REJECTED'"
                  label=""
                  routerLink="/actualizar-ssta/{{ alerta.assignedRequest.id }}"
                  icon="pi pi-pencil"
                  styleClass="p-button-sm"
                  pTooltip="Editar SSTA"
                >
                </p-button>
              </div>
            </ng-template>
          </p-card>
        </div>
      </div>
    </div>
  </div>

  <p-paginator
    *ngIf="alertas.length > 0"
    [rows]="size"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="[1, 2, 3]"
    (onPageChange)="paginate($event)"
  ></p-paginator>
</div>
<ng-template #vacio>
  <p-messages
    [closable]="false"
    [(value)]="mensajeVacio"
    [enableService]="false"
  ></p-messages>
</ng-template>

<p-confirmDialog
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
>
</p-confirmDialog>
<p-toast></p-toast>
