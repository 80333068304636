<p-toast position="top-right" key="msg"></p-toast>

<div class="container-fluid">
  <div class="row flex-nowrap" style="margin-right: -200px !important">
    <div
      class="col-auto w-100 col-md-3 col-xl-2 px-sm-2 px-0 sidebar-background"
      *ngIf="login()"
    >
      <div
        class="d-flex w-100 flex-column align-items-start align-items-sm-start pt-2 text-white min-vh-100"
      >
        <a
          href="/"
          class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
          ><span class="fs-5 px-3 d-sm-inline">Menu</span></a
        >
        <ul
          class="nav w-100 nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start px-3"
          id="menu"
        >
          <app-menu-general *ngIf="rol.length > 0" class="w-100"> </app-menu-general>
          <!-- <app-menu-usuario-interno
            class="w-100"
            *ngIf="rol == 'INTERNAL'"
          ></app-menu-usuario-interno>
          <app-menu-administrador
            class="w-100"
            *ngIf="rol == 'ADMIN'"
          ></app-menu-administrador>
          <app-menu-gerencial
            class="w-100"
            *ngIf="rol == 'MANAGER'"
          ></app-menu-gerencial>
          <app-menu-lider-de-planificacion
            class="w-100"
            *ngIf="rol == 'PLANNING_LEADER'"
          ></app-menu-lider-de-planificacion>
          <app-menu-programador-logistica
            class="w-100"
            *ngIf="rol == 'PROGRAMMER'"
          ></app-menu-programador-logistica>
          <app-menu-proveedor
            class="w-100"
            *ngIf="rol == 'SUPPLIER'"
          ></app-menu-proveedor>
          <app-menu-transportista
            class="w-100"
            *ngIf="rol == 'TRANSPORTER'"
          ></app-menu-transportista> -->
        </ul>
        <hr />

        <div class="d-flex px-1 text-white w-100 profile-background mt-auto">
          <!-- <div class="dropdown mt-auto pb-0">
            <p
              class="d-flex align-items-center text-white palabra mb-0 mt-2 mb-2"
              id="dropdownUser1"
            >
              <img
                src="./assets/img/profile.jpg"
                alt="hugenerd"
                width="45"
                height="45"
                style="object-fit: cover; border-radius: 12px"
              />
              <span class="d-sm-inline mx-3"
                >{{ user }}
                <a (click)="logout()"
                  ><i class="bx bx-log-out text-end" id="log_out"></i></a></span
              ><br />
            </p>
            <span *ngFor="let rolUser of rol">{{ rolUser }}<br /></span>
          </div> -->
          <div class="row">
            <div [ngClass]="{
              'col-3' : rol.length > 1,
              'col-4' : rol.length == 1
            }">
              <img
                src="./assets/img/profile.jpg"
                alt="hugenerd"
                width="45"
                height="45"
                class="mx-2"
                style="object-fit: cover; border-radius: 12px"
              />
            </div>
            <div class="col-8">
              <span class="mx-1 text-white" *ngFor="let rolUser of rol"
                ><small>{{ rolUser }}</small
                ><br
              /></span>
              <span class="d-sm-inline palabra mx-1"
                >{{ user }}
                <a (click)="logout()"
                  ><i class="bx bx-log-out text-end" id="log_out"></i></a></span
              ><br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col home_content m-0 p-0" id="home">
      <div class="body" style="width: 100%">
        <app-main></app-main>
      </div>
    </div>
  </div>
</div>

<!-- <div id="sidebar" class="sidebar active" *ngIf="login()">
  <div class="logo_content">
    <div class="logo">
      <div class="logo_name">Menu</div>
    </div>
    <i class="bx bx-menu" id="btn" (click)="click()"></i>
  </div>
  <ul class="nav_list">
    <app-menu-usuario-interno
      *ngIf="rol == 'INTERNAL'"
    ></app-menu-usuario-interno>
    <app-menu-administrador *ngIf="rol == 'ADMIN'"></app-menu-administrador>
    <app-menu-gerencial *ngIf="rol == 'MANAGER'"></app-menu-gerencial>
    <app-menu-lider-de-planificacion
      *ngIf="rol == 'Lider De Planificacion'"
    ></app-menu-lider-de-planificacion>
    <app-menu-programador-logistica
      *ngIf="rol == 'PROGRAMMER'"
    ></app-menu-programador-logistica>
    <app-menu-proveedor *ngIf="rol == 'SUPPLIER'"></app-menu-proveedor>
    <app-menu-transportista
      *ngIf="rol == 'TRANSPORTER'"
    ></app-menu-transportista>
  </ul>
  <div class="row col-2 fixed-bottom m-0 p-0 text-white" style="background-color: #1d1b31;">
    <div class="row">
        <div class="col-3">
            <img src="./assets/img/profile.jpg" class="img-fluid img-round" alt="profile image">
        </div>
        <div class="col">
            <div class="profile_details palabra">
              <div class="name_job">
                <p class="name palabra">{{ user }}</p>
                
                <p class="job palabra">{{ rol }} <a (click)="logout()"><i class="bx bx-log-out text-end" id="log_out"></i></a> </p>
              </div>
            </div>
          </div>
    </div>


  </div>
<div class="contruccion" id="constr">
  <div class="title-c">PAGINA EN CONSTRUCCIÓN</div>
</div>
<div class="home_content" id="home">
  <div class="body" style="width: 100%">
    <app-main></app-main>
  </div>
  <div class="footer">
    <div></div>
  </div>
</div> -->
