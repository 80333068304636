import { Component, Input, OnInit } from "@angular/core";
import { ConductoresService } from "src/app/core/services/transporte/conductores/conductores.service";
import { TractoresService } from "src/app/core/services/transporte/tractores/tractores.service";
import { TrailersService } from "src/app/core/services/transporte/trailers/trailers.service";
import { Conductor } from "src/app/shared/models/vehiculos/conductor";
import { Tractor } from "src/app/shared/models/vehiculos/tractor";
import { Trailer } from "src/app/shared/models/vehiculos/trailers";

@Component({
  selector: "app-popup-documentacion",
  templateUrl: "./popup-documentacion.component.html",
  styleUrls: ["./popup-documentacion.component.scss"],
})
export class PopupDocumentacionComponent implements OnInit {
  @Input() type;
  @Input() car;

  tractor:Tractor
  conductor:Conductor
  trailer:Trailer

  title:string
  link:string;

  constructor(
    private tractorService: TractoresService,
    private conductoService: ConductoresService,
    private trailerService: TrailersService
  ) {}

  ngOnInit(): void {
    switch (this.type) {
      case 'Tractor':
          this.title = this.type
        break;
      case 'Trailer':
        this.title = this.type

        break;
      case 'Conductor':
        this.title = this.type

        break;
    
      default:
        break;
    }
  }
}
