import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-transportista',
  templateUrl: './menu-transportista.component.html',
  styleUrls: ['./menu-transportista.component.scss'],
})
export class MenuTransportistaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
