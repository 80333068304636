<p-dialog header="Agregar Semirremolques / Acoplados" [(visible)]="displayNewSemirremolquesAcoplados">
    <app-popup-new-semirremolques-acoplados (eventHiddenPopUpSemirremolquesAcoplados)="
      cerrarPopUpNewSemirremolquesAcoplados($event)
    "></app-popup-new-semirremolques-acoplados>
</p-dialog>
<p-dialog [header]="'Documentación De ' + type" [style]="{ 'background-color': 'white', 'min-width': '50vw' }"
    [(visible)]="displayDocumentacion">
    <app-popup-documentacion [type]="type" [car]="car"></app-popup-documentacion>
</p-dialog>

<div class="card text-center" *ngIf="spinner">
    <span>
        <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
</div>

<div class="container min-width-500" *ngIf="!spinner">
    <div class="row mt-3 m-0">
        <div class="col">
        </div>
        <div class="col text-end">
            <p-fileUpload mode="basic" [files]="uploadedFiles" chooseLabel="Cargar unidades" accept=".xls, .xlsx"
                maxFileSize="1000000" (onSelect)="cargarVehiculosExcel($event)"></p-fileUpload>
        </div>
    </div>
    <p-card>
        <div class="row">
            <div class="col">
                <h1 class="title">Semirremolques / Acoplados</h1>
                <br />
            </div>
            <div class="col text-end">
                <button pButton pRipple type="button" label="Volver" class="p-button-secondary p-button-text"
                    routerLink="/transporte"></button>
                <button pButton pRipple type="button" label="Agregar" class="p-button-secondary p-button-text"
                    (click)="showDialogNewSemirremolquesAcoplados()"></button>
            </div>
        </div>
        <div class="row">
            <p-card class="col-4" *ngFor="let trailer of smirremolquesAcoplados; index as i" [style]="{
        width: '100%',
        height: '23rem',
        'margin-bottom': '2em'
      }">
                <div class="row">
                    <div class="col">
                        <h1 class="title">Acoplado {{ i + 1 }}</h1>
                    </div>
                    <div class="col text-end">
                        <div class="state">{{ trailer.state }}</div>
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="col m-0 p-0">
                        <label>Patente: <span> {{ trailer.patent }} </span> </label><br />
                        <label>Marca: <span> {{ trailer.brand }} </span> </label><br />
                        <label>Modelo: <span> {{ trailer.model }} </span> </label><br />
                        <div class="row m-0 p-0">
                            <div class="col m-0 p-0">
                                <label>Alto: <span> {{ trailer.height }} </span> </label><br />
                                <label>Ancho: <span> {{ trailer.width }} </span> </label><br />
                            </div>
                            <div class="col m-0 p-0">
                                <label>Largo: <span> {{ trailer.length }} </span> </label><br />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <p-button label="" (click)="showDialogDocumentacion('Trailer', trailer.id)"
                        pTooltip="Ver Documentación" icon="pi pi-file" styleClass="p-button-sm">
                    </p-button>
                    <p-button *ngIf="trailer.state != 'ACTIVE'" label="" icon="pi pi-check-circle"
                        styleClass="p-button-sm" pTooltip="Habilitar" (click)="habilitarTrailer(trailer.id)"></p-button>
                    <p-button *ngIf="trailer.state == 'ACTIVE'" label="" icon="pi pi-minus-circle
        " styleClass="p-button-sm" pTooltip="Deshabilitar" (click)="deshabilitarTrailer(trailer.id)"></p-button>
                </div>
            </p-card>
        </div>
        <div class="row">
            <div class="col">
                <p-divider></p-divider>
            </div>
        </div>
    </p-card>
</div>

<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-toast></p-toast>