import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ExcelService } from "src/app/core/services/documentos/excel.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import {
  Destino,
  Envio,
  Origen,
  solicitudes,
  User,
} from "src/app/shared/models/sst/solicitudes";
@Component({
  selector: 'app-report-sst-tiempo',
  templateUrl: './report-sst-tiempo.component.html',
  styleUrls: ['./report-sst-tiempo.component.scss']
})
export class ReportSstTiempoComponent implements OnInit {

  solicitudes: any[] = [];
  querySolicitud: any[] = [];

  programadores: User[] = [];

  spinner = true;

  rol: string;

  mensajeVacio: Message[];

  roles: string[] = [];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private solicitudesServices: SolcitudesService,
    private excelService: ExcelService
  ) {
  }

  ngOnInit(): void {
    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  getData() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.roles = data.rolenames
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
        }
      }),
      concatMap(() => this.getProgramadores()),
      concatMap(() => this.getListSolicitudes()),
    )
  }

  getProgramadores() {
    return this.userService.getByRol("PROGRAMADOR").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.programadores.push({ name: e.username, code: e.id });
            } else {
              this.programadores.push({
                name: (e.companyName || e.username),
                code: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      }))
  }

  getListSolicitudes() {
    return this.solicitudesServices
      .reporteSstTiempoHastaAsignacionPorProgramador(this.querySolicitud, this.pageSolicitudes, this.sizeSolicitudes)
      .pipe(
        tap({
          next: (data) => {
            this.solicitudes = data.list;
            this.totalRecordosSolicitudes = data.totalElements;
          }, error: (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error ? error.error.message : "Ups! ocurrio un error",
            });
          },
        })
      )
  }

  programador = "";
  minDateValue = new Date();
  startDateSolicitud = "";
  endDateSolicitud = ""

  filtrosSolicitud() {
    this.querySolicitud = [];
    if (this.startDateSolicitud) {
      this.minDateValue = new Date(this.startDateSolicitud);
    }

    if (this.startDateSolicitud && this.endDateSolicitud) {
      this.querySolicitud.push({
        col: "startDate",
        value: this.startDateSolicitud,
      });
      this.querySolicitud.push({
        col: "endDate",
        value: this.endDateSolicitud,
      });
    }
    if (this.programador) {
      this.querySolicitud.push({
        col: "programmerId",
        value: this.programador,
      });
    }
    if (this.querySolicitud.length > 0) {
      this.spinner = true;
    }
    this.getListSolicitudes().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  descargarReporteSst() {
    this.excelService.descargarTiempoSst(this.querySolicitud).subscribe(
      (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = `reporte-tiempo-programador-SST.xlsx`;
        link.click();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  //paginado --INICIO
  totalRecordosSolicitudes = 0;
  pageSolicitudes = 1;
  sizeSolicitudes = 10;

  paginateSolicitudes(event) {
    this.pageSolicitudes = event.page + 1;
    this.sizeSolicitudes = event.rows;
    this.getListSolicitudes().subscribe();
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }

}
