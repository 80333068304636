import { Component, OnInit } from "@angular/core";
import {
  MessageService,
  ConfirmationService,
  ConfirmEventType,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { UserService } from "src/app/core/services/user/user.service";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: 'app-list-programador',
  templateUrl: './list-programador.component.html',
  styleUrls: ['./list-programador.component.scss']
})
export class ListProgramadorComponent implements OnInit {

  programadores: User[] = [];

  statuses: any[];

  cols: any[] = [];

  spinner = true;

  rol: string;

  //paginado
  totalRecords = 0;

  page = 1;

  size = 10;
  //paginado


  userActual: User;
  roles: string[] = [];
  idUserActual = "";

  //filters
  query: any = [];

  users: any = [];
  //filters

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) {
    this.statuses = [
      { label: "Habilitado", value: 1 },
      { label: "Deshabilitado", value: 2 },
    ];

    this.rol = this.authService.queryRole();
  }

  async rolesInit() {
    this.userActual = await this.authService.actual2();
    this.idUserActual = (await this.authService.actual2()).id;
    this.roles = (await this.authService.actual2()).rolenames;
  }

  async parametros() {
    return new Promise((resolve, reject) => {
      let findIncludeInactive = this.query.find(
        (d) => d.col == "includeInactives"
      );
      if (findIncludeInactive) {
        let index = this.query.indexOf(findIncludeInactive);
        this.query.splice(index, 1);
      }
      if (this.idUserActual) {
        if (this.hasRole("ADMIN")) {
          this.query.push({ col: "includeInactives", value: true });
        } else {
          this.query.push({ col: "includeInactives", value: false });
        }
      }
      resolve("");
    });
  }

  async ngOnInit() {
    await this.rolesInit();
    await this.parametros();
    this.getList();
    this.getListFiltersUsers();

    this.cols = [
      { field: "nombre", header: "Nombre" },
      { field: "razonSocial", header: "Razón Social" },
      { field: "cuit", header: "CUIT" },
      { field: "estadoId", header: "Estado" },
    ];
  }

  getList() {
    this.userService
      .filterByRol("programador", this.page, this.size, this.query)
      .subscribe((data: any) => {
        this.programadores = data.list;
        this.totalRecords = data.totalElements;
        this.spinner = false;
      });
  }

  getListFiltersUsers() {
    this.userService
      .getAll(this.query, "programador")
      .subscribe((data: any) => {
        this.users = data;
      });
  }

  userSearch = "";

  filter(value?, col?) {
    let find = this.query.find((d) => d.col == col);
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }

    switch (col) {
      case "userId":
        this.userSearch = value;
        this.query.push({ col: col, value: this.userSearch });
        break;
      default:
        break;
    }
    this.getList();
  }

  paginate(event) {
    this.page = event.page + 1;
    this.size = event.rows;
    this.getList();
  }

  habilitar(id: string) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea habilitarlo?",
      header: "Confirmar ",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.userService.habilitarUser(id.toString()).subscribe(
          (resp) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Habilitado",
              detail: "El proveedor ha sido habilitado con exito",
            });
            this.spinner = true;
            this.getList();
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  deshabilitar(id: string) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea deshabilitarlo?",
      header: "Confirmar ",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.userService.deshabilitarUser(id.toString()).subscribe(
          (resp) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Deshabilitado",
              detail: "El proveedor ha sido deshabilitado con exito",
            });
            this.spinner = true;
            this.getList();
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  formatearCUIT(cuit: any): string {
    if (cuit) {

      const cuitComoCadena = cuit.toString();

      // Verificar que la longitud sea la correcta (11 dígitos)
      if (cuitComoCadena.length !== 11) {
        return cuit
      }

      // Formatear como "11-31412324-5"
      const primeraParte = cuitComoCadena.slice(0, 2);
      const segundaParte = cuitComoCadena.slice(2, 10);
      const terceraParte = cuitComoCadena.slice(10);

      return `${primeraParte}-${segundaParte}-${terceraParte}`;
    }

  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }

}
