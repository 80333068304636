import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { UserService } from "src/app/core/services/user/user.service";
import { User } from "src/app/shared/models/user/user";
import { TransportistaService } from "../../transportista/services/transportista.service";
@Component({
  selector: 'app-create-programador',
  templateUrl: './create-programador.component.html',
  styleUrls: ['./create-programador.component.scss']
})
export class CreateProgramadorComponent implements OnInit {

  form: FormGroup;

  user: User = new User();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private transportistaService: TransportistaService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      razonSocial: new FormControl("", [Validators.required]),
      cuit: new FormControl("", [Validators.required]),
      direccion: new FormControl("", [Validators.required]),
      telefono: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      usuario: new FormControl("", [Validators.required]),
      contraseña: new FormControl("", [Validators.required]),
    });
  }

  crearProgramador() {
    this.user.username = this.form.get("usuario").value;
    this.user.address = this.form.get("direccion").value;
    this.user.companyName = this.form.get("razonSocial").value;
    this.user.emails = [this.form.get("email").value];
    this.user.password = this.form.get("contraseña").value;
    this.user.phone = this.form.get("telefono").value;
    this.user.taxPayerId = this.form.get("cuit").value;
    this.user.rolenames = ["PROGRAMMER"];
    this.user.state = "ACTIVE";

    this.userService.createUser(this.user).subscribe(
      (resp) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Creado",
          detail: "¡El programador ha sido dado de alta con exito!",
        });
        this.router.navigate(["/programadores"]);
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail:(err.error)? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

}
