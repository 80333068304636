<div class="container min-width-500">
    <div class="header">
      <h2>Detalles Programador</h2>
    </div>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br>
      <span class="text-center">Cargando...</span>
    </div>
    <div class="card col-8"  *ngIf="!spinner">
      <div class="row">
          <div class="col">
              <label>Nombre: <span>{{programador.username}}</span> </label><br>
              <label>Razón Social: <span> {{programador.companyName}} </span> </label><br>
              <label>Cuit: <span> </span> {{formatearCUIT(programador.taxPayerId)}} </label><br>
              <label>Dirección: <span> {{programador.address}} </span> </label><br>
          </div>
          <div class="col">
              <label>Teléfono <span> {{programador.phone}} </span> </label><br>
              <label>Email: <span> {{programador.emails[0]}} </span> </label><br>
              <label>Usuario: <span> {{programador.username}} </span> </label><br>
              <label>Contraseña: <span> {{programador.password}} </span> </label><br>
          </div>
      </div>
      <div class="flex justify-content-end">
          <p-button
          label="Volver"
          class="p-button-primary p-button-text"
          routerLink="/programadores" 
        ></p-button>
      </div>
  </div>
  