import { Component, OnInit, ViewChild } from "@angular/core";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Table } from "primeng/table";
import { SstService } from "src/app/services/sst/sst.service";
import { Estado, Origen } from "src/app/shared/models/sst/solicitudes";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
  SortEvent,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import { UserService } from "src/app/core/services/user/user.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { PdfService } from "src/app/core/services/documentos/pdf.service";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { DatePipe } from "@angular/common";
import { TemplatesService } from "src/app/core/services/documentos/templates/templates.service";
import { DespositosService } from "src/app/core/services/depositos/despositos.service";
import { Calendar } from "primeng/calendar";
import { RolesService } from "src/app/core/services/roles/roles.service";
import { User } from "src/app/shared/models/user/user";
import { DialogService } from "primeng/dynamicdialog";
import { RejectSstModalComponent } from "../../shared/reject-sst-modal/reject-sst-modal.component";
import { EMPTY, Observable, concat, of } from "rxjs";
import { Paginator } from "primeng/paginator";
import { concatMap, switchMap, tap } from "rxjs/operators";
import { Destino } from "../../interfaces/destino.interface";

@Component({
  selector: "app-list-sst",
  templateUrl: "./list-sst.component.html",
  styleUrls: ["./list-sst.component.scss"],
  //encapsulation: ViewEncapsulation.None,
})
export class ListSstComponent implements OnInit {
  userActual: User;
  query: any[] = [];

  ordenes: any[] = [
    { label: "N°SST", value: "ID" },
    { label: "Fecha de solicitud", value: "CREATION_DATE" },
    { label: "Solicitante", value: "REQUESTER" },
    { label: "Destino", value: "ORIGIN" },
    { label: "Origen", value: "DESTINATION" },
    { label: "Fecha de necesidad", value: "DATE" },
    { label: "Estado", value: "STATUS" },
  ];

  ordenesAscDesc: any[] = [
    { label: "Descendente", value: false },
    { label: "Ascendente", value: true },
  ];
  users: any[] = [];
  programadores: any[] = [];
  solicitudes: PostSolicitud[] = [];

  selectedSst: PostSolicitud[] = [];

  cols: any[] = [];

  estados2: Estado[];

  statuses: any[] = [
    { label: "Pendiente", value: "PENDING" },
    { label: "Asignada", value: "ASSIGNED" },
    { label: "Rechazada", value: "REJECTED" },
    { label: "Cancelada", value: "CANCELLED" },
    { label: "Eliminada", value: "DELETED" },
    { label: "Finalizada", value: "FINISHED" },
  ];

  loading: boolean = true;

  rol: string;

  roles: string[] = [];

  displayAceptar = false;

  displayProponer = false;

  spinner = true;

  url: string;

  mode = "table";

  origenes: Origen[] = [];
  destinos: Destino[] = [];

  //paginado

  paginator: Paginator;

  totalRecords = 0;

  first;

  page = 1;

  size = 10;
  //paginado

  idUserActual = "";
  viewSstProgrammerId = "";

  form: FormGroup;

  constructor(
    private authService: AuthService,
    private sstService: SstService,
    private activatedRoute: ActivatedRoute,
    private solicitudesServices: SolcitudesService,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private templateService: TemplatesService,
    private pdfService: PdfService,
    private depositosService: DespositosService,
    private rolesService: RolesService,
    private dialogService: DialogService
  ) {
    this.rol = this.authService.queryRole();
    this.url = route.snapshot.url[0].path;
  }

  ngOnInit() {
    if (history.state.query) {
      this.query = history.state.query;
    }
    this.rolesInit();
    localStorage.removeItem("sstGenerate");
    localStorage.removeItem("sstTransfer");
  }

  rolesInit() {
    this.authService
      .actual()
      .pipe(
        tap({
          next: (data) => {
            this.userActual = data;
            this.idUserActual = data.id;
            this.roles = data.rolenames;
          },
          complete: () => {
            if (
              this.hasRole("SUPPLIER") ||
              this.hasRole("ADMIN") ||
              this.hasRole("PROGRAMMER")
            ) {
              this.statuses.push({ label: "Guardada", value: "NEW" });
            }
          },
        }),
        concatMap(() => this.getDestinos()),
        concatMap(() => this.getOrigienes()),
        concatMap(() => this.getInternals()),
        concatMap(() => this.getSuppliers()),
        concatMap(() => this.getProgrammers()),
        concatMap(() => concat(of(this.startFormFilter()))),
        concatMap(() =>
          concat(
            of(
              this.getListSolicitudes(
                JSON.parse(localStorage.getItem("querySst")) || this.query
              )
            )
          )
        )
      )
      .subscribe();
  }

  parametros() {
    if (this.idUserActual) {
      this.procesarRol(
        "PROGRAMMER",
        "programmerId",
        this.programmerId || this.idUserActual
      );
      this.procesarRol(
        "SUPPLIER",
        "supplierId",
        this.supplierId || this.idUserActual
      );
      this.procesarRol(
        "INTERNAL",
        "supplierId",
        this.supplierId || this.idUserActual
      );
    }
  }

  private procesarRol(rol: string, col: string, value: any): void {
    if (this.hasRole(rol)) {
      const findCol = this.query.find((d) => d.col === col);
      if (!findCol) {
        // const index = this.query.indexOf(findCol);
        // this.query.splice(index, 1);
        this.query.push({ col, value });
      }
    }
  }

  checkSelectsSstButtons() {
    let permiso = false;
    this.selectedSst.forEach((element) => {
      if (element.status == "ASSIGNED") {
        permiso = true;
      }
    })
    return permiso;
  }

  getListSolicitudes(query: any = []) {
    this.parametros();

    localStorage.setItem("querySst", JSON.stringify(query));

    this.first =
      ((JSON.parse(localStorage.getItem("pageSst")) || this.page) - 1) *
      this.size;

    this.solicitudesServices
      .filterAllSst(
        query,
        JSON.parse(localStorage.getItem("pageSst")) || this.page,
        this.size
      )
      .pipe(
        switchMap((data) => {
          this.solicitudes = data.list;
          this.totalRecords = data.totalElements;
          this.page = data.currentPage;
          this.spinner = false;
          return EMPTY; // Puedes devolver EMPTY u otro observable vacío si no necesitas emitir nuevos valores.
        })
      )
      .subscribe();
  }

  getOrigienes() {
    return this.depositosService.getOrigenes().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.origenes.push({ description: d.name, id: d.id });
          });

          this.origenes.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getDestinos() {
    return this.depositosService.getDestinos().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.destinos.push({ description: d.name, id: d.id });
          });

          this.destinos.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getSuppliers() {
    return this.userService.getByRol("PROVEEDOR").pipe(
      tap({
        next: (res) => {
          res.forEach((e) => {
            if (e.id != this.idUserActual) {
              this.users.push(e);
            }
          });
        },
        complete: () => { },
      })
    );
  }

  getInternals() {
    return this.userService.getByRol("INTERNAL").pipe(
      tap({
        next: (res) => {
          res.forEach((e) => {
            if (e.id != this.idUserActual) {
              this.users.push(e);
            }
          });
        },
        complete: () => { },
      })
    );
  }

  getProgrammers() {
    return this.userService.getByRol("PROGRAMMER").pipe(
      tap({
        next: (res) => {
          res.forEach((e) => {
            if (e.id != this.idUserActual) {
              this.programadores.push(e);
            }
          });
        },
        complete: () => { },
      })
    );
  }

  startFormFilter() {
    // Obtiene el filtro guardado en el almacenamiento local
    const queryBack = JSON.parse(localStorage.getItem("querySst"));

    // Asigna el filtro si está disponible
    if (queryBack) {
      this.query = queryBack;
    }

    // Extrae los valores de los filtros
    const solicitante = this.searchFilter("supplierId");
    const programmerId = this.searchFilter("programmerId");
    if (programmerId) {
      this.viewSstProgrammerId = programmerId.value;
    }
    const originId = this.searchFilter("originId");
    const destinationId = this.searchFilter("destinationId");
    const startRequirementDate = this.searchFilter("startRequirementDate");
    const endRequirementDate = this.searchFilter("endRequirementDate");
    const startCreationDate = this.searchFilter("startCreationDate");
    const endCreationDate = this.searchFilter("endCreationDate");
    const statuses = this.searchFilter("statuses");
    const esAe = this.searchFilter("esAe");
    const purchaseOrderNumber = this.searchFilter("purchaseOrderNumber");
    const sort = this.searchFilter("sort");
    const requestId = this.searchFilter("requestId");
    const asc = this.searchFilter("asc");

    // Crea el formulario y asigna los valores
    this.form = new FormGroup({
      solicitante: new FormControl(
        solicitante.value != this.idUserActual ? solicitante.value : ""
      ),
      programadores: new FormControl(
        programmerId.value != this.idUserActual ? programmerId.value : ""
      ),
      origen: new FormControl(originId.value || ""),
      destino: new FormControl(destinationId.value || ""),
      fechaNecesidadInit: new FormControl(
        startRequirementDate.value ? new Date(startRequirementDate.value) : ""
      ),
      fechaNecesidadEnd: new FormControl(
        endRequirementDate.value ? new Date(endRequirementDate.value) : ""
      ),
      fechaSolicitudInit: new FormControl(
        startCreationDate.value ? new Date(startCreationDate.value) : ""
      ),
      fechaSolicitudEnd: new FormControl(
        endCreationDate.value ? new Date(endCreationDate.value) : ""
      ),
      estado: new FormControl(statuses.value || []),
      esAe: new FormControl(esAe.value || ""),
      oc: new FormControl(purchaseOrderNumber.value || ""),
      numeroSst: new FormControl(requestId.value || ""),
      ordenSst: new FormControl(sort.value || ""),
      sort: new FormControl(sort.value || ""),
      asc: new FormControl(asc.value || false),
    });

    // Suscripciones a cambios en los valores del formulario
    this.form.valueChanges.subscribe(() => {
      // this.spinner = true;
    });

    // Configuración de suscripciones a cambios individuales
    this.setupFormControlSubscription("solicitante", "supplierId");
    this.setupFormControlSubscription("programadores", "programmerId");
    this.setupFormControlSubscription("origen", "originId");
    this.setupFormControlSubscription("destino", "destinationId");
    this.setupFormControlSubscriptionDate(
      "fechaNecesidadInit",
      "startRequirementDate",
      "endRequirementDate"
    );
    this.setupFormControlSubscriptionDate(
      "fechaNecesidadEnd",
      "endRequirementDate",
      "startRequirementDate"
    );
    this.setupFormControlSubscriptionDate(
      "fechaSolicitudInit",
      "startCreationDate",
      "endCreationDate"
    );
    this.setupFormControlSubscriptionDate(
      "fechaSolicitudEnd",
      "endCreationDate",
      "startCreationDate"
    );
    this.setupFormControlSubscriptionArray("estado", "statuses");
    this.setupFormControlSubscription("esAe", "esAe");
    this.setupFormControlSubscription("oc", "purchaseOrderNumber");
    this.setupFormControlSubscription("numeroSst", "requestId");
    this.setupFormControlSubscription("ordenSst", "sort");
    this.setupFormControlSubscription("sort", "sort");
  }

  // Método para configurar suscripciones a cambios en un control del formulario
  private setupFormControlSubscription(
    controlName: string,
    filterName: string
  ) {
    this.form.get(controlName).valueChanges.subscribe({
      next: (selectedValue) => {
        this.deleteFilter(filterName);
        if (selectedValue) {
          this.query.push({ col: filterName, value: selectedValue });
        }
        if (filterName == "programmerId") {
          this.viewSstProgrammerId = selectedValue;
        }
        this.page = 1;
        this.getListSolicitudes(this.query);
      },
    });
  }

  // Método para configurar suscripciones a cambios en un control de fecha del formulario
  private setupFormControlSubscriptionDate(
    controlName: string,
    filterName: string,
    correspondingControl: string
  ) {
    this.form.get(controlName).valueChanges.subscribe({
      next: (selectedValue) => {
        this.deleteFilter(filterName);
        if (selectedValue) {
          this.query.push({ col: filterName, value: selectedValue });
        }

        if (
          this.searchFilter(filterName) &&
          this.searchFilter(correspondingControl)
        ) {
          this.page = 1;
          this.getListSolicitudes(this.query);
        }
      },
    });
  }

  // Método para configurar suscripciones a cambios en un control de array del formulario
  private setupFormControlSubscriptionArray(
    controlName: string,
    filterName: string
  ) {
    this.form.get(controlName).valueChanges.subscribe({
      next: (selectedValue) => {
        this.deleteFilter(filterName);
        if (selectedValue) {
          this.query.push({
            col: filterName,
            value: selectedValue.length > 0 ? selectedValue : "",
          });
        }
        this.page = 1;
        this.getListSolicitudes(this.query);
      },
    });
  }

  changeAscDescOrder(order: any) {
    this.deleteFilter("asc");

    this.ordenesAscDescFilters = !this.ordenesAscDescFilters;
    this.ordenesAscDescIcon = this.ordenesAscDescFilters
      ? "pi pi-sort-amount-up"
      : "pi pi-sort-amount-down-alt";
    this.ordenesAscDescTooltip = this.ordenesAscDescFilters
      ? "Orden Ascendente"
      : "Orden Descendiente";
    this.query.push({ col: "asc", value: this.ordenesAscDescFilters });

    this.form.get("asc").setValue(this.ordenesAscDescFilters);
    this.page = 1;
    this.getListSolicitudes(this.query);
  }

  deleteFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }
  }

  searchFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      return find;
    }
    return false;
  }

  clear(table: Table) {
    table.clear();
  }

  fechaSolicitudFilter: Date;
  fechaNecesidadFilter: Date;

  seeDetails(solicitud: any) {
    this.router.navigate([`/details-sst/${solicitud.id}`], {
      state: {
        query: this.query,
      },
    });
  }

  deleteSst(id) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la solicitud?",
      header: "Confirmar eliminar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesServices.deleteSst(id).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "La solicitud ha sido eliminada con exito",
            });
            this.getListSolicitudes(this.query);
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error
                ? error.error.message
                : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }
  addSST($event, sst) {
    if ($event.checked) {
      this.selectedSst.push(sst);
    } else {
      let index = this.selectedSst.indexOf(sst);
      this.selectedSst.splice(index, 1);
    }
  }

  descargarPdfSst(sst: Solicitud) {
    this.pdfService
      .descargarSst(this.templateService.sendTemplateSst(sst))
      .subscribe(
        (data) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Descargado",
            detail: "SST Descargada",
          });
          var downloadURL = URL.createObjectURL(data);
          var link = document.createElement("a");
          link.href = downloadURL;
          link.download = `SST-${sst.requestId}.pdf`;
          link.click();
          // window.open(downloadURL);;
        },
        (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      );
  }

  generarSsta() {
    if (this.selectedSst.length <= 0) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Seleccione mínimo una SST",
      });
    } else {
      localStorage.setItem("sstGenerate", JSON.stringify(this.selectedSst));
      this.router.navigate(["/generar-ssta"]);
    }
  }

  transferirSst() {
    if (this.selectedSst.length <= 0) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Seleccione mínimo una SST",
      });
    } else {
      localStorage.setItem("sstTransfer", JSON.stringify(this.selectedSst));
      this.router.navigate(["/transferir-sst"]);
    }
  }

  showDialogAceptar() {
    this.displayAceptar = true;
  }
  showDialogProponer() {
    this.displayProponer = true;
  }

  rechazarSstProgrammer(sst: any) {
    const ref = this.dialogService.open(RejectSstModalComponent, {
      data: {
        sst,
      },
      header: "Rechazar solicitud N°" + sst.requestId,
      width: "50%",
    });

    ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        this.getListSolicitudes(this.query);
      }
    });
  }

  //filtros --INICIO
  requirementDate = "";
  requirementDateStart = "";
  requirementDateEnd = "";
  requirementDateStartDate = new Date();

  // creationDate = "";
  // startCreationDate = "";
  // endCreationDate = "";
  // creationDateStartDate = new Date();

  // destinationId = "";
  // originId = "";
  supplierId = "";
  programmerId = "";
  // status = [];
  ordenSst = "";
  // esAe = "";
  // purchaseOrderNumber = "";
  // requestId = "";

  //filtros de modo tarjeta
  ordenesAscDescFilters = false;
  ordenesAscDescIcon = "pi pi-sort-amount-down-alt";
  ordenesAscDescTooltip = "Orden Ascendente";

  resetDate(col) {
    this.query = [];
    // let find = "";
    // if (col == "requirementDateStart") {
    //   find = this.query.find((d) => d.col == "requirementDate");
    // }
    // if(!find){
    //   find = this.query.find((d) => d.col == "requirementDateStart");
    // }

    // let index = this.query.indexOf(find);
    // this.query.splice(index, 1);
    this.requirementDateStart = "";
    this.requirementDateEnd = "";
    this.getListSolicitudes();
  }

  paginate(event) {
    this.page = event.page + 1;
    localStorage.setItem("pageSst", JSON.stringify(this.page));
    this.size = event.rows;
    this.getListSolicitudes(this.query);
  }

  customSort(event: any) {
    let find = "";
    find = this.query.find((d) => d.col == "sort");
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }

    switch (event.sortField) {
      case "id":
        this.query.push({ col: "sort", value: "ID" });
        break;
      case "creationDate":
        this.query.push({ col: "sort", value: "CREATION_DATE" });

        break;
      case "supplierName":
        this.query.push({ col: "sort", value: "REQUESTER" });

        break;
      case "originName":
        this.query.push({ col: "sort", value: "ORIGIN" });

        break;
      case "destinationName":
        this.query.push({ col: "sort", value: "DESTINATION" });

        break;
      case "requirementDate":
        this.query.push({ col: "sort", value: "DATE" });
        break;
      default:
        break;
    }

    find = this.query.find((d) => d.col == "asc");
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }

    let ordenAscendente = false;

    event.sortOrder == 1
      ? this.query.push({ col: "asc", value: ordenAscendente })
      : this.query.push({ col: "asc", value: !ordenAscendente });

    this.getListSolicitudes(this.query);
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
