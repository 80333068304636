import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { TractoresService } from "src/app/core/services/transporte/tractores/tractores.service";
import { SstService } from "src/app/services/sst/sst.service";
import { Envio, Origen } from "src/app/shared/models/sst/solicitudes";
import { User } from "src/app/shared/models/user/user";
import { Tractor } from "src/app/shared/models/vehiculos/tractor";

@Component({
  selector: "app-popup-new-tractor",
  templateUrl: "./popup-new-tractor.component.html",
  styleUrls: ["./popup-new-tractor.component.scss"],
})
export class PopupNewTractorComponent implements OnInit {
  nuevoTractor: Tractor = new Tractor();
  form: FormGroup;
  marcas = [
    { name: "CHEVROLET", code: "CHEVROLET" },
    { name: "FORD", code: "FORD" },
    { name: "KIA", code: "KIA" },
    { name: "MAZDA", code: "MAZDA" },
    { name: "MERCEDES BENZ", code: "MERCEDES_BENZ" },
    { name: "NISSAN", code: "NISSAN" },
    { name: "RENAULT", code: "RENAULT" },
    { name: "VOLKSWAGEN", code: "VOLKSWAGEN" },
    { name: "VOLVO", code: "VOLVO" },
    { name: "OTRO", code: "OTHER" },
  ];

  hiddenPopUpNewTractor = true;

  userActual: User;
  roles: string[] = [];
  idUserActual = "";

  @Output()
  eventHiddenPopUpTractor: EventEmitter<Boolean> = new EventEmitter();

  constructor(
    private messageService: MessageService,
    private tractorService: TractoresService,
    private authService: AuthService
  ) {}

  async rolesInit() {
    this.userActual = await this.authService.actual2();
    this.idUserActual = (await this.authService.actual2()).id;
    this.roles = (await this.authService.actual2()).rolenames;
  }

  ngOnInit(): void {
    this.rolesInit();
    this.form = new FormGroup({
      patente: new FormControl("", [Validators.required]),
      marca: new FormControl("", [Validators.required]),
      modelo: new FormControl("", [Validators.required]),
    });
  }

  showOtherBrand = true;

  otherBrand(event) {
    if (event.value == "OTHER" && !this.form.get("otherBrand")) {
      this.addControlOtherBrand();
    } else {
      if (this.form.get("otherBrand")) {
        this.removeControlOtherBrand();
      }
    }
  }

  addControlOtherBrand() {
    this.form.addControl(
      "otherBrand",
      new FormControl("", [Validators.required])
    );
    this.showOtherBrand = false;
  }

  removeControlOtherBrand() {
    this.form.removeControl("otherBrand");
    this.showOtherBrand = true;
  }

  crear(): void {
    this.nuevoTractor.state = "ACTIVE";
    if (this.form.get("marca").value == "OTHER") {
      this.nuevoTractor.brand = this.form.get("otherBrand").value;
    } else {
      this.nuevoTractor.brand = this.form.get("marca").value;
    }
    this.nuevoTractor.model = this.form.get("modelo").value;
    this.nuevoTractor.patent = this.form.get("patente").value;
    this.nuevoTractor.state = "ACTIVE";
    this.nuevoTractor.transporterId = this.userActual.id;

    this.tractorService.createTractor(this.nuevoTractor).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Confirmado",
          detail: "El tractor se ha creado con exito",
        });
        this.eventHiddenPopUpTractor.emit(this.hiddenPopUpNewTractor);
        this.form.reset();
        this.removeControlOtherBrand();
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
