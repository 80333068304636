import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { EditSolicitud } from "src/app/shared/models/sst/editSolicitud";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SolcitudesService {
  constructor(private http: HttpClient) { }

  /*getAll() {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer " + localStorage.getItem("token"));
        console.log("Bearer " + localStorage.getItem("token"))
        return this.http.get(`${environment.apiUrl}/solicitudes`, { headers: headers }).pipe(
            map((res: any) => {
                return res;
            })
        );
    }*/

  filterAllSst(query: any, page, size) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "requirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startCreationDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endCreationDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startRequirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endRequirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "status") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "originId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "destinationId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "supplierId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "asc") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "esAe") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "purchaseOrderNumber") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "requestId") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/filtrar/${page}/${size}?${queryBuid}`,
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    // return new Promise((resolve, reject) => {
    //   this.http
    //     .get(
    //       `${environment.apiUrl}/solicitudes/filtrar/${page}/${size}?${queryBuid}`,
    //       { headers: headers }
    //     )
    //     .subscribe({
    //       next: (data: any) => resolve(data),
    //       error: (err) => reject(err),
    //     });
    // });
  }

  filter(query: any, page, size): Promise<any> {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "requirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startCreationDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endCreationDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startRequirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endRequirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "status") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "originId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "destinationId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "supplierId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "asc") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "esAe") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "purchaseOrderNumber") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "requestId") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.apiUrl}/solicitudes/filtrar/${page}/${size}?${queryBuid}`,
          { headers: headers }
        )
        .subscribe({
          next: (data: any) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  filterReporte(query: any, page, size) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "requirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "requestId") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "status") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "originId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "destinationId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "supplierId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "asc") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "esAe") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "purchaseOrderNumber") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/reporte/${page}/${size}?${queryBuid}`,
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  createNewSst(data: PostSolicitud) {
    console.log(data);

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes/nuevo`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  generateSst(data: PostSolicitud | EditSolicitud) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes/generar`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  editSst(data: any) {
    console.log(data);

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes/guardar`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  transferirSst(data: any) {

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes/reasignar`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  delegarSst(data: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes/delegar`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteSst(id) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/solicitudes/borrar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  dividirSolicitud(id: number, bultos: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes/dividir/${id}`, bultos, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getById(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/solicitudes/buscar/${id}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  rechazarSstProgramador(id: string, motivo: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes/rechazar`, { id, text: motivo }, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  reporteSstTiempoHastaAsignacionPorProgramador(query: any, page, size) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/reporte/tiempo/${page}/${size}?${queryBuid}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  reportSstTiempoDeRecepcionHastaSuAsignacion(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/reporte/tiempo/programadores?${queryBuid}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  reportSstTiempoPromedioHastaSuAsignacion(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDateSst") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "startDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "endDateSst") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "endDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders({
      "Content-Type": "text/plain",
    });
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/reporte/tiempo/promedio?${queryBuid}`,
        {
          headers: headers,
          responseType: "text",
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  
  dataProgramadoresTableroGeneralSst(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDateSst") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "startDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "endDateSst") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "endDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/reporte/tabla?${queryBuid}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  dataTiempoProgramadoresTableroGeneralSst(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDateSst") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "startDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "endDateSst") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "endDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "alert" ) {
        if (q.value) {
          queryBuid = queryBuid + "alert" + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerIds") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "estadoSst") {
        if (q.value) {
          queryBuid = queryBuid + "status" + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/reporte/tablero?${queryBuid}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
