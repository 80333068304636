<div class="container min-width-500 mb-5">
    <div class="row col-12 mb-5">
      <div class="col mb-5">
        <label for="fecha" class="pr-5">Roles</label>
        <p-multiSelect
          [(ngModel)]="rolesSelected"
          [options]="roles"
          defaultLabel="Seleccione"
          optionLabel="label"
          optionValue="value"
          selectedItemsLabel="{0} Roles seleccionados"
          [style]="{ width: '100%' }"
          class="mb-5"
          (onChange)="validRolChange($event)"
          optionDisabled="disabled"
        ></p-multiSelect>
        <div>
        </div>
      </div>
    </div>

    <div class="row col-12 mb-5">
      <div class="col-12 mb-5">
        <button
          [disabled]="rolesSelected.length <= 0"
          pButton
          pRipple
          (click)="submit()"
          type="button"
          label="Confirmar"
          class="p-button-secondary p-button-text"
        ></button>

        <p-button label="cancelar" styleClass="p-mr-2" (click)="cerrarPopUp()">
        </p-button>
      </div>
    </div>
</div>
