import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as ApexCharts from 'apexcharts';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexPlotOptions,
  ApexDataLabels,
  ApexResponsive,
  ApexLegend
} from "ng-apexcharts";
import { MessageService } from "primeng/api";
import { concat, of } from "rxjs";
import { concatMap, debounceTime, tap } from "rxjs/operators";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any; // ApexStroke;
  dataLabels: any; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
  plotOptions: ApexPlotOptions;
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  colors: string[];
};

@Component({
  selector: 'app-tablero-general',
  templateUrl: './tablero-general.component.html',
  styleUrls: ['./tablero-general.component.scss']
})

export class TableroGeneralComponent implements OnInit {

  spinner = true;
  loadingData = true;
  value: number = 1;
  justifyOptions: any[] = [
    { icon: 'bi bi-file-earmark-text', value: 1, label: "SST" },
    { icon: 'bi bi-file-check', value: 2, label: "SSTA" },
  ];

  programadores = []

  transportistas = []

  mesesProgramadores = []

  mesesTransportistas = []

  sstsDataProgrammer = null;

  sstsDataTransportistas = null;

  statusesSsta: any[] = [
    { label: "Pendiente", value: "PENDING" },
    { label: "Aceptada", value: "ACCEPTED" },
    { label: "Rechazada", value: "REJECTED" },
    { label: "Cancelada", value: "CANCELLED" },
    { label: "En curso", value: "IN_PROGRESS" },
    { label: "Finalizada", value: "FINISHED" },
    { label: "Informada", value: "INFORMED" },
  ];

  statusesSst: any[] = [
    { label: "Pendiente", value: "PENDING" },
    { label: "Asignada", value: "ASSIGNED" },
    { label: "Rechazada", value: "REJECTED" },
    { label: "Cancelada", value: "CANCELLED" },
    { label: "Eliminada", value: "DELETED" },
    { label: "Finalizada", value: "FINISHED" },
    { label: "Guardada", value: "NEW" }
  ];

  programadoresFilter: any = []
  transportistaFilter: any = []

  @ViewChild('chart1', { static: false }) chartGraphic1: ElementRef;
  @ViewChild('chart2', { static: false }) chartGraphic2: ElementRef;
  @ViewChild('chart3', { static: false }) chartGraphic3: ElementRef;

  form: FormGroup;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private solicitudesAsignadaService: SolicudesAsignadasService,
    private solicitudesService: SolcitudesService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {

    

  }

  ngOnInit(): void {
    this.getData(JSON.parse(localStorage.getItem("querySstTable")) || this.query).subscribe({
      complete: () => {
        this.spinner = false;
        this.loadingData = false
      }
    })

    this.changeDetectorRef.detectChanges()
  }

  query: any[] = [];
  startFormFilter() {
    const queryBack = JSON.parse(localStorage.getItem("querySstTable"));
    if (queryBack) {
      this.query = queryBack;
    }

    // Extrae los valores de los filtros
    const programmers = this.searchFilter("programmerIds");
    const transports = this.searchFilter("transporterIds");
    const alert = this.searchFilter("alert");
    const startDate = this.searchFilter("startDate");
    const endDate = this.searchFilter("endDate");
    const estadoSst = this.searchFilter("estadoSst");
    const estadoSsta = this.searchFilter("estadoSsta");

    this.form = new FormGroup({
      programmerIds: new FormControl(
        programmers.value || ""
      ),
      transporterIds: new FormControl(
        transports.value || ""
      ),
      alert: new FormControl(alert.value || ""),
      startDate: new FormControl(
        startDate.value ? new Date(startDate.value) : ""
      ),
      endDate: new FormControl(
        endDate.value ? new Date(endDate.value) : ""
      ),
      estadoSst: new FormControl(
        estadoSst.value || ""
      ),
      estadoSsta: new FormControl(
        estadoSsta.value || ""
      ),
    });

    this.setupFormControlSubscriptionDate("startDate", "startDate", "endDate");
    this.setupFormControlSubscriptionDate("endDate", "endDate", "startDate");
    this.setupFormControlSubscriptionArray("programmerIds", "programmerIds");
    this.setupFormControlSubscriptionArray("transporterIds", "transporterIds");
    this.setupFormControlSubscriptionArray("estadoSst", "estadoSst");
    this.setupFormControlSubscriptionArray("estadoSsta", "estadoSsta");
    this.setupFormControlSubscription("alert", "alert");
  }

  getData(query: any = []) {
    // localStorage.setItem("querySstTable", JSON.stringify(query));
    return of({
    }).pipe(
      tap({
        next: (data) => {

        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }

      }),
      concatMap(() => this.getTransportistas()),
      concatMap(() => this.getProgramadores()),
      concatMap(() => concat(of(this.startFormFilter()))),
      concatMap(() => this.reloadData(JSON.parse(localStorage.getItem("querySstTable")) || this.query)),
    )
  }

  reloadData(query: any = []) {
    localStorage.setItem("querySstTable", JSON.stringify(query));
    return of({
    }).pipe(
      tap({
        next: (data) => {

        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }

      }),
      concatMap(() => this.getGraficoDatarechazadasAceptadasTableroGeneralSsta()),
      concatMap(() => this.tablaDataTransportistasTableroGeneralSsta()),
      concatMap(() => this.tablaDataProgramadoresTableroGeneralSsta()),
      concatMap(() => this.dataTiempoTransportistasTableroGeneralSsta()),
      concatMap(() => this.dataTiempoTransportistasTableroGeneralSst())
    )
    
  }

  getTransportistas() {
    return this.userService.getByRol("transportista").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.transportistaFilter.push({ username: e.username, id: e.id });
            } else {
              this.transportistaFilter.push({
                username: (e.companyName || e.username),
                id: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }

  getProgramadores() {
    return this.userService.getByRol("PROGRAMADOR").pipe(tap({
      next: (data) => {
        data.forEach((e) => {
          if (e.azure) {
            this.programadoresFilter.push({ username: e.username, id: e.id });
          } else {
            this.programadoresFilter.push({ username: e.companyName, id: e.id });
          }
        });
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    })
    )
  }

  tablaDataTransportistasTableroGeneralSsta() {
    return this.solicitudesAsignadaService.dataTransportistasTableroGeneralSsta(this.query).pipe(tap({
      next: (data) => {
        this.sstsDataTransportistas = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        this.changeDetectorRef.detectChanges()
      }
    })
    )
  }

  tablaDataProgramadoresTableroGeneralSsta() {
    return this.solicitudesService.dataProgramadoresTableroGeneralSsta(this.query).pipe(tap({
      next: (data) => {
        this.sstsDataProgrammer = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        this.changeDetectorRef.detectChanges()
      }
    })
    )
  }

  dataTiempoTransportistasTableroGeneralSsta() {
    return this.solicitudesAsignadaService.dataTiempoTransportistasTableroGeneralSsta(this.query).pipe(tap({
      next: (data) => {
        this.mesesTransportistas = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        // this.tiempoPromedioGraficoSsta()
      }
    })
    )
  }

  dataTiempoTransportistasTableroGeneralSst() {
    return this.solicitudesService.dataTiempoProgramadoresTableroGeneralSst(this.query).pipe(tap({
      next: (data) => {
        this.mesesProgramadores = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        // this.tiempoPromedioGraficoSst()
      }
    })
    )
  }


  //grafico ssta aceptada rechazadas -- INICIO
  getGraficoDatarechazadasAceptadasTableroGeneralSsta() {
    return this.solicitudesAsignadaService.rechazadasAceptadasTableroGeneralSsta(this.query).pipe(tap({
      next: (data) => {
        this.transportistas = data;
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        // this.cantidadAceptadaRechazadaSsta()
      }
    })
    )
  }


  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Método para configurar suscripciones a cambios en un control del formulario
  private setupFormControlSubscription(
    controlName: string,
    filterName: string
  ) {
    this.form.get(controlName).valueChanges.pipe(debounceTime(300)).subscribe({
      next: (selectedValue) => {
        this.loadingData = true;
        this.deleteFilter(filterName);
        if (selectedValue) {
          this.query.push({ col: filterName, value: selectedValue });
        }
        this.reloadData(this.query).subscribe({
          complete: () => {
            this.loadingData = false;
          }
        })
      },
    });
  }

  // Método para configurar suscripciones a cambios en un control de fecha del formulario
  private setupFormControlSubscriptionDate(
    controlName: string,
    filterName: string,
    correspondingControl: string
  ) {
    this.form.get(controlName).valueChanges.pipe(debounceTime(300)).subscribe({
      next: (selectedValue) => {
        this.deleteFilter(filterName);
        if (selectedValue) {
          this.query.push({ col: filterName, value: selectedValue });
        }
        if (
          this.searchFilter(filterName) &&
          this.searchFilter(correspondingControl)
        ) {
          this.loadingData = true;
          this.reloadData(this.query).subscribe({
            complete: () => {
              this.loadingData = false;
            }
          })
        }
      },
    });
  }

   clearDate(){
    this.form.get('startDate').setValue(null)
    this.form.get('endDate').setValue(null)
    
    if(!this.form.get('startDate').value && !this.form.get('endDate').value){
      this.loadingData = true;
      this.reloadData(this.query).subscribe({
        complete: () => {
          this.loadingData = false;
        }
      })
    }
  }

  // Método para configurar suscripciones a cambios en un control de array del formulario
  private setupFormControlSubscriptionArray(
    controlName: string,
    filterName: string
  ) {

    this.form.get(controlName).valueChanges.pipe(debounceTime(300)).subscribe({
      next: (selectedValue) => {
        this.loadingData = true;
        this.deleteFilter(filterName);
        if (!selectedValue.includes(null)) {
          this.query.push({
            col: filterName,
            value: selectedValue.length > 0 ? selectedValue : "",
          });
          this.reloadData(this.query).subscribe({
            complete: () => {
              this.loadingData = false;
            }
          })
        }
      },
    });
  }

  deleteFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }
  }

  searchFilter(filter: any) {
    let find = this.query.find((d) => d.col == filter);
    if (find) {
      return find;
    }
    return false;
  }

  searchLocalStorageSst(data:any) {
    const filter = [];

    if (data && data.user) {
      filter.push({ "col": "programmerId", "value": data.user })
    }

    if (data && data.status) {
      filter.push({ "col": "statuses", "value": [data.status] })
    }

    localStorage.setItem('querySst', JSON.stringify(filter))

    this.router.navigate(['/solicitudes'])
  }

  searchLocalStorageSsta(data:any) {
    const filter = [];

    if (data && data.user) {
      filter.push({ "col": "transporterId", "value": data.user })
    }

    if (data && data.status) {
      filter.push({ "col": "statuses", "value": [data.status] })
    }

    localStorage.setItem('querySsta', JSON.stringify(filter))

    this.router.navigate(['/solicitudes-asignadas'])
  }
}

