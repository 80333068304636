<div class="container min-width-500">
  <form [formGroup]="form" class="form">
    <div class="row col-12" formArrayName="transportGuideArray">
      <div class="col-12">
        <label for="fecha" class="pr-5">Guía de transporte</label>
      </div>
      <div
        *ngFor="let emp of transportGuideArray.controls; let i = index"
        [formGroupName]="i" class="row"
      >
        <div class="col-10">
          <input
            type="number"
            formControlName="transportGuide"
            pInputText
            [style]="{ width: '100%' }"
          />
          <div>
            <span
              class="danger"
              *ngIf="
              transportGuideArray.controls[i].hasError('required') &&
              transportGuideArray.controls[i].touched
              "
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
            <span
              class="danger"
              *ngIf="
              transportGuideArray.controls[i].hasError('min') &&
              transportGuideArray.controls[i].touched
              "
            >
              <small class="p-error">Debe ser mayor a <strong>0</strong></small>
            </span>
          </div>
        </div>
        <div class="col-2">
          <p-button
            *ngIf="i == 0"
            class="text-white"
            styleClass="p-button-sm"
            icon="pi pi-plus"
            (click)="addTransportGuide()"
          ></p-button>
          <p-button
            *ngIf="i > 0"
            class="text-white"
            styleClass="p-button-sm"
            icon="pi pi-trash"
            (click)="deleteTransportGuide(i)"
          ></p-button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          pButton
          pRipple
          (click)="submit()"
          [disabled]="form.invalid"
          type="button"
          label="Finalizar"
          class="p-button-secondary p-button-text"
        ></button>

        <p-button label="cancelar" styleClass="p-mr-2" (click)="cerrarPopUp(1)">
        </p-button>
      </div>
    </div>
  </form>
</div>
