import { DatePipe } from "@angular/common";
import { inject } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";


export const isNumberOnlyCuit: ValidatorFn = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  const cuit: any = control.get('cuit').value;

  let exists = cuit.toString().includes("-")
  console.log(cuit);


  if (exists) {
    return { notNumber: true };
  }
  return null;
};

export function isFechaNecesidadValid(datePipe: DatePipe): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    const fechaNecesidad = new Date(control.value);

    if (fechaNecesidad) {
      // const transformedFechaNecesidad = new Date(datePipe.transform(fechaNecesidad, "YYYY-MM-dd"));
      const today = new Date()

      today.setHours(0, 0, 0, 0);
      fechaNecesidad.setHours(0, 0, 0, 0);
  
      if (fechaNecesidad < today) {
        return { "fechaNecesidadInvalid": true };
      }
    }

    return null;
  };
}