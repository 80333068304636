import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { TractoresService } from "src/app/core/services/transporte/tractores/tractores.service";
import { TrailersService } from "src/app/core/services/transporte/trailers/trailers.service";
import { Trailer } from "src/app/shared/models/vehiculos/trailers";

@Component({
  selector: "app-document-add-trailer",
  templateUrl: "./document-add-trailer.component.html",
  styleUrls: ["./document-add-trailer.component.scss"],
})
export class DocumentAddTrailerComponent implements OnInit {
  form: FormGroup;
  spinner = true;
  uploadedFiles: any[] = [];
  url: string;
  trailer: Trailer;
  id: any;
  cantidad = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private solcitudAsignadasService: SolicudesAsignadasService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService,
    private messageService: MessageService,
    private trailerService: TrailersService
  ) {}

  ngOnInit(): void {
    this.url = this.router.url.split("/", 2)[1];
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id;

    this.trailerService.getById(this.id).subscribe((res) => {
      this.trailer = res;
      this.spinner = false;
    });

    this.form = new FormGroup({});
  }

  selectFile(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.cantidadArchivos();
  }

  onUpload(event) {}

  descargarDocumento(file) {
    let url = window.URL.createObjectURL(file);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.messageService.add({
      key: "msg",
      severity: "success",
      summary: "Descargado",
    });
  }

  eliminarDocumento(file, indice) {
    this.uploadedFiles[indice] = null;
    this.cantidadArchivos();
  }

  agregarDocumentos() {
    if (this.cantidad <= 0) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Debe subir al menos un documento",
      });
      return;
    }

    let archivos = [];
    for (let file of this.uploadedFiles) {
      if (file) {
        archivos.push(file);
      }
    }

    let formData = new FormData();
    this.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    this.fileService.uploadFiles(formData).subscribe(
      (data) => {
        this.trailer.documentation = [];
        for (let file of data) {
          this.trailer.documentation.push(file.id);
        }
        this.trailerService.updateTrailer(this.trailer).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "Se adjuntaron los documentos al vehiculo",
            });
            this.router.navigate(["/informacion-de-transporte"]);
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  cantidadArchivos() {
    let cantidad = 0;
    for (let file of this.uploadedFiles) {
      if (file) {
        cantidad++;
      }
    }
    this.cantidad = cantidad;
  }
}
