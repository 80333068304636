import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { switchMap } from "rxjs/operators";
import { UserService } from "src/app/core/services/user/user.service";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-edit-transportista",
  templateUrl: "./edit-transportista.component.html",
  styleUrls: ["./edit-transportista.component.scss"],
})
export class EditTransportistaComponent implements OnInit {
  form: FormGroup;

  user: User = new User();

  userEncontrado: User;

  spinner = true;

  id;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService: UserService
  ) {
    this.cargarUser();
  }

  ngOnInit(): void {
  }

  cargarUser() {
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id;
    this.userService.getByUserId(id).subscribe((user: User) => {
      this.userEncontrado = user;
      this.form = new FormGroup({
        nombre: new FormControl(user.username, [Validators.required]),
        razonSocial: new FormControl(user.companyName, [Validators.required]),
        cuit: new FormControl(user.taxPayerId, [Validators.required]),
        direccion: new FormControl(user.address, [Validators.required]),
        telefono: new FormControl(user.phone, [Validators.required]),
        emails: new FormArray([]),
        usuario: new FormControl(user.username, [Validators.required]),
        // contraseña: new FormControl(user.password, [Validators.required]),
      });
      let emails = this.emailsUserModel;

      for (let i = 0; i < this.userEncontrado.emails.length; i++) {
        emails.push(
          new FormGroup({
            email: new FormControl(this.userEncontrado.emails[i], [
              Validators.required,
              Validators.email,
            ]),
          })
        );
      }
      if (this.userEncontrado.emails.length <= 0) {
        this.addEmailUser();
      }
      this.spinner = false;
    });
  }

  createEmailUser() {
    return new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  addEmailUser() {
    let newEmail = this.createEmailUser();
    this.emailsUserModel.push(newEmail);
  }

  deleteEmailUser(idx: number) {
    this.emailsUserModel.removeAt(idx);
  }

  get emailsUserModel(): FormArray {
    return this.form.get("emails") as FormArray;
  }

  modificarTransportista() {
    this.user.address = this.form.get("direccion").value;
    this.user.companyName = this.form.get("razonSocial").value;
    // this.user.password = this.form.get("contraseña").value;
    this.user.phone = this.form.get("telefono").value;
    this.user.taxPayerId = this.form.get("cuit").value;
    this.user.id = this.id;
    this.user.username = this.form.get("usuario").value;
    this.user.rolenames = this.userEncontrado.rolenames

    this.emailsUserModel.controls.forEach((f) => {
      this.user.emails.push(f.value.email);
    });

    

    this.userService.editUser(this.user).subscribe(
      (resp) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Creado",
          detail: "¡El transportista ha sido modificado con exito!",
        });
        this.router.navigate(["/transportistas"]);
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
