import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/core/authentication/auth.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";

@Component({
  selector: "app-details-sst",
  templateUrl: "./details-sst.component.html",
  styleUrls: ["./details-sst.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailsSstComponent implements OnInit {
  sstEncontrado: any;
  rol: string;
  roles: string[] = [];
  idSst: any;
  spinner = true;
  detalles = [];
  displayHabilitaciones = false;
  url: string;

  query: any = []

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.url = route.snapshot.url[0].path;
    this.rol = this.authService.queryRole();
  }

  async rolesInit() {
    let promesa = await this.authService.actual2();
    this.roles = promesa.rolenames;
  }

  ngOnInit(): void {
    if (history.state.query) {
      this.query = history.state.query
    }

    this.url = this.router.url.split("/", 2)[1];
    let { id } = this.activatedRoute.snapshot.params;
    this.idSst = id;

    this.solcitudService.getById(this.idSst).subscribe((res) => {
      this.sstEncontrado = res;
      this.spinner = false;
    });
  }

  showDialogHabilitaciones() {
    this.displayHabilitaciones = true;
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }

  backToList(){
    this.router.navigate([`/solicitudes`], {
      state: {
        query : this.query
      }
    })
  }
}
