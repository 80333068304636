import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ConductoresService } from "src/app/core/services/transporte/conductores/conductores.service";
import { User } from "src/app/shared/models/user/user";
import { Conductor } from "src/app/shared/models/vehiculos/conductor";

@Component({
  selector: "app-popup-new-conductor",
  templateUrl: "./popup-new-conductor.component.html",
  styleUrls: ["./popup-new-conductor.component.scss"],
})
export class PopupNewConductorComponent implements OnInit {
  nuevoConductor: Conductor = new Conductor();
  form: FormGroup;
  tipos = [
    { name: "DNI", code: "DNI" },
    { name: "PASAPORTE", code: "PASSPORT" },
  ];
  hiddenPopUpNewConductor = true;

  userActual: User;
  roles: string[] = [];
  idUserActual = "";

  @Output()
  eventHiddenPopUpConductor:EventEmitter<Boolean> =new EventEmitter();

  constructor(
    private messageService: MessageService,
    private conductorService: ConductoresService,
    private authService:AuthService
  ) {
  }

  async rolesInit() {
    this.userActual = await this.authService.actual2();
    this.idUserActual = (await this.authService.actual2()).id;
    this.roles = (await this.authService.actual2()).rolenames;
  }

  ngOnInit(): void {
    this.rolesInit();
    this.form = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      apellido: new FormControl("", [Validators.required]),
      tipo: new FormControl("", [Validators.required]),
      numero: new FormControl("", [Validators.required]),
      fecha: new FormControl("", [Validators.required]),
      direccion: new FormControl("", [Validators.required]),
      telefono: new FormControl("", [Validators.required]),
    });
  }

  crear(): void {
    this.nuevoConductor.fullName =
      this.form.get("nombre").value + " " + this.form.get("apellido").value;
    this.nuevoConductor.documentType = this.form.get("tipo").value.name; // solo funciona con DNI
    this.nuevoConductor.documentNumber = this.form.get("numero").value;
    this.nuevoConductor.dateOfBirth = this.form.get("fecha").value;
    this.nuevoConductor.phone = this.form.get("telefono").value;
    this.nuevoConductor.address = this.form.get("direccion").value;
    this.nuevoConductor.state = "ACTIVE";
    this.nuevoConductor.transporterId = this.userActual.id;

    this.conductorService.createConductor(this.nuevoConductor).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Confirmado",
          detail: "El conductor se ha creado con exito",
        });
        this.eventHiddenPopUpConductor.emit(this.hiddenPopUpNewConductor);
        this.form.reset();
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail:(err.error)? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
