import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {

  @Input ()filtros:[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
