import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Destino, Envio, Origen, solicitudes, User } from "src/app/shared/models/sst/solicitudes";
@Injectable({
  providedIn: "root",
})
export class SstService {

  datos:any;

  tipoGestion:Envio = {
    id : 1,
    tipo  :  "Retiro",
  }
  user:User = {
    name: "Joel",
    code: "12345"
  }

  origen:Origen = {
    id : 1,
    description : "Argentina",
  }

  destino:Destino = {
    id : 1,
    description : "Quatar",
  }

  sstEdit:solicitudes = {
    id: 1,
    numero: 1000,
    fechaSolicitud: new Date(),
    estado: null,
    estadoId : null,
    estadoDescription: "Cancelada",
    solicitante: "Guille",
    origen: this.origen,
    destino: this.destino,
    fechaNecesidad: new Date(),
    comentarios: "comentario1",
    eaEs : "eaEs1",
    programador: this.user,
    tipoGestion: this.tipoGestion,
    bultosModel:[
      {
        bultos: 1,
        pesoAprox: 2,
        ancho:3,
        largo:4,
        alto:5
      },
      {
        bultos: 3,
        pesoAprox: 21,
        ancho:12,
        largo:412,
        alto:513
      }
    ]
 }

  constructor(private http: HttpClient) {}

  async getSolicitudes() {
    const res = await this.http
      .get<any>("assets/solicitudes-large.json")
      .toPromise();
    const data = <solicitudes[]>res.data;
    return data;
  }

  getSst(): Observable<any> {
    return this.http.get<any>("assets/solicitudes-large.json");
  }

  updaterSst(form:any):void{
  }

  guardarSst(form: any): Observable<any> {
    let data = new Array();
    this.getSst().subscribe((data) => {
      this.datos = data.data;
    });

    let objeto = {
      "id": 10,
      "bultosModel": form.bultosModel,
      "comentarios": form.comentarios,
      "esAe": form.esAe,
      "origen": form.origen.description,
      "destino":form.destino.description,
      "solicitante": "Prueba",
      "programador": "qsy",
      "tipoGestion": form.tipoGestion.tipo,
      "fechaNecesidad": form.fechaNecesidad,
      "fechaSolicitud": new Date(),
      "estado":{
        "description":"Cancelada",
        "id":3
     },
    }
    this.datos.push(objeto);
    return this.http.get<any>("assets/solicitudes-large.json");
  }

  getSstById(id){
      return this.sstEdit;
  }

}
