import { AfterContentInit, AfterViewInit, Component, OnInit } from "@angular/core";
import {
  MessageService,
  ConfirmationService,
  ConfirmEventType,
} from "primeng/api";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ExcelService } from "src/app/core/services/documentos/excel.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { UserService } from "src/app/core/services/user/user.service";
import { User } from "src/app/shared/models/sst/solicitudes";

@Component({
  selector: "app-report-sst-recepcion-hasta-asignacion",
  templateUrl: "./report-sst-recepcion-hasta-asignacion.component.html",
  styleUrls: ["./report-sst-recepcion-hasta-asignacion.component.scss"],
})
export class ReportSstRecepcionHastaAsignacionComponent implements OnInit, AfterContentInit {
  reportSst: any[] = [];

  users: User[] = [];

  tiempoTotal: string = "";

  statuses: any[];

  querySolicitud: any[] = [];

  programadores: User[] = [];

  cols: any[] = [];

  spinner = true;


  roles: string[] = [];

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private solicitudesService: SolcitudesService,
    private excelService: ExcelService
  ) {

  }
  ngAfterContentInit(): void {
  }

  ngOnInit(): void {
    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  ngAfterViewInit(): void {
  }


  getData() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.roles = data.rolenames
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
        }
      }),
      concatMap(() => this.getProgramadores()),
      concatMap(() => this.getReportSstTiempoPromedioHastaSuAsignacion()),
      concatMap(() => this.getReportSstTiempoDeRecepcionHastaSuAsignacion()),
    )
  }

  getReportSstTiempoPromedioHastaSuAsignacion() {
    return this.solicitudesService
      .reportSstTiempoPromedioHastaSuAsignacion(this.querySolicitud).pipe(
        tap({
          next: (data) => {
            this.tiempoTotal = data;
          }, error: (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error ? error.error.message : "Ups! ocurrio un error",
            });
          }
        })
      )
  }

  getReportSstTiempoDeRecepcionHastaSuAsignacion() {
    return this.solicitudesService
      .reportSstTiempoDeRecepcionHastaSuAsignacion(this.querySolicitud).pipe(
        tap({
          next: (data) => {
            this.users = data;
          },
          error: (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error ? error.error.message : "Ups! ocurrio un error",
            });
          }
        })
      )
  }

  getProgramadores() {
    return this.userService.getByRol("PROGRAMADOR").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.programadores.push({ name: e.username, code: e.id });
            } else {
              this.programadores.push({
                name: (e.companyName || e.username),
                code: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }

  programador = "";
  minDateValue = new Date();
  startDateSolicitud = "";
  endDateSolicitud = ""

  filtrosSolicitud() {
    this.querySolicitud = [];

    if (this.startDateSolicitud) {
      this.minDateValue = new Date(this.startDateSolicitud);
    }

    if (this.startDateSolicitud && this.endDateSolicitud) {
      this.querySolicitud.push({
        col: "startDate",
        value: this.startDateSolicitud,
      });
      this.querySolicitud.push({
        col: "endDate",
        value: this.endDateSolicitud,
      });
    }

    if (this.programador) {
      this.querySolicitud.push({
        col: "programmerId",
        value: this.programador,
      });
    }
    if (this.querySolicitud.length > 0) {
      this.spinner = true;
    }
    this.getReportSstTiempoDeRecepcionHastaSuAsignacion().subscribe();
    
    this.getReportSstTiempoPromedioHastaSuAsignacion().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }


  descargarExcel() {
    this.excelService.descargarTiempoSst(this.querySolicitud).subscribe(
      (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = `reporte-tiempo-programador-SST.xlsx`;
        link.click();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
