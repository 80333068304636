<div class="container min-width-500">
  <div class="header">
    <h2>Documentos<br /></h2>
  </div>
  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
</div>
<div class="card card-form" *ngIf="!spinner">
  <div class="row">
    <div class="col header">
      <div style="float: left">
        <h1 class="title">SST Nº {{ sst.requestId }}</h1>
        <br />
      </div>
    </div>
  </div>
  <div class="" *ngIf="sst.documentation.length > 0; else vacio">
    <div class="row" *ngFor="let document of sst.documentation">
      <div class="col">
        <h3
          pTooltip="Descargar"
          class="cursor"
          (click)="descargarDocumento(document)"
        >
          {{ document.name }}
        </h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        pButton
        pRipple
        label="Volver"
        class="p-button-primary p-button-text float-right"
        routerLink="{{urlBefore}}"
      ></button>
    </div>
  </div>
  <ng-template #vacio>
    <p-messages
      [closable]="false"
      [(value)]="mensajeVacio"
      [enableService]="false"
    ></p-messages>
  </ng-template>
</div>
