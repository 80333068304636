import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { FileService } from 'src/app/core/services/documentos/file.service';
import { SolcitudesService } from 'src/app/core/services/solicitudes/solcitudes.service';
import { SolicudesAsignadasService } from 'src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service';
import { TractoresService } from 'src/app/core/services/transporte/tractores/tractores.service';
@Component({
  selector: 'app-document-tractor',
  templateUrl: './document-tractor.component.html',
  styleUrls: ['./document-tractor.component.scss']
})
export class DocumentTractorComponent implements OnInit {

  tractor: any;
  rol: string;
  id: any;
  spinner = true;
  detalles = []
  displayHabilitaciones = false;
  url: string;
  mensajeVacio: Message[];
  
  documents:[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private solcitudAsignadasService: SolicudesAsignadasService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService:FileService,
    private messageService:MessageService,
    private tractorService:TractoresService

  ) {
    this.url = route.snapshot.url[0].path;
    this.rol = this.authService.queryRole()
  }

  ngOnInit(): void {
    this.url = this.router.url.split('/', 2)[1];
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id;

    this.tractorService.getById(this.id).subscribe(res => {
      this.tractor = res;
      if(this.tractor.documentation.length <= 0){
        this.mensajeVacio = [{
          severity:'info', summary:'Esté vehiculo no tiene documentos para mostrar',
        }]
      }
      this.spinner = false;
    })
  }

  descargarDocumento(file) {
    this.fileService.getFile(file.id).subscribe(
      (data) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Descargado",
        });
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

}
