
<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<p-card *ngIf="ssta && !spinner">
  <form [formGroup]="form" (ngSubmit)="confirmPutInCourse()" class="m-0 p-0">
    <div formArrayName="transports" class="m-0 p-0" style="width: 100%">
      <div
        *ngFor="let transport of transports.controls; let i = index"
        [formGroupName]="i"
        class="bultosModel m-0 p-0"
      >
        <div class="row">
          <div class="col-6">
            <h4 class="">Transporte</h4>
          </div>
          <div class="col-6">
            <h4>Nº de Orden: {{ i + 1 }}</h4>
          </div>
        </div>
        <div class="row mt-5 p-0">
          <div class="col-6">
            <label for="name" class="padding-2">Chofer Designado </label><br />
            <p-dropdown
            [filter]="true"
              [options]="conductores"
              formControlName="driverId"
              placeholder="Seleccione un conductor"
              optionLabel="label"
              optionValue="value"
              [showClear]="true"
              (onChange)="mostrarDatosChofer($event, i)"
            >
              <ng-template let-option pTemplate="item">
                <span [class]="'chofer-' + option.value">{{
                  option.label
                }}</span>
              </ng-template>
            </p-dropdown>
            <span
              class="danger"
              *ngIf="
                transports.controls[i].get('driverId').hasError('required') &&
                transports.controls[i].get('driverId').touched
              "
            >
              <br />
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <br /><label *ngIf="!choferSelecteds[i]"
              >Tel. Chofer: <span>-</span>
            </label>
            <label *ngIf="choferSelecteds[i]"
              >Tel. Chofer: <span>{{ choferSelecteds[i].phone }}</span>
            </label>
          </div>
          <div class="col-6">
            <br /><label *ngIf="!choferSelecteds[i]"
              >DNI Chofer: <span>-</span>
            </label>
            <label *ngIf="choferSelecteds[i]"
              >DNI Chofer:
              <span>{{ choferSelecteds[i].documentNumber }}</span>
            </label>
          </div>
        </div>
        <!-- <div class="row mt-5 p-0">
            <div class="col-12">
              <label for="name" class="padding-2">Tipo de Bulto: </label>
            </div>
          </div> -->
        <!-- <div class="row">
            <div class="col-12">
              <p-multiSelect
                formControlName="packageTypeList"
                [options]="tipos"
                defaultLabel="--Seleccione--"
                optionLabel="name"
                optionValue="code"
                selectedItemsLabel="{0} tipos de bultos seleccionados"
              ></p-multiSelect>
              <span
                class="danger"
                *ngIf="
                  transports.controls[i]
                    .get('packageTypeList')
                    .hasError('required') &&
                  transports.controls[i].get('packageTypeList').touched
                "
              >
                <br />
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
            </div>
          </div> -->
        <!-- <div class="row mt-5 p-0">
            <div class="col-12">
              <label for="bultos">Cantidad </label><br />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <p-inputNumber
                mode="decimal"
                [showButtons]="true"
                inputId="minmax-buttons"
                [min]="0"
                formControlName="quantity"
              >
              </p-inputNumber>
              <br />
              <span
                class="danger"
                *ngIf="
                  transports.controls[i]
                    .get('quantity')
                    .hasError('required') &&
                  transports.controls[i].get('quantity').touched
                "
              >
                <br />
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
            </div>
          </div> -->

        <div class="row mt-5 p-0">
          <div class="col-12">
            <label for="name" class="padding-2">Tractor </label>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p-dropdown
            [filter]="true"
              [options]="tractores"
              formControlName="tractorId"
              placeholder="Seleccione un tractor"
              optionLabel="label"
              optionValue="value"
              [showClear]="true"
              (onChange)="mostrarDatoTractor($event, i)"
            ></p-dropdown>

            <span
              class="danger"
              *ngIf="
                transports.controls[i].get('tractorId').hasError('required') &&
                transports.controls[i].get('tractorId').touched
              "
            >
              <br />
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label *ngIf="!tractorSelecteds[i]"
              >Patente Tractor <br /><span>-</span>
            </label>
            <label *ngIf="tractorSelecteds[i]"
              >Patente Tractor: <span>{{
                tractorSelecteds[i].patent
              }}</span>
            </label>
          </div>
        </div>
        <div class="row mt-5 p-0">
          <div class="col-6">
            <label for="name" class="padding-2"
              >Semirremolques / Acoplables
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p-dropdown
            [filter]="true"
              [options]="semirremolqueAcoplados"
              formControlName="trailerId"
              placeholder="Seleccionar Semirremolques / Acoplables"
              optionLabel="label"
              optionValue="value"
              [showClear]="true"
              (onChange)="mostrarDatoTrailer($event, i)"
            >
              <ng-template let-option pTemplate="item">
                <span [class]="'trailer-' + option.value">{{
                  option.label
                }}</span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label *ngIf="!trailerSelecteds[i]"
              >Patente Semirremolques / Acoplables<br />
              <span>-</span>
            </label>
            <label *ngIf="trailerSelecteds[i]"
              >Patente Semirremolques / Acoplables: 
              <span>{{ trailerSelecteds[i].patent }}</span>
            </label>
          </div>
        </div>
        <div class="row mt-5 p-0">
          <div class="col-12">
            <button
              *ngIf="i > 0"
              pButton
              pRipple
              label="Eliminar"
              (click)="deleteInform(i)"
              icon="pi pi-trash p-button-danger"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-12 mt-5 p-0">
      <div class="col-6">
        <button
          pButton
          pRipple
          type="button"
          label="Agregar Otro Vehículo"
          (click)="addNewInform()"
          class="p-button-secondary p-button-text d-block"
        ></button>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <p-button label="cancelar" styleClass="p-mr-2" (click)="cerrarPopUp(1)">
        </p-button>
        <button
          pButton
          pRipple
          [disabled]="form.invalid"
          type="submit"
          label="Poner en curso"
          class="p-button-secondary p-button-text"
        ></button>
      </div>
    </div>
  </form>
</p-card>
