<p-dialog header="Agregar Conductor" [(visible)]="displayNewConductor">
    <app-popup-new-conductor (eventHiddenPopUpConductor)="cerrarPopUpNewConductores($event)"></app-popup-new-conductor>
</p-dialog>
<p-dialog [header]="'Documentación De ' + type" [style]="{ 'background-color': 'white', 'min-width': '50vw' }"
    [(visible)]="displayDocumentacion">
    <app-popup-documentacion [type]="type" [car]="car"></app-popup-documentacion>
</p-dialog>

<div class="card text-center" *ngIf="spinner">
    <span>
        <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
</div>

<div class="container min-width-500" *ngIf="!spinner">
    <div class="row mt-3 m-0">
        <div class="col">
        </div>
        <div class="col text-end">
            <p-fileUpload mode="basic" [files]="uploadedFiles" chooseLabel="Cargar unidades" accept=".xls, .xlsx"
                maxFileSize="1000000" (onSelect)="cargarVehiculosExcel($event)"></p-fileUpload>
        </div>
    </div>
    <p-card>
        <div class="row">
            <div class="col">
                <h1 class="title">Conductores</h1>
                <br />
            </div>
            <div class="col text-end">
                <button pButton pRipple type="button" label="Volver" class="p-button-secondary p-button-text"
                    routerLink="/transporte"></button>
                <button pButton pRipple type="button" label="Agregar" class="p-button-secondary p-button-text"
                    (click)="showDialogNewConductor()"></button>
            </div>
        </div>
        <div class="row">

            <p-card class="col-4" *ngFor="let conductor of conductores; index as i" [style]="{
        width: '90%',
        height: '28rem',
        'margin-bottom': '2em'
      }">
                <div class="row">
                    <div class="col">
                        <h1 class="title">Conductor {{ i + 1 }}</h1>
                    </div>
                    <div class="col text-end">
                        <div class="state">{{ conductor.state }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Nombre: <span> {{ conductor.fullName }} </span> </label><br />
                        <label>DNI: <span> {{ conductor.documentNumber }} </span> </label><br />
                        <label>Tipo: <span> {{ conductor.documentType }} </span> </label><br />
                        <label>Fecha de nacimiento:
                            <span>
                                {{ conductor.dateOfBirth | date : "dd/MM/YYYY" }}
                            </span> </label><br />
                        <label>Dirección: <span> {{ conductor.address }} </span> </label><br />
                        <label>Telefono: <span> {{ conductor.phone }} </span> </label><br />
                    </div>
                </div>
                <div class="buttons">
                    <p-button label="" (click)="showDialogDocumentacion('Conductor', conductor.id)"
                        pTooltip="Ver Documentación" icon="pi pi-file" styleClass="p-button-sm">
                    </p-button>
                    <p-button *ngIf="conductor.state != 'ACTIVE'" label="" icon="pi pi-check-circle"
                        styleClass="p-button-sm" pTooltip="Habilitar"
                        (click)="habilitarConductor(conductor.id)"></p-button>
                    <p-button *ngIf="conductor.state == 'ACTIVE'" label="" icon="pi pi-minus-circle
        " styleClass="p-button-sm" pTooltip="Deshabilitar" (click)="deshabilitarConductor(conductor.id)"></p-button>
                </div>
            </p-card>
        </div>

        <div class="row">
            <div class="col">
                <p-divider></p-divider>
            </div>
        </div>
    </p-card>
</div>

<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-toast></p-toast>