<div class="d-inline" *ngIf="roles">
  <div class="d-inline" *ngFor="let item of menu; let i = index">
    <li class="nav-item text-left" *ngIf="hasRole(item.roles)">
      <a [routerLink]="item.url">
        <i [class]="item.icon"></i>
        <span class="links_name">{{ item.name }}</span>
      </a>
      <span class="tooltip">{{ item.toolTip }}</span>
    </li>
  </div>
</div>
