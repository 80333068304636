import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { SolcitudesService } from 'src/app/core/services/solicitudes/solcitudes.service';
import { SolicudesAsignadasService } from 'src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { SstService } from 'src/app/services/sst/sst.service';
import { EditSolicitud } from 'src/app/shared/models/sst/editSolicitud';
import { PostSolicitud } from 'src/app/shared/models/sst/postSolicitud';
import { SolicitudAsignada } from 'src/app/shared/models/sst/solicitudAsignada';
import { Destino, Envio, Origen, solicitudes, User } from 'src/app/shared/models/sst/solicitudes';

@Component({
  selector: 'app-transferir-sst',
  templateUrl: './transferir-sst.component.html',
  styleUrls: ['./transferir-sst.component.scss']
})
export class TransferirSstComponent implements OnInit, OnDestroy {
  form: FormGroup;

  vistaPreliminar: boolean = false
  popUpGenerarSsta: boolean = false;
  checked: boolean = false;

  programadores: User[] = []

  solicitudes: PostSolicitud[] = [];
  sstaVistaPreliminar = new SolicitudAsignada()
  rol: string

  url: string

  tipo: string;

  requests: number[] = [];

  constructor(private authService: AuthService, private sstService: SstService,
    private activatedRoute: ActivatedRoute,
    private solcitudService: SolcitudesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, route: ActivatedRoute,
    private userService: UserService,
    private router: Router) {
    this.rol = this.authService.queryRole()
    this.url = route.snapshot.url[0].path;
    this.userService.getByRol("programador").subscribe(res => {
      res.forEach(e => {
        this.programadores.push({ name: e.username, code: e.id })
      });
    })
  }


  ngOnInit(): void {
    this.form = new FormGroup(
      {
        programador: new FormControl('', [Validators.required]),
      });
    this.solicitudes = JSON.parse(localStorage.getItem('sstTransfer')) || []
    let alto = 0;
    let ancho = 0;
    let largo = 0;
    this.sstaVistaPreliminar.requests = this.solicitudes;
    this.solicitudes.forEach(d => {
      this.requests.push(d.id)
      d.packages.forEach(p => {
        alto = alto + Number(p.height);
        ancho = ancho + Number(p.width);
        largo = largo + Number(p.length);
      })
    })
  }

  ngOnDestroy(): void {
    localStorage.removeItem("sstTransfer");
  }


  transferir() {
    let data = {
      programmerId : (this.form.get("programador").value),
      requestIds : []

    }
    this.solicitudes.forEach(s => {
      data.requestIds.push(s.id)
    })

    this.solcitudService.transferirSst(data).subscribe(({
      next : (data)=>{
        this.messageService.add({
          key: 'msg',
          severity: "success",
          summary: "Confirmado",
          detail: "Las solicitudes se reasignaron con exito!",
        });
        this.router.navigate(["/solicitudes"]);
      },
      error : (err)=>{
        this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error', detail: 'Ups! Ocurrio un error. Posiblemete algunas de las solicitudes no puedieron ser reasignadas' });
      }
    }))
  }


}
