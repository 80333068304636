import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { transportista } from 'src/app/shared/models/transportista/transportista';

@Injectable({
  providedIn: 'root'
})
export class TransportistaService {

  private _transportistas : transportista[] = []

  private transportista:transportista = {
    id: 1,
    nombre : "Javier",
    razonSocial : "eeee",
    cuit : "4321",
    direccion : "calle falsa 123",
    telefono : "112233445566",
    email : "algo@gmail.com",
    usuario : "javier12",
    password : "1234",
    estado : {
      id : 1,
      description : "Habilitado"
    },
    estadoDescription : "habilitado",
    estadoId : 1
  }

  get transportistas():transportista[]{
    return [...this._transportistas];
  }

  constructor(private http: HttpClient) {
     this.getTransportistas();
   }

  async getSolicitudes() {
    const res = await this.http
      .get<any>("assets/transportista-large.json")
      .toPromise();
    const data = <transportista[]>res.data;
    return data;
  }

  getTransportistas(){
    return this.http.get("assets/transportista-large.json").subscribe((response:any)=>{
      response.data.forEach((t) => {
        t.estadoId = t.estado.id;
        t.estadoDescription = t.estado.description;
      });
      
      this._transportistas = response.data;
      
    })
  }

  getTransportistaById(id){
    // return this._transportistas.find(t => t.id === id);
    return this.transportista;
  }
}
