<li class="text-left">
  <a href="/home">
    <i class="bi bi-house"></i>
    <span class="links_name">Home</span>
  </a>
  <span class="tooltip">Home</span>
</li>

<li class="text-left">
  <a href="/solicitudes">
    <i class="bi bi-file-earmark-text"></i>
    <span class="links_name">Solicitudes</span>
  </a>
  <span class="tooltip">Solicitudes</span>
</li>
<li class="text-left">
  <a href="/crear-sst">
    <i class="bi bi-file-earmark-plus"></i>

    <span class="links_name">Crear SST</span>
  </a>

  <span class="tooltip"></span>
</li>

<li class="text-left">
  <a href="/solicitudes">
    <i class="bi bi-file-text"></i>

    <span class="links_name">Reportes/KPI</span>
  </a>

  <span class="tooltip"></span>
</li>
