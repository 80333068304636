import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-transport',
  templateUrl: './list-transport.component.html',
  styleUrls: ['./list-transport.component.scss']
})
export class ListTransportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
