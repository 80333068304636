import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { concat, of } from "rxjs";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { ConductoresService } from "src/app/core/services/transporte/conductores/conductores.service";
import { TractoresService } from "src/app/core/services/transporte/tractores/tractores.service";
import { TrailersService } from "src/app/core/services/transporte/trailers/trailers.service";
import { UserService } from "src/app/core/services/user/user.service";
import { SstService } from "src/app/services/sst/sst.service";
import { InformSsta } from "src/app/shared/models/modelsForm/informSsta";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import { solicitudes } from "src/app/shared/models/sst/solicitudes";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-informar-ssta",
  templateUrl: "./informar-ssta.component.html",
  styleUrls: ["./informar-ssta.component.scss"],
})
export class InformarSstaComponent implements OnInit, OnDestroy {
  spinner = true;
  solicitudesId: number[] = [];
  sstas: any = [];
  rol: string;
  idSst: any;
  displayHabilitaciones = false;
  form: FormGroup;
  formDates: FormGroup;

  choferSelecteds = [];
  trailerSelecteds = [];
  tractorSelecteds = [];

  tipos = [
    { name: "Caja ", code: "BOX" },
    { name: "Bulto ", code: "BULTO" },
    { name: "Liquido ", code: "LIQUID" },
    { name: "Otro ", code: "OTHER" },
  ];

  tractores: any = [];
  semirremolqueAcoplados: any = [];
  conductores: any = [];

  items: any[] = [];
  filteredItems: any[];

  userActual: User;
  roles: string[] = [];
  idUserActual = "";

  query: any[] = [];

  constructor(
    private messageService: MessageService,
    private sstService: SstService,
    private solicitudesAsignadasService: SolicudesAsignadasService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private conductoresService: ConductoresService,
    private tractorService: TractoresService,
    private trailerService: TrailersService,
    private router: Router,
    private datepipe: DatePipe
  ) {
    this.rol = this.authService.queryRole();
  }

  ngOnInit() {
    this.solicitudesId =
      JSON.parse(localStorage.getItem("sstReportSstaId")) || [];
    this.sstas = JSON.parse(localStorage.getItem("sstReportSsta")) || [];

    this.rolesInit()
  }


  rolesInit() {
    this.authService
      .actual()
      .pipe(
        tap({
          next: (data) => {
            this.userActual = data;
            this.idUserActual = data.id;
            this.roles = data.rolenames;
          },
          complete: () => {
          },
        }),
        concatMap(() => concat(of(this.parametros()))),
        concatMap(() => this.getConductores()),
        concatMap(() => this.getTractores()),
        concatMap(() => this.getTrailers()),
        concatMap(() => concat(of(this.startForm()))),
        concatMap(() => concat(of(this.startFormDate()))),
      ).subscribe()


  }

  parametros() {
    if (this.idUserActual) {
      if (this.hasRole("TRANSPORTER")) {
        let findTransporter = this.query.find(
          (d) => d.col == "transporterId"
        );
        if (findTransporter) {
          let index = this.query.indexOf(findTransporter);
          this.query.splice(index, 1);
        }
        this.query.push({ col: "transporterId", value: this.idUserActual });
      }
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem("sstReportSstaId");
    localStorage.removeItem("sstReportSsta");
  }

  showDialogHabilitaciones() {
    this.displayHabilitaciones = true;
  }

  startForm() {
    this.form = new FormGroup({
      ids: new FormControl(this.solicitudesId),
      transports: new FormArray([this.crearNewInform()]),
    });
  }

  startFormDate() {
    this.formDates = new FormGroup({
      sstas: new FormArray([]),
    });

    this.sstas.forEach(element => {
      this.addNewDateForm(element)
    });
  }

  crearNewDateForm(ssta?:any) {
    return new FormGroup({
      id: new FormControl(ssta?.id || ""),
      fechaRetiro: new FormControl(ssta ? new Date(this.datepipe.transform(ssta.programmingDate, 'yyyy/MM/dd'))  : "", [Validators.required]),
    });
  }

  addNewDateForm(ssta:any) {
    let newItem = this.crearNewDateForm(ssta);
    this.sstasDateFormsArray.push(newItem);
  }


  get sstasDateFormsArray(): FormArray {
    return this.formDates.get("sstas") as FormArray;
  }
  get transports(): FormArray {
    return this.form.get("transports") as FormArray;
  }

  getConductores() {
    return this.conductoresService.getAll(this.query).pipe(
      tap({
        next: (data) => {
          const list = data.list;
          for (let i = 0; i < list.length; i++) {
            this.conductores.push({
              label: list[i].fullName,
              value: list[i].id,
              document: list[i].documentNumber,
              phone: list[i].phone,
            });
          }
        }
      }
      )
    )
  }

  getTractores() {
    return this.tractorService.getAll(this.query).pipe(
      tap({
        next: (data) => {
          const list = data.list
          for (let i = 0; i < list.length; i++) {
            this.tractores.push({
              label: list[i].patent + " - " + list[i].brand,
              value: list[i].id,
            });
          }
        }
      }
      )
    )
  }

  getTrailers() {
    return this.trailerService.getAll(this.query).pipe(
      tap({
        next: (data) => {
          const list = data.list;
          for (let i = 0; i < list.length; i++) {
            this.semirremolqueAcoplados.push({
              label: list[i].model + " - " + list[i].brand,
              value: list[i].id,
              patent: list[i].patent,
            });
          }
        },
        complete : ()=>{
          this.spinner = false;
        }
      }
      )
    )
  }

  mostrarDatosChofer(event, indice) {
    if (event.value && event.value > 0) {
      this.conductoresService.getById(event.value).subscribe((data) => {
        this.choferSelecteds[indice] = data;
      });
    }
    this.choferSelecteds[indice] = null;
  }

  mostrarDatoTrailer(event, indice) {
    if (event.value && event.value > 0) {
      this.trailerService.getById(event.value).subscribe((data) => {
        this.trailerSelecteds[indice] = data;
      });
    }
    this.trailerSelecteds[indice] = null;
  }

  mostrarDatoTractor(event, indice) {
    if (event.value && event.value > 0) {
      this.tractorService.getById(event.value).subscribe((data) => {
        this.tractorSelecteds[indice] = data;
      });
    }
    this.tractorSelecteds[indice] = null;
  }

  crearNewInform() {
    return new FormGroup({
      driverId: new FormControl("", [Validators.required]),
      id: new FormControl(""),
      // packageTypeList: new FormControl([]),
      // quantity: new FormControl("", [Validators.required]),
      tractorId: new FormControl("", [Validators.required]),
      trailerId: new FormControl(""),
    });
  }

  addNewInform() {
    let newInform = this.crearNewInform();
    this.transports.push(newInform);
  }

  deleteInform(idx: number) {
    this.choferSelecteds[idx] = null;
    this.trailerSelecteds[idx] = null;
    this.tractorSelecteds[idx] = null;
    this.transports.removeAt(idx);
  }

  crear(): void {
    let informSsta: InformSsta = new InformSsta();

    this.sstasDateFormsArray.controls.forEach(element => {
      informSsta.informs.push(
        { 
          id:  element.get('id').value,
          pickupDate:  this.datepipe.transform(element.get('fechaRetiro').value, 'yyyy-MM-dd'),
        }
      )
    });

    this.transports.controls.forEach((t: FormGroup) => {
      if (t.get("trailerId").value) {
        informSsta.transports.push({
          driverId: t.get("driverId").value,
          id: 0,
          tractorId: t.get("tractorId").value,
          trailerId: t.get("trailerId").value,
        });
      } else {
        informSsta.transports.push({
          driverId: t.get("driverId").value,
          id: 0,
          tractorId: t.get("tractorId").value,
        });
      }
    });

    this.solicitudesAsignadasService.informarSsta(informSsta).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Hecho!",
          detail: "Las SSTA fueron informadas correctamente ",
        });
        this.router.navigate(["/solicitudes-asignadas"]);
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  filterItems(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      if (item.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(item);
      }
    }

    this.filteredItems = filtered;
  }

  filterCountry(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.tipos.length; i++) {
      let country = this.tipos[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.tipos = filtered;
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
