import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ExcelService } from "src/app/core/services/documentos/excel.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import {
  Destino,
  Envio,
  Origen,
  solicitudes,
  User,
} from "src/app/shared/models/sst/solicitudes";

@Component({
  selector: "app-reportes-ssta-rechazadas",
  templateUrl: "./reportes-ssta-rechazadas.component.html",
  styleUrls: ["./reportes-ssta-rechazadas.component.scss"],
})
export class ReportesSstaRechazadasComponent implements OnInit {
  reportSst: any[] = [];

  users: User[] = [];

  transportistas: User[] = [];

  querySolicitudAsignada: any[] = [];

  porcentajeTotal: string = "";

  statuses: any[];

  cols: any[] = [];

  spinner = true;

  rol: string;

  roles: string[] = [];

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private authService: AuthService,
    private solicitudesAsignadas: SolicudesAsignadasService,
    private excelService: ExcelService
  ) {
  }

  ngOnInit(): void {
    this.getReportSstaPorcentajeRechazadas();
    this.getReportSstaRechazadasByTransportistas();

    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  getData() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.roles = data.rolenames
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
        }
      }),
      concatMap(() => this.getTransportistas()),
      concatMap(() => this.getReportSstaPorcentajeRechazadas()),
      concatMap(() => this.getReportSstaRechazadasByTransportistas()),
    )
  }

  getTransportistas() {
    return this.userService.getByRol("transportista").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.transportistas.push({ name: e.username, code: e.id });
            } else {
              this.transportistas.push({
                name: (e.companyName || e.username),
                code: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }

  getReportSstaPorcentajeRechazadas() {
    return this.solicitudesAsignadas.reportSstaPorcentajeRechazadas(this.querySolicitudAsignada)
      .pipe(
        tap({
          next: (data) => {
            this.porcentajeTotal = data;

          },
          error: (error) => {
            (error) => {
              this.messageService.add({
                key: "msg",
                severity: "error",
                summary: "Error",
                detail: error.error ? error.error.message : "Ups! ocurrio un error",
              });
            }
          }
        })
      )
  }

  getReportSstaRechazadasByTransportistas() {
    return this.solicitudesAsignadas
      .reportSstaRechazadasByTransportistas(this.querySolicitudAsignada).pipe(
        tap({
          next: (data) => {
          this.users = data;
          },
          error: () => {
            (error) => {
              this.messageService.add({
                key: "msg",
                severity: "error",
                summary: "Error",
                detail: error.error ? error.error.message : "Ups! ocurrio un error",
              });
            }
          }
        }
        )
      )
  }

  transportistaSolicitudAsignada = "";
  minDateValue = new Date();
  startDateSolicitud = "";
  endDateSolicitud = ""

  filtrosSolicitudAsignada() {
    this.querySolicitudAsignada = [];

    if (this.startDateSolicitud) {
      this.minDateValue = new Date(this.startDateSolicitud);
    }

    if (this.startDateSolicitud && this.endDateSolicitud) {
      this.querySolicitudAsignada.push({
        col: "startDate",
        value: this.startDateSolicitud,
      });
      this.querySolicitudAsignada.push({
        col: "endDate",
        value: this.endDateSolicitud,
      });
    }

    if (this.transportistaSolicitudAsignada) {
      this.querySolicitudAsignada.push({
        col: "transporterId",
        value: this.transportistaSolicitudAsignada,
      });
    }
    if (this.querySolicitudAsignada.length > 0) {
      this.spinner = true;
    }
    this.getReportSstaRechazadasByTransportistas().subscribe();
    this.getReportSstaPorcentajeRechazadas().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  descargarExcel() {
    this.excelService.descargarSstaRechazadoByTransportista(this.querySolicitudAsignada).subscribe(
      (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = `reporte-rechazadas-SSTA.xlsx`;
        link.click();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
