import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-proveedor',
  templateUrl: './menu-proveedor.component.html',
  styleUrls: ['./menu-proveedor.component.scss']
})
export class MenuProveedorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
