<div class="container min-width-500">
    <div class="header">
      <h2>Documentos<br /></h2>
    </div>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br />
      <span class="text-center">Cargando...</span>
    </div>
    <p-card class="card-form" *ngIf="!spinner && form">
      <form [formGroup]="form" (ngSubmit)="agregarDocumentos()" class="form">
        <div class="row col-12 p-0">
          <div class="col">
            <h4 class="title">Agregar Documentos</h4>
          </div>
        </div>
        <div class="row col-12 mt-5">
          <div class="col m-0 p-0">
            <div class="card">
              <p-fileUpload
                chooseLabel="Adjuntar documentación"
                [files]="uploadedFiles"
                customUpload="true"
                (onSelect)="selectFile($event)"
                (uploadHandler)="onUpload($event)"
                multiple="multiple"
                maxFileSize="1000000"
                [auto]="true"
              >
                <ng-template pTemplate="toolbar" class="m-0 p-0">
                  <div class="row">
                    <div class="col">
                      {{ cantidad + " archivo seleccionado/s" }}
                    </div>
                  </div>
                  <div *ngIf="uploadedFiles.length > 0" class="">
                    <div *ngFor="let file of uploadedFiles; let i = index">
                      <div class="row mt-5 text-center" *ngIf="file">
                        <div class="col-6">
                          <p
                            pTooltip="Descargar"
                            class="cursor"
                            (click)="descargarDocumento(file)"
                          >
                            {{ file.name }}
                          </p>
                        </div>
                        <div class="col-6">
                          <button
                            (click)="eliminarDocumento(file, i)"
                            type="button"
                            icon="pi pi-times"
                            pbutton=""
                            class="p-button p-button-icon-only"
                          >
                            <span
                              class="p-button-icon pi pi-times"
                              aria-hidden="true"
                            ></span
                            ><span aria-hidden="true" class="p-button-label"
                              >&nbsp;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>
        </div>
        <div class="row col-12 mt-5">
          <div class="col text-center">
            <p-button
              type="submit"
              label="Agregar Documentacion"
              class="p-button-primary p-button-text"
            ></p-button>
          </div>
        </div>
      </form>
    </p-card>
  </div>
  