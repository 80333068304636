import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { finalize } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";

@Component({
  selector: "app-document-sst",
  templateUrl: "./document-sst.component.html",
  styleUrls: ["./document-sst.component.scss"],
})
export class DocumentSstComponent implements OnInit, OnDestroy {
  sst: any;
  rol: string;
  idSst: any;
  spinner = true;
  detalles = [];
  displayHabilitaciones = false;
  url: string;
  mensajeVacio: Message[];

  urlBefore = "";

  documents: [] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private solcitudAsignadasService: SolicudesAsignadasService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService,
    private messageService: MessageService
  ) {
    this.url = router.url;
    this.rol = this.authService.queryRole();
  }
  ngOnDestroy(): void {
    localStorage.removeItem("navigateUpdateSstaDocument");
    localStorage.removeItem("navigateDetailsSstaDocument");
  }

  ngOnInit(): void {
    let { id } = this.activatedRoute.snapshot.params;

    this.solcitudService
      .getById(id)
      .pipe(
        finalize(() => {
          this.idSst = id;
        })
      )
      .subscribe((res: any) => {
        this.sst = res;
        if (this.sst.documentation.length <= 0) {
          this.mensajeVacio = [
            {
              severity: "info",
              summary: "Está SST no tiene documentos para mostrar",
            },
          ];
        }
        this.spinner = false;
      });

    if (localStorage.getItem("navigateUpdateSstaDocument")) {
      this.urlBefore = localStorage.getItem("navigateUpdateSstaDocument");
    } else if(localStorage.getItem("navigateDetailsSstaDocument")){
      this.urlBefore = localStorage.getItem("navigateDetailsSstaDocument");
    }  else {
      this.urlBefore = "/details-sst/" + id;
    }
  }

  descargarDocumento(file) {
    this.fileService.getFile(file.id).subscribe(
      (data) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Descargado",
        });
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }
}
