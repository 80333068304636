import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserService } from 'src/app/core/services/user/user.service';
import { User } from 'src/app/shared/models/user/user';

@Component({
  selector: 'app-alta-transportista',
  templateUrl: './alta-transportista.component.html',
  styleUrls: ['./alta-transportista.component.scss']
})
export class AltaTransportistaComponent implements OnInit {

  user: User = new User();

  form:FormGroup;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService:UserService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup(
      {nombre: new FormControl('',[Validators.required]),
       razonSocial: new FormControl('',[Validators.required]),
       cuit: new FormControl('',[Validators.required]),
       direccion: new FormControl('',[Validators.required]),
       telefono: new FormControl('',[Validators.required]),
       emails: new FormArray([this.createEmailUser()]),
       usuario: new FormControl('', [Validators.required]),
       contraseña: new FormControl('', [Validators.required]),
    }); 
  }

  createEmailUser() {
    return new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  addEmailUser() {
    let newEmail = this.createEmailUser();
    this.emailsUserModel.push(newEmail);
  }

  deleteEmailUser(idx: number) {
    this.emailsUserModel.removeAt(idx);
  }

  get emailsUserModel(): FormArray {
    return this.form.get("emails") as FormArray;
  }

  crearTransportista(){
    this.user.username = this.form.get("usuario").value;
    this.user.address = this.form.get("direccion").value;
    this.user.companyName = this.form.get("razonSocial").value;
    this.user.password = this.form.get("contraseña").value;
    this.user.phone = this.form.get("telefono").value;
    this.user.taxPayerId = this.form.get("cuit").value;
    this.user.rolenames = ["TRANSPORTER"]
    this.user.state = "ACTIVE"

    this.emailsUserModel.controls.forEach((f) => {
      this.user.emails.push(f.value.email);
    });

    this.userService.createUser(this.user).subscribe(
      (resp) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Creado",
          detail: "¡El transportista ha sido dado de alta con exito!",
        });
         this.router.navigate(["/transportistas"]);
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail:(err.error)? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
    
  }

}
