import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { registerLocaleData } from "@angular/common";
import localeAr from "@angular/common/locales/es-AR";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CookieService } from "ngx-cookie-service";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { MessageService } from "primeng/api";
import { ShellComponent } from "./modules/shell/pages/shell/shell.component";
import { FooterComponent } from "./modules/shell/pages/footer/footer.component";
import { MainComponent } from "./modules/shell/pages/main/main.component";
import { LoginComponent } from "./modules/login/pages/login/login.component";
import { HomeComponent } from "./modules/home/pages/home/home.component";
import { PrimengModule } from "./shared/primeng/primeng.module";
import { MenuUsuarioInternoComponent } from "./modules/shell/components/menu-usuario-interno/menu-usuario-interno.component";
import { MenuAdministradorComponent } from "./modules/shell/components/menu-administrador/menu-administrador.component";
import { MenuGerencialComponent } from "./modules/shell/components/menu-gerencial/menu-gerencial.component";
import { MenuLiderDePlanificacionComponent } from "./modules/shell/components/menu-lider-de-planificacion/menu-lider-de-planificacion.component";
import { MenuProgramadorLogisticaComponent } from "./modules/shell/components/menu-programador-logistica/menu-programador-logistica.component";
import { MenuProveedorComponent } from "./modules/shell/components/menu-proveedor/menu-proveedor.component";
import { MenuTransportistaComponent } from "./modules/shell/components/menu-transportista/menu-transportista.component";
import { TransportistaModule } from "./components/transportista/transportista.module";
import { ProveedorModule } from "./components/proveedor/proveedor.module";
import { RegistroComponent } from "./modules/login/pages/registro/registro.component";
import { CreateProgramadorComponent } from "./components/programador/create-programador/create-programador.component";
import { DetailsProgramadorComponent } from "./components/programador/details-programador/details-programador.component";
import { UpdateProgramadorComponent } from "./components/programador/update-programador/update-programador.component";
import { ListProgramadorComponent } from "./components/programador/list-programador/list-programador.component";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RecuperarComponent } from "./modules/login/pages/recuperar/recuperar.component";
import { CambiarContraseniaComponent } from "./modules/login/pages/cambiar-contrasenia/cambiar-contrasenia.component";
import { ReportesModule } from "./components/reportes/reportes.module";

import {
  MsalModule,
  MsalService,
  MSAL_INSTANCE,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalGuard,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import { FiltrosComponent } from './shared/filtros/filtros.component';
import { SstModule } from "./components/sst/sst.module";
import { VehiculosModule } from "./components/vehiculos/vehiculos.module";
import { MenuGeneralComponent } from './modules/shell/components/menu-general/menu-general.component';
import { RolesModule } from "./components/roles/roles.module";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TableroGeneralModule } from "./components/tablero-general/tablero-general.module";
import { ApexchartModule } from "./shared/apexchart/apexchart.module";

export function cargarTraductorJson(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}
// tenant-id=8cc0318e-b04a-40e4-83b6-d6b0ed536b74
// client-secret=hM-8Q~uPYTCtawRgGgFGRWh6_yVU1VZQh2etIc7e

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      authority: environment.authority,

    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ["openid", "profile"]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

registerLocaleData(localeAr, "es-AR");

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    FooterComponent,
    MainComponent,
    LoginComponent,
    HomeComponent,
    MenuUsuarioInternoComponent,
    MenuAdministradorComponent,
    MenuGerencialComponent,
    MenuLiderDePlanificacionComponent,
    MenuProgramadorLogisticaComponent,
    MenuProveedorComponent,
    MenuTransportistaComponent,
    RegistroComponent,
    CreateProgramadorComponent,
    DetailsProgramadorComponent,
    UpdateProgramadorComponent,
    ListProgramadorComponent,
    RecuperarComponent,
    CambiarContraseniaComponent,
    FiltrosComponent,
    MenuGeneralComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    MatCarouselModule.forRoot(),
    PrimengModule,
    ApexchartModule,
    SstModule,
    TransportistaModule,
    ProveedorModule,
    ReportesModule,
    VehiculosModule,
    RolesModule,
    TableroGeneralModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: cargarTraductorJson,
        deps: [HttpClient],
      },
    }),
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    CookieService,
    DialogService,DynamicDialogRef,DynamicDialogConfig,
    {
      provide: MAT_DATE_LOCALE,
      useValue: "es-AR",
    },
    {
      provide: LOCALE_ID,
      useValue: "es-AR",
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "82221627499-6u6omqdmfrkh2u9hi8tjah9a9j9a5gul.apps.googleusercontent.com"
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
