import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserService } from 'src/app/core/services/user/user.service';
import { proveedor } from 'src/app/shared/models/proveedor/proveedor';
import { transportista } from 'src/app/shared/models/transportista/transportista';
import { User } from 'src/app/shared/models/user/user';
import { TransportistaService } from '../../transportista/services/transportista.service';
@Component({
  selector: 'app-details-programador',
  templateUrl: './details-programador.component.html',
  styleUrls: ['./details-programador.component.scss']
})
export class DetailsProgramadorComponent implements OnInit {

  id;
  programador:User;
  spinner = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private transportistaService: TransportistaService,
    private userService:UserService
  ) {}

  ngOnInit(): void {
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id;
    this.userService.getByUserId(id).subscribe((user: User) => {
      this.programador = user;
      this.spinner = false;
    });
  }

  formatearCUIT(cuit: any): string {
    if (cuit) {

      const cuitComoCadena = cuit.toString();

      // Verificar que la longitud sea la correcta (11 dígitos)
      if (cuitComoCadena.length !== 11) {
        return cuit
      }

      // Formatear como "11-31412324-5"
      const primeraParte = cuitComoCadena.slice(0, 2);
      const segundaParte = cuitComoCadena.slice(2, 10);
      const terceraParte = cuitComoCadena.slice(10);

      return `${primeraParte}-${segundaParte}-${terceraParte}`;
    }

  }

}
