import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AltaProveedorComponent } from './alta-proveedor/alta-proveedor.component';
import { DetailsProveedorComponent } from './details-proveedor/details-proveedor.component';
import { EditProveedorComponent } from './edit-proveedor/edit-proveedor.component';
import { ListProveedorComponent } from './list-proveedor/list-proveedor.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SocialLoginModule } from 'angularx-social-login';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';


@NgModule({
  declarations: [AltaProveedorComponent, EditProveedorComponent, ListProveedorComponent, DetailsProveedorComponent],
  imports: [
    CommonModule,
    PrimengModule,
    BrowserModule, HttpClientModule, FormsModule, AppRoutingModule, BrowserAnimationsModule, SocialLoginModule, MatCarouselModule.forRoot()
  ],
  exports: [AltaProveedorComponent, EditProveedorComponent, ListProveedorComponent, DetailsProveedorComponent],

})
export class ProveedorModule { }
