<div class="container min-width-500">
  <div class="header">
    <h2>Crear Proveedor</h2>
  </div>
  <div class="card col-8">
    <form [formGroup]="form" (ngSubmit)="crearProveedor()" class="form">
      <div class="row col-12 mt-5 p-0">
        <div class="col-4">
          <label for="nombre" class="pl-5">Nombre </label>
        </div>
        <div class="col-8">
          <input
            type="text"
            pInputText
            formControlName="nombre"
            class="form-control"
          />
          <span
            class="danger"
            *ngIf="
              form.get('nombre').hasError('required') &&
              form.get('nombre').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5 p-0">
        <div class="col-4">
          <label for="razonSocial" class="pl-5">Razón social </label>
        </div>
        <div class="col-8">
          <input
            type="text"
            pInputText
            formControlName="razonSocial"
            class="form-control"
          />
          <span
            class="danger"
            *ngIf="
              form.get('razonSocial').hasError('required') &&
              form.get('razonSocial').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5 p-0">
        <div class="col-4">
          <label for="cuit" class="pl-5">CUIT </label>
        </div>
        <div class="col-8">
          <input
            type="text"
            pInputText
            formControlName="cuit"
            class="form-control"
          />
          <span
            class="danger"
            *ngIf="
              form.get('cuit').hasError('required') && form.get('cuit').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5 p-0">
        <div class="col-4">
          <label for="direccion" class="pl-5">Dirección </label>
        </div>
        <div class="col-8">
          <input
            type="text"
            pInputText
            formControlName="direccion"
            class="form-control"
          />
          <span
            class="danger"
            *ngIf="
              form.get('direccion').hasError('required') &&
              form.get('direccion').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5 p-0">
        <div class="col-4">
          <label for="telefono" class="pl-5">Télefono </label>
        </div>
        <div class="col-8">
          <input
            type="text"
            pInputText
            formControlName="telefono"
            class="form-control"
          />
          <span
            class="danger"
            *ngIf="
              form.get('telefono').hasError('required') &&
              form.get('telefono').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div formArrayName="emails" class="row col-12 mt-5 p-0">
        <div
          *ngFor="let email of emailsUserModel.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="row">
            <div class="col-4">
              <label *ngIf="emailsUserModel.length == 1" class="pl-5" for=""
                >Mail</label
              >
              <label *ngIf="emailsUserModel.length > 1" class="pl-5" for=""
                >Mail {{ i + 1 }}</label
              >
            </div>
            <div class="col-8">
              <input
                type="mail"
                formControlName="email"
                pInputText
                placeholder="username@gmail.com.ar"
                class=""
                [style]="{width: '90%'}"
              />
              <button
                *ngIf="i == 0"
                type="button"
                pButton
                pRipple
                icon="pi pi-plus"
                styleClass="p-button-warn"
                pTooltip="Agregar Email"
                (click)="addEmailUser()"
              ></button>
              <button
                *ngIf="i != 0"
                type="button"
                pButton
                pRipple
                icon="pi pi-trash"
                styleClass="p-button-warn"
                pTooltip="Eliminar Email"
                (click)="deleteEmailUser(i)"
              ></button>
              <div
                *ngIf="
                  emailsUserModel.controls[i]
                    .get('email')
                    .hasError('required') &&
                  emailsUserModel.controls[i].get('email').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </div>
              <div
                *ngIf="
                  emailsUserModel.controls[i].get('email').hasError('email') &&
                  emailsUserModel.controls[i].get('email').touched
                "
              >
                <small class="p-error"
                  >El email debe ser uno <strong>valido</strong></small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-12 mt-5 p-0">
        <div class="col-4">
          <label for="usuario" class="pl-5">Usuario </label>
        </div>
        <div class="col-8">
          <input
            type="text"
            pInputText
            formControlName="usuario"
            class="form-control"
          />
          <span
            class="danger"
            *ngIf="
              form.get('usuario').hasError('required') &&
              form.get('usuario').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5 p-0">
        <div class="col-4">
          <label for="contraseña" class="pl-5">Contraseña </label>
        </div>
        <div class="col-8">
          <p-password
            formControlName="contraseña"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [toggleMask]="true"
          ></p-password>
          <span
            class="danger"
            *ngIf="
              form.get('contraseña').hasError('required') &&
              form.get('contraseña').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row col-12 mt-5">
        <div class="col-6">
          <!-- <button
            pButton
            pRipple
            type="button"
            label="Cargar foto"
            class="p-button-secondary p-button-text"
          ></button> -->
        </div>
        <div class="col-6 text-right">
          <p-button
            type="submit"
            [disabled]="form.invalid"
            label="Generar Alta usuario"
            class="p-button-primary p-button-text float-end"
          ></p-button>
        </div>
      </div>
    </form>
  </div>
</div>
<p-toast></p-toast>
