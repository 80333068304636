import { DatePipe } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { SolcitudesService } from 'src/app/core/services/solicitudes/solcitudes.service';
import { SolicudesAsignadasService } from 'src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { SstService } from 'src/app/services/sst/sst.service';
import { EditSolicitud } from 'src/app/shared/models/sst/editSolicitud';
import { PostSolicitud } from 'src/app/shared/models/sst/postSolicitud';
import { SolicitudAsignada } from 'src/app/shared/models/sst/solicitudAsignada';
import { Destino, Envio, Origen, solicitudes, User } from 'src/app/shared/models/sst/solicitudes';

@Component({
  selector: 'app-delegate-sst',
  templateUrl: './delegate-sst.component.html',
  styleUrls: ['./delegate-sst.component.scss']
})
export class DelegateSstComponent implements OnInit, OnDestroy {

  form: FormGroup;
  programadores: any[] = []

  rol: string

  url: string

  userActual: any;
  roles: string[] = [];
  idUserActual = "";

  spinner = true;

  minDate = new Date()

  constructor(private authService: AuthService, private sstService: SstService,
    private activatedRoute: ActivatedRoute,
    private solcitudService: SolcitudesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, route: ActivatedRoute,
    private userService: UserService,
    private router: Router) {
    this.url = route.snapshot.url[0].path;
  }
  ngOnInit(): void {
    this.rolesInit()

    this.form = new FormGroup(
      {
        programador: new FormControl('', [Validators.required]),
        fechaDesde: new FormControl('', [Validators.required]),
        fechaHasta: new FormControl({ value: '', disabled: true }, [Validators.required]),
      });
  }

  ngOnDestroy(): void {
  }

  rolesInit() {
    this.authService.actual().subscribe({
      next: (data) => {
        this.userActual = data;
        this.roles = data.rolenames
        this.idUserActual = data.id
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }, complete: () => {
        this.spinner = false;
        this.getProgramadores()

      }
    })
  }

  getProgramadores() {
    this.userService.getByRol("PROGRAMMER").subscribe((res) => {
      res.forEach((e: any) => {
        if (this.userActual?.id != e.id) {
          if (e.azure) {
            this.programadores.push({ label: e.username, value: e.id });
          } else {
            this.programadores.push({ label: e.companyName, value: e.id });
          }
        }
      });
    });
  }

  enableToDate() {
    this.form.get('fechaHasta').enable()
  }

  delegarSsts() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea delegar las SSTs?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        let data = {
          programmerId: this.form.get('programador').value,
          startDate: this.form.get('fechaDesde').value,
          endDate: this.form.get('fechaHasta').value,
        }
        this.solcitudService.delegarSst(data).subscribe(({
          next: (data) => {
            this.messageService.add({
              key: 'msg',
              severity: "success",
              summary: "Confirmado",
              detail: "Las solicitudes se delegaron con exito!",
            });
          },
          error: (err) => {
            this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error', detail: 'Ups! Ocurrio un error. Posiblemete algunas de las solicitudes no puedieron ser reasignadas' });
          },
          complete: () => {
            this.form.reset();
            // window.location.reload();
          }
        }))
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });




  }

}
