import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { UserService } from "src/app/core/services/user/user.service";
import { transportista } from "src/app/shared/models/transportista/transportista";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-details-transportista",
  templateUrl: "./details-transportista.component.html",
  styleUrls: ["./details-transportista.component.scss"],
})
export class DetailsTransportistaComponent implements OnInit {
  
  id;
  transportista:User;
  spinner = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService:UserService
  ) {}

  ngOnInit(): void {
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id;
    this.userService.getByUserId(id).subscribe((user: User) => {
      this.transportista = user;
      this.spinner = false;
    });
  }

  formatearCUIT(cuit: any): string {
    if (cuit) {

      const cuitComoCadena = cuit.toString();

      // Verificar que la longitud sea la correcta (11 dígitos)
      if (cuitComoCadena.length !== 11) {
        return cuit
      }

      // Formatear como "11-31412324-5"
      const primeraParte = cuitComoCadena.slice(0, 2);
      const segundaParte = cuitComoCadena.slice(2, 10);
      const terceraParte = cuitComoCadena.slice(10);

      return `${primeraParte}-${segundaParte}-${terceraParte}`;
    }
  }
    
}
