<p-table [value]="sstsDataProgrammer.usuarios" dataKey="id" [scrollable]="true"
scrollHeight="35vh" [rowHover]="true" [tableStyle]="{ 'min-width': '50rem' }"
styleClass="p-datatable p-datatable-striped ">
<ng-template pTemplate="header">
    <tr>
        <th>Usuario</th>
        <th>Asignadas</th>
        <th>Canceladas</th>
        <th>Eliminada</th>
        <th>Guardadas</th>
        <th>Pendientes</th>
        <th>Rechazadas</th>
        <th>Total General</th>
    </tr>
</ng-template>
<ng-template pTemplate="body" let-usuario>
    <tr>
        <td class="cursor" (click)="searchLocalStorageSst(usuario.id)">{{usuario.username}}
        </td>
        <td class="cursor" (click)="searchLocalStorageSst(usuario.id,'ASSIGNED')">
            {{usuario.asignadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSst(usuario.id,'CANCELLED')">
            {{usuario.canceladas}}</td>
        <td class="cursor" (click)="searchLocalStorageSst(usuario.id,'DELETED')">
            {{usuario.eliminadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSst(usuario.id,'NEW')">
            {{usuario.guardadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSst(usuario.id,'PENDING')">
            {{usuario.pendientes}}</td>
        <td class="cursor" (click)="searchLocalStorageSst(usuario.id,'REJECTED')">
            {{usuario.rechazadas}}</td>
        <td>{{usuario.total}}</td>
        <!-- <td></td> -->
    </tr>
</ng-template>
<ng-template pTemplate="emptymessage" class="w-100">
    <tr>
        <td colspan="12">
            <p-messages class="w-100"
                [value]="[{ severity: 'info', detail: 'No se encontraron resultados' }]"
                [enableService]="false" [closable]="false">
            </p-messages>
        </td>
    </tr>
</ng-template>
<ng-template pTemplate="footer" let-usuario>
    <tr>
        <td>Total general:</td>
        <td>{{sstsDataProgrammer.asignadas}}</td>
        <td>{{sstsDataProgrammer.canceladas}}</td>
        <td>{{sstsDataProgrammer.eliminadas}}</td>
        <td>{{sstsDataProgrammer.guardadas}}</td>
        <td>{{sstsDataProgrammer.pendientes}}</td>
        <td>{{sstsDataProgrammer.rechazadas}}</td>
        <td>{{sstsDataProgrammer.totalGeneral}}</td>
    </tr>
</ng-template>
</p-table>