<div class="container min-width-500">
    <form [formGroup]="form" class="form">
      <div class="row col-12" >
        <div class="col-12">
          <label for="fecha" class="pr-5">Motivo del rechazo</label>
        </div>
          <div class="col-10">
            <textarea 
            [rows]="5"   
            [cols]="30"
            formControlName="motivo"   
            [style]="{ width: '100%',border : '1px solid #000', padding : '11px' }" 
            pInputTextarea autoResize="autoResize">
          </textarea>
            <div>
              <span
                class="danger"
                *ngIf="
                form.get('motivo').hasError('required') &&
                form.get('motivo').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-12">
          <button
            pButton
            pRipple
            (click)="submit()"
            [disabled]="form.invalid"
            type="button"
            label="Rechazar"
            class="p-button-secondary p-button-text"
          ></button>
        </div>
      </div>
    </form>
  </div>
  