import { Component, OnInit } from "@angular/core";
import {
  MessageService,
  ConfirmationService,
  ConfirmEventType,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { UserService } from "src/app/core/services/user/user.service";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements OnInit {
  usuarios: User[] = [];

  roles: any[];

  cols: any[] = [];

  spinner = true;

  rol: string;

  //paginado
  totalRecords = 0;

  page = 1;

  size = 10;
  //paginado


  //filters
  query: any = []

  users: any = []
  //filters

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) {
    this.roles = [
      { label: "Interno", value: 'INTERNAL' },
      { label: "Proveedor", value: 'SUPPLIER' },
      { label: "Programador", value: 'PROGRAMMER' },
      { label: "Transportista", value: 'TRANSPORTER' },
      { label: "Admin", value: 'ADMIN' },
      { label: "Gerente", value: 'MANAGER' },
      { label: "Lider de planificación", value: "PLANNING_LEADER" },
      { label: "Visualizador", value: "VISUALIZER" },
    ];

    this.rol = this.authService.queryRole();
  }

  ngOnInit(): void {
    this.getList();
    this.getListFiltersUsers();

    this.cols = [
      { field: "nombre", header: "Nombre" },
      { field: "razonSocial", header: "Razón Social" },
      { field: "cuit", header: "CUIT" },
      { field: "estadoId", header: "Estado" },
    ];
  }

  getList() {
    this.userService
      .filterByRol("", this.page, this.size, this.query)
      .subscribe({
        next: (data: any) => {
          this.usuarios = data.list;
          console.log(data.list);
          
          this.totalRecords = data.totalElements;
          this.spinner = false;
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error
              ? error.error.message
              : "Ups! ocurrio un error",
          });
        },
        complete: () => {
          this.getListFiltersUsers();
        }

      })
  }

  getListFiltersUsers() {
    this.userService
      .getAll(this.query)
      .subscribe((data: any) => {
        this.users = data;
      });
  }

  paginate(event) {
    this.page = event.page + 1;
    this.size = event.rows;
    this.getList();
  }

  showPopUpCambiarRol = false;
  userChange: User;

  cerrarPopUpCambiarRol(event) {
    this.showPopUpCambiarRol = !event;
  }

  showDialogCambiarRol(userChange: User) {
    this.userChange = userChange;
    if (!this.userChange) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Seleccione un usuario",
      });
      return;
    }
    this.showPopUpCambiarRol = true;
  }



  userSearch = ""
  rolFilter = "";
  ordenRoles = "";

  filter(value?, col?) {
    let find = this.query.find((d) => d.col == col);
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }

    switch (col) {
      case "userId":
        this.userSearch = value;
        this.query.push({ col: col, value: this.userSearch });
        break;
      case "rol":
        this.rolFilter = value;
        this.query.push({ col: col, value: this.rolFilter });
        break;
      default:
        break;
    }
    this.getList();
  }

  customSort(event: any) {
    let find = "";
    find = this.query.find((d) => d.col == "sort");
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }

    console.log("ada");
    

    switch (event.sortField) {
      case "nombre":
        this.query.push({ col: "sort", value: "USERNAME" });
        break;
      case "razonSocial":
        this.query.push({ col: "sort", value: "COMPANY_NAME" });
        break;
      case "cuit":
        this.query.push({ col: "sort", value: "TAX_PAYER_ID" });
        break;
    default:
        break;
    }

    find = this.query.find((d) => d.col == "asc");
    if (find) {
      let index = this.query.indexOf(find);
      this.query.splice(index, 1);
    }

    let ordenAscendente = false;

    event.sortOrder == 1
      ? this.query.push({ col: "asc", value: ordenAscendente })
      : this.query.push({ col: "asc", value: !ordenAscendente });

    this.getList();
  }

  formatearCUIT(cuit: any): string {
    if (cuit) {

      const cuitComoCadena = cuit.toString();

      // Verificar que la longitud sea la correcta (11 dígitos)
      if (cuitComoCadena.length !== 11) {
        return cuit
      }

      // Formatear como "11-31412324-5"
      const primeraParte = cuitComoCadena.slice(0, 2);
      const segundaParte = cuitComoCadena.slice(2, 10);
      const terceraParte = cuitComoCadena.slice(10);

      return `${primeraParte}-${segundaParte}-${terceraParte}`;
    }
  }

}
