import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ListSstComponent } from "./pages/list-sst/list-sst.component";
import { UpdateSstComponent } from "./pages/update-sst/update-sst.component";
import { CreateSstComponent } from "./pages/create-sst/create-sst.component";
import { DetailsSstComponent } from "./pages/details-sst/details-sst.component";
import { PrimengModule } from "src/app/shared/primeng/primeng.module";
import { BrowserModule } from "@angular/platform-browser";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "src/app/app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TableroTransportistaComponent } from "../transportista/tablero-transportista/tablero-transportista.component";
import { PopupSstaAceptadaComponent } from "../ssta/shared/popup-ssta-aceptada/popup-ssta-aceptada.component";
import { PopupProponerNuevaFechaComponent } from "../ssta/shared/popup-proponer-nueva-fecha/popup-proponer-nueva-fecha.component";
import { InformarSstaComponent } from "../ssta/pages/informar-ssta/informar-ssta.component";
import { HabilitacionesModule } from "../habilitaciones/habilitaciones.module";
import { AlertasModule } from "../alertas/alertas.module";
import { PopUpVistaPreliminarComponent } from "../ssta/shared/pop-up-vista-preliminar/pop-up-vista-preliminar.component";
import { GenerarSstaComponent } from "../ssta/pages/generar-ssta/generar-ssta.component";
import { PopupSstaGeneradaComponent } from "../ssta/shared/popup-ssta-generada/popup-ssta-generada.component";
import { TransferirSstComponent } from "./pages/transferir-sst/transferir-sst.component";
import { EditSstProgramadorComponent } from "./pages/edit-sst-programador/edit-sst-programador.component";
import { UpdateSstaComponent } from "../ssta/pages/update-ssta/update-ssta.component";
import { DetailsSstaComponent } from "../ssta/pages/details-ssta/details-ssta.component";
import { ListSstaComponent } from "../ssta/pages/list-ssta/list-ssta.component";
import { PopUpFinalizarSstaComponent } from '../ssta/shared/pop-up-finalizar-ssta/pop-up-finalizar-ssta.component';
import { PopUpCursoSstaComponent } from "../ssta/shared/pop-up-curso-ssta/pop-up-curso-ssta.component";
import { DividirSstComponent } from './pages/dividir-sst/dividir-sst.component';
import { DelegateSstComponent } from './pages/delegate-sst/delegate-sst.component';
import { RejectSstModalComponent } from './shared/reject-sst-modal/reject-sst-modal.component';

@NgModule({
  declarations: [
    ListSstComponent,
    UpdateSstComponent,
    CreateSstComponent,
    DetailsSstComponent,
    TableroTransportistaComponent,
    PopupSstaAceptadaComponent,
    PopupProponerNuevaFechaComponent,
    InformarSstaComponent,
    PopUpVistaPreliminarComponent,
    GenerarSstaComponent,
    PopupSstaGeneradaComponent,
    TransferirSstComponent,
    EditSstProgramadorComponent,
    UpdateSstaComponent,
    DetailsSstaComponent,
    ListSstaComponent,
    PopUpFinalizarSstaComponent,
    PopUpCursoSstaComponent,
    DividirSstComponent,
    DelegateSstComponent,
    RejectSstModalComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    PrimengModule,
    AppRoutingModule,
    HabilitacionesModule,
    AlertasModule,
    SocialLoginModule,
    MatCarouselModule.forRoot(),
  ],
  exports: [
    ListSstComponent,
    UpdateSstComponent,
    CreateSstComponent,
    DetailsSstComponent,
    PopUpCursoSstaComponent
  ],
  providers: [DatePipe],
})
export class SstModule {}
