import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { SstService } from "src/app/services/sst/sst.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import { Solicitud } from "src/app/shared/models/sst/solicitud";

@Component({
  selector: "app-details-ssta",
  templateUrl: "./details-ssta.component.html",
  styleUrls: ["./details-ssta.component.scss"],
})
export class DetailsSstaComponent implements OnInit {

  rol: string;

  roles:string[] = [];

  url: string;

  tipo: string;

  @Input() ssta: any;

  hiddenPopUpVistaPreliminar = true;

  @Output()
  eventHiddenPopUp:EventEmitter<Boolean> =new EventEmitter();

  requests: number[] = [];

  idSsta;

  spinner = true;

  tipoVista = "edit";

  solicitudesPreliminar: Solicitud[] = [];

  backUrl:string = "/solicitudes-asignadas"

  constructor(
    private authService: AuthService,
    private sstService: SstService,
    private solicitudesAsignadasServices: SolicudesAsignadasService,
    private activatedRoute: ActivatedRoute,
    private solicitudesService: SolcitudesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router:Router
  ) {
  }

  async rolesInit() {
    let promesa = await this.authService.actual2();
    this.roles = promesa.rolenames;
  }

  ngOnInit(): void {
    this.rolesInit();
    if (!this.ssta) {
      this.tipoVista = "edit";
      this.rol = this.authService.queryRole();
      this.url = this.activatedRoute.snapshot.url[0].path;
      let { id } = this.activatedRoute.snapshot.params;
      this.idSsta = id;
      this.cargar();
    } else {
      this.tipoVista = "vistaPreliminar";
      for (let i = 0; i < this.ssta.requests.length; i++) {
        this.solicitudesService.getById(this.ssta.requests[i]).subscribe(
          (data) => {
            this.solicitudesPreliminar.push(data);
            this.spinner = false;
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      }
    }
  }

  cargar() {
    this.solicitudesAsignadasServices.getById(this.idSsta).subscribe((res) => {
      this.ssta = res;
      let alto = 0;
      let ancho = 0;
      let largo = 0;
      res.requests.forEach((d) => {
        d.packages.forEach((p) => {
          this.requests.push(p.id);
          alto = alto + Number(p.height);
          ancho = ancho + Number(p.width);
          largo = largo + Number(p.length);
        });
      });
      this.spinner = false;
    });
  }

  cerrarVistaPreliminar(){
    this.eventHiddenPopUp.emit(this.hiddenPopUpVistaPreliminar);
  }

  navegarDocumentSst(idSst:any){
    if(!localStorage.getItem('navigateDetailsSstaDocument')){
      localStorage.setItem("navigateDetailsSstaDocument","/details-ssta/" + this.idSsta)
    }
    this.router.navigate(["/document/SST/" + idSst]);
  }

  hasRole(role:string){
    return this.roles.indexOf(role) !== -1;
  }
}
