import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from "primeng/api";
import { RolesService } from "src/app/core/services/roles/roles.service";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-pop-up-cambiar-rol",
  templateUrl: "./pop-up-cambiar-rol.component.html",
  styleUrls: ["./pop-up-cambiar-rol.component.scss"],
})
export class PopUpCambiarRolComponent implements OnInit {
  @Input()
  userChange: User;

  hiddenPopUpProponerFecha = true;

  roles: any[] = [];

  rolesSelected: string[] = [];

  @Output()
  eventHiddenPopUp: EventEmitter<Boolean> = new EventEmitter();

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private roleService: RolesService
  ) { }



  ngOnInit(): void {
    if (this.userChange) {
      this.rolesSelected = this.userChange.rolenames;
    }

    this.roles.push(
      { label: "Transportista", value: "TRANSPORTER", disabled: false },
      { label: "Administrador", value: "ADMIN", disabled: false },
      { label: "Programador", value: "PROGRAMMER", disabled: false },
      { label: "Gerente", value: "MANAGER", disabled: false },
      { label: "Lider de planificación", value: "PLANNING_LEADER", disabled: false },
      { label: "Proveedor", value: "SUPPLIER", disabled: false },
      { label: "Interno", value: "INTERNAL", disabled: false },
      { label: "Visualizador", value: "VISUALIZER", disabled: false }
    );
  }

  validRolChange(event: any) {
    const roles = event.value
    const isVisualizerSelected = this.hasRole('VISUALIZER', roles);

    this.rolesSelected = isVisualizerSelected ? ['VISUALIZER'] : event.value;
    this.roles.forEach(role => role.disabled = isVisualizerSelected && role.value !== 'VISUALIZER');

    // if (this.hasRole('VISUALIZER', roles)) {
    //   this.rolesSelected = ['VISUALIZER']
    //   this.roles.forEach((rol) => {
    //     if (rol.value != "VISUALIZER") {
    //       rol.disabled = true;
    //     }
    //   })
    // } else {
    //   this.roles.forEach((rol) => {
    //     rol.disabled = false;
    //   })
    // }
  }

  cerrarPopUp() {
    this.eventHiddenPopUp.emit(this.hiddenPopUpProponerFecha);
  }

  submit() {
    if (this.rolesSelected.length <= 0) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "No selecciono ningún rol",
        detail: "El usuario debe tener al menos un rol",
      });
    }
    this.userChange.rolenames = this.rolesSelected;

    let mensaje = "¿Está aseguro que desea cambiar los roles al usuario?";

    this.confirmationService.confirm({
      message: mensaje,
      header: "Confirmar ",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.roleService.actualizarRol(this.userChange).subscribe(
          (resp) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "El rol del usuario fue actualizado",
              detail: "",
            });
            this.cerrarPopUp()
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  hasRole(role: string, rolesReceived = this.roles) {
    return rolesReceived.indexOf(role) !== -1;
  }
}
