import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { User } from "src/app/shared/models/user/user";
import { isNumberOnlyCuit } from "src/app/shared/validators/validators.form";

@Component({
  selector: "app-registro",
  templateUrl: "./registro.component.html",
  styleUrls: ["./registro.component.scss"],
})
export class RegistroComponent implements OnInit {
  progressBarValue = 100;
  keywords: any = [];
  nuevo: User = new User();
  formDatos: FormGroup;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authServices: AuthService
  ) {
    var home = document.getElementById("home");
    home.style.left = "0px";
    localStorage.clear();
  }

  ngOnInit(): void {
    this.formDatos = new FormGroup({
      alias: new FormControl("", [Validators.required]),
      companyName: new FormControl("", [Validators.required]),
      cuit: new FormControl("", [Validators.pattern(/^\d+$/),Validators.required,]),
      nroProveedor: new FormControl("", [Validators.required]),
      pass: new FormControl("", [Validators.required]),
      emails: new FormArray([this.createEmailUser()]),
    },
    {
      // validators : [isNumberOnlyCuit]
    });
    document.getElementById("home").style.width = "100%";
  }

  form(){
    console.log(this.formDatos.get('cuit'));
    
  }

  createEmailUser() {
    return new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  addEmailUser() {
    let newEmail = this.createEmailUser();
    this.emailsUserModel.push(newEmail);
  }

  deleteEmailUser(idx: number) {
    this.emailsUserModel.removeAt(idx);
  }

  get emailsUserModel(): FormArray {
    return this.formDatos.get("emails") as FormArray;
  }

  submit() {
    if (this.formDatos.invalid) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "error",
        detail: "Faltan completar campos",
      });
    } else {
      this.nuevo.username = this.formDatos.get("alias").value;
      this.nuevo.companyName = this.formDatos.get("companyName").value;
      this.nuevo.taxPayerId = this.formDatos.get("cuit").value;
      this.nuevo.phone = this.formDatos.get("nroProveedor").value;
      this.nuevo.password = this.formDatos.get("pass").value;
      this.nuevo.rolenames = ["SUPPLIER"];

      this.emailsUserModel.controls.forEach((f) => {
        this.nuevo.emails.push(f.value.email);
      });

      this.authServices.registro(this.nuevo).subscribe(
        (res) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "¡Registrado!",
            detail: "¡Ya podes iniciar sesión con el usuario creado!",
          });
          this.router.navigate(["/login"]);
        },
        (err) => {
          if (err.error) {
            if (err.error.errors.global) {
              if (err.error.errors.global.length > 0) {
                for (let i = 0; i < err.error.errors.global.length; i++) {
                  this.messageService.add({
                    key: "msg",
                    severity: "error",
                    summary: "Error",
                    detail: err.error.errors.global[i],
                  });
                }
              }
            } else {
              this.messageService.add({
                key: "msg",
                severity: "error",
                summary: "Error",
                detail: err.error ? err.error.message : "Ups! ocurrio un error",
              });
            }
          }
        }
      );
    }
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
