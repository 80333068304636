<div class="container min-width-500">
  <form [formGroup]="form" class="form">
    <div class="row col-12">
      <div class="col">
        <label for="fecha" class="pr-5">Fecha</label>
        <p-calendar
          appendTo="body"
          formControlName="fecha"
          [showIcon]="true"
          inputId="icon"
          class="pl-5"
          [minDate]="minDateValue"
          [yearNavigator]="true"
          yearRange="1970:2050"
          dateFormat="dd/mm/yy"
        ></p-calendar>
        <div>
          <span
            class="danger"
            *ngIf="
              form.get('fecha').hasError('required') &&
              form.get('fecha').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          pButton
          pRipple
          (click)="submit()"
          [disabled]="form.invalid"
          type="button"
          label="Confirmar"
          class="p-button-secondary p-button-text"
        ></button>

        <p-button label="cancelar" styleClass="p-mr-2" (click)="cerrarPopUp()"> </p-button>
      </div>
    </div>
  </form>
</div>
