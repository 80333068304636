import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { PdfService } from "src/app/core/services/documentos/pdf.service";
import { TemplatesService } from "src/app/core/services/documentos/templates/templates.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { SstService } from "src/app/services/sst/sst.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import { SolicitudAsignada } from "src/app/shared/models/sst/solicitudAsignada";
import {
  Destino,
  Envio,
  Origen,
  solicitudes,
  User,
} from "src/app/shared/models/sst/solicitudes";
@Component({
  selector: "app-generar-ssta",
  templateUrl: "./generar-ssta.component.html",
  styleUrls: ["./generar-ssta.component.scss"],
})
export class GenerarSstaComponent implements OnInit, OnDestroy {

  sstaGenerate:SolicitudAsignada;

  minDateValue = new Date();

  vistaPreliminar: boolean = false;
  popUpGenerarSsta: boolean = false;
  checked: boolean = false;

  transportistas: User[] = [];

  solicitudes: PostSolicitud[] = [];
  sstaVistaPreliminar = new SolicitudAsignada();
  rol: string;
  roles: string[] = [];

  url: string;
  urlBack: string;

  tipo: string;

  requests: number[] = [];

  checkedForm: boolean = false;
  disabledTransport: Boolean = false;
  transportForm: number = null;
  dateProgrammingForm: any = "";

  constructor(
    private authService: AuthService,
    private sstService: SstService,
    private activatedRoute: ActivatedRoute,
    private solicitudesAsignadasServices: SolicudesAsignadasService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
  ) {
    this.rol = this.authService.queryRole();
    this.url = route.snapshot.url[0].path;
    this.userService.getByRol("transportista").subscribe((res) => {
      res.forEach((e) => {
        this.transportistas.push({ name: e.username, code: e.id });
      });
    });
  }

  async rolesInit() {
    let promesa = await this.authService.actual2();
    this.roles = promesa.rolenames;
  }

  ngOnInit(): void {
    this.rolesInit();
    this.urlBack = JSON.parse(localStorage.getItem("sstGenerate"))
      ? "/solicitudes"
      : "/alertas";
    this.solicitudes =
      JSON.parse(localStorage.getItem("sstGenerate")) ||
      JSON.parse(localStorage.getItem("sstGenerateAlert"));

    let alto = 0;
    let ancho = 0;
    let largo = 0;
    this.sstaVistaPreliminar.requests = this.solicitudes;
    this.solicitudes.forEach((d) => {
      this.requests.push(d.id);
      d.packages.forEach((p) => {
        alto = alto + Number(p.height);
        ancho = ancho + Number(p.width);
        largo = largo + Number(p.length);
      });
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("sstGenerate");
    localStorage.removeItem("sstGenerateAlert");
  }

  showDialogVistaPreliminar() {
    var datePipe = new DatePipe("en-US");
    this.sstaVistaPreliminar.programmingDate = datePipe.transform(
      this.dateProgrammingForm,
      "YYYY-MM-dd"
    );
    this.sstaVistaPreliminar.transporter = this.transportForm;
    this.sstaVistaPreliminar.requests = this.requests;
    this.sstaVistaPreliminar.expressService = this.checkedForm;
    this.vistaPreliminar = true;
  }

  showPopUpGenerarSsta() {
    let data:any = {}
    var datePipe = new DatePipe("en-US");
    data.programmingDate = datePipe.transform(
      this.dateProgrammingForm,
      "YYYY-MM-dd"
    );
    data.transporter = this.transportForm;
    data.requests = this.requests;
    data.expressService = this.checkedForm;
    data.status = "PENDING";

    this.solicitudesAsignadasServices.createSst(data).subscribe(
      (res) => {
        this.sstaGenerate = res;
        this.popUpGenerarSsta = true;
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  cerrarPopUpVistaPreliminar(event) {
    this.vistaPreliminar = !event;
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }

  validFechaForm(event:any){
  }

  validFormCamp() {
    if (this.checkedForm == true) {
      this.transportForm = null;
      this.disabledTransport = true;
    } else if (this.checkedForm == false) {
      this.disabledTransport = false;
    }
  }

  validForm() {
    if (
      this.dateProgrammingForm != null &&
      this.transportForm != null &&
      this.checkedForm == false
    ) {
      return false;
    } else if (
      this.dateProgrammingForm != null &&
      this.transportForm == null &&
      this.checkedForm == true
    ) {
      return false;
    }
    return true;
  }
}
