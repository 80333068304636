import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { UserService } from "src/app/core/services/user/user.service";

@Component({
  selector: "app-recuperar",
  templateUrl: "./recuperar.component.html",
  styleUrls: ["./recuperar.component.scss"],
})
export class RecuperarComponent implements OnInit {
  progressBarValue = 100;

  keywords: any = [];

  form: FormGroup;

  @Output() cerrar: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authServices: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      mail: new FormControl("", Validators.required),
    });
  }

  confirmar() {
    let mail = this.form.get("mail").value;
    this.authServices.recuperarContraseña(mail).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "info",
          summary: "Hecho!",
          detail: "Por favor revisa tu email para poder cambiar tu contraseña",
        });
        // localStorage.setItem("token", JSON.stringify(res));
        // this.router.navigate(["/activar"]);
      },
      (err) => {
        
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error : "Ups! ocurrio un error",
        });
      }
    );
  }
}
