import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { SolicitudAsignada } from "src/app/shared/models/sst/solicitudAsignada";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SolicudesAsignadasService {
  constructor(private http: HttpClient) { }

  /*getAllAsignadas(query: any) {
 
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', "Bearer " + localStorage.getItem("token"));
        return this.http.get(`${environment.apiUrl}/solicitudes-asignadas`, { headers: headers }).pipe(
            map((res: any) => {
                return res;
            })
        );
    }*/

  // actual2():Promise<User> {
  //   let headers = new HttpHeaders();
  //   headers = headers.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("token")
  //   );
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get(`${environment.apiUrl}/usuarios/actual`, { headers: headers })
  //       .subscribe({
  //         next: (data:User) => resolve(data),
  //         error: (err) => reject(err),
  //       });
  //   });
  // }

  getAllSstaFiltrar(query: any, page, size) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "programmingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "originId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "destinationId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "status") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "esAe") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "purchaseOrderNumber") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "requestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "asc") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "assignedRequestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "expressService") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });


    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/filtrar/${page}/${size}?${queryBuid}`,
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getAllAsignadasFiltrar(query: any, page, size): Promise<any> {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "programmingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "originId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "destinationId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "status") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "esAe") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "purchaseOrderNumber") {
        if (q.value != undefined && q.value != "") {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "requestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "asc") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "assignedRequestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "expressService") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });


    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.apiUrl}/solicitudes-asignadas/filtrar/${page}/${size}?${queryBuid}`,
          { headers: headers }
        )
        .subscribe({
          next: (data: any) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  getAllAsignadasFiltrarReporte(query: any, page, size) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "requestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "asc") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "assignedRequestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "expressService") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/${page}/${size}?${queryBuid}`,
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  createSst(data: SolicitudAsignada) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/nuevo`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  editSst(data: SolicitudAsignada) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/guardar`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  cancelarSstaInformada(data: SolicitudAsignada) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/cancelar/${data.id}`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  cancelarSstaEnCurso(data: SolicitudAsignada) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/cancelar/${data.id}`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteSst(id) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/solicitudes-asignadas/borrar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getById(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/solicitudes-asignadas/buscar/${id}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  ponerEnCurso(id: string, ssta: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/en-curso`, ssta, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  finalizarSsta(id: string, transportGuide: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/solicitudes-asignadas/finalizar/${id}`,
        transportGuide,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  acceptSstas(data) {
    let ids = {
      ids: data,
    };

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/aceptar`, ids, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  rejectSstas(data) {
    let ids = {
      ids: data,
    };

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/rechazar`, ids, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  posponerFechaSstas(fechas) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/nueva-fecha`, fechas, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  aceptarPropuestaFecha(data) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/solicitudes-asignadas/nueva-fecha/aceptar`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  rechazarPropuestaFecha(data) {
    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/solicitudes-asignadas/nueva-fecha/rechazar`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  informarSsta(data) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/solicitudes-asignadas/informar`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  //REPORTES -- INICIO

  reportSstaPorcentajeRechazadas(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/rechazado/porcentaje?${queryBuid}`,
        { headers: headers, responseType: "text" }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  reportSstaRechazadasByTransportistas(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/rechazado/transportistas?${queryBuid}`,
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  reporteSstaTiempoHastaAsignacionPorTransportista(query: any, page, size) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tiempo/${page}/${size}?${queryBuid}`,
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  reporteSstaTiempoPromedioPorProgramadorHastaAsignacionPorTransportista(
    query: any
  ) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tiempo/programadores?${queryBuid}`,
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  reportSstaTiempoPromedioHastaSuAsignacion(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tiempo/promedio?${queryBuid}`,
        { headers: headers, responseType: "text" }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  reportSstaTiempoDeRecepcionHastaSuAsignacion(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tiempo/transportistas?${queryBuid}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  //REPORTES -- FIN

  // TABLERO-GENERAL -- INICIO

  rechazadasAceptadasTableroGeneralSsta(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDateSsta") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "startDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "endDateSsta") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "endDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterIds") {
        if (q.value) {
          queryBuid = queryBuid + "transporterId" + "=" + q.value + "&";
        }
      }
      if (q.col == "estadoSsta") {
        if (q.value) {
          queryBuid = queryBuid + "status" + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/rechazadas/tablero?${queryBuid}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  dataTiempoTransportistasTableroGeneralSsta(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDateSsta") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "startDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "endDateSsta") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "endDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "alertSsta") {
        if (q.value) {
          queryBuid = queryBuid + "alert" + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterIds") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "estadoSsta") {
        if (q.value) {
          queryBuid = queryBuid + "status" + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tablero?${queryBuid}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  dataTransportistasTableroGeneralSsta(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDateSsta") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "startDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "endDateSsta") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + "endDate" + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterIds") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tabla?${queryBuid}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
