<div class="container min-width-500">
  <div class="header">
    <h2>Transportistas</h2>
  </div>
  <div *ngIf="!hasRole('PROGRAMMER')" class="d-flex justify-content-end">
    <button
      pButton
      pRipple
      routerLink="/alta-transportista"
      type="button"
      label="Alta transportista"
      class="p-button-secondary p-button-text p-button-lg"
    ></button>
  </div>
  <div class="card filters">
    <div class="row col-12 p-0">
      <div class="col">
        <h1 class="title">Filtros</h1>
      </div>
    </div>
    <div class="row p-0">
      <div class="col-6">
        <label class="">Transportista </label><br />
        <p-dropdown
          [style]="{ width: '100%' }"
          class=""
          appendTo="body"
          [options]="users"
          (onChange)="filter($event.value, 'userId')"
          placeholder="-"
          [showClear]="true"
          [filter]="true"
          optionValue="id"
          filterBy="username,companyName"
        >
          <ng-template let-option pTemplate="item">
            <span [class]="'user -' + option.id"
              >{{ option.username }} - {{ option.companyName }}</span
            >
          </ng-template>
          <ng-template let-option pTemplate="selectedItem">
            {{ option.username }} - {{ option.companyName }}
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  <div *ngIf="!spinner">

    <div class="card">
      <p-table
        #myTab
        [value]="transportistas"
        [scrollable]="true"
        scrollHeight="400px"
        styleClass="p-datatable-striped"
        [tableStyle]="{ 'min-width': '50rem' }"
        [rowHover]="true"
        dataKey="id"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="nombre" style="width: 20%">
              Nombre <p-sortIcon field="nombre"></p-sortIcon>
            </th>
            <th pSortableColumn="razonSocial" style="width: 20%">
              Razón Social<p-sortIcon field="razonSocial"></p-sortIcon>
            </th>
            <th pSortableColumn="cuit" style="width: 20%">
              CUIT<p-sortIcon field="cuit"></p-sortIcon>
            </th>
            <th style="width: 20%">Estado</th>
            <th style="width: 20%">Acciones</th>
          </tr>
          <!--<tr>
            <th *ngFor="let col of cols">
              <input
                type="text"
                *ngIf="col.field == 'nombre'"
                class="form-control"
                placeholder=""
                (input)="
                  myTab.filter(
                    $event.target.value,
                    col.field,
                    col.filterMatchMode
                  )
                "
              />

              <input
                type="text"
                *ngIf="col.field == 'razonSocial'"
                class="form-control"
                placeholder=""
                (input)="
                  myTab.filter(
                    $event.target.value,
                    col.field,
                    col.filterMatchMode
                  )
                "
              />

              <input
                type="text"
                *ngIf="col.field == 'cuit'"
                class="form-control"
                placeholder=""
                (input)="
                  myTab.filter(
                    $event.target.value,
                    col.field,
                    col.filterMatchMode
                  )
                "
              />
              <p-columnFilter
                *ngIf="col.field == 'estadoId'"
                field="estadoId"
                matchMode="equals"
                [showMenu]="false"
              >
                <ng-template
                  pTemplate="filter"
                  let-value
                  let-filter="filterCallback"
                >
                  <p-dropdown
                    class="p-dropdown p-dropdown-trigger"
                    appendTo="body"
                    [ngModel]="value"
                    [options]="statuses"
                    (onChange)="filter($event.value)"
                    placeholder="-"
                    [showClear]="true"
                  >
                    <ng-template let-option pTemplate="item">
                      <span [class]="'customer-badge status-' + option.value">{{
                        option.label
                      }}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
            <th></th>
          </tr>-->
        </ng-template>

        <ng-template pTemplate="body" let-transportistas>
          <tr>
            <td>{{ transportistas.username }}</td>
            <td>{{ transportistas.companyName }}</td>
            <td>{{formatearCUIT( transportistas.taxPayerId) }}</td>
            <td *ngIf="transportistas.state == 'ACTIVE'" class="text-success">
              {{ transportistas.state }}
            </td>
            <td *ngIf="transportistas.state != 'ACTIVE'" class="text-danger">
              {{ transportistas.state }}
            </td>
            <td colspan="1">
              <p-button
                *ngIf="transportistas.state == 'ACTIVE'"
                label=""
                icon="pi pi-minus-circle"
                styleClass="p-button-sm"
                pTooltip="Deshabilitar"
                (click)="deshabilitar(transportistas.id)"
              ></p-button>
              <p-button
                *ngIf="transportistas.state != 'ACTIVE'"
                label=""
                icon="pi pi-check-circle"
                styleClass="p-button-sm"
                pTooltip="Habilitar"
                (click)="habilitar(transportistas.id)"
              ></p-button>
              <p-button *ngIf="!hasRole('PROGRAMMER')"
                label=""
                icon="pi pi-pencil"
                styleClass="p-button-sm"
                pTooltip="Editar información"
                routerLink="/actualizar-transportista/{{ transportistas.id }}"
              ></p-button>
              <p-button
                label=""
                icon="pi pi-eye"
                styleClass="p-button-sm"
                pTooltip="Ver detalles"
                routerLink="/details-transportista/{{ transportistas.id }}"
              ></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator [rows]="size" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
      (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-toast></p-toast>