export class Package{
    name?:string;
    description: string;
    height: string;
    length:string;
    weight: string;
    width: string
    id?:number;
    totalWeight:Boolean;
    divisor?:any;
}