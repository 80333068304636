<div class="container min-width-500">
  <div class="header">
    <h2>Editar SST<br /></h2>
  </div>
  <div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
</div>
<div *ngIf="!spinner">
  <div class="row m-0 p-0">
    <div class="col">
      <p-card>
        <div>
          <p-card>
            <div class="row">
              <div class="col">
                <div style="float: left">
                  <h1 class="title">SST Nº {{ sst.requestId }}</h1>
                  <br />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label
                  >Solicitante: <span> {{ sst.supplierName }}</span> </label
                ><br />
                <label
                  >Programador: <span>{{ sst.programmerName }} </span> </label
                ><br />
                <label *ngIf="sst.delegatedByName"
                  >Delegado por: <span>{{ sst.delegatedByName }} </span> </label
                ><br />
                <label
                  >Fecha Necesidad del Transporte:
                  <span>{{
                    sst.requirementDate | date : "dd/MM/YYYY"
                  }}</span> </label
                ><br />
                <label
                  >Fecha Solicitud:
                  <span>
                    {{ sst.creationDate | date : "dd/MM/YYYY" }}
                  </span> </label
                ><br />
              </div>
              <div class="col">
                <label
                  >Tipo Gestión: <span>{{ sst.managementType }}</span> </label
                ><br />
                <label
                  >Origen: <span> {{ sst.originName }}</span> </label
                ><br />
                <label
                  >Destino: <span> {{ sst.destinationName }}</span> </label
                ><br />
                <label
                  >Descripción de la carga:
                  <span> {{ sst.loadDescription }}</span> </label
                ><br />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Bultos: <span></span> </label><br />
              </div>
            </div>
            <div
              class=""
              *ngFor="let package of sst.packages; let i = index; let l = last"
            >
              <form [formGroup]="form" class="row" *ngIf="package">
                <div formArrayName="subdivideArray" class="col">
                  <div class="row" [formGroupName]="i">
                    <div class="col-sm-12 col-md-6 mt-3">
                      <label
                        >Tipo de bulto N°{{ package?.name || i + 1 }}
                        <span></span>
                      </label>
                      <p-checkbox
                        *ngIf="sst.packages.length > 1"
                        formControlName="checkSeparate"
                        (onChange)="
                          seleccionarBultoEntero('all', $event, package, i)
                        "
                        [value]="true"
                        class="m-0 pl-3 pb-1"
                        [binary]="true"
                      ></p-checkbox>
                    </div>
                    <div class="col-sm-12 col-lg-5 mt-sm-3 mt-lg-0">
                      <label
                        >Cantidad a separar
                        <p-checkbox
                          [binary]="true"
                          [value]="true"
                          formControlName="checkSubdivide"
                          class="m-0 pl-3 pb-1"
                          (onChange)="
                            seleccionarBultoDividiendo(
                              'part',
                              $event,
                              package,
                              i
                            )
                          "
                        ></p-checkbox>
                      </label>

                      <br />
                      <input
                        [style]="{ width: '100%' }"
                        type="number"
                        class="form-control"
                        placeholder=""
                        formControlName="quantity"
                        min="1"
                        (change)="habilitarDividirSolicitud()"
                      />
                      <span
                        class="danger"
                        *ngIf="
                          subdivideArray.controls[i]
                            .get('quantity')
                            .hasError('min') &&
                          subdivideArray.controls[i].get('quantity').touched
                        "
                      >
                        <small class="p-error"
                          >El minimo debe ser al menos
                          <strong> mayor a 1</strong></small
                        >
                      </span>
                      <span
                        class="danger"
                        *ngIf="
                          subdivideArray.controls[i]
                            .get('quantity')
                            .hasError('required') &&
                          subdivideArray.controls[i].get('quantity').touched
                        "
                      >
                        <small class="p-error"
                          >Debe colocar una
                          <strong> cantidad mínima</strong></small
                        >
                      </span>
                      <span
                        class="danger"
                        *ngIf="
                          subdivideArray.controls[i]
                            .get('quantity')
                            .hasError('max') &&
                          subdivideArray.controls[i].get('quantity').touched
                        "
                      >
                        <small class="p-error"
                          >La cantidad a dividir no puede superar
                          <strong> a la cantidad original</strong></small
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </form>

              <div class="row">
                <div class="col">
                  <label
                    >Cantidad de bultos: {{ package.description
                    }}<span>&nbsp; &nbsp; &nbsp;</span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label
                    >Peso Aprox(Kgs) : <span> {{ package.weight }}</span></label
                  ><br />
                  <label
                    >Ancho(Mts) : <span>{{ package.weight }}</span></label
                  ><br />
                </div>
                <div class="col">
                  <label
                    >Largo(Mts) : <span>{{ package.length }}</span></label
                  ><br />
                  <label
                    >Alto(Mts) : <span>{{ package.height }}</span></label
                  ><br />
                </div>
              </div>
              <div class="row mt-3" *ngIf="l">
                <div class="col">
                  <h6>
                    <b>NOTA:</b> El peso y las dimensiones ingresadas por tipo de bulto son medidas unitarias.
                  </h6>
                </div>
              </div>
            </div>
            <div class="row col-12" *ngIf="sst.ocEa.length > 0">
              <div class="col-12">
                <div class="row col-12">
                  <div
                    class="col-12"
                    *ngFor="let oc of sst.ocEa; let i = index"
                  >
                    <label for="" class=""
                      >Orden de compra: {{ oc.oc != "" ? oc.oc : "N/A" }}</label
                    >
                    <ul>
                      <li *ngFor="let ea of oc.ea">
                        Entrega de salida / Aviso de Entrega :
                        {{ ea != "" ? ea : "N/A" }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-12" *ngIf="sst.comments">
              <div class="col-12">
                <label for="Comentarios" class="">Comentarios: </label>
                <h6>{{ sst.comments }}</h6>
              </div>
            </div>
          </p-card>
        </div>
      </p-card>
    </div>
    <div class="col" [class]="hiddenDividirSolicitud()">
      <p-card>
        <div class="A" *ngFor="let sstDividida of sstDivididas; let i = index">
          <div>
            <p-card>
              <div class="row">
                <div class="col">
                  <div style="float: left">
                    <h1 class="title h3">
                      SST Nº {{ sstDividida.requestId }}
                      <cite class="text-success">Nueva</cite>
                    </h1>
                    <br />
                  </div>
                </div>
                <div class="col-6">
                  <button
                    (click)="eliminarSolicitudDividida(sstDividida, i)"
                    type="button"
                    icon="pi pi-times"
                    pbutton=""
                    class="p-button p-button-icon-only float-right"
                  >
                    <span
                      class="p-button-icon pi pi-times"
                      aria-hidden="true"
                    ></span
                    ><span aria-hidden="true" class="p-button-label"
                      >&nbsp;</span
                    >
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label
                    >Solicitante:
                    <span> {{ sstDividida.supplierName }}</span> </label
                  ><br />
                  <label
                    >Programador:
                    <span>{{ sstDividida.programmerName }} </span> </label
                  ><br />
                  <label
                    >Fecha Necesidad del Transporte:
                    <span>{{
                      sstDividida.requirementDate | date : "dd/MM/YYYY"
                    }}</span> </label
                  ><br />
                  <label
                    >Fecha Solicitud:
                    <span>
                      {{ sstDividida.creationDate | date : "dd/MM/YYYY" }}
                    </span> </label
                  ><br />
                </div>
                <div class="col">
                  <label
                    >Tipo Gestión:
                    <span>{{ sstDividida.managementType }}</span> </label
                  ><br />
                  <label
                    >Origen: <span> {{ sstDividida.originName }}</span> </label
                  ><br />
                  <label class="pr-3"
                    >Destino:
                    <span> {{ sstDividida.destinationName }}</span></label
                  >
                  <br />
                  <!-- <p-dropdown
                      [options]="origenes"
                      placeholder="-- Seleccione --"
                      optionLabel="description"
                      [showClear]="true"
                    ></p-dropdown> -->
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>Bultos: <span></span> </label><br />
                </div>
              </div>
              <div
                class=""
                *ngFor="
                  let package of sstDividida.packages;
                  let i = index;
                  let l = last
                "
              >
                <div class="row">
                  <div class="col">
                    <label
                      >Tipo de bulto N°{{ package?.name || i + 1 }}
                      <span></span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label
                      >Cantidad de bultos: {{ package.description
                      }}<span></span> </label
                    ><br />
                  </div>
                </div>
                <!-- <div class="row">
                    <div class="col" *ngIf="package?.divisor">
                      <label
                        >Cantidad a separar: {{ package?.divisor
                        }}<span>&nbsp; &nbsp; &nbsp;</span>
                      </label>
                    </div>
                  </div> -->
                <div class="row">
                  <div class="col">
                    <label
                      >Peso Aprox(Kgs) :
                      <span> {{ package.weight }}</span></label
                    ><br />
                    <label
                      >Ancho(Mts) : <span>{{ package.weight }}</span></label
                    ><br />
                  </div>
                  <div class="col">
                    <label
                      >Largo(Mts) : <span>{{ package.length }}</span></label
                    ><br />
                    <label
                      >Alto(Mts) : <span>{{ package.height }}</span></label
                    ><br />
                  </div>
                </div>
                <div class="row mt-3" *ngIf="l">
                  <div class="col">
                    <h6>
                      <b>NOTA:</b> Las medidas ingresadas por tipo de bulto son
                      medidas unitarias.
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row col-12" *ngIf="sstDividida.ocEa.length > 0">
                <div class="col-12">
                  <div class="row col-12">
                    <div
                      class="col-12"
                      *ngFor="let oc of sstDividida.ocEa; let i = index"
                    >
                      <label for="" class=""
                        >Orden de compra:
                        {{ oc.oc != "" ? oc.oc : "N/A" }}</label
                      >
                      <ul>
                        <li *ngFor="let ea of oc.ea">
                          Entrega de salida / Aviso de Entrega :
                          {{ ea != "" ? ea : "N/A" }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="Comentarios" class="">Comentarios: </label>
                  <h6>{{ sstDividida.comments }}</h6>
                </div>
              </div>
            </p-card>
          </div>
        </div>
      </p-card>
    </div>
  </div>
  <p-card>
    <div class="flex justify-content-center">
      <!-- <p-button
          label="Editar SST"
          class="p-button-primary p-button-text"
        ></p-button> -->
      <p-button
        [disabled]="disabledDividirSolicitudButton"
        label="Separar en nueva solicitud"
        class="p-button-primary p-button-text"
        (click)="dividirSolicitud()"
      ></p-button>
      <p-button
        [disabled]="disabledDividirGuardarCambiosButton"
        label="Guardar cambios"
        class="p-button-primary p-button-text"
        (click)="guardarCambios()"
      ></p-button>
      <p-button
        label="Volver"
        class="p-button-primary p-button-text"
        routerLink="/solicitudes"
      ></p-button>
    </div>
  </p-card>
</div>
