import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  FormGroup,
  FormControl,
  FormArray,
  Form,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { concatMap, finalize, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { DespositosService } from "src/app/core/services/depositos/despositos.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { UserService } from "src/app/core/services/user/user.service";
import { Package } from "src/app/shared/models/sst/package";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import { Origen } from "../../interfaces/origen.interface";
import { Envio } from "../../interfaces/envios.interface";

import { User } from "src/app/shared/models/user/user";
import { Users } from "../../interfaces/user.interface";
import { TemplatesService } from "src/app/core/services/documentos/templates/templates.service";
import { PdfService } from "src/app/core/services/documentos/pdf.service";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { Oc } from "src/app/shared/models/sst/oc";
import { DatePipe } from "@angular/common";
import { isFechaNecesidadValid } from "src/app/shared/validators/validators.form";
import { Destino } from "../../interfaces/destino.interface";
import { concat, of } from "rxjs";

@Component({
  selector: "app-create-sst",
  templateUrl: "./create-sst.component.html",
  styleUrls: ["./create-sst.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateSstComponent implements OnInit {

  spinner = true;

  userActual: User;
  idUserActual;

  nuevo = new PostSolicitud();
  borrador: PostSolicitud;
  form: FormGroup;

  sstGenerate: Solicitud;

  minDateValue = new Date();

  applicationDate: Date = new Date();

  programadores: Users[] = [];
  selectUser: Users;

  envios: Envio[] = [];
  selectEnvio: Envio;

  origenes: Origen[] = [];
  destinos: Destino[] = [];
  selectOrigen: Origen;

  displayMaximizable: boolean;
  displayModal: boolean;
  supplierId = 0;

  showDestinoInputCustom = true;
  showOrigenInputCustom = true;

  uploadedFiles: any[] = [];
  filesServer: any[] = [];

  buttonGenerarBorradorSst: boolean = false;
  buttonGenerarSst: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private solicitudesService: SolcitudesService,
    private depositosService: DespositosService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private fileService: FileService,
    private templateService: TemplatesService,
    private pdfService: PdfService,
    private datepipe: DatePipe
  ) {
    this.envios = [
      { tipo: "Retiro", id: "PICKUP" },
      { tipo: "Envio", id: "SHIPPING" },
    ];

  }

  rolesInit() {
    this.authService
      .actual()
      .pipe(
        tap({
          next: (data) => {
            this.userActual = data;
            this.idUserActual = data.id;
            this.supplierId = data.id;
          },
          complete: () => {
          },
        }),
        concatMap(() => this.getProgrammers()),
        concatMap(() => this.getOrigenes()),
        concatMap(() => this.getDestinos()),
        concatMap(() => concat(of(this.startForm()))),
      ).subscribe()

  }

  ngOnInit(): void {
    this.rolesInit()
  }

  startForm() {
    this.form = new FormGroup({
      programador: new FormControl("", [Validators.required]),
      tipoGestion: new FormControl("", [Validators.required]),
      origen: new FormControl("", [Validators.required]),
      destino: new FormControl("", [Validators.required]),
      fechaNecesidad: new FormControl("", [Validators.required, isFechaNecesidadValid(this.datepipe)]),
      ordenCompraModel: new FormArray([this.createOrdenCompra()]),
      comentarios: new FormControl(""),
      bultosModel: new FormArray([this.createBulto()]),
      noLlevaOc: new FormControl(false, []),
      noLlevaAe: new FormControl(false, []),
      emailNotification: new FormControl(false, []),
      contacto: new FormControl("", [Validators.required]),
      telefono: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      loadDescription: new FormControl("", [Validators.required]),
    })
    this.spinner = false;
  }

  getProgrammers() {
    return this.userService.getByRol("programador").pipe(
      tap({
        next: (res) => {
          res.forEach((e) => {
            if (e.id != this.idUserActual) {
              this.programadores.push({ name: e.username, code: e.id });
            }
          });
        },
        complete: () => { },
      })
    );
  }

  getOrigenes() {
    return this.depositosService.getOrigenes().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.origenes.push({ description: d.name, id: d.id });
          });

          this.origenes.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getDestinos() {
    return this.depositosService.getDestinos().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.destinos.push({ description: d.name, id: d.id });
          });

          this.destinos.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  selectFile(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.cantidadArchivos();
  }

  cantidad = 0;
  cantidadArchivos() {
    let cantidad = 0;
    for (let file of this.uploadedFiles) {
      if (file) {
        cantidad++;
      }
    }
    this.cantidad = cantidad;
  }

  descargarDocumento(file) {
    let url = window.URL.createObjectURL(file);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.messageService.add({
      key: "msg",
      severity: "success",
      summary: "Descargado",
    });
  }

  eliminarDocumento(file, indice) {
    this.uploadedFiles[indice] = null;
    this.cantidadArchivos();
  }

  destinoCustom(event) {
    if (event.value == 0 && !this.form.get("destinoCustom")) {
      this.form.addControl(
        "destinoCustom",
        new FormControl("", [Validators.required])
      );
      this.showDestinoInputCustom = false;
    } else {
      if (this.form.get("destinoCustom")) {
        this.form.removeControl("destinoCustom");
        this.showDestinoInputCustom = true;
      }
    }
  }

  origenCustom(event) {
    if (event.value == 0 && !this.form.get("origenCustom")) {
      this.form.addControl(
        "origenCustom",
        new FormControl("", [Validators.required])
      );
      this.showOrigenInputCustom = false;
    } else {
      if (this.form.get("origenCustom")) {
        this.form.removeControl("origenCustom");
        this.showOrigenInputCustom = true;
      }
    }
  }

  createBulto() {
    return new FormGroup({
      bultos: new FormControl("", [Validators.required, Validators.min(0)]),
      pesoAprox: new FormControl("", [Validators.required, Validators.min(0)]),
      ancho: new FormControl("", [Validators.required, Validators.min(0)]),
      largo: new FormControl("", [Validators.required, Validators.min(0)]),
      alto: new FormControl("", [Validators.required, Validators.min(0)]),
      // totalWeight: new FormControl("", [
      //   Validators.required,
      // ]),
    });
  }

  createOrdenCompra() {
    return new FormGroup({
      ordenCompra: new FormControl("", [Validators.minLength(10), Validators.required]),
      ea: new FormArray([this.createEsAesModel()]),
    });
  }

  createEsAesModel() {
    return new FormGroup({
      esAe: new FormControl("", [Validators.minLength(9)]),
    });
  }

  addOrdenCompra() {
    let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;
    if (!noLlevaOc) {
      let newOrdenCompra = this.createOrdenCompra();
      this.ordenCompraModel.push(newOrdenCompra);
    } else {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Desactive el check OC AE",
      });
    }
  }

  addEsAesModel(index: any) {
    let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;
    if (!noLlevaOc) {
      let newEsAes = this.createEsAesModel();
      this.getEaArrayOc(index).push(newEsAes);
    } else {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Desactive el check OC AE",
      });
    }
  }

  addBulto() {
    let newBulto = this.createBulto();
    this.bultosModel.push(newBulto);
  }

  deleteBulto(idx: number) {
    this.bultosModel.removeAt(idx);
  }

  deleteOrdenCompra(idx: number) {
    this.ordenCompraModel.removeAt(idx);
  }

  deleteEsAesModel(indexOc, idx: number) {
    this.getEaArrayOc(indexOc).removeAt(idx);
  }

  get bultosModel(): FormArray {
    return this.form.get("bultosModel") as FormArray;
  }

  get ordenCompraModel(): FormArray {
    return this.form.get("ordenCompraModel") as FormArray;
  }

  getEaArrayOc(index: any): FormArray {
    return this.ordenCompraModel.controls[index].get("ea") as FormArray;
  }

  get noLlevaOc(): Boolean {
    return this.form.get("noLlevaOc").value;
  }

  get noLlevaAe(): Boolean {
    return this.form.get("noLlevaAe").value;
  }

  disabledOcAe() {
    let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;

    this.ordenCompraModel.controls.forEach((oc: FormGroup, i: any) => {
      if (noLlevaOc) {
        oc.controls.ordenCompra.setValue("");
        oc.controls.ordenCompra.disable();
        this.getEaArrayOc(i).controls.forEach((ea: FormGroup) => {
          ea.controls.esAe.setValue("");
          ea.controls.esAe.disable();
        });
      } else {
        oc.controls.ordenCompra.enable();
        this.getEaArrayOc(i).controls.forEach((ea: FormGroup) => {
          ea.controls.esAe.enable();
        });
      }
    });
  }

  limpiar() {
    this.nuevo = new PostSolicitud();
    this.form = new FormGroup({
      programador: new FormControl("", [Validators.required]),
      tipoGestion: new FormControl("", [Validators.required]),
      origen: new FormControl("", [Validators.required]),
      destino: new FormControl("", [Validators.required]),
      ordenCompraModel: new FormArray([this.createOrdenCompra()]),
      fechaNecesidad: new FormControl("", [Validators.required]),
      comentarios: new FormControl(""),
      bultosModel: new FormArray([this.createBulto()]),
      noLlevaOc: new FormControl(false, []),
      noLlevaAe: new FormControl(false, []),
      emailNotification: new FormControl(false, []),
      contacto: new FormControl("", [Validators.required]),
      telefono: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      loadDescription: new FormControl("", [Validators.required]),
    });
  }

  showMaximizableDialog() {
    this.displayMaximizable = true;
  }

  showModalDialog() {
    this.displayModal = true;
  }

  crearSst(): void {
    this.buttonGenerarSst = true;

    this.nuevo.programmerId = this.form.get("programador").value.code;
    this.nuevo.supplierId = this.supplierId;
    this.nuevo.type = this.form.get("tipoGestion").value.id;
    this.nuevo.status = "PENDING";
    this.nuevo.requirementDate = this.form.get("fechaNecesidad").value;
    this.nuevo.contactName = this.form.get("contacto").value;
    this.nuevo.phone = this.form.get("telefono").value;
    this.nuevo.email = this.form.get("email").value;
    this.nuevo.loadDescription = this.form.get("loadDescription").value;
    this.nuevo.emailNotification = this.form.get("emailNotification").value;

    this.nuevo.comments = this.form.get("comentarios").value || "";
    this.bultosModel.controls.forEach((f, index) => {
      let bulto = new Package();
      bulto.name = String(index + 1);
      bulto.description = f.value.bultos;
      bulto.height = f.value.alto;
      bulto.length = f.value.largo;
      bulto.width = f.value.ancho;
      bulto.weight = f.value.pesoAprox;
      // bulto.totalWeight = f.value.totalWeight ? Boolean(JSON.parse(f.value.totalWeight)) : true;
      this.nuevo.packages.push(bulto);
    });

    let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;

    if (!noLlevaOc) {
      if (this.ordenCompraModel.controls.length > 0) {
        this.ordenCompraModel.controls.forEach((oc: FormGroup, i: any) => {
          let ocData: Oc = new Oc();

          ocData.oc = oc.controls.ordenCompra.value;
          this.getEaArrayOc(i).controls.forEach((ea: FormGroup) => {
            ocData.ea.push(ea.controls.esAe.value);
          });

          if (ocData.oc != "" && ocData.oc != null) {
            this.nuevo.ocEa.push(ocData);
          }
        });
      }
    }

    if (this.form.get("origen").value == 0) {
      this.nuevo.externalOrigin = this.form.get("origenCustom").value;
    } else {
      this.nuevo.origin = this.form.get("origen").value;
    }

    if (this.form.get("destino").value == 0) {
      this.nuevo.externalDestination = this.form.get("destinoCustom").value;
    } else {
      this.nuevo.destination = this.form.get("destino").value;
    }

    let archivos = [];
    for (let file of this.uploadedFiles) {
      if (file) {
        archivos.push(file);
      }
    }

    let formData = new FormData();
    this.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    // this.fileService.uploadFiles(formData).subscribe((data) => {
    //   this.nuevo.documentation = [];
    //   for (let file of data) {
    //     this.nuevo.documentation.push(file.id);
    //   }
    // });

    if (this.uploadedFiles.length > 0) {
      this.fileService
        .uploadFiles(formData)
        .pipe(finalize(() => { }))
        .subscribe(
          (data: any) => {
            for (let file of data) {
              this.nuevo.documentation.push(file.id);
            }
            this.solicitudesService.generateSst(this.nuevo).subscribe(
              (res) => {
                this.sstGenerate = res;
                this.displayModal = true;
                this.limpiar();
                this.buttonGenerarSst = false;
              },
              (err) => {
                this.messageService.add({
                  key: "msg",
                  severity: "error",
                  summary: "Error",
                  detail: err.error
                    ? err.error.message
                    : "Ups! ocurrio un error",
                });
              }
            );
          },
          (error) => {
            console.log("No se subio nada");
          }
        );
    } else {
      this.solicitudesService.generateSst(this.nuevo).subscribe(
        (res) => {
          this.sstGenerate = res;
          this.displayModal = true;
          this.limpiar();
          this.buttonGenerarSst = false;
        },
        (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        }
      );
    }
  }

  guardarBorrador() {
    this.buttonGenerarBorradorSst = true;

    this.nuevo.programmerId = this.form.get("programador").value.code;
    this.nuevo.supplierId = this.supplierId;
    this.nuevo.type = this.form.get("tipoGestion").value.id;
    this.nuevo.status = "NEW";
    this.nuevo.requirementDate = this.form.get("fechaNecesidad").value;
    this.nuevo.contactName = this.form.get("contacto").value;
    this.nuevo.phone = this.form.get("telefono").value;
    this.nuevo.email = this.form.get("email").value;
    this.nuevo.loadDescription = this.form.get("loadDescription").value;
    this.nuevo.emailNotification = this.form.get("emailNotification").value;

    this.nuevo.comments = this.form.get("comentarios").value || "";

    this.bultosModel.controls.forEach((f, index) => {
      let bulto = new Package();
      bulto.name = String(index + 1);
      bulto.description = f.value.bultos;
      bulto.height = f.value.alto;
      bulto.length = f.value.largo;
      bulto.width = f.value.ancho;
      bulto.weight = f.value.pesoAprox;
      // bulto.totalWeight = f.value.totalWeight ? Boolean(JSON.parse(f.value.totalWeight)) : true;
      this.nuevo.packages.push(bulto);
    });

    let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;

    // if (!noLlevaOc) {
    if (this.ordenCompraModel.controls.length > 0) {
      this.ordenCompraModel.controls.forEach((oc: FormGroup, i: any) => {
        let ocData: Oc = new Oc();

        ocData.oc = oc.controls.ordenCompra.value;
        this.getEaArrayOc(i).controls.forEach((ea: FormGroup) => {
          ocData.ea.push(ea.controls.esAe.value);
        });

        if (ocData.oc != "" && ocData.oc != null) {
          this.nuevo.ocEa.push(ocData);
        }
      });
    }
    // }

    if (
      this.form.get("origen").value === 0
    ) {
      this.nuevo.externalOrigin = this.form.get("origenCustom").value;
    } else {
      this.nuevo.origin = this.form.get("origen").value;
    }

    if (
      this.form.get("destino").value === 0
    ) {
      this.nuevo.externalDestination = this.form.get("destinoCustom").value;
    } else {
      this.nuevo.destination = this.form.get("destino").value;
    }

    let archivos = [];
    for (let file of this.uploadedFiles) {
      if (file) {
        archivos.push(file);
      }
    }

    let formData = new FormData();
    this.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    this.fileService.uploadFiles(formData).subscribe((data) => {
      this.nuevo.documentation = [];
      for (let file of data) {
        this.nuevo.documentation.push(file.id);
      }
    });

    if (this.uploadedFiles.length > 0) {
      this.fileService
        .uploadFiles(formData)
        .pipe(
          finalize(() => {
            console.log("Paso 1");
          })
        )
        .subscribe(
          (data: any) => {
            for (let file of data) {
              this.nuevo.documentation.push(file.id);
            }
            this.solicitudesService.createNewSst(this.nuevo).subscribe(
              (res) => {
                this.limpiar();
                this.messageService.add({
                  key: "msg",
                  severity: "success",
                  summary: "Guardado",
                  detail: "La solicitud fue guardada como borrador",
                });
                this.buttonGenerarBorradorSst = false;
                this.router.navigate(["/solicitudes"]);
              },
              (err) => {
                this.messageService.add({
                  key: "msg",
                  severity: "error",
                  summary: "Error",
                  detail: err.error
                    ? err.error.message
                    : "Ups! ocurrio un error",
                });
              }
            );
          },
          (error) => {
            console.log("No se subio nada");
          }
        );
    } else {
      this.solicitudesService.createNewSst(this.nuevo).subscribe(
        (res) => {
          this.limpiar();
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Guardado",
            detail: "La solicitud fue guardada como borrador",
          });
          this.buttonGenerarBorradorSst = false;
          this.router.navigate(["/solicitudes"]);

        },
        (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        }
      );
    }
  }

  downloadPdf() {
    if (this.sstGenerate) {
      this.pdfService
        .descargarSst(this.templateService.sendTemplateSst(this.sstGenerate))
        .subscribe(
          (data) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Descargado",
              detail: "SST Descargada",
            });
            var downloadURL = URL.createObjectURL(data);
            var link = document.createElement("a");
            link.href = downloadURL;
            link.download = `SST-${this.sstGenerate.requestId}.pdf`;
            link.click();
            // window.open(downloadURL);;
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error
                ? error.error.message
                : "Ups! ocurrio un error",
            });
          }
        );
    } else {
      this.messageService.add({
        key: "msg",
        severity: "error",
        summary: "Error",
        detail: "Ups! ocurrio un error, contacte con el administrador",
      });
    }
  }

  generarOtraSolicitud() {
    window.location.reload();
  }
}
