import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { SolcitudesService } from 'src/app/core/services/solicitudes/solcitudes.service';
import { SolicudesAsignadasService } from 'src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { SstService } from 'src/app/services/sst/sst.service';
import { PostSolicitud } from 'src/app/shared/models/sst/postSolicitud';
import { Solicitud } from 'src/app/shared/models/sst/solicitud';
import { SolicitudAsignada } from 'src/app/shared/models/sst/solicitudAsignada';
import { User } from 'src/app/shared/models/sst/solicitudes';

@Component({
  selector: 'app-update-ssta',
  templateUrl: './update-ssta.component.html',
  styleUrls: ['./update-ssta.component.scss']
})
export class UpdateSstaComponent implements OnInit {

  form: FormGroup;

  vistaPreliminar: boolean = false
  popUpGenerarSsta: boolean = false;
  checked: boolean = false;

  transportistas: User[] = []
  spinner = true;

  minDateValue = new Date();

  solicitudes: any[] = [];

  rol: string

  url: string

  tipo: string;

  requests: number[] = [];
  idSsta;
  constructor(private authService: AuthService, private sstService: SstService,
    private solicitudesAsignadasServices: SolicudesAsignadasService,
    private messageService: MessageService, private route: ActivatedRoute,
    private userService: UserService, private router: Router) {
    this.rol = this.authService.queryRole()
    this.url = this.route.snapshot.url[0].path;
    
    let { id } = this.route.snapshot.params;
    this.idSsta = id;

    this.userService.getByRol("transportista").subscribe(res => {

      res.forEach(e => {
        this.transportistas.push({ name: e.username, code: e.id })
      });
      this.cargar()

    })
  }

  ssta:SolicitudAsignada;

  cargar() {
    this.solicitudesAsignadasServices.getById(this.idSsta).subscribe(res => {
      this.ssta = res;
      this.form = new FormGroup(
        {
          checked: new FormControl(res.status == 'REJECTED' ? false : res.expressService),
          transportista: new FormControl(res.transporter, [Validators.required]),
          fechaProgramacion: new FormControl(new Date(res.programmingDate), [Validators.required]),

        });
      this.solicitudes = res.requests;
      let alto = 0;
      let ancho = 0;
      let largo = 0;
      this.solicitudes.forEach(d => {
        this.requests.push(d.id)
        d.packages.forEach(p => {
          alto = alto + Number(p.height);
          ancho = ancho + Number(p.width);
          largo = largo + Number(p.length);
        })
      })
      this.spinner = false;
    })
  }
  ngOnInit(): void {



  }

  showDialogVistaPreliminar() {
    this.vistaPreliminar = true;
  }

  showPopUpEditarSsta() {
    let data = new SolicitudAsignada();
    var datePipe = new DatePipe('en-US');
    data.programmingDate = datePipe.transform(this.form.get("fechaProgramacion").value, 'YYYY-MM-dd');
    data.transporter = this.form.get("transportista").value;
    data.requests = this.requests;
    data.expressService = this.form.get("checked").value;
    data.id = new Number(this.idSsta);
    data.status = this.ssta.status === 'REJECTED' ? "PENDING" : this.ssta.status
    this.solicitudesAsignadasServices.editSst(data).subscribe(
      res => {
        this.messageService.add({
          key: 'msg',
          severity: "success",
          summary: "Confirmado",
          detail: "La solicitud asignada se ha editado con exito",
        });
        this.router.navigate(["/solicitudes-asignadas"]);
      },
      err => {
        this.messageService.add({
          key: 'msg', severity: 'error', summary: 'Error', detail: (err.error) ? err.error.message : "Ups! ocurrio un error",
        });

      })

  }

  navegarDocumentSst(idSst:any){
    if(!localStorage.getItem('navigateUpdateSstaDocument')){
      localStorage.setItem("navigateUpdateSstaDocument","/actualizar-ssta/" + this.idSsta)
    }
    this.router.navigate(["/document/SST/" + idSst]);
  }
  

}