<div class="container min-width-500">
    <div class="header">
        <h2>Delegar SSTs<br /></h2>
    </div>

    <div class="row">
        <div class="col">
            <div class="card text-center" *ngIf="spinner">
                <span>
                    <p-progressSpinner></p-progressSpinner>
                </span>
                <br />
                <span class="text-center">Cargando...</span>
            </div>
            <p-card *ngIf="!spinner">
                <div class="row">
                    <h4>Programador: {{userActual?.username}} delega sus SSTs a:</h4>
                </div>
                <div class="row">
                    <div class="col">
                        <p-divider></p-divider>
                    </div>
                </div>
                <form [formGroup]="form" class="row">

                    <div class="col-4">
                        <label class="">Programador</label><br>
                        <p-dropdown [options]="programadores" formControlName="programador"
                            placeholder="-- Seleccione --" optionLabel="label" optionValue="value"
                            [showClear]="form.get('programador').value != ''" [style]="{ width: '100%' }">
                        </p-dropdown><br>
                        <span class="danger" *ngIf="
                              form.get('programador').hasError('required') &&
                              form.get('programador').touched
                            ">
                            <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>
                    </div>
                    <div class="col-4">
                        <label class="">Desde </label><br>
                        <p-calendar inputId="basic" showIcon="true" required [yearNavigator]="true" [minDate]="minDate"
                            [readonlyInput]="true" yearRange="1970:2050" dateFormat="dd/mm/yy"
                            formControlName="fechaDesde" [style]="{ width: '100%' }" [showTime]="true"
                            inputId="time" (onSelect)="enableToDate()"></p-calendar><br />
                        <span class="danger" *ngIf="
                                  form.get('fechaDesde').hasError('required') &&
                                  form.get('fechaDesde').touched
                                ">
                            <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>
                    </div>
                    <div class="col-4">
                        <label class="">Hasta </label><br>
                        <p-calendar inputId="basic" showIcon="true" required [yearNavigator]="true"
                            [readonlyInput]="true" yearRange="1970:2050" dateFormat="dd/mm/yy"
                            formControlName="fechaHasta" [style]="{ width: '100%' }" [showTime]="true"
                            inputId="time" [minDate]="this.form.get('fechaDesde').value"></p-calendar><br />
                        <span class="danger" *ngIf="
                                  form.get('fechaHasta').hasError('required') &&
                                  form.get('fechaHasta').touched
                                ">
                            <small class="p-error">Este campo es <strong>requerido</strong></small>
                        </span>
                    </div>
                    <div class="col-12 mt-5 p-0">
                        <div class="col">
                            <button pButton pRipple type="button" [disabled]="form.invalid" label="Delegar SSTs"
                                class="p-button-secondary" (click)="delegarSsts()"></button>
                            <p-button label="Volver" class="p-button-primary p-button-text"
                                routerLink="/home"></p-button>
                        </div>
                    </div>

                    <p-confirmDialog
                    [style]="{ width: '50vw' }"
                    [baseZIndex]="10000"
                    rejectButtonStyleClass="p-button-text"
                  ></p-confirmDialog>
                </form>
            </p-card>
        </div>
    </div>
</div>