export class Trailer {
  state: string;
  brand: string;
  model: string;
  patent: string;
  length: string;
  height: string;
  width: string;
  details: string;
  documentation:string[]=[]
  transporterId:string;
}
