import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tablero-transportistas',
  templateUrl: './tablero-transportistas.component.html',
  styleUrls: ['./tablero-transportistas.component.scss']
})
export class TableroTransportistasComponent implements OnInit {

  @Input()
  sstsDataTransportistas:any = []

  @Output()
  eventEmitterUserFilterSearch:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  searchLocalStorageSsta(user:any,status?:any){
    this.eventEmitterUserFilterSearch.emit({user,status})
  }

}

