import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { ConductoresService } from "src/app/core/services/transporte/conductores/conductores.service";
import { TractoresService } from "src/app/core/services/transporte/tractores/tractores.service";
import { TrailersService } from "src/app/core/services/transporte/trailers/trailers.service";
import { Conductor } from "src/app/shared/models/vehiculos/conductor";
import { Tractor } from "src/app/shared/models/vehiculos/tractor";
import { Trailer } from "src/app/shared/models/vehiculos/trailers";
@Component({
  selector: "app-document-update-conductor",
  templateUrl: "./document-update-conductor.component.html",
  styleUrls: ["./document-update-conductor.component.scss"],
})
export class DocumentUpdateConductorComponent implements OnInit {
  form: FormGroup;
  spinner = true;
  uploadedFiles: any[] = [];
  url: string;
  conductor: Conductor;
  id: any;
  cantidad = 0;
  documentsModel: any[] = [];
  documentsModelDelete: any[] = [];
  editoUser = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private solcitudAsignadasService: SolicudesAsignadasService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService,
    private messageService: MessageService,
    private conductorService: ConductoresService
  ) {}

  ngOnInit(): void {
    this.url = this.router.url.split("/", 2)[1];
    let { id } = this.activatedRoute.snapshot.params;
    this.id = id;

    this.conductorService.getById(this.id).subscribe((res) => {
      this.conductor = res;
      this.documentsModel = this.conductor.documentation;
      this.spinner = false;
    });

    this.form = new FormGroup({});
  }

  selectFile(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.cantidadArchivos();
  }

  onUpload(event) {}

  descargarDocumento(file) {
    let url = window.URL.createObjectURL(file);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.messageService.add({
      key: "msg",
      severity: "success",
      summary: "Descargado",
    });
  }

  descargarDocumentoServer(file) {
    this.fileService.getFile(file.id).subscribe(
      (data) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Descargado",
        });
      },
      (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  eliminarDocumento(file, indice) {
    this.uploadedFiles[indice] = null;
    this.cantidadArchivos();
  }

  eliminarDocumentoServer(file, indice) {
    this.documentsModelDelete.push(file.id);
    this.editoUser = true;
    this.documentsModel[indice] = null;
  }

  agregarDocumentos() {
    let mensaje = this.uploadedFiles.length > 0 ? "agrego" : "edito";

    if (this.cantidad <= 0 && !this.editoUser) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Debe subir al menos un documento",
      });
      return;
    }

    let archivos = [];
    for (let file of this.uploadedFiles) {
      if (file) {
        archivos.push(file);
      }
    }

    for (let file of this.documentsModelDelete) {
      if (file) {
        this.fileService.deleteFile(file).subscribe(
          (data) => {
            if (mensaje == "edito") {
              this.messageService.add({
                key: "msg",
                severity: "success",
                summary: "Confirmado",
                detail: "Se actualizaron los documentos del vehiculo",
              });
              this.router.navigate(["/informacion-de-transporte"]);
            }
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      }
    }

    if (this.uploadedFiles.length > 0) {
      let formData = new FormData();
      this.uploadedFiles.forEach((file) => {
        formData.append("files", file);
      });
      this.fileService.uploadFiles(formData).subscribe(
        (data) => {
          this.conductor.documentation = [];
          for (let file of data) {
            this.conductor.documentation.push(file.id);
          }
          this.conductorService.updateConductor(this.conductor).subscribe(
            (res) => {
              if (mensaje == "agrego") {
                this.messageService.add({
                  key: "msg",
                  severity: "success",
                  summary: "Confirmado",
                  detail: "Se actualizaron los documentos del vehiculo",
                });
              }
              this.router.navigate(["/informacion-de-transporte"]);
            },
            (err) => {
              this.messageService.add({
                key: "msg",
                severity: "error",
                summary: "Error",
                detail: err.error ? err.error.message : "Ups! ocurrio un error",
              });
            }
          );
        },
        (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        }
      );
    }
  }

  cantidadArchivos() {
    let cantidad = 0;
    for (let file of this.uploadedFiles) {
      if (file) {
        cantidad++;
      }
    }
    this.cantidad = cantidad;
  }
}
