import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authService: AuthService, private msalservice: MsalService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }
  private checkLogin() {
    const check = false;
    if (!this.checkLoginAzure() && !this.checkLoginBack()) {
      this.router.navigate(["login"]);
    } else {
      return true;
    }
  }
  private checkLoginBack() {
    return this.authService.isLoggedIn();
  }
  private checkLoginAzure() {
    return this.msalservice.instance.getAllAccounts().length > 0
  }
}
