import { Component, Input, OnInit } from '@angular/core';
import { PostSolicitud } from 'src/app/shared/models/sst/postSolicitud';
import { solicitudes } from 'src/app/shared/models/sst/solicitudes';

@Component({
  selector: 'app-pop-up-vista-preliminar',
  templateUrl: './pop-up-vista-preliminar.component.html',
  styleUrls: ['./pop-up-vista-preliminar.component.scss']
})
export class PopUpVistaPreliminarComponent implements OnInit {

  @Input()
  solicitudes: PostSolicitud[] = []

  popUpGenerarSsta:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  hiddenDialogVistaPreliminar(){

  }

  showPopUpGenerarSsta(){
    this.popUpGenerarSsta = true;
  }

}
