<div class="container min-width-500">
    <div class="header">
      <h2>Documentos<br /></h2>
    </div>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br />
      <span class="text-center">Cargando...</span>
    </div>
    <p-card class="card-form" *ngIf="!spinner && form">
      <form [formGroup]="form" (ngSubmit)="agregarDocumentos()" class="form">
        <div class="row col-12 p-0">
          <div class="col-6">
            <h4 class="title">Editar Documentos</h4>
          </div>
          <div class="col-6">
            <button
              pButton
              pRipple
              label="Volver"
              class="p-button-primary p-button-text float-right"
              routerLink="/actualizar-sst/{{sst.id}}"
            ></button>
          </div>
        </div>
        <div class="row col-12 mt-5">
          <div class="col m-0 p-0">
            <div class="card">
              <div class="" *ngIf="documentsModel.length > 0">
                <div class="row">
                  <div class="col">
                    <h1>Documentos</h1>
                  </div>
                </div>
                <div
                  class=""
                  *ngFor="let document of documentsModel; let i = index"
                >
                  <div class="row" *ngIf="document">
                    <div class="col">
                      <h3
                        pTooltip="Descargar"
                        class="cursor"
                        (click)="descargarDocumentoServer(document)"
                      >
                        {{ document.name }}
                      </h3>
                    </div>
                    <div class="col-6">
                      <button
                        (click)="eliminarDocumentoServer(document, i)"
                        type="button"
                        icon="pi pi-times"
                        pbutton=""
                        class="p-button p-button-icon-only"
                      >
                        <span
                          class="p-button-icon pi pi-times"
                          aria-hidden="true"
                        ></span
                        ><span aria-hidden="true" class="p-button-label"
                          >&nbsp;</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p-fileUpload
                chooseLabel="Adjuntar documentación"
                [files]="uploadedFiles"
                customUpload="true"
                (onSelect)="selectFile($event)"
                (uploadHandler)="onUpload($event)"
                multiple="multiple"
                maxFileSize="1000000"
                [auto]="true"
              >
                <ng-template pTemplate="toolbar" class="m-0 p-0">
                  <div class="row">
                    <div class="col">
                      {{ cantidad + " archivo seleccionado/s" }}
                    </div>
                  </div>
                  <div *ngIf="uploadedFiles.length > 0" class="">
                    <div *ngFor="let file of uploadedFiles; let i = index">
                      <div class="row mt-5 text-center" *ngIf="file">
                        <div class="col-6">
                          <p
                            pTooltip="Descargar"
                            class="cursor"
                            (click)="descargarDocumento(file)"
                          >
                            {{ file.name }}
                          </p>
                        </div>
                        <div class="col-6">
                          <button
                            (click)="eliminarDocumento(file, i)"
                            type="button"
                            icon="pi pi-times"
                            pbutton=""
                            class="p-button p-button-icon-only"
                          >
                            <span
                              class="p-button-icon pi pi-times"
                              aria-hidden="true"
                            ></span
                            ><span aria-hidden="true" class="p-button-label"
                              >&nbsp;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>
        </div>
        <div class="row col-12 mt-5">
          <div class="col text-center">
            <p-button
              type="submit"
              label="Editar Documentacion"
              class="p-button-primary p-button-text"
            ></p-button>
          </div>
        </div>
      </form>
    </p-card>
  </div>
  