<p-dialog [draggable]="false" [resizable]="false" [position]="position" header="Alertas" [modal]="true" [style]="{width: '75vw',position: 'fixed',margin: '0px',left:'287.5px',top:'35.75px'}" [baseZIndex]="11001"  [(visible)]="displayAlert">
  <app-popup-alertas></app-popup-alertas>
</p-dialog>

<div class="container min-width-500">
  <div class="header">
    <div class="header">
      <a href="index.html" class="logo"><img class="img-l" src="./assets/img/Pan_American_Energy_logo.svg.png"
          alt=""></a>
    </div>
  </div>
  <div class="card">
    <div class="row">
      <div class="col-12 text-center">
        <button pButton pRipple type="button" label="Alertas" class="p-button-secondary p-button-text" icon="pi pi-bell"
        iconPos="right" (click)="showDialogAlert()"></button>
      </div>
    </div>
  </div>
</div>