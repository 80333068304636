import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { of, concat } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Timeouts } from 'selenium-webdriver';

@Component({
  selector: 'app-graphic-ssta-rejected-accepted',
  templateUrl: './graphic-ssta-rejected-accepted.component.html',
  styleUrls: ['./graphic-ssta-rejected-accepted.component.scss']
})
export class GraphicSstaRejectedAcceptedComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  transportistas = []

  spinnerGraphicRejected = true;

  chart3: any;
  xaxisChart3 = []
  graphic3: any = null;
  dataChart3: any = [];

  constructor() {
    this.chart3 = {
      series: [],
      chart: {
        // id: 'chart3',
        type: 'bar',
        height: 500,
        stacked: true,
        stackType: '100%',
        zoom: {
          enabled: false,
        },
        toolbar: {
          tools: {
            zoomin: false,
            zoomout: false,
            selection: false,
            pan: false,
            reset: false
          },
        },
      },
      dataLabels: {
        formatter: function (val, opts) {
          return val
        },
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        },
      }],
      markers: {
        size: 1
      },
      xaxis: {

      },
      yaxis: {
        show: false
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false,
        position: 'right',
        offsetX: 0,
        offsetY: 50
      },
    };

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    
    of({
      
    }).pipe(
      tap({
        next: (data) => {
          this.graphicInsert()
        },
        error: (error) => {

        },
        complete : ()=>{
          this.spinnerGraphicRejected = false
        }

      }),
    ).subscribe()
  }

  graphicInsert() {
    const chartElement = document.querySelector("#chart3")

    if (this.graphic3) {
      // this.transportistaFilter = []
      this.xaxisChart3 = [];
      this.dataChart3 = [];
      this.graphic3.destroy()
    }

    this.transportistas.forEach((transportista, index) => {
      // this.transportistaFilter.push(transportista)
      this.xaxisChart3.push(transportista.username);
    })

    if (chartElement) {
      const sstaAceptadas =
      {
        name: "Aceptadas",
        data: [],
        color: '#24A900'
      }

      const sstaRechazadas =
      {
        name: "Rechazadas",
        data: [],
        color: '#f00'
      }
      this.transportistas.forEach((transportista) => {
        sstaAceptadas.data.push(parseFloat(transportista.cantidadAceptadas.toFixed(2)))
        sstaRechazadas.data.push(parseFloat(transportista.cantidadRechazadas.toFixed(2)))
      })

      this.dataChart3.push(sstaAceptadas)
      this.dataChart3.push(sstaRechazadas)

      this.chart3.labels = this.xaxisChart3
      this.chart3.series = this.dataChart3

      this.graphic3 = new ApexCharts(chartElement, this.chart3);
      // this.graphic3.updateSeries([
      //   {data: this.dataChart3}
      // ]
      // )
      this.graphic3.render();

      // this.graphic3.updateSeries([{
      //   data: this.dataChart3
      // }])
      
      // ApexCharts.exec('chart3', 'updateSeries', {
      //  data: this.dataChart3}, true);
    }
  }

  stopInterval(interval: any) {
    clearInterval(interval);
  }

}
