import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';




@NgModule({
  declarations: [],
  imports: [
    NgApexchartsModule
  ],
  exports :[
    NgApexchartsModule
  ]
})
export class ApexchartModule { }
