import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-reporte-listado',
  templateUrl: './reporte-listado.component.html',
  styleUrls: ['./reporte-listado.component.scss']
})
export class ReporteListadoComponent implements OnInit {

  roles: string[] = [];
  spinner = true;


  constructor(private authService:AuthService,private messageService:MessageService) { }

  ngOnInit(): void {
    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  getData() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.roles = data.rolenames
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
        }
      })
    )
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }

}
