import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { ExcelService } from "src/app/core/services/documentos/excel.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import {
  Destino,
  Envio,
  Origen,
  solicitudes,
  User,
} from "src/app/shared/models/sst/solicitudes";

@Component({
  selector: "app-report-ssta-tiempo",
  templateUrl: "./report-ssta-tiempo.component.html",
  styleUrls: ["./report-ssta-tiempo.component.scss"],
})
export class ReportSstaTiempoComponent implements OnInit {

  querySolicitudAsignada: any[] = [];
  solicitudesAsignadas: any[] = [];
  transportistas: User[] = [];
  statuses: any[];
  ordenSSTA: any[];
  spinner = true;

  rol: string;

  mensajeVacio: Message[];

  roles: string[] = [];


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private excelService: ExcelService,
    private solicitudesAsignadasService: SolicudesAsignadasService
  ) {
  }

  ngOnInit(): void {
    this.statuses = [
      { label: "Pendiente", value: "PENDING" },
      { label: "Aceptada", value: "ACCEPTED" },
      { label: "Rechazada", value: "REJECTED" },
      { label: "Cancelada", value: "CANCELLED" },
      { label: "Eliminada", value: "DELETED" },
      { label: "Finalizada", value: "FINISHED" },
      { label: "Informada", value: "INFORMED" },
      { label: "En Curso", value: "IN_PROGRESS" },

    ];

    this.ordenSSTA = [
      { label: "Por Fecha", value: "DATE" },
      { label: "Por Número SSTA", value: "ID" },
    ];

    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  getData() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.roles = data.rolenames
        }, error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
        }
      }),
      concatMap(() => this.getTransportistas()),
      concatMap(() => this.getListSolicitudes()),
    )
  }

  getTransportistas() {
    return this.userService.getByRol("transportista").pipe(
      tap({
        next: (data) => {
          data.forEach((e) => {
            if (e.azure) {
              this.transportistas.push({ name: e.username, code: e.id });
            } else {
              this.transportistas.push({
                name: (e.companyName || e.username),
                code: e.id,
              });
            }
          });
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }


  getListSolicitudes() {
    return this.solicitudesAsignadasService
      .reporteSstaTiempoHastaAsignacionPorTransportista(this.querySolicitudAsignada, this.pageAsignadas, this.sizeAsignadas)
      .pipe(
        tap({
          next: (data) => {
            this.solicitudesAsignadas = data.list;
            this.totalRecordsAsignadas = data.totalElements;
          }, error: (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error ? error.error.message : "Ups! ocurrio un error",
            });
          },
        })
      )
  }

  transporterId = "";
  minDateValue = new Date();
  startDateSolicitud = "";
  endDateSolicitud = ""

  filtrosSolicitudAsignada() {
    this.querySolicitudAsignada = [];
    if (this.transporterId) {
      this.querySolicitudAsignada.push({
        col: "transporterId",
        value: this.transporterId,
      });
    }
    if (this.startDateSolicitud) {
      this.minDateValue = new Date(this.startDateSolicitud);
    }

    if (this.startDateSolicitud && this.endDateSolicitud) {
      this.querySolicitudAsignada.push({
        col: "startDate",
        value: this.startDateSolicitud,
      });
      this.querySolicitudAsignada.push({
        col: "endDate",
        value: this.endDateSolicitud,
      });
    }
    if (this.querySolicitudAsignada.length > 0) {
      this.spinner = true;
    }
    this.getListSolicitudes().subscribe({
      complete: () => {
        this.spinner = false;
      }
    });
  }

  descargarReporteSsta() {
    this.excelService.descargarTiempoSsta(this.querySolicitudAsignada).subscribe(
      (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = `reporte-tiempo-transportista-SSTA.xlsx`;
        link.click();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  //paginado --INICIO
  totalRecordsAsignadas = 0;
  pageAsignadas = 1;
  sizeAsignadas = 10;

  paginateAsignadas(event) {
    this.pageAsignadas = event.page + 1;
    this.sizeAsignadas = event.rows;
    this.getListSolicitudes().subscribe();
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
