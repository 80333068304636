<div class="container min-width-500">
    <div class="header">
      <h2>Detalles Proveedor</h2>
    </div>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br>
      <span class="text-center">Cargando...</span>
    </div>
    <div class="card col-8"  *ngIf="!spinner">
      <div class="row">
          <div class="col">
              <label>Nombre: <span>{{proveedor.username}}</span> </label><br>
              <label>Razón Social: <span> {{proveedor.companyName}} </span> </label><br>
              <label>Cuit: <span> </span>{{formatearCUIT(proveedor.taxPayerId)}} </label><br>
          </div>
          <div class="col">
              <label>Teléfono <span> {{proveedor.phone}} </span> </label><br>
              <label>Usuario: <span> {{proveedor.username}} </span> </label><br>
              <label>Dirección: <span> {{proveedor.address}} </span> </label><br>
          </div>
      </div>
      <div class="row">
        <div class="col">
          <h4>Emails:</h4>
          <ul>
            <li *ngFor="let email of proveedor.emails; let i = index">{{email}}</li>
          </ul>
        </div>
      </div>
      <div class="flex justify-content-end">
          <p-button
          label="Volver"
          class="p-button-primary p-button-text"
          routerLink="/proveedores" 
        ></p-button>
      </div>
  </div>
  