import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Trailer } from "src/app/shared/models/vehiculos/trailers";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TrailersService {
  constructor(private http: HttpClient) {}

  getAll(query:any = []):Observable<any> {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http.get(`${environment.apiUrl}/transporte/trailers/lista?${queryBuid}`, {
      headers: headers,
    });
  }

  getAllAsync(query:any = []) :Promise<any>{
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.apiUrl}/transporte/trailers/lista?${queryBuid}`, {
          headers: headers,
        })
        .subscribe({
          next: (data:any) => resolve(data.list),
          error: (err) => reject(err),
        });
    });
  }

  createTrailer(data: Trailer) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/transporte/trailers/nuevo`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deshabilitarTrailer(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/transporte/trailers/desactivar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  habilitarTrailer(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(
        `${environment.apiUrl}/transporte/trailers/activar/${id}`,
        {},
        { headers: headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getById(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/transporte/trailers/buscar/${id}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateTrailer(data: Trailer) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .post(`${environment.apiUrl}/transporte/trailers/guardar`, data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
