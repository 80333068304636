<div class="container-fluid min-width-500 p-0">
  <div class="header">
    <h2>Reportes</h2>
  </div>
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0">
      <p-card>
        <div class="card text-center" *ngIf="spinner">
          <span>
            <p-progressSpinner></p-progressSpinner>
          </span>
          <br />
          <span class="text-center">Cargando...</span>
        </div>
        <div *ngIf="!spinner">

          <div class="row">
            <div class="col-6">
              <div style="float: left">
                <h1 class="title">SSTs PROGRAMADORES</h1>
                <br />
              </div>
            </div>
            <div class="col-6">
              <button class="p-button-lg float-right" pButton pRipple label="Volver" routerLink="/reportes"></button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="">Programadores <span></span></label>
              <br />
              <p-dropdown [filter]="true" [options]="programadores" [(ngModel)]="programadorSolicitud"
                placeholder="-- Seleccione --" optionLabel="name" optionValue="code" [showClear]="true"
                [style]="{ width: '100%' }" (onChange)="filtrosSolicitud()"></p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="">Proveedores <span></span></label>
              <br />
              <p-dropdown [filter]="true" [options]="proveedores" [(ngModel)]="proveedorSolicitud"
                placeholder="-- Seleccione --" optionLabel="name" optionValue="code" [showClear]="true"
                [style]="{ width: '100%' }" (onChange)="filtrosSolicitud()"></p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="">Fecha necesidad del Transporte<span></span></label>
              <br />
              <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitud()" [(ngModel)]="startDateSolicitud"
                inputId="basic" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"
                (onSelect)="filtrosSolicitud()" showIcon="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                yearRange="1970:2050">
                <ng-template pTemplate="header">
                  <h4 class="text-center">Desde</h4>
                </ng-template>
              </p-calendar>
            </div>
            <div class="col-6">
              <label class="">Fecha necesidad del Transporte<span></span></label>
              <br />
              <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitud()" [(ngModel)]="endDateSolicitud"
                inputId="basic" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"
                (onSelect)="filtrosSolicitud()" showIcon="true" [minDate]="minDateValue" dateFormat="dd/mm/yy"
                [yearNavigator]="true" yearRange="1970:2050">
                <ng-template pTemplate="header">
                  <h4 class="text-center">Hasta</h4>
                </ng-template>
              </p-calendar>
            </div>
          </div>
          <div class="row">
            <div class="col-6" [class]="columnasPending()">
              <label class="">N°SST </label><br />
              <input [style]="{ width: '100%' }" [(ngModel)]="requestId" (input)="filtrosSolicitud()" type="text"
                pInputText appendTo="body" />
            </div>
            <div class="col-6" *ngIf="url != '/reportes/SST/pendientes'">
              <label class="">Estado <span></span></label>
              <br />
              <p-dropdown [filter]="true" [(ngModel)]="estadoSolicitud" class="p-dropdown" [options]="statuses"
                optionLabel="label" optionValue="value" placeholder="-- Seleccione --" [showClear]="true"
                [style]="{ width: '100%' }" (onChange)="filtrosSolicitud()">
                <ng-template let-option pTemplate="item" class="p-dropdown">
                  <span [class]="'p-dropdown customer-badge status-' + option.value">{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="">Origen </label><br />
              <p-dropdown [filter]="true" [style]="{ width: '100%' }" optionLabel="description" optionValue="id"
                class="" appendTo="body" [(ngModel)]="origen" [options]="origenes" (onChange)="filtrosSolicitud()"
                placeholder="-" [showClear]="true">
                <ng-template let-option pTemplate="item">
                  <span [class]="'customer-badge status-' + option.id">{{
                    option.description
                    }}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-6">
              <label class="">Destino </label><br />
              <p-dropdown [filter]="true" [(ngModel)]="destino" [style]="{ width: '100%' }" optionLabel="description"
                optionValue="id" class="" appendTo="body" [options]="destinos" (onChange)="filtrosSolicitud()"
                placeholder="-" [showClear]="true">
                <ng-template let-option pTemplate="item">
                  <span [class]="'customer-badge status-' + option.id">{{
                    option.description
                    }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="">Entrega de salida / Aviso de Entrega </label><br />
              <input [style]="{ width: '100%' }" [(ngModel)]="esAe" (input)="filtrosSolicitud()" type="text" pInputText
                appendTo="body" />
            </div>
            <div class="col-6">
              <label class="">Orden de compra </label><br />
              <input [style]="{ width: '100%' }" [(ngModel)]="purchaseOrderNumber" (input)="filtrosSolicitud()"
                type="text" pInputText appendTo="body" />
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-12">
              <label class="">Ordenar Por <span></span></label>
              <br />
              <p-dropdown [filter]="true" class="p-dropdown" optionLabel="label" optionValue="value"
                [(ngModel)]="ordenSolicitud" [options]="ordenSST" placeholder="-- Seleccione --" [showClear]="true"
                [style]="{ width: '100%' }" (onChange)="filtrosSolicitud()">
                <ng-template let-option pTemplate="item" class="p-dropdown">
                  <span [class]="'p-dropdown customer-badge status-' + option.value">{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <p-table *ngIf="!spinner" #myTab2 [value]="solicitudes" [scrollable]="true" scrollHeight="300px"
            [tableStyle]="{ 'min-width': '50rem' }" [resizableColumns]="true"
            styleClass="p-datatable p-datatable-striped p-datatable-sm p-datatable-gridlines p-datatable-responsive-demo"
            [rowHover]="true" dataKey="id">
            <ng-template pTemplate="caption">
              <p class="text-center">SSTs</p>
            </ng-template>
            <ng-template pTemplate="header" class="p-datatable-header">
              <tr>
                <th>Nro SST</th>
                <th>Fecha solicitud</th>
                <th *ngIf="url != '/reportes/SST/pendientes'">Fecha asignación</th>
                <th>Solicitante</th>
                <th>Origen</th>
                <th>Destino</th>
                <th>Fecha necesidad del Transporte</th>
                <th>Estado</th>
              </tr>
              <tr></tr>
            </ng-template>

            <ng-template pTemplate="body" let-sst>
              <tr class="p-selectable-row" class="p-datatable-tbody">
                <td class="">
                  {{ sst.requestId }}
                </td>
                <td class="">
                  {{ sst.creation | date : 'dd/MM/yyyy HH:mm:ss' }}
                </td>
                <td class="" *ngIf="url != '/reportes/SST/pendientes'">
                  {{ sst.assignedDateTime | date : 'dd/MM/yyyy HH:mm:ss' }}
                </td>
                <td class="">
                  {{ sst.supplierName }}
                </td>
                <td class="">
                  {{ sst.originName }}
                </td>
                <td class="">
                  {{ sst.destinationName }}
                </td>
                <td class="">
                  {{ sst.requirementDate | date : "dd/MM/YYYY" }}
                </td>
                <td class="">
                  <div class="state">
                    <p *ngIf="sst.status == 'CANCELLED'">CANCELADA</p>
                    <p *ngIf="sst.status == 'DELETED'">ELIMINADA</p>
                    <p *ngIf="sst.status == 'FINISHED'">FINALIZADA</p>
                    <p *ngIf="sst.status == 'ASSIGNED'">ASIGNADA</p>
                    <p *ngIf="sst.status == 'REJECTED'">RECHAZADA</p>
                    <p *ngIf="sst.status == 'PENDING'">PENDIENTE</p>
                    <p *ngIf="sst.status == 'INFORMED'">INFORMADA</p>
                    <p *ngIf="sst.status == 'NEW'">GUARDADA</p>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" class="w-100">
              <tr>
                <td colspan="12">
                  <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No se encontraron SSTs' }]"
                    [enableService]="false" [closable]="false">
                  </p-messages>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-paginator [rows]="sizeSolicitudes" [totalRecords]="totalRecordosSolicitudes"
            [rowsPerPageOptions]="[10, 20, 30]" (onPageChange)="paginateSolicitudes($event)"></p-paginator>

          <div class="d-flex justify-content-center flex-wrap mt-5" *ngIf="!hasRole('VISUALIZER')">
            <button pButton pRipple type="button" label="Descargar Reporte" class="p-button-secondary"
              (click)="descargarReporteSst()"></button>
          </div>
        </div>

      </p-card>
    </div>
  </div>
</div>