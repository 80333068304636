import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { concatMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/core/authentication/auth.service";
import { DespositosService } from "src/app/core/services/depositos/despositos.service";
import { ExcelService } from "src/app/core/services/documentos/excel.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { UserService } from "src/app/core/services/user/user.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";
import {
  Destino,
  Envio,
  Origen,
  solicitudes,
  User,
} from "src/app/shared/models/sst/solicitudes";

@Component({
  selector: "app-report-sst-requirente",
  templateUrl: "./report-sst-requirente.component.html",
  styleUrls: ["./report-sst-requirente.component.scss"],
})
export class ReportSstRequirenteComponent implements OnInit {
  users: User[] = [];
  programadores: User[] = [];
  statuses: any[];
  ordenSST: any[];

  solicitudes: PostSolicitud[] = [];
  querySolicitud: any[] = [];

  spinner = true;

  rol: string;

  mensajeVacio: Message[];

  url: string = "";

  minDateValue = new Date();

  origenes: Origen[] = [];
  destinos: Destino[] = [];

  roles: string[] = [];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private solicitudesServices: SolcitudesService,
    private excelService: ExcelService,
    private depositosService: DespositosService
  ) {
    // this.userService.getByRol("LIDER").subscribe((res) => {
    //   res.forEach((e) => {
    //     this.users.push({ name: e.username + " - LIDER DE PLANIFICACIÓN", code: e.id });
    //   });
    // });

  }

  ngOnInit(): void {
    this.statuses = [
      { label: "Pendiente", value: "PENDING" },
      { label: "Asignada", value: "ASSIGNED" },
      { label: "Rechazada", value: "REJECTED" },
      { label: "Cancelada", value: "CANCELLED" },
      { label: "Eliminada", value: "DELETED" },
      { label: "Finalizada", value: "FINISHED" },
      { label: "INFORMADA", value: "INFORMED" },
    ];

    this.ordenSST = [
      { label: "Por Destino", value: "DESTINATION" },
      { label: "Por Origen", value: "ORIGIN" },
      { label: "Por Fecha", value: "DATE" },
      { label: "Por Número SST", value: "ID" },
    ];

    this.getData().subscribe({
      complete: () => {
        this.spinner = false;
      }
    })
  }

  getData() {
    return this.userService.getByRol("PROVEEDOR").pipe(tap({
      next: (data) => {
        data.forEach((e) => {
          if (e.azure) {
            this.users.push({ name: e.username + " - PROVEEDOR", code: e.id });
          } else {
            this.users.push({
              name: (e.companyName || e.username) + " - PROVEEDOR",
              code: e.id,
            });
          }
        });
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    }),
      concatMap(() => this.getProgramadores()),
      concatMap(() => this.getInternos()),
      concatMap(() => this.getOrigenes()),
      concatMap(() => this.getDestinos()),
      concatMap(() => this.getUserActual()),
      concatMap(() => this.getListSolicitudes()),
    )

  }

  getProgramadores() {
    return this.userService.getByRol("PROGRAMADOR").pipe(tap({
      next: (data) => {
        data.forEach((e) => {
          if (e.azure) {
            this.programadores.push({ name: e.username + " - PROGRAMADOR", code: e.id });
          } else {
            this.programadores.push({
              name: (e.companyName || e.username) + " - PROGRAMADOR",
              code: e.id,
            });
          }
        });
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    })
    )
  }

  getInternos() {
    return this.userService.getByRol("INTERNO").pipe(tap({
      next: (data) => {
        data.forEach((e) => {
          if (e.azure) {
            this.users.push({ name: e.username + " - INTERNO", code: e.id });
          } else {
            this.users.push({
              name: (e.companyName || e.username) + " - INTERNO",
              code: e.id,
            });
          }
        });
      }, error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    })
    )
  }

  getOrigenes() {
    return this.depositosService.getOrigenes().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.origenes.push({ description: d.name, id: d.id });
          });

          this.origenes.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getDestinos() {
    return this.depositosService.getDestinos().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.destinos.push({ description: d.name, id: d.id });
          });

          this.destinos.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getUserActual() {
    return this.authService.actual().pipe(
      tap({
        next: (data) => {
          this.roles = data.rolenames
        },
        error: (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      })
    )
  }

  getListSolicitudes() {
    return this.solicitudesServices
      .filterReporte(
        this.querySolicitud,
        this.pageSolicitudes,
        this.sizeSolicitudes
      ).pipe(
        tap({
          next : (data)=>{
            this.solicitudes = data.list;
            this.totalRecordosSolicitudes = data.totalElements;
          }
        })
      )
  }

  startDateSolicitud = "";
  endDateSolicitud = "";
  ordenSolicitud = "";
  programmerId = "";
  userRequestSolicitud = "";
  estadoSolicitud = "";
  esAe = "";
  purchaseOrderNumber = "";
  destino = "";
  origen = "";
  requestId = "";

  filtrosSolicitud() {
    this.querySolicitud = [];
    if (this.startDateSolicitud) {
      this.minDateValue = new Date(this.startDateSolicitud);
    }

    if (this.startDateSolicitud && this.endDateSolicitud) {
      this.querySolicitud.push({
        col: "startDate",
        value: this.startDateSolicitud,
      });
      this.querySolicitud.push({
        col: "endDate",
        value: this.endDateSolicitud,
      });
    }
    if (this.ordenSolicitud) {
      this.querySolicitud.push({
        col: "sort",
        value: this.ordenSolicitud,
      });
    }
    if (this.esAe) {
      this.querySolicitud.push({
        col: "esAe",
        value: this.esAe,
      });
    }
    if (this.purchaseOrderNumber) {
      this.querySolicitud.push({
        col: "purchaseOrderNumber",
        value: this.purchaseOrderNumber,
      });
    }
    if (this.userRequestSolicitud) {
      this.querySolicitud.push({
        col: "supplierId",
        value: this.userRequestSolicitud,
      });
    }
    if (this.programmerId) {
      this.querySolicitud.push({
        col: "programmerId",
        value: this.programmerId,
      });
    }
    if (this.estadoSolicitud) {
      this.querySolicitud.push({
        col: "statuses",
        value: this.estadoSolicitud,
      });
    }
    if (this.destino) {
      this.querySolicitud.push({
        col: "destinationId",
        value: this.destino,
      });
    }
    if (this.origen) {
      this.querySolicitud.push({
        col: "originId",
        value: this.origen,
      });
    }
    if (this.requestId) {
      this.querySolicitud.push({
        col: "requestId",
        value: this.requestId,
      });
    }
    if (this.querySolicitud.length > 0) {
      this.spinner = true;
    }
    this.getListSolicitudes().subscribe({
      complete: () => {
        this.spinner = false;
      }
    })
  }



  descargarReporteSst() {
    this.excelService.descargarSst(this.querySolicitud).subscribe(
      (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = downloadURL;
        link.download = `reporte-requirente-SST.xlsx`;
        link.click();
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  //paginado --INICIO
  totalRecordosSolicitudes = 0;
  pageSolicitudes = 1;
  sizeSolicitudes = 10;

  paginateSolicitudes(event) {
    this.pageSolicitudes = event.page + 1;
    this.sizeSolicitudes = event.rows;
    this.getListSolicitudes().subscribe();
  }

  columnasPending() {
    if (this.url == "/reportes/SST/pendientes") {
      return "col-12";
    }
    return "col-6";
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
