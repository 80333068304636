import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { UserService } from "src/app/core/services/user/user.service";

@Component({
  selector: "app-cambiar-contrasenia",
  templateUrl: "./cambiar-contrasenia.component.html",
  styleUrls: ["./cambiar-contrasenia.component.scss"],
})
export class CambiarContraseniaComponent implements OnInit {
  progressBarValue = 100;

  keywords: any = [];

  form: FormGroup;

  token: string = "";

  @Output() cerrar: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authServices: AuthService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let { token } = this.activatedRoute.snapshot.params;
    this.token = token || "";
    if (token == "") {
      this.router.navigate(["/login"]);
    }

    this.form = new FormGroup({
      pass: new FormControl("", Validators.required),
      repitePassword: new FormControl("", Validators.required),
    });
  }

  cambiarContrasenia() {
    let password = this.form.get("pass").value;
    let repitePassword = this.form.get("repitePassword").value;

    if (password == repitePassword) {
      let objeto = {
        password: password,
        token: this.token,
      };
      this.authServices.cambiarContrasenia(objeto).subscribe(
        (res) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Hecho!",
            detail: "Inicia Sesión con tu nueva contraseña",
          });
          this.router.navigate(["/login"]);
        },
        (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        }
      );
    } else {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "¡Contraseñas no coinciden!",
        detail: "Las contraseñas deben de coincidir",
      });
    }
  }
}
