import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-habilitaciones',
  templateUrl: './popup-habilitaciones.component.html',
  styleUrls: ['./popup-habilitaciones.component.scss']
})
export class PopupHabilitacionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
