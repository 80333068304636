import { Oc } from "./oc";
import { Package } from "./package";

export class PostSolicitud{
    id:number;
    creationDate:string
    programmerId: number;
    supplierId: number;
    type:string;
    origin: number;
    destination: number;
    status: string;
    requirementDate: string;
    requirementeDateTypeDate;
    packages: Package[]=[];
    comments: string;
    externalDestination:string;
    externalOrigin:string;
    documentation:string[]=[];
    report:string;
    contactName:string;
    phone:string;
    email:string;
    loadDescription:string;
    transporterGuide:string;
    emailNotification:Boolean;
    programmingDate?:Date;
    ocEa:Oc[] = []

    constructor(){
        if(this.requirementDate){
            this.requirementeDateTypeDate = new Date(this.requirementDate);
        }
    }
}

