import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DespositosService {
  constructor(private http: HttpClient) {
  }

  getAll() {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', "Bearer " + localStorage.getItem("token"));
      return this.http.get(`${environment.apiUrl}/depositos/lista`, { headers: headers }).pipe(
          map((res: any) => {
              return res;
          })
      );
  }


  getDestinos() {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', "Bearer " + localStorage.getItem("token"));
      return this.http.get(`${environment.apiUrl}/depositos/lista?destination=${true}`, { headers: headers }).pipe(
          map((res: any) => {
              return res;
          })
      );
  }

  getOrigenes() {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', "Bearer " + localStorage.getItem("token"));
      return this.http.get(`${environment.apiUrl}/depositos/lista?origin=true`, { headers: headers }).pipe(
          map((res: any) => {
              return res;
          })
      );
  }
}
