export class Tractor {
    brand: string;
    chassis: string;
    engine:string
    id: number;
    model: string;
    motor: string;
    patent: string;
    state: string;
    documentation:string[]=[];
    transporterId:string;
}