import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { User } from "src/app/shared/models/user/user";
import { AzureDate } from "./interfaces/groupAzure.interface.";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isLoggedIn() {
    if (localStorage.getItem("userName")) {
      return true;
    }
  }
  logout() {
    localStorage.clear();
  }
  constructor(private http: HttpClient) {}

  login(data: any) {
    return this.http.post(`${environment.apiUrl}/authenticate`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  loginAzure(data: any) {
    return this.http.post(`${environment.apiUrl}/authenticate/ad`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getGroupAdAzure(idUser: any, token: any): Promise<AzureDate> {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + token);
    return new Promise((resolve, reject) => {
      this.http
        .get(`https://graph.microsoft.com/v1.0/users/${idUser}/memberOf`, {
          headers: headers,
        })
        .subscribe({
          next: (data: AzureDate) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  registro(data: any) {
    return this.http.post(`${environment.apiUrl}/usuarios/nuevo`, data,{observe : "response"}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  recuperarContraseña(mail: string) {
    let data = {
      username: mail.trim(),
    };
    return this.http
      .post(`${environment.apiUrl}/usuarios/password?username=${mail}`, {})
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  cambiarContrasenia(data: any) {
    return this.http.post(`${environment.apiUrl}/usuarios/activar`, data, { observe : "response"}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  actual() {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/usuarios/actual`, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  actual2(): Promise<User> {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.apiUrl}/usuarios/actual`, { headers: headers })
        .subscribe({
          next: (data: User) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  //ROLES
  //esto deberia ser una llamada a un backo una variable de sesion que tenga en rol
  //la idea es que me devuelta el rol del usaurio actual
  public queryRole() {
    return localStorage.getItem("rol");
  }
}
