<div class="container min-width-500">
    <div class="header">
      <h2>Editar Programador</h2>
    </div>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br>
      <span class="text-center">Cargando...</span>
    </div>
    <div class="card col-8">
      <form [formGroup]="form" (ngSubmit)="modificarProveedor()" class="form" *ngIf="!spinner && form">
        <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="nombre" class="pl-5">Nombre </label>
          </div>
          <div class="col-8">
            <input
              type="text"
              pInputText
              formControlName="nombre"
              class="form-control"
            />
            <span
            class="danger"
            *ngIf="
              form.get('nombre').hasError('required') &&
              form.get('nombre').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          </div>
        </div>
        <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="razonSocial" class="pl-5">Razón social </label>
          </div>
          <div class="col-8">
            <input
              type="text"
              pInputText
              formControlName="razonSocial"
              class="form-control"
            />
            <span
            class="danger"
            *ngIf="
              form.get('razonSocial').hasError('required') &&
              form.get('razonSocial').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          </div>
        </div>
        <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="cuit" class="pl-5">CUIT </label>
          </div>
          <div class="col-8">
            <input
              type="text"
              pInputText
              formControlName="cuit"
              class="form-control"
            />
            <span
            class="danger"
            *ngIf="
              form.get('cuit').hasError('required') &&
              form.get('cuit').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          </div>
        </div>
        <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="direccion" class="pl-5">Dirección </label>
          </div>
          <div class="col-8">
            <input
              type="text"
              pInputText
              formControlName="direccion"
              class="form-control"
            />
            <span
            class="danger"
            *ngIf="
              form.get('direccion').hasError('required') &&
              form.get('direccion').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          </div>
        </div>
        <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="telefono" class="pl-5">Télefono </label>
          </div>
          <div class="col-8">
            <input
              type="text"
              pInputText
              formControlName="telefono"
              class="form-control"
            />
            <span
            class="danger"
            *ngIf="
              form.get('telefono').hasError('required') &&
              form.get('telefono').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          </div>
        </div>
        <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="email" class="pl-5">Email </label>
          </div>
          <div class="col-8">
            <input
              type="email"
              pInputText
              formControlName="email"
              class="form-control"
            />
            <span
            class="danger"
            *ngIf="
              form.get('email').hasError('required') &&
              form.get('email').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          </div>
        </div>
        <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="usuario" class="pl-5">Usuario </label>
          </div>
          <div class="col-8">
            <input
              type="text"
              pInputText
              formControlName="usuario"
              class="form-control"
            />
            <span
            class="danger"
            *ngIf="
              form.get('usuario').hasError('required') &&
              form.get('usuario').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
          </div>
        </div>
        <!-- <div class="row col-12 mt-5 p-0">
          <div class="col-4">
            <label for="contraseña" class="pl-5">Contraseña </label>
          </div>
          <div class="col-8">
              <p-password formControlName="contraseña" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
  
              [toggleMask]="true"></p-password>
              <span
              class="danger"
              *ngIf="
                form.get('contraseña').hasError('required') &&
                form.get('contraseña').touched
              "
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
          </div>
        </div> -->
        <div class="row col-12 mt-5">
          <div class="col-6">
            <!-- <button
              pButton
              pRipple
              type="button"
              label="Cargar foto"
              class="p-button-secondary p-button-text"
            ></button> -->
          </div>
          <div class="col-6 text-right">
            <p-button
              type="submit"
              [disabled]="form.invalid"
              label="Modificar Alta usuario"
              class="p-button-primary p-button-text float-end"
            ></p-button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <p-toast></p-toast>