<p-dialog header="SSTA Aceptada" [(visible)]="showPopUpSstaAceptada">
  <app-popup-ssta-aceptada (eventHiddenPopUp)="cerrarPopUpSstaAceptada($event)"></app-popup-ssta-aceptada>
</p-dialog>
<p-dialog header="Proponer Nueva Fecha" [style]="{ 'background-color': 'white', 'min-width': '50vw' }"
  [(visible)]="showPopUpProponerFecha">
  <app-popup-proponer-nueva-fecha [idSstSelected]="idSstSelected"
    (eventHiddenPopUp)="cerrarPopUpPosponerFecha($event)"></app-popup-proponer-nueva-fecha>
</p-dialog>

<p-dialog header="Finalizar SSTA" [style]="{ 'background-color': 'white', 'min-width': '50vw', width: '50vw' }"
  [(visible)]="showPopUpFinalizarSsta">
  <app-pop-up-finalizar-ssta [sstaAFinalizar]="sstaAFinalizar"
    (eventHiddenPopUp)="cerrarPopUpFinalizarSsta($event)"></app-pop-up-finalizar-ssta>
</p-dialog>

<p-dialog header="Confirmar transporte" [(visible)]="showPopUpEnCursoSsta" *ngIf="sstaEnCurso"
  [style]="{ 'background-color': 'white', 'min-width': '50vw' }" (onHide)="cerrarPopUpEnCursoSsta(1)">
  <app-pop-up-curso-ssta [ssta]="sstaEnCurso"
    (eventHiddenPopUp)="cerrarPopUpEnCursoSsta($event)"></app-pop-up-curso-ssta>
</p-dialog>

<div class="card text-center" *ngIf="spinner || !form">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<form [formGroup]="form" class="" *ngIf="form && !spinner">
  <div class="container min-width-500">
    <div class="header">
      <h2>SOLICITUDES ASIGNADAS<br /></h2>
    </div>
    <div class="card">
      <div class="row col-12 p-0">
        <div class="col">
          <h1 class="title">Filtros</h1>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-12 col-md-6 col-lg-3 p-2">
          <label class="">Programadores </label><br />
          <p-dropdown [filter]="true" [style]="{ width: '100%' }" class="" appendTo="body" [options]="programadores" placeholder="-"
            [showClear]="form.get('programadores').value" optionLabel="username" optionValue="id"
            formControlName="programadores">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.username || option.companyName
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3">
          <label class="">Transportistas </label><br />
          <p-dropdown [filter]="true" [style]="{ width: '100%' }" class="" appendTo="body" [options]="transportistas" placeholder="-"
            [showClear]="form.get('transportista').value" optionLabel="username" optionValue="id"
            formControlName="transportista">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.username || option.companyName
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3">
          <label class="">Fecha Programación (comienzo) </label><br />
          <p-calendar [style]="{ width: '100%'  }" formControlName="fechaProgramacionInit"
            (onClearClick)="resetDate('startProgrammingDate')" showButtonBar="true" dateFormat="dd/mm/yy" class=""
            appendTo="body" inputId="basic" [yearNavigator]="true" yearRange="1970:2050"></p-calendar>
        </div>
        <div class="col-3">
          <label class="">Fecha Programación (fin) </label><br />
          <p-calendar [style]="{ width: '100%'  }" formControlName="fechaProgramacionEnd"
            (onClearClick)="resetDate('endProgrammingDate')" showButtonBar="true" dateFormat="dd/mm/yy" class=""
            appendTo="body" inputId="basic" [minDate]="form.get('fechaProgramacionInit').value" [yearNavigator]="true"
            yearRange="1970:2050"></p-calendar>
        </div>

        <div class="col-3">
          <label class="">Nº SSTA </label><br />
          <input [style]="{ width: '100%'  }" formControlName="numeroSsta" type="number" min="0" pInputText
            appendTo="body" />
        </div>
        <div class="col-3">
          <label class="">Nº SST </label><br />
          <input [style]="{ width: '100%'  }" formControlName="numeroSst" type="number" min="0" pInputText
            appendTo="body" />
        </div>
        <div class="col-3">
          <label class="">ES/AE </label><br />
          <input [style]="{ width: '100%'  }" formControlName="esAe" type="text" pInputText appendTo="body" />
        </div>
        <div class="col-3">
          <label class="">Orden de compra </label><br />
          <input [style]="{ width: '100%'  }" formControlName="oc" type="text" pInputText appendTo="body" />
        </div>
        <div class="col-3">
          <label class="">Estado </label><br />
          <p-multiSelect formControlName="estado" optionValue="value" [style]="{ width: '100%' }" class=""
            appendTo="body" [options]="statuses" placeholder="-">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.value">{{
                option.label
                }}</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="col-3">
          <label class="">Origen </label><br />
          <p-dropdown [filter]="true" [style]="{ width: '100%' }" formControlName="origen" optionLabel="description" optionValue="id"
            class="" appendTo="body" [options]="origenes" placeholder="-" [showClear]="form.get('origen').value">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.description
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3">
          <label class="">Destino </label><br />
          <p-dropdown [filter]="true" [style]="{ width: '100%' }" formControlName="destino" optionLabel="description" optionValue="id"
            class="" appendTo="body" [options]="destinos" placeholder="-" [showClear]="form.get('destino').value">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.id">{{
                option.description
                }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="!spinner">
      <p-messages *ngIf="!solicitudes || solicitudes.length == 0" severity="warn">
        <ng-template pTemplate>
          <div class="ml-2">No hay resultados</div>
        </ng-template>
      </p-messages>
      <div class="buttons-v" *ngIf="solicitudes && solicitudes.length > 0">
        <label for="" class="float-left"><span>{{ totalRecords }} Resultados</span></label>
        <p-button label="Tarjetas" class="float-right" (click)="mode = 'cards'" *ngIf="mode != 'cards'"
          icon="pi pi-credit-card    " styleClass="p-button-sm" pTooltip="Ver en Formato Cards"></p-button>
        <p-button label="Tabla" class="float-right" (click)="mode = 'table'" *ngIf="mode != 'table'" icon="pi pi-table
        " styleClass="p-button-sm" pTooltip="Ver en Formato Tabla"></p-button>
        <div class="flex flex-row justify-content-start flex-wrap" *ngIf="mode != 'table'">
          <label class="pr-3">Ordenar por: </label>
          <br />
          <p-dropdown [options]="ordenes" formControlName="ordenSsta" [showClear]="this.form.get('ordenSsta').value"
            placeholder="Ordenar Por">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge sort-' + option.value">{{
                option.label
                }}</span>
            </ng-template>
          </p-dropdown>
          <p-button *ngIf="mode != 'table'" [icon]="ordenesAscDescIcon" styleClass="p-button-sm"
            [pTooltip]="ordenesAscDescTooltip" (onClick)="changeAscDescOrder(!ordenesAscDescFilters)"></p-button>
        </div>
      </div>

      <div *ngIf="solicitudes && solicitudes.length > 0 && mode == 'cards'" class="text-center">
        <p-card class="card-r" *ngFor="let solicitud of solicitudes">
          <div class="row">
            <div class="col header">
              <h1 class="title">SSTA Nº {{ solicitud.id }}</h1>
              <div class="stateCard">
                <p *ngIf="solicitud.status == 'CANCELLED'">CANCELADA</p>
                <p *ngIf="solicitud.status == 'DELETED'">ELIMINADA</p>
                <p *ngIf="solicitud.status == 'FINISHED'">FINALIZADA</p>
                <p *ngIf="solicitud.status == 'ACCEPTED'">ACEPTADA</p>
                <p *ngIf="solicitud.status == 'REJECTED'">RECHAZADA</p>
                <p *ngIf="solicitud.status == 'PENDING'">PENDIENTE</p>
                <p *ngIf="solicitud.status == 'EXPRESS_SERVICE_ACCEPTED'">Servicio urgente aceptado</p>
                <p *ngIf="solicitud.status == 'EXPRESS_SERVICE_REJECTED'">Servicio urgente rechzado</p>
                <p *ngIf="solicitud.status == 'IN_PROGRESS'">EN CURSO</p>
                <p *ngIf="solicitud.status == 'INFORMED'">INFORMADA</p>
                <p *ngIf="solicitud.status == 'ASSIGNMENT_PENDING'">
                  PENDIENTE DE ASIGNACIÓN
                </p>
                <p *ngIf="solicitud.status == 'AUTHORIZATION_PENDING'">
                  PENDIENTE DE AUTORIZACIÓN
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label>Fecha Asignación:
                <span>
                  {{ solicitud.assignedDateTime | date : "dd/MM/YYYY HH:mm:ss'" }}
                </span> </label><br />
              <label>Fecha Programacion:
                <span>
                  {{ solicitud.programmingDate | date : "dd/MM/YYYY" }}</span> </label><br />
              <label>Treansportista:
                <span> {{ solicitud.transporterName }}</span> </label><br />
              <label>Servicio Urgente:
                <span *ngIf="solicitud.expressService">Si </span>
                <span *ngIf="!solicitud.expressService"> No </span> </label><br />
            </div>
          </div>
          <div class="buttons">
            <p-button label="" routerLink="/details-ssta/{{ solicitud.id }}" icon="pi pi-eye" styleClass="p-button-sm"
              pTooltip="Detalles"></p-button>
            <p-button label="" *ngIf="
(              ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                viewSstProgrammerId == '' &&
                solicitud.status != 'AUTHORIZATION_PENDING') ||
              ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                viewSstProgrammerId == idUserActual &&
                solicitud.status != 'AUTHORIZATION_PENDING') ||
              ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                viewSstProgrammerId == idUserActual &&
               ( solicitud.status === 'REJECTED' || 'EXPRESS_SERVICE_REJECTED'))) && !hasRole('VISUALIZER')
            " routerLink="/actualizar-ssta/{{ solicitud.id }}" icon="pi pi-pencil" styleClass="p-button-sm"
              pTooltip="Editar"></p-button>
            <p-button *ngIf="
              ((hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
              (solicitud.status == 'INFORMED' || solicitud.status == 'ACCEPTED')) && !hasRole('VISUALIZER')
            " pTooltip="Cancelar" icon="pi pi-times-circle" (click)="cancelarSstaInformada(solicitud)" styleClass="p-button-sm">
            </p-button>
            <p-button *ngIf="
              ((hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
              solicitud.status == 'IN_PROGRESS') && !hasRole('VISUALIZER')
            " pTooltip="Cancelar" icon="pi pi-times-circle" (click)="cancelarSstaEnCurso(solicitud)">
            </p-button>
            <p-button *ngIf="
(              (hasRole('PROGRAMMER') ||
                hasRole('ADMIN') ||
                hasRole('MANAGER')) &&
              solicitud.status != 'FINISHED') && !hasRole('VISUALIZER')
            " label="" icon="pi pi-trash" (click)="deleteSst(solicitud.id)" styleClass="p-button-sm"
              pTooltip="Eliminar "></p-button>
            <p-button *ngIf="
(              (url == 'solicitudes-asignadas' && hasRole('PROGRAMMER')) ||
              (!hasRole('PROGRAMMER') && !hasRole('PLANNING_LEADER'))) && !hasRole('VISUALIZER')
            " label="" icon="pi pi-download" styleClass="p-button-sm" pTooltip="Descargar"></p-button>
            <p-button *ngIf="
(              (hasRole('PLANNING_LEADER') || hasRole('ADMIN')) &&
              solicitud.status == 'AUTHORIZATION_PENDING') && !hasRole('VISUALIZER')
            " label="" icon="pi pi-times" styleClass="p-button-sm" pTooltip="Rechazar Servicio Urgente"
              (click)="rechazar(solicitud.id, solicitud.id)">
            </p-button>
            <p-button [loading]="loadingAcceptSsta" *ngIf="
              ((hasRole('PLANNING_LEADER') || hasRole('ADMIN')) &&
              solicitud.status == 'AUTHORIZATION_PENDING') && !hasRole('VISUALIZER')
            " label="" icon="pi pi-check-circle" styleClass="p-button-sm" pTooltip="Aceptar Servicio Urgente"
              (click)="showDialogAceptar($event, solicitud.id)">
            </p-button>
            <p-button *ngIf="
              (url == 'solicitudes-asignadas' &&
              (hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
              solicitud.status == 'INFORMED') && !hasRole('VISUALIZER')
            " icon="bi bi-box-seam-fill" styleClass="p-button-sm" pTooltip="Poner en curso"
              (click)="showDialogEnCursoSsta(solicitud)"></p-button>
            <p-button *ngIf="
              (url == 'solicitudes-asignadas' &&
              (hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
              solicitud.status == 'IN_PROGRESS') && !hasRole('VISUALIZER')
            " icon="bi bi-check-square-fill" styleClass="p-button-sm" pTooltip="Finalizar"
              (click)="showDialogFinalizarSsta(solicitud.id)"></p-button>
          </div>
        </p-card>
      </div>
      <p-table *ngIf="solicitudes && solicitudes.length > 0 && mode == 'table'" #myTab [value]="solicitudes"
        [(selection)]="selectedSst" [scrollable]="true" scrollHeight="600px" styleClass="p-datatable-striped"
        [tableStyle]="{ 'min-width': '50rem' }" [rowHover]="true" dataKey="id" (onLazyLoad)="customSort($event)"
        [customSort]="true" [lazy]="true">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" *ngIf="(hasRole('TRANSPORTER') || hasRole('ADMIN')) && !hasRole('VISUALIZER')">
              <p-tableHeaderCheckbox *ngIf="hasRole('TRANSPORTER') || hasRole('ADMIN')"></p-tableHeaderCheckbox>
            </th>

            <th pSortableColumn="id">
              Nro SSTA <p-sortIcon field="id"></p-sortIcon>
            </th>
            <th >
              Fecha Asignación 
              <!-- <p-sortIcon field="assignedDateTime"></p-sortIcon> -->
            </th>
            <th pSortableColumn="programmingDate">
              Fecha Programacion <p-sortIcon field="programmingDate"></p-sortIcon>
            </th>
            <th pSortableColumn="transporterName">
              Transportista <p-sortIcon field="transporterName"> </p-sortIcon>
            </th>

            <th pSortableColumn="expressService">
              Servicio Urgente <p-sortIcon field="expressService"></p-sortIcon>
            </th>
            <th>Estado</th>

            <th>Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-sst>
          <tr class="p-selectable-row">
            <td class="text-left" *ngIf="(hasRole('TRANSPORTER') || hasRole('ADMIN')) && !hasRole('VISUALIZER')">
              <p-tableCheckbox [value]="sst" (click)="verificarExistPendientes()"></p-tableCheckbox>
            </td>
            <td class="">
              {{ sst.id }}
            </td>
            <td>
              {{ sst.assignedDateTime | date : "dd/MM/YYYY HH:mm:ss'" }}
            </td>
            <td class="">
              {{ sst.programmingDate | date : "dd/MM/YYYY'" }}
            </td>
            <td class="">
              {{ sst.transporterName }}
            </td>
            <!-- <td class="text-center">
            {{ sst.requests.length }}
          </td> -->

            <td class="">
              <span *ngIf="sst.expressService">Si </span>
              <span *ngIf="!sst.expressService"> No </span>
            </td>
            <td class="m-0 p-0">
              <div class="state">
                <p *ngIf="sst.status == 'CANCELLED'">CANCELADA</p>
                <p *ngIf="sst.status == 'DELETED'">ELIMINADA</p>
                <p *ngIf="sst.status == 'FINISHED'">FINALIZADA</p>
                <p *ngIf="sst.status == 'ACCEPTED'">ACEPTADA</p>
                <p *ngIf="sst.status == 'REJECTED'">RECHAZADA</p>
                <p *ngIf="sst.status == 'PENDING'">PENDIENTE</p>
                <p *ngIf="sst.status == 'EXPRESS_SERVICE_ACCEPTED'">Servicio urgente aceptado</p>
                <p *ngIf="sst.status == 'EXPRESS_SERVICE_REJECTED'">Servicio urgente rechzado</p>
                <p *ngIf="sst.status == 'IN_PROGRESS'">EN CURSO</p>
                <p *ngIf="sst.status == 'INFORMED'">INFORMADA</p>
                <p *ngIf="sst.status == 'AUTHORIZATION_PENDING'">
                  PENDIENTE DE AUTORIZACIÓN
                </p>
                <p *ngIf="sst.status == 'ASSIGNMENT_PENDING'">
                  PENDIENTE DE ASIGNACIÓN
                </p>
              </div>
            </td>
            <td colspan="1">
              <div class="row m-0 p-0">
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button label="" routerLink="/details-ssta/{{ sst.id }}" icon="pi pi-eye" styleClass="p-button-sm"
                    pTooltip="Detalles"></p-button>
                </div>
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button *ngIf="
                  ((hasRole('PROGRAMMER') ||
                    hasRole('ADMIN') ||
                    hasRole('MANAGER')) &&
                  sst.status != 'FINISHED') && !hasRole('VISUALIZER')
                " label="" icon="pi pi-trash" (click)="deleteSst(sst.id)" styleClass="p-button-sm"
                    pTooltip="Eliminar "></p-button>

                </div>
              </div>
              <div class="row m-0 p-0">
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button *ngIf="
                    ((url == 'solicitudes-asignadas' && hasRole('PROGRAMMER')) ||
                    (!hasRole('PROGRAMMER') && !hasRole('PLANNING_LEADER'))) && !hasRole('VISUALIZER')
                  " label="" icon="pi pi-download" styleClass="p-button-sm" pTooltip="Descargar"
                    (click)="descargarPdfSsta(sst)"></p-button>
                </div>
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button *ngIf="
                  ((hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
                  (sst.status == 'INFORMED' || sst.status == 'ACCEPTED')) && !hasRole('VISUALIZER')
                " pTooltip="Cancelar" icon="pi pi-times-circle" styleClass="p-button-sm"
                    (click)="cancelarSstaInformada(sst)">
                  </p-button>
                </div>
              </div>
              <div class="row m-0 p-0">
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button label="" *ngIf="(
                    ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                      viewSstProgrammerId == '' &&
                      (sst.status == 'ASSIGNMENT_PENDING' ||
                        sst.status == 'PENDING' ||
                        sst.status == 'REJECTED' || 'EXPRESS_SERVICE_ACCEPTED' || 'EXPRESS_SERVICE_REJECTED')) ||
                    ((hasRole('PROGRAMMER') || hasRole('ADMIN')) &&
                      viewSstProgrammerId == idUserActual &&
                      (sst.status == 'ASSIGNMENT_PENDING' ||
                        sst.status == 'PENDING' || 'EXPRESS_SERVICE_ACCEPTED' || 'EXPRESS_SERVICE_REJECTED' ||
                        sst.status == 'REJECTED'))) && !hasRole('VISUALIZER')
                  " routerLink="/actualizar-ssta/{{ sst.id }}" icon="pi pi-pencil" styleClass="p-button-sm"
                    pTooltip="Editar"></p-button>
                  <p-button *ngIf="
                    ((hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
                    sst.status == 'IN_PROGRESS') && !hasRole('VISUALIZER')
                  " pTooltip="Cancelar" icon="pi pi-times-circle" styleClass="p-button-sm"
                    (click)="cancelarSstaEnCurso(sst)">
                  </p-button>
                </div>
                <div class="col-3 m-0 mx-2 p-0">
                  <p-button *ngIf="
                  (url == 'solicitudes-asignadas' &&
                  (hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
                  sst.status == 'INFORMED') && !hasRole('VISUALIZER')
                " icon="bi bi-box-seam-fill" styleClass="p-button-sm" pTooltip="Poner en curso"
                    (click)="showDialogEnCursoSsta(sst)"></p-button>
                  <p-button *ngIf="
                    (url == 'solicitudes-asignadas' &&
                    (hasRole('TRANSPORTER') || hasRole('ADMIN')) &&
                    sst.status == 'IN_PROGRESS') && !hasRole('VISUALIZER')
                  " icon="bi bi-check-square-fill" styleClass="p-button-sm" pTooltip="Finalizar"
                    (click)="showDialogFinalizarSsta(sst.id)"></p-button>
                </div>
              </div>
              <div class="row m-0 p-0">
                <div class="col-3 m-0 mx-2 p-0 m-1">
                  <p-button *ngIf="
                  ((hasRole('PLANNING_LEADER') || hasRole('ADMIN')) &&
                  sst.status == 'AUTHORIZATION_PENDING') && !hasRole('VISUALIZER')
                " label="" icon="pi pi-times" styleClass="p-button-sm" pTooltip="Rechazar Servicio Urgente"
                    (click)="rechazar(sst.id, sst.id)">
                  </p-button>

                </div>
                <div class="col-3 m-0 mx-2 p-0 m-1">
                  <p-button [loading]="loadingAcceptSsta" *ngIf="
                  ((hasRole('PLANNING_LEADER') || hasRole('ADMIN')) &&
                  sst.status == 'AUTHORIZATION_PENDING') && !hasRole('VISUALIZER')
                " label="" icon="pi pi-check-circle" styleClass="p-button-sm" pTooltip="Aceptar Servicio Urgente"
                    (click)="showDialogAceptar($event, sst.id)">
                  </p-button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator [first]="first" [rows]="size" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]"
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>

  <div class="container min-width-500" *ngIf="(hasRole('TRANSPORTER') || hasRole('ADMIN')) && !hasRole('VISUALIZER')">
    <div class="card">
      <div class="d-flex justify-content-center flex-wrap">
        <button [disabled]="disableButtonAcceptSsta" pButton pRipple type="button" label="Aceptar"
          class="p-button-secondary p-button-text" (click)="showDialogAceptar($event)"
          [loading]="loadingAcceptSsta"></button>
        <button [disabled]="disableButtonNewDateSsta" pButton pRipple type="button" label="Proponer Nueva Fecha"
          class="p-button-secondary p-button-text" (click)="showDialogProponer()"></button>
        <button [disabled]="disableButtonInformSsta" pButton pRipple type="button" label="Informar"
          class="p-button-secondary p-button-text" (click)="informarSsta()"></button>
        <button [disabled]="disableButtonRejectSsta" pButton pRipple type="button" label="Rechazar"
          (click)="rechazar($event)" class="p-button-secondary p-button-text"></button>
      </div>
    </div>
  </div>
</form>
<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-toast></p-toast>