<div class="row">
  <div class="col">
    <div class="text-center">
      <h1 class="title">SSTA N° 62</h1>
      <br />
    </div>
  </div>
</div>

<div *ngFor="let sst of solicitudes">
  <div class="row">
    <div class="col">
      <div style="float: left">
        <h1 class="title">N°Solicitud {{ sst.requestId }}</h1>
        <br />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label
        >Solicitante: <span> {{ sst.supplierName }}</span> </label
      ><br />
      <label
        >Programador: <span>{{ sst.programmerName }} </span> </label
      ><br />
      <label
        >Fecha Necesidad del Transporte:
        <span>{{ sst.requirementDate | date: "dd/MM/YYYY" }}</span> </label
      ><br />
      <label
        >Fecha Solicitud:
        <span> {{ sst.creationDate | date: "dd/MM/YYYY" }} </span> </label
      ><br />
    </div>
    <div class="col">
      <label
        >Tipo Gestión: <span>{{ sst.managementType }}</span> </label
      ><br />
      <label
        >Origen: <span> {{ sst.originName }}</span> </label
      ><br />
      <label
        >Destino: <span> {{ sst.destinationName }}</span> </label
      ><br />
     <br />
    </div>
  </div>
  <div class="" *ngFor="let package of sst.packages; let i = index">
    <div class="row">
      <div class="col">
        <label>Bultos:{{ package.description }}<span></span> </label><br />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>Peso Aprox(Kgs) : <span> {{ package.weight }}</span></label><br>
        <label>Ancho(Mts) : <span>{{ package.weight }}</span></label>
      </div>
      <div class="col">
        <label>Largo(Mts) : <span>{{ package.length }}</span></label><br>
        <label>Alto(Mts) : <span>{{ package.height }}</span></label>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <p-divider></p-divider>
  </div>
</div>
<div class="row">
  <div class="col">
    <label>Volumenes Totales: 125<span></span> </label><br />
    <label>Pesos Totales: 250<span></span> </label><br />
  </div>
</div>
<div class="flex justify-content-end">
  <button
    pButton
    pRipple
    type="button"
    label="Volver"
    class="p-button-secondary"
    (click) ="hiddenDialogVistaPreliminar()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Generar SSTA"
    class="p-button-secondary"
    (click) = "showPopUpGenerarSsta()"
  ></button>
</div>

<p-dialog header="SSTA Generada" [(visible)]="popUpGenerarSsta" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
    <app-popup-ssta-generada></app-popup-ssta-generada>
</p-dialog>