<div class="container min-width-500">
  <div class="row">
    <div class="col-6">
      <button
        pButton
        pRipple
        type="button"
        label="Aceptar Nueva SSTA"
        class="p-button-secondary p-button"
        (click)="cerrarPopUp()"
      ></button>
    </div>
    <div class="col-6">
      <button
        pButton
        pRipple
        type="button"
        label="Historial"
        class="p-button-secondary p-button-lg"
      ></button>
    </div>
  </div>
</div>
