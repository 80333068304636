import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";

@Component({
  selector: "app-pop-up-finalizar-ssta",
  templateUrl: "./pop-up-finalizar-ssta.component.html",
  styleUrls: ["./pop-up-finalizar-ssta.component.scss"],
})
export class PopUpFinalizarSstaComponent implements OnInit {
  applicationDate: Date = new Date();
  form: FormGroup;
  minDateValue = new Date();

  @Input()
  sstaAFinalizar = "";

  hiddenPopUpFinalizarSsta = true;

  @Output()
  eventHiddenPopUp: EventEmitter<number> = new EventEmitter();

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private solicitudesAsignadasService: SolicudesAsignadasService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      transportGuideArray: new FormArray([this.createTransportGuide()]),
    });
  }

  cerrarPopUp(tipoDeCambio: number) {
    this.eventHiddenPopUp.emit(tipoDeCambio);
  }

  get transportGuideArray(): FormArray {
    return this.form.get("transportGuideArray") as FormArray;
  }

  createTransportGuide() {
    return new FormGroup({
      transportGuide: new FormControl("", [
        Validators.required,
        Validators.min(1),
      ]),
    });
  }

  addTransportGuide() {
    let newGuide = this.createTransportGuide();
    this.transportGuideArray.push(newGuide);
  }

  deleteTransportGuide(idx: number) {
    this.transportGuideArray.removeAt(idx);
  }

  submit(): void {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea finalizar la SSTA?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.finalizarSsta();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  finalizarSsta() {
    let transportArray = []
    this.transportGuideArray.value.forEach((f,index) => {
      transportArray.push(f.transportGuide)
    });

    this.solicitudesAsignadasService
      .finalizarSsta(this.sstaAFinalizar, transportArray)
      .subscribe(
        (res) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Finalizado",
            detail: "La SSTA ha sido finalizada con exito",
          });
          this.form.reset()
          this.cerrarPopUp(2);
        },
        (error) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Ups! ocurrio un error",
          });
        }
      );
  }
}
