import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Validators, FormGroup, FormControl, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { DespositosService } from "src/app/core/services/depositos/despositos.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { UserService } from "src/app/core/services/user/user.service";
import { SstService } from "src/app/services/sst/sst.service";
import { EditSolicitud } from "src/app/shared/models/sst/editSolicitud";
import { Package } from "src/app/shared/models/sst/package";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { Users } from "../../interfaces/user.interface";
import { Envio } from "../../interfaces/envios.interface";
import { Origen } from "../../interfaces/origen.interface";
import { Destino } from "../../interfaces/destino.interface";
import { User } from "src/app/shared/models/user/user";
import { AuthService } from "src/app/core/authentication/auth.service";
import { Oc } from "src/app/shared/models/sst/oc";
import { DatePipe } from "@angular/common";
import { isFechaNecesidadValid } from "src/app/shared/validators/validators.form";
import { concatMap, tap } from "rxjs/operators";
import { concat, of } from "rxjs";
@Component({
  selector: "app-update-sst",
  templateUrl: "./update-sst.component.html",
  styleUrls: ["./update-sst.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateSstComponent implements OnInit {
  userActual: User;
  idUserActual;

  form: FormGroup;
  spinner = true;
  applicationDate: Date = new Date();
  sstMax: any = 1;

  programadores: Users[] = [];
  selectUser: Users;
  envios: Envio[] = [];
  selectEnvio: Envio;
  supplierId = 0;
  origenes: Origen[] = [];
  destinos: Origen[] = [];
  selectOrigen: Origen;
  minDateValue = new Date();
  selectDestino: Destino;

  showDestinoInputCustom = true;
  showOrigenInputCustom = true;

  constructor(
    private sstService: SstService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private solcitudService: SolcitudesService,
    private userService: UserService,
    private depositosService: DespositosService,
    private router: Router,
    private authService: AuthService,
    private datepipe: DatePipe
  ) {
    this.envios = [
      { tipo: "Retiro", id: "PICKUP" },
      { tipo: "Envio", id: "SHIPPING" },
    ];
  }

  origen: string;

  idSst: any;

  sstEncontrado: Solicitud;

  bultos: FormArray;

  ngOnInit(): void {
    this.rolesInit();
  }

  rolesInit() {
    this.authService
      .actual()
      .pipe(
        tap({
          next: (data) => {
            this.userActual = data;
            this.idUserActual = data.id;
          },
          complete: () => {
          },
        }),
        concatMap(() => this.getProgrammers()),
        concatMap(() => this.getOrigenes()),
        concatMap(() => this.getDestinos()),
        concatMap(() => concat(of(this.startForm()))),
      ).subscribe()
  }

  startForm() {
    let { id } = this.activatedRoute.snapshot.params;
    this.idSst = id;
    this.solcitudService.getById(this.idSst).subscribe((res) => {
      this.sstEncontrado = res;
      this.supplierId = res.supplierId;
      this.form = new FormGroup({
        programador: new FormControl(this.sstEncontrado.programmerId || "",
          this.sstEncontrado.status == "NEW" ? [Validators.required] : [],
        ),
        tipoGestion: new FormControl(this.sstEncontrado.managementType, [
          Validators.required,
        ]),
        origen: new FormControl(this.sstEncontrado.externalOrigin ? 0 : this.sstEncontrado.origin, [
          Validators.required,
        ]),

        ordenCompraModel: new FormArray(
          this.sstEncontrado.ocEa.length <= 0 ? [this.createOrdenCompra()] : []
        ),
        destino: new FormControl(this.sstEncontrado.externalDestination ? 0 : this.sstEncontrado.destination, [
          Validators.required,
        ]),
        fechaNecesidad: new FormControl(
          this.sstEncontrado.requirementDate ?
            new Date(this.datepipe.transform(
              this.sstEncontrado.requirementDate,
              'yyyy/MM/dd'
            )) : ""
          ,
          [Validators.required, isFechaNecesidadValid(this.datepipe)]
        ),
        comentarios: new FormControl(this.sstEncontrado.comments),
        bultosModel: new FormArray([]),
        noLlevaOc: new FormControl(
          this.sstEncontrado.ocEa.filter(Boolean)?.length <= 0,
          []
        ),
        noLlevaAe: new FormControl(
          // this.sstEncontrado.esAe.filter(Boolean)?.length <= 0,
          []
        ),
        emailNotification: new FormControl(
          this.sstEncontrado.emailNotification,
          []
        ),
        contacto: new FormControl(this.sstEncontrado.contactName, [
          Validators.required,
        ]),
        telefono: new FormControl(this.sstEncontrado.phone, [
          Validators.required,
        ]),
        email: new FormControl(this.sstEncontrado.email, [
          Validators.required,
          Validators.email,
        ]),
        loadDescription: new FormControl(this.sstEncontrado.loadDescription, [
          Validators.required,
        ]),
      },
        // { validators: isFechaNecesidadValidFactory(this.datepipe) }
      );

      let bultos = this.form.get("bultosModel") as FormArray;
      let purchaseOrderNumbers = this.form.get("ordenCompraModel") as FormArray;
      let esAe = this.form.get("esAesModel") as FormArray;

      for (let i = 0; i < this.sstEncontrado.ocEa.length; i++) {
        purchaseOrderNumbers.push(
          new FormGroup({
            ordenCompra: new FormControl(this.sstEncontrado.ocEa[i].oc,

              this.sstEncontrado.ocEa[i].oc == "N/A"
                ? [Validators.minLength(1), Validators.required]
                : [Validators.minLength(9), Validators.required]),
            ea: new FormArray([], []),
          })
        );

        for (let j = 0; j < this.sstEncontrado.ocEa[i].ea.length; j++) {
          this.getEaArrayOc(i).push(
            new FormGroup({
              esAe: new FormControl(this.sstEncontrado.ocEa[i].ea[j],
                this.sstEncontrado.ocEa[i].ea[j] == "N/A"
                  ? [Validators.minLength(1)]
                  : [Validators.minLength(9)]),
            })
          );
        }
      }

      for (let i = 0; i < this.sstEncontrado.packages.length; i++) {
        bultos.push(
          new FormGroup({
            id: new FormControl(this.sstEncontrado.packages[i].id, []),
            nombreBulto: new FormControl(
              this.sstEncontrado.packages[i]?.name,
              []
            ),
            bultos: new FormControl(
              this.sstEncontrado.packages[i].description,
              [Validators.required, Validators.min(0)]
            ),
            pesoAprox: new FormControl(this.sstEncontrado.packages[i].weight, [
              Validators.required,
              Validators.min(0),
            ]),
            ancho: new FormControl(this.sstEncontrado.packages[i].width, [
              Validators.required,
              Validators.min(0),
            ]),
            largo: new FormControl(this.sstEncontrado.packages[i].length, [
              Validators.required,
              Validators.min(0),
            ]),
            alto: new FormControl(this.sstEncontrado.packages[i].height, [
              Validators.required,
              Validators.min(0),
            ]),
            // totalWeight: new FormControl(
            //   this.sstEncontrado.packages[i].totalWeight,
            //   [Validators.required]
            // ),
          })
        );
      }

      if (this.sstEncontrado.externalOrigin != null && this.sstEncontrado.externalOrigin != "") {
        this.addOrigenCustom(this.sstEncontrado.externalOrigin)
      }

      if (this.sstEncontrado.externalDestination != null && this.sstEncontrado.externalDestination != "") {
        this.addDestinoCustom(this.sstEncontrado.externalDestination)
      }
      // this.disabledOcAe();
      this.spinner = false;
    });
  }

  getProgrammers() {
    return this.userService.getByRol("programador").pipe(
      tap({
        next: (res) => {
          res.forEach((e) => {
            if (e.id != this.idUserActual) {
              this.programadores.push({ name: e.username, code: e.id });
            }
          });
        },
        complete: () => { },
      })
    );
  }

  getOrigenes() {
    return this.depositosService.getOrigenes().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.origenes.push({ description: d.name, id: d.id });
          });

          this.origenes.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  getDestinos() {
    return this.depositosService.getDestinos().pipe(
      tap({
        next: (res) => {
          res.list.forEach((d) => {
            this.destinos.push({ description: d.name, id: d.id });
          });

          this.destinos.push({ description: "Otro", id: 0 });
        },
      })
    );
  }

  updateSst(): void {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea modificar la solicitud?",
      header: "Confirmar modificación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        let edit = this.armedFormSst();
        this.actualizarSst(edit);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  generarSstPending() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea generar la solicitud?",
      header: "Confirmar modificación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        let edit = this.armedFormSst();
        if (!this.form.invalid && edit.status == "NEW") {
          this.generarSst(edit);
        } else {
          this.messageService.add({
            key: "msg",
            severity: "warn",
            summary: "Faltan datos",
            detail: "Asegurese de haber completado todos los datos",
          });
        }
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  //formulario

  createBulto() {
    return new FormGroup({
      id: new FormControl("", []),
      nombreBulto: new FormControl("", []),
      bultos: new FormControl("", [Validators.required, Validators.min(0)]),
      pesoAprox: new FormControl("", [Validators.required, Validators.min(0)]),
      ancho: new FormControl("", [Validators.required, Validators.min(0)]),
      largo: new FormControl("", [Validators.required, Validators.min(0)]),
      alto: new FormControl("", [Validators.required, Validators.min(0)]),
      // totalWeight: new FormControl("", [Validators.required]),
    });
  }

  createOrdenCompra() {
    return new FormGroup({
      ordenCompra: new FormControl("", [Validators.minLength(10), Validators.required]),
      ea: new FormArray([this.createEsAesModel()]),
    });
  }

  createEsAesModel() {
    return new FormGroup({
      esAe: new FormControl("", [Validators.minLength(9)]),
    });
  }

  addBulto() {
    let newBulto = this.createBulto();
    this.bultosModel.push(newBulto);
    this.bultosModel.controls.forEach((element, index) => {
      element.get('nombreBulto').setValue(index + 1)
    })
  }

  addOrdenCompra() {
    // let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;
    // if (!noLlevaOc) {
    let newOrdenCompra = this.createOrdenCompra();
    this.ordenCompraModel.push(newOrdenCompra);
    // } else {
    //   this.messageService.add({
    //     key: "msg",
    //     severity: "warn",
    //     summary: "Desactive el check OC AE",
    //   });
    // }
  }

  addEsAesModel(index: any) {
    // let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;
    // if (!noLlevaOc) {
    let newEsAes = this.createEsAesModel();
    this.getEaArrayOc(index).push(newEsAes);
    // } else {
    //   this.messageService.add({
    //     key: "msg",
    //     severity: "warn",
    //     summary: "Desactive el check OC AE",
    //   });
    // }
  }

  deleteBulto(idx: number) {
    this.bultosModel.removeAt(idx);
    this.bultosModel.controls.forEach((element, index) => {
      element.get('nombreBulto').setValue(index + 1)
    })
  }

  deleteOrdenCompra(idx: number) {
    this.ordenCompraModel.removeAt(idx);
  }

  deleteEsAesModel(indexOc, idx: number) {
    this.getEaArrayOc(indexOc).removeAt(idx);
  }

  get bultosModel(): FormArray {
    return this.form.get("bultosModel") as FormArray;
  }

  get ordenCompraModel(): FormArray {
    return this.form.get("ordenCompraModel") as FormArray;
  }

  get esAesModel(): FormArray {
    return this.form.get("esAesModel") as FormArray;
  }

  getEaArrayOc(index: any): FormArray {
    return this.ordenCompraModel.controls[index].get("ea") as FormArray;
  }

  get noLlevaOc(): Boolean {
    return this.form.get("noLlevaOc").value;
  }

  get noLlevaAe(): Boolean {
    return this.form.get("noLlevaAe").value;
  }

  disabledOcAe() {
    let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;

    this.ordenCompraModel.controls.forEach((oc: FormGroup, i: any) => {
      if (noLlevaOc) {
        oc.controls.ordenCompra.setValue("");
        oc.controls.ordenCompra.disable();
        this.getEaArrayOc(i).controls.forEach((ea: FormGroup) => {
          ea.controls.esAe.setValue("");
          ea.controls.esAe.disable();
        });
      } else {
        oc.controls.ordenCompra.enable();
        this.getEaArrayOc(i).controls.forEach((ea: FormGroup) => {
          ea.controls.esAe.enable();
        });
      }
    });
  }

  actualizarSst(edit: any) {
    this.solcitudService.editSst(edit).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Confirmado",
          detail: "La solicitud se ha editado con exito",
        });
        this.router.navigate(["/solicitudes"]);
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  generarSst(edit) {
    this.solcitudService.generateSst(edit).subscribe(
      (res) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Confirmado",
          detail: "La solicitud se ha generado con exito",
        });
        this.router.navigate(["/solicitudes"]);
      },
      (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Ups! ocurrio un error",
        });
      }
    );
  }

  armedFormSst(): EditSolicitud {
    let edit = new EditSolicitud();
    edit.comments = this.form.get("comentarios").value || "";
    edit.id = this.sstEncontrado.id;
    edit.destination = this.form.get("destino").value;
    edit.origin = this.form.get("origen").value;
    // let date = new Date(this.form.get("fechaNecesidad").value.toString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
    let dateStr = this.form.get("fechaNecesidad").value;
    let date = typeof dateStr === 'string' && dateStr != "" ? new Date((dateStr.split('/').reverse().join('-') + 'T00:00:00Z')) : dateStr; // Parsea la cadena a un objeto de fecha

    if (typeof dateStr === 'string' && dateStr != "") {
      date.setDate(date.getDate() + 1)
    }

    edit.requirementDate = date ? this.datepipe.transform(date, 'yyyy-MM-dd') : "";
    edit.programmerId = this.form.get("programador").value || this.sstEncontrado.programmerId;
    edit.supplierId = this.supplierId;
    edit.contactName = this.form.get("contacto").value;
    edit.phone = this.form.get("telefono").value;
    edit.email = this.form.get("email").value;
    edit.loadDescription = this.form.get("loadDescription").value;
    edit.emailNotification = this.form.get("emailNotification").value;
    // edit.type = (this.form.get("tipoGestion").value);
    edit.type = this.form.get("tipoGestion").value;
    edit.status = this.sstEncontrado.status; // esto no se si cambia

    this.bultosModel.controls.forEach((f, index) => {
      let bulto = new Package();
      bulto.name = f?.value.nombreBulto || String(index + 1);
      bulto.description = f.value.bultos;
      bulto.height = f.value.alto;
      bulto.length = f.value.largo;
      bulto.width = f.value.ancho;
      bulto.weight = f.value.pesoAprox;
      bulto.id = f.value.id;
      edit.packages.push(bulto);
    });

    let noLlevaOc: Boolean = this.form.get("noLlevaOc").value;

    // if (!noLlevaOc) {
    if (this.ordenCompraModel.controls.length > 0) {
      this.ordenCompraModel.controls.forEach((oc: FormGroup, i: any) => {
        let ocData: Oc = new Oc();

        ocData.oc = oc.controls.ordenCompra.value;
        this.getEaArrayOc(i).controls.forEach((ea: FormGroup) => {
          ocData.ea.push(ea.controls.esAe.value);
        });

        if (ocData.oc != "" && ocData.oc != null) {
          edit.ocEa.push(ocData);
        }
      });
    } else {
      edit.ocEa = [];
    }
    // }

    if (this.form.get("origen").value === 0) {
      edit.externalOrigin = this.form.get("origenCustom").value;
    } else {
      edit.origin = this.form.get("origen").value;
    }

    if (this.form.get("destino").value === 0) {
      edit.externalDestination = this.form.get("destinoCustom").value;
    } else {
      edit.destination = this.form.get("destino").value;
    }
    return edit;
  }

  addDestinoCustom(value = "") {
    this.form.addControl(
      "destinoCustom",
      new FormControl(value, [Validators.required])
    );
    this.showDestinoInputCustom = false;
  }

  removeDestinoCustom() {
    this.form.removeControl("destinoCustom");
    this.sstEncontrado.externalDestination = ""
    this.showDestinoInputCustom = true;
  }

  destinoCustom(event) {
    if (event.value == 0 && !this.form.get("destinoCustom")) {
      this.addDestinoCustom()
    } else {
      if (this.form.get("destinoCustom")) {
        this.form.removeControl("destinoCustom");
        this.showDestinoInputCustom = true;
      }
    }
  }

  addOrigenCustom(value = "") {
    this.form.addControl(
      "origenCustom",
      new FormControl(value, [Validators.required])
    );
    this.showOrigenInputCustom = false;
  }

  removeOrigenCustom() {
    this.form.removeControl("origenCustom");
    this.sstEncontrado.externalOrigin = ""
    this.showOrigenInputCustom = true;
  }

  origenCustom(event) {
    if (event.value == 0 && !this.form.get("origenCustom")) {
      this.addOrigenCustom();
    } else {
      if (this.form.get("origenCustom")) {
        this.removeOrigenCustom();
      }
    }
  }

  maxBultoNumber() {

    let number: number = 0;

    this.bultosModel.controls.forEach((f, index) => {
      if (parseInt(f?.value.nombreBulto) > number) {
        number = parseInt(f?.value.nombreBulto)
      }
    });

    return number
  }
}
