import { Oc } from "./oc";
import { Package } from "./package";

export class EditSolicitud {
    programmerId: string;
    id: number;
    supplierId: number;
    managementType:string;
    origin: number;
    destination: number;
    externalDestination:string;
    externalOrigin:string;
    status: string;
    type: string;
    requirementDate: string;
    packages: Package[]=[];
    comments: string;
    supplierName:string;
    originName:string;
    destinationName:string;
    creationDate:string;
    programmerName:string;
    documentation:string[]=[];
    purchaseOrderNumber:string;
    purchaseOrderNumbers:string[] = [];
    contactName:string;
    phone:string;
    email:string;
    loadDescription:string;
    transporterGuide:string;
    emailNotification:string;
    ocEa:Oc[] = []
}

