export class Conductor {
    fullName: string;
    documentType: string;
    documentNumber:string
    id: number;
    dateOfBirth: string;
    phone: string;
    address: string;
    state: string;
    documentation:string[] = [];
    transporterId:string;
}