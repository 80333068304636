import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { DespositosService } from "src/app/core/services/depositos/despositos.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SstService } from "src/app/services/sst/sst.service";
import { Package } from "src/app/shared/models/sst/package";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { Destino, Origen } from "src/app/shared/models/sst/solicitudes";

@Component({
  selector: "app-edit-sst-programador",
  templateUrl: "./edit-sst-programador.component.html",
  styleUrls: ["./edit-sst-programador.component.scss"],
})
export class EditSstProgramadorComponent implements OnInit {
  alphabet: string[] = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  destinos: Destino[] = [{ description: "Cerro Dragón, Chubut", id: 1 }];
  sst: Solicitud;

  originalSst: Solicitud;

  sstDivididas: any[] = [];

  rol: string;
  roles: string[] = [];
  idSst: any;
  spinner = true;
  detalles = [];
  displayHabilitaciones = false;
  url: string;
  origenes: Origen[] = [];
  bultosSelected: any[] = [];
  mostrarDividida = "d-none";

  form: FormGroup;

  constructor(
    private sstService: SstService,
    private depositosService: DespositosService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private solcitudService: SolcitudesService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.authService.actual().subscribe((actual) => {
      this.roles = actual.rolesnames;
    });
    this.rol = this.authService.queryRole();
    this.depositosService.getAll().subscribe((res) => {
      res.list.forEach((d) => {
        this.origenes.push({ description: d.name, id: d.id });
      });
      this.origenes.push({ description: "Otro", id: 0 });
    });
  }

  ngOnInit(): void {
    this.url = this.router.url.split("/", 2)[1];
    let { id } = this.activatedRoute.snapshot.params;
    this.idSst = id;
    this.solcitudService.getById(id).subscribe((res) => {
      this.originalSst = res;
      this.sst = res;
      if (this.originalSst) {
        this.disabledChecksSubdivide();
      }
      this.spinner = false;
    });
    this.habilitarDividirSolicitud();
    this.habilitarGuardarCambios();
    this.hiddenDividirSolicitud();

    this.updateForm();
  }

  get subdivideArray() {
    return this.form.get("subdivideArray") as FormArray;
  }

  updateForm(){
    this.form = new FormGroup({
      subdivideArray: new FormArray([]),
    });

    for (let packageBulto of this.sst.packages) {
      this.subdivideArray.push(
        new FormGroup({
          idPackage: new FormControl(packageBulto.id, [
            Validators.required,
          ]),
          checkSeparate: new FormControl(false),
          checkSubdivide: new FormControl(false),
          quantity: new FormControl(1, [
            Validators.min(1),
            Validators.max(parseInt(packageBulto.description)),
            Validators.required,
          ]),
        })
      );
    }
  }

  seleccionarBulto(field: string, event: any, packageNew: any, index: any) {
    let findBulto = this.bultosSelected.find(
      (bulto) => bulto.id == packageNew.id
    );

    let sstSeparateSearch = this.searchSstSeparatePackage(packageNew);

    let checkSeparate = this.getCheckSeparateAllPackageIndex(index);
    let checkSubdivide = this.getCheckSubDividePackageIndex(index);

    switch (field) {
      case "all":
        checkSeparate.value
          ? checkSubdivide.disable()
          : checkSubdivide.enable();
        if (findBulto && event.checked === false && !checkSeparate.value) {
          this.deleteBultoSelected(findBulto, index);
        } else if (event.checked && !findBulto) {
          if (this.getLimitPackages(field, index)) {
            this.addBultoSelected(packageNew, index, event.checked);
          } else {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "No puede seleccionar todos",
              detail: "No puede dejar la solicitud original sin bultos",
            });
            this.changeCheckSeparateValueFalse(index);
            this.habilitarGuardarCambios();
          }
        }
        break;
      case "part":
        checkSubdivide.value ? checkSeparate.disable() : checkSeparate.enable();
        if (findBulto && event.checked === false && !checkSubdivide.value) {
          this.deleteBultoSelected(findBulto, index);
        } else if (event.checked && !findBulto) {
          this.addBultoSelected(packageNew, index, event.checked);
        }
        break;

      default:
        break;
    }
    // this.checkSplitSttLumps(packageNew,checkSeparate,checkSubdivide);
    this.habilitarDividirSolicitud();
    this.habilitarGuardarCambios();
  }

  deleteBultoSelected(packageDeleted: any, index: any) {
    let exist = this.bultosSelected.indexOf(packageDeleted);
    this.bultosSelected.splice(exist, 1);
    // this.checkChangeSubdivide(
    //   { checked: false },
    //   index,
    //   packageDeleted.description
    // );
  }

  addBultoSelected(packageNew: any, index: any, checked: boolean) {
    this.bultosSelected.push(packageNew);
  }

  checkSplitSttLumps(
    packageCheck: any,
    checkSeparate: boolean,
    checkSubdivide: boolean
  ) {
    // if (this.sstDivididas.length > 0) {
    //   this.sstDivididas.forEach((sst, f) => {
    //     sst.packages.forEach((packageBulto, i) => {
    //       if (packageBulto.id === packageCheck.id) {
    //         if(checkSeparate || checkSubdivide){
    //           this.bultosSelected.push(packageBulto);
    //         }
    //         sst.packages.splice(i, 1);
    //         if (sst.packages.length <= 0) {
    //           this.sstDivididas.splice(f, 1);
    //           this.mostrarDividida = "d-none";
    //         }
    //       }
    //     });
    //   });
    // }
  }

  searchSstSeparatePackage(packageSearch: Package) {
    let packageFind: Package = null;
    let sstSearch = null;
    this.sstDivididas.forEach((sst, f) => {
      sst.packages.forEach((packageBulto, i) => {
        if (packageSearch.id === packageBulto.id) {
          packageFind = packageBulto;
          sstSearch = sst;
        }
      });
    });
    return sstSearch;
  }

  checkChangeSubdivide(check: any, i: string, packageNew: Package) {
    let findBulto = this.bultosSelected.find(
      (bulto) => bulto.id == packageNew.id
    );

    if (check?.checked) {
      if (
        packageNew &&
        parseInt(packageNew.description) >= 1 &&
        this.getLimitPackages("part", i)
      ) {
        this.seleccionarBulto(
          "part",
          { checked: check?.checked },
          packageNew,
          i
        );
        this.subdivideArray.controls[i].get("quantity").enable();
      } else {
        this.deleteBultoSelected(findBulto, i);
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "No se puede subdividir",
          detail: "La cantidad de bultos no se puede subdividir más",
        });
        this.subdivideArray.controls[i].get("checkSubdivide").setValue(false);
      }
    } else {
      this.seleccionarBulto("part", { checked: check?.checked }, packageNew, i);
      this.subdivideArray.controls[i].get("checkSubdivide").setValue(false);
      this.subdivideArray.controls[i].get("quantity").setValue(1);
      this.subdivideArray.controls[i].get("quantity").disable();
    }
    this.habilitarDividirSolicitud();
  }

  disabledDividirSolicitudButton = false;
  habilitarDividirSolicitud() {
    if (this.bultosSelected.length <= 0) {
      this.disabledDividirSolicitudButton = true;
    } else if (this.form.valid) {
      this.disabledDividirSolicitudButton = false;
    } else {
      this.disabledDividirSolicitudButton = true;
    }
  }

  disabledDividirGuardarCambiosButton = false;
  habilitarGuardarCambios() {
    if (this.sstDivididas.length <= 0) {
      this.disabledDividirGuardarCambiosButton = true;
    } else {
      this.disabledDividirGuardarCambiosButton = false;
    }
  }

  hiddenDividirSolicitud() {
    return this.mostrarDividida;
  }

  showDialogHabilitaciones() {
    this.displayHabilitaciones = true;
  }

  devolverSstOriginal() {
    return this.sst;
  }

  dividirSolicitud() {
    if (!this.disabledDividirSolicitudButton) {
      let nuevaSolicitud: Solicitud;
      if (this.bultosSelected.length > 0) {
        nuevaSolicitud = JSON.parse(JSON.stringify(this.sst));
        nuevaSolicitud.packages = JSON.parse(
          JSON.stringify(this.bultosSelected)
        );

        for (let packageItem of nuevaSolicitud.packages) {
          for (let control of this.subdivideArray.controls) {
            if (control.get("idPackage").value == packageItem.id) {
              if (control.get("checkSubdivide").value == true) {
                packageItem.divisor = control.get("quantity").value;
                packageItem.description = packageItem.divisor;
                control.get("checkSubdivide").setValue(false);
                control.get("checkSeparate").enable();
                control.get("quantity").disable();
               
                
              } else if (control.get("checkSeparate").value == true) {
                control.get("checkSeparate").setValue(false);
                let find = this.sst.packages.find((pack: Package) => {
                  return pack.id == packageItem.id;
                });
                let exist = this.sst.packages.indexOf(find);
                this.sst.packages.splice(exist, 1);
              }
            }
          }
        }

        for (let packageItem of this.sst.packages) {
          for (let packageSplit of nuevaSolicitud.packages) {
            if (packageItem.id === packageSplit.id) {
              packageItem.description = String(
                parseInt(packageItem.description) -
                  parseInt(packageSplit.divisor)
              );
              if (parseInt(packageItem.description) <= 0) {
                let exist = this.sst.packages.indexOf(packageItem);
                this.sst.packages.splice(exist, 1);
              }
            }
          }
        }

        this.sstDivididas.push(nuevaSolicitud);

        this.bultosSelected = [];
        this.mostrarDividida = "";
        this.habilitarDividirSolicitud();
        this.habilitarGuardarCambios();
      }
    }
  }

  eliminarSolicitudDividida(sstDividida: Solicitud, indice: number) {
    this.habilitarDividirSolicitud();

    for (let i = 0; i < sstDividida.packages.length; i++) {
      let find = this.sst.packages.find((pack: Package) => {
        return pack.id == sstDividida.packages[i].id;
      });
      let exist = this.sst.packages.indexOf(find);
      for (let j = 0; j < this.sst.packages.length; j++) {
        if (this.sst.packages[j].id === sstDividida.packages[i].id && exist) {
          this.sst.packages[j].divisor = null;
          this.sst.packages[j].description = String(
            parseInt(this.sst.packages[j].description) +
              parseInt(sstDividida.packages[i].divisor)
          );
        }
      }
      if (exist == -1) {
        this.sst.packages.push(sstDividida.packages[i]);
      }
    }

    let exist = this.sstDivididas.indexOf(sstDividida);
    if (exist != -1) {
      this.sstDivididas.splice(exist, 1);
    }

    if (this.sstDivididas.length <= 0) {
      this.mostrarDividida = "d-none";
    }
    this.habilitarDividirSolicitud();
    this.habilitarGuardarCambios();

    this.messageService.add({
      key: "msg",
      severity: "success",
      summary: "Eliminado",
      detail: `La solicitud ${sstDividida.requestId} ${this.alphabet[indice]} ha sido eliminada`,
    });

    this.updateForm();
  }

  guardarCambios() {
    if (!this.disabledDividirGuardarCambiosButton) {
      let bultos = [];
      for (let i = 0; i < this.sstDivididas.length; i++) {
        for (let j = 0; j < this.sstDivididas[i].packages.length; j++) {
          let bulto: any = this.sstDivididas[i].packages[j] || "";
          bultos.push({ packageId: bulto.id, divisor: bulto?.divisor || 0 });
        }
      }
      this.solcitudService.dividirSolicitud(this.sst.id, bultos).subscribe(
        (data) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Confirmado",
            detail: "¡La solicitud ha sido dividida con exito!",
          });
          this.router.navigate(["/solicitudes"]);
        },
        (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        }
      );
    }
  }

  hiddenSubdivide(checkSeparate: any) {
    return checkSeparate.value[0] === true
      ? "col-sm-12 col-lg-5 mt-sm-3 mt-lg-0"
      : "d-none";
  }

  disabledChecksSubdivide() {
    for (let control of this.subdivideArray.controls) {
      control.get("quantity").disable();
    }
  }

  getCheckSubDividePackageIndex(index: any) {
    return this.subdivideArray.controls[index].get("checkSubdivide");
  }

  getCheckSeparateAllPackageIndex(index: any) {
    return this.subdivideArray.controls[index].get("checkSeparate");
  }

  changeCheckSeparateValueFalse(index: any) {
    this.subdivideArray.controls[index].get("checkSeparate").setValue(false);
  }

  changeAllChecksSeparateValueFalse() {
    for (let control of this.subdivideArray.controls) {
      control.get("checkSeparate").setValue(false);
    }
  }

  getLimitPackages(action: string, index: any) {
    let available = false;

    let packageLength = 0;
    for (let sst of this.sstDivididas) {
      for (sst of sst.packages) {
        packageLength += 1;
      }
    }

    switch (action) {
      case "all":
        switch (this.getCheckSeparateAllPackageIndex(index).disabled) {
          case true:
            if (
              this.bultosSelected.length + packageLength + 1 <
              this.sst.packages.length
            ) {
              available = true;
            }
            break;

          case false:
            if (
              this.bultosSelected.length + packageLength + 1 <
              this.sst.packages.length
            ) {
              available = true;
            }
            break;

          default:
            break;
        }

        break;
      case "part":
        if (
          this.bultosSelected.length + packageLength + 1 <=
            this.sst.packages.length &&
          this.getLimitSplit()
        ) {
          available = true;
        }
        break;

      default:
        break;
    }

    return available;
  }

  getLimitSplit() {
    let available = false;
    for (let packageItem of this.sst.packages) {
      if (parseInt(packageItem.description) > 1) {
        available = true;
        break;
      }
    }
    return available;
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
