import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AuthService } from "src/app/core/authentication/auth.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { PostSolicitud } from "src/app/shared/models/sst/postSolicitud";

@Component({
  selector: 'app-reject-sst-modal',
  templateUrl: './reject-sst-modal.component.html',
  styleUrls: ['./reject-sst-modal.component.scss']
})
export class RejectSstModalComponent implements OnInit {

  applicationDate: Date = new Date();
  form: FormGroup;
  minDateValue = new Date();

  @Input()
  sstaAFinalizar = "";

  hiddenPopUpFinalizarSsta = true;

  @Output()
  eventHiddenPopUp: EventEmitter<number> = new EventEmitter();

  solicitud: any;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private solicitudesAsignadasService: SolicudesAsignadasService,
    private solicitudesService: SolcitudesService,
    private dialogService: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    console.log(this.dialogService.data);

    this.solicitud = this.dialogService.data.sst
    this.form = new FormGroup({
      motivo: new FormControl('', [Validators.required])
    });
  }


  submit(): void {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea rechazar la SST?",
      header: "Confirmar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.rechazarSst();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  rechazarSst() {
    let motivo = this.form.get('motivo').value
    this.solicitudesService.rechazarSstProgramador(this.solicitud.id, motivo).subscribe({
      next: (data) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Confirmado",
          detail: "La solicitud ha sido rechazada con exito",
        });
      },
      error: (error) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: error.error
            ? error.error.message
            : "Ups! ocurrio un error",
        });
      },
      complete : ()=>{
        this.dynamicDialogRef.close({success:true})
      }
    });
  }
}
