<div class="container-fluid min-width-500 p-0">
  <div class="header">
    <h2>Reportes</h2>
  </div>
  <p-card>
    <div class="card text-center" *ngIf="spinner">
      <span>
        <p-progressSpinner></p-progressSpinner>
      </span>
      <br />
      <span class="text-center">Cargando...</span>
    </div>
    <div *ngIf="!spinner">

      <div class="row mb-3">
        <div class="offset-6 col-6">
          <button class="p-button-lg float-right" pButton pRipple label="Volver" routerLink="/reportes"></button>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <label class="pr-5">Programadores <span></span> </label><br /><p-dropdown [options]="programadores"
            [(ngModel)]="programador" placeholder="-- Seleccione --" optionLabel="name" optionValue="code"
            [showClear]="true" [style]="{ width: '100%' }" (onChange)="filtrosSolicitud()"></p-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4>Buscar por tiempo entre fechas: </h4>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-6">
          <label class="">Fecha desde:<span></span></label>
          <br />
          <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitud()" [(ngModel)]="startDateSolicitud"
            inputId="basic" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }" (onSelect)="filtrosSolicitud()"
            showIcon="true" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="1970:2050">
            <ng-template pTemplate="header">
              <h4 class="text-center">Desde</h4>
            </ng-template>
          </p-calendar>
        </div>
        <div class="col-6">
          <label class="">Fecha hasta:<span></span></label>
          <br />
          <p-calendar showButtonBar="true" (onClearClick)="filtrosSolicitud()" [(ngModel)]="endDateSolicitud"
            inputId="basic" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }" (onSelect)="filtrosSolicitud()"
            showIcon="true" [minDate]="minDateValue" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="1970:2050">
            <ng-template pTemplate="header">
              <h4 class="text-center">Hasta</h4>
            </ng-template>
          </p-calendar>
        </div>
      </div>

      <p-table *ngIf="!spinner" #myTab2 [value]="solicitudes" [scrollable]="true" scrollHeight="400px"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable p-datatable-striped p-datatable-sm p-datatable-gridlines p-datatable-responsive-demo"
        [rowHover]="true" dataKey="id">
        <ng-template pTemplate="caption">
          <p class="text-center">SSTs</p>
        </ng-template>
        <ng-template pTemplate="header" class="p-datatable-header">
          <tr>
            <th>
              Nro SST
            </th>
            <th>
              Fecha solicitud
            </th>
            <th>
              Solicitante
            </th>
            <th>
              Origen
            </th>
            <th>
              Destino
            </th>
            <th>
              Fecha necesidad del Transporte
            </th>
            <th>Tiempo</th>
          </tr>
          <tr></tr>
        </ng-template>

        <ng-template pTemplate="body" let-sst>
          <tr class="p-selectable-row" class="p-datatable-tbody">
            <td class="">
              {{ sst.request.requestId }}
            </td>
            <td class="palabra">
              {{ sst.request.creation | date : 'dd/MM/yyyy h:mm:ss' }}
            </td>
            <td class="palabra">
              {{ sst.request.supplierName }}
            </td>
            <td class="palabra">
              {{ sst.request.originName }}
            </td>
            <td class="palabra">
              {{ sst.request.destinationName }}
            </td>
            <td class="palabra">
              {{ sst.request.requirementDate | date : "dd/MM/YYYY" }}
            </td>
            <td class="palabra">
              {{sst.report}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="12">
              <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No se encontraron SSTs' }]"
                [enableService]="false" [closable]="false">
              </p-messages>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator [rows]="sizeSolicitudes" [totalRecords]="totalRecordosSolicitudes"
        [rowsPerPageOptions]="[10, 20, 30]" (onPageChange)="paginateSolicitudes($event)"></p-paginator>

      <div class="d-flex justify-content-center flex-wrap mt-5" *ngIf="!hasRole('VISUALIZER')">
        <button pButton pRipple type="button" label="Descargar Reporte" class="p-button-secondary"
          (click)="descargarReporteSst()"></button>
      </div>
    </div>
  </p-card>
</div>