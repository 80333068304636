import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  constructor(private http: HttpClient) {}

  //SST -- INICIO
 

  descargarSst(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "requirementDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "supplierId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();

    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/solicitudes/reporte?${queryBuid}`, {
        headers: headers,
        responseType: "blob",
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  descargarTiempoSst(query: any = []) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    return this.http
      .get(`${environment.apiUrl}/solicitudes/reporte/tiempo/excel?${queryBuid}`, {
        headers: headers,
        responseType: "blob",
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  descargarSstTiempoProgramadores(query: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes/reporte/tiempo/programadores/excel`,
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  //SST - FIN

  //SSTA - INICIO

  descargarSsta(query: any) {
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "programmingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "startProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endProgrammingDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "expressService") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "statuses") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "requestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "assignedRequestId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "sort") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/solicitudes-asignadas/reporte?${queryBuid}`, {
        headers: headers,
        responseType: "blob",
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  descargarSstaRechazadoByTransportista(query: any = []) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/rechazado/transportistas/excel?${queryBuid}`,
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  descargarSstaTiempoTransportista(query: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tiempo/transportistas/excel`,
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  descargarTiempoSsta(query: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "transporterId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    return this.http
      .get(
        `${environment.apiUrl}/solicitudes-asignadas/reporte/tiempo/excel?${queryBuid}`,
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  descargarTiempoPromedioPorProgramadorHastaAsignacionPorTransportista(query:any){
    let queryBuid = "";
    query.forEach((q) => {
      if (q.col == "startDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "endDate") {
        var datePipe = new DatePipe("en-US");
        q.value = datePipe.transform(q.value, "YYYY-MM-dd");
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "programmerId") {
        if (q.value) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/solicitudes-asignadas/reporte/tiempo/programadores/excel?${queryBuid}`, {
        headers: headers,
        responseType: "blob",
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
