import { AfterContentChecked, Component, OnInit } from "@angular/core";
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { FileService } from "src/app/core/services/documentos/file.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { ConductoresService } from "src/app/core/services/transporte/conductores/conductores.service";
import { TractoresService } from "src/app/core/services/transporte/tractores/tractores.service";
import { TrailersService } from "src/app/core/services/transporte/trailers/trailers.service";
import { User } from "src/app/shared/models/user/user";
import { Trailer } from "src/app/shared/models/vehiculos/trailers";

@Component({
  selector: 'app-tractores',
  templateUrl: './tractores.component.html',
  styleUrls: ['./tractores.component.scss']
})
export class TractoresComponent implements OnInit {

  displayDocumentacion = false;
  type = null;
  car = null;
  tractores = [];
  displayNewTractor = false;
  spinner = true;

  userActual: User;
  roles: string[] = [];
  idUserActual = "";

  query: any[] = [];

  uploadedFiles: any[] = [];

  constructor(
    private tractorService: TractoresService,
    private trailersService: TrailersService,
    private conductorService: ConductoresService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private fileService: FileService
  ) { }

  async ngOnInit() {
    await this.rolesInit();
    await this.parametros();
    await this.cargarTractores();
  }

  async rolesInit() {
    this.userActual = await this.authService.actual2();
    this.idUserActual = (await this.authService.actual2()).id;
    this.roles = (await this.authService.actual2()).rolenames;
  }

  async parametros() {
    return new Promise((resolve, reject) => {
      if (this.idUserActual) {
        if (this.hasRole("TRANSPORTER")) {
          let findTransporter = this.query.find(
            (d) => d.col == "transporterId"
          );
          if (findTransporter) {
            let index = this.query.indexOf(findTransporter);
            this.query.splice(index, 1);
          }
          this.query.push({ col: "transporterId", value: this.idUserActual });
        }
      }

      resolve("");
    });
  }

  cargarVehiculosExcel(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    let formData = new FormData();
    this.uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });


    this.fileService.uploadTransport(formData).subscribe({
      next: (data) => {
        this.messageService.add({
          key: "msg",
          severity: "success",
          summary: "Hecho",
          detail: "Las unidades fueron cargadas con exito",
        });
        this.cargarTractores();
      },
      error: (err) => {
        this.messageService.add({
          key: "msg",
          severity: "error",
          summary: "Error",
          detail: err.error ? err.error.message : "Ups! ocurrio un error",
        });
        this.uploadedFiles = [];
      },
      complete: () => {
        this.uploadedFiles = [];
      },
    });
  }

  async cargarTractores() {
    await this.tractorService.getAllAsync(this.query).then((tractores) => {
      this.tractores = tractores;
    }).finally(() => {
      this.spinner = false;
    });
  }

  showDialogDocumentacion(type: string, id: string) {
    this.type = type;
    this.car = id;
    this.displayDocumentacion = true;
  }

  showDialogNewTractor() {
    this.displayNewTractor = true;
  }

  cerrarPopUpNewTractor(event) {
    this.displayNewTractor = !event;
    this.cargarTractores();
  }


  habilitarTractor(id) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea habilitarlo?",
      header: "Confirmar ",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.tractorService.habilitarTractor(id.toString()).subscribe(
          (resp) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Habilitado",
              detail: "El tractor ha sido habilitado con exito",
            });
            this.spinner = true;
            this.cargarTractores();
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  deshabilitarTractor(id) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea deshabilitarlo?",
      header: "Confirmar ",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.tractorService.deshabilitarTractor(id.toString()).subscribe(
          (resp) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Deshabilitado",
              detail: "El tractor ha sido deshabilitado con exito",
            });
            this.spinner = true;
            this.cargarTractores();
          },
          (err) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: err.error ? err.error.message : "Ups! ocurrio un error",
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }

}
