<p-dialog header="Agregar tractor" [(visible)]="displayNewTractor" [style]="{
    'background-color': 'white',
    'min-width': '50vw',
    'min-height': '40vw'
  }">
    <app-popup-new-tractor (eventHiddenPopUpTractor)="cerrarPopUpNewTractor($event)"></app-popup-new-tractor>
</p-dialog>

<p-dialog
  [header]="'Documentación De ' + type"
  [style]="{ 'background-color': 'white', 'min-width': '50vw' }"
  [(visible)]="displayDocumentacion"
>
  <app-popup-documentacion [type]="type" [car]="car"></app-popup-documentacion>
</p-dialog>

<div class="card text-center" *ngIf="spinner">
    <span>
        <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
</div>

<div class="container min-width-500" *ngIf="!spinner">
    <div class="row mt-3 m-0">
        <div class="col-5">
        </div>
        <div class="col text-end">
            <p-fileUpload mode="basic" [files]="uploadedFiles" chooseLabel="Cargar unidades" accept=".xls, .xlsx"
                maxFileSize="1000000" (onSelect)="cargarVehiculosExcel($event)"></p-fileUpload>
        </div>
    </div>
    <p-card>
        <div class="row">
            <div class="col">
                <h1 class="title">Tractores</h1>
            </div>
            <div class="col text-end">
                <button pButton pRipple type="button" label="Volver" class="p-button-secondary p-button-text"
                    routerLink="/transporte"></button>
                <button pButton pRipple type="button" label="Agregar" class="p-button-secondary p-button-text"
                    (click)="showDialogNewTractor()"></button>
            </div>
        </div>
        <div class="row">
            <p-card class="col-4" *ngFor="let tractor of tractores; index as i" [style]="{
        width: '23rem',
        height: '23rem',
        'margin-bottom': '2em'
      }">
                <div class="row">
                    <div class="col">
                        <h1 class="title">Tractor {{ i + 1 }}</h1>
                    </div>
                    <div class="col text-end">
                        <div class="state">{{ tractor.state }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Patente: <span> {{ tractor.patent }} </span> </label><br />
                        <label>Marca: <span> {{ tractor.brand }} </span> </label><br />
                        <label>Modelo: <span> {{ tractor.model }} </span> </label><br />
                    </div>
                </div>
                <div class="buttons">
                    <p-button label="" (click)="showDialogDocumentacion('Tractor', tractor.id)"
                        pTooltip="Ver Documentación" icon="pi pi-file" styleClass="p-button-sm">
                    </p-button>
                    <p-button *ngIf="tractor.state != 'ACTIVE'" label="" icon="pi pi-check-circle"
                        styleClass="p-button-sm" pTooltip="Habilitar" (click)="habilitarTractor(tractor.id)"></p-button>
                    <p-button *ngIf="tractor.state == 'ACTIVE'" label="" icon="pi pi-minus-circle
        " styleClass="p-button-sm" pTooltip="Deshabilitar" (click)="deshabilitarTractor(tractor.id)"></p-button>
                </div>
            </p-card>
        </div>
        <div class="row">
            <div class="col">
                <p-divider></p-divider>
            </div>
        </div>
    </p-card>
</div>

<p-confirmDialog
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
>
</p-confirmDialog>
<p-toast></p-toast>