import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tablero-transportista',
  templateUrl: './tablero-transportista.component.html',
  styleUrls: ['./tablero-transportista.component.scss'],
  
})
export class TableroTransportistaComponent implements OnInit {
  displayAlert = false;

  position = "right"
  
  constructor(private route:Router) { }

  ngOnInit(): void {
  }
  showDialogAlert() {
    this.displayAlert = true;
  }
}
