import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-administrador',
  templateUrl: './menu-administrador.component.html',
  styleUrls: ['./menu-administrador.component.scss']
})
export class MenuAdministradorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
