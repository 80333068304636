import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import {
  ConfirmationService,
  MessageService,
  ConfirmEventType,
  Message,
} from "primeng/api";
import { AuthService } from "src/app/core/authentication/auth.service";
import { AlertasService } from "src/app/core/services/alertas/alertas.service";
import { SolcitudesService } from "src/app/core/services/solicitudes/solcitudes.service";
import { SolicudesAsignadasService } from "src/app/core/services/solicitudesAsignadas/solicudes-asignadas.service";
import { SstService } from "src/app/services/sst/sst.service";
import { Alerta } from "src/app/shared/models/alerta/alerta";
import { Solicitud } from "src/app/shared/models/sst/solicitud";
import { solicitudes } from "src/app/shared/models/sst/solicitudes";
import { User } from "src/app/shared/models/user/user";

@Component({
  selector: "app-popup-alertas",
  templateUrl: "./popup-alertas.component.html",
  styleUrls: ["./popup-alertas.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PopupAlertasComponent implements OnInit {
  solicitudes: solicitudes[] = [];
  alertas: Alerta[] = [];
  mensajeVacio: Message[];

  loading: boolean = true;
  rol: string;
  page = 1;
  size = 9;
  spinner = true;
  totalRecords = 0;

  roles: string[] = [];

  userActual: User = new User();
  idUserActual;

  ngOnInit(): void {
    this.rolesInit();
    if (localStorage.getItem("sstGenerate")) {
      localStorage.removeItem("sstGenerate");
    }
  }
  constructor(
    private authService: AuthService,
    private solicitudesServices: SolcitudesService,
    private solicitudesAsigndasServices: SolicudesAsignadasService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private alertaService: AlertasService,
    private router: Router
  ) {
    this.authService.actual().subscribe((data) => {
      this.userActual = data;
      this.getAlertas();
    });

    this.rol = this.authService.queryRole();
  }

  async rolesInit() {
    this.userActual = await this.authService.actual2();
    this.idUserActual = (await this.authService.actual2()).id;
    this.roles = (await this.authService.actual2()).rolenames;
  }

  getAlertas() {
    this.alertaService
      .filterAlertByIdUser(this.userActual.id, this.page, this.size)
      .subscribe((res) => {
        this.alertas = res.list;
        if (this.alertas.length <= 0) {
          this.mensajeVacio = [
            {
              severity: "info",
              summary: "No hay alertas pendientes por mostrar",
            },
          ];
        }
        this.totalRecords = res.totalElements;
        this.spinner = false;
      });
  }

  aceptarExpressServiceLider(item: Alerta) {
    if (!item) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Acepte una alerta valida",
      });
      return;
    }

    let mensaje = "¿Está aseguro que desea aceptar la ssta seleccionada?";

    this.confirmationService.confirm({
      message: mensaje,
      header: "Confirmar ",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesAsigndasServices
          .acceptSstas([item.assignedRequest.id])
          .subscribe(
            (resp) => {
              this.messageService.add({
                key: "msg",
                severity: "success",
                summary: "Aceptado",
                detail: "",
              });
              this.getAlertas();
            },
            (err) => {
              this.messageService.add({
                key: "msg",
                severity: "error",
                summary: "Error",
                detail: err.error ? err.error.message : "Ups! ocurrio un error",
              });
            }
          );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  rechazarExpressServiceLider(item: Alerta) {
    if (!item) {
      this.messageService.add({
        key: "msg",
        severity: "warn",
        summary: "Acepte una alerta valida",
      });
      return;
    }

    let mensaje = "¿Está aseguro que desea rechazar la ssta seleccionada?";

    this.confirmationService.confirm({
      message: mensaje,
      header: "Confirmar ",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Rechazar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesAsigndasServices
          .rejectSstas([item.assignedRequest.id])
          .subscribe(
            (resp) => {
              this.messageService.add({
                key: "msg",
                severity: "success",
                summary: "Rechazado",
                detail: "",
              });
              this.getAlertas();
            },
            (err) => {
              this.messageService.add({
                key: "msg",
                severity: "error",
                summary: "Error",
                detail: err.error ? err.error.message : "Ups! ocurrio un error",
              });
            }
          );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  aceptarAlerta(item: Alerta) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea aceptar la propuesta?",
      header: "Confirmar propuesta",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesAsigndasServices.aceptarPropuestaFecha(item).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "La propuesta ha sido aceptada con exito",
            });
            this.getAlertas();
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error.message,
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  rechazarALerta(item: Alerta) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea rechazar la propuesta?",
      header: "Confirmar rechazo",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.solicitudesAsigndasServices.rechazarPropuestaFecha(item).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "La propuesta ha sido rechazada con exito",
            });
            this.getAlertas();
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error.message,
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  deleteSst(item: Alerta) {
    let id = item.id.toString();
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la alerta?",
      header: "Confirmar eliminar",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        this.alertaService.deleteAlerta(id).subscribe(
          (res) => {
            this.messageService.add({
              key: "msg",
              severity: "success",
              summary: "Confirmado",
              detail: "La alerta ha sido eliminada con exito",
            });
            this.getAlertas();
          },
          (error) => {
            this.messageService.add({
              key: "msg",
              severity: "error",
              summary: "Error",
              detail: error.error.message,
            });
          }
        );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: "msg",
              severity: "warn",
              summary: "Cancelado",
              detail: "Usted ha cancelado",
            });
            break;
        }
      },
    });
  }

  asignarSst(alerta) {
    let selectedSst: Solicitud[] = [];
    selectedSst.push(alerta.request);
    localStorage.setItem("sstGenerateAlert", JSON.stringify(selectedSst));
    this.router.navigate(["/generar-ssta"]);
  }

  loadingAcceptSsta = false;
  aceptarSstaTransportista(alerta: Alerta, eventClick: Event) {
    eventClick.preventDefault();
    this.loadingAcceptSsta = true;

    this.solicitudesAsigndasServices
      .acceptSstas([alerta.assignedRequest.id])
      .subscribe({
        next: (resp) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Ssta aceptada",
            detail: "",
          });
          this.getAlertas();
        },
        error: (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
          this.loadingAcceptSsta = false;
        },
      });
  }

  loadingRejectSsta = false;
  rechazarSstaTransportista(alerta: Alerta, eventClick: Event) {
    eventClick.preventDefault();
    this.loadingRejectSsta = true;

    this.solicitudesAsigndasServices
      .rejectSstas([alerta.assignedRequest.id])
      .subscribe({
        next: (resp) => {
          this.messageService.add({
            key: "msg",
            severity: "success",
            summary: "Ssta Rechazada",
            detail: "",
          });
          this.getAlertas();
        },
        error: (err) => {
          this.messageService.add({
            key: "msg",
            severity: "error",
            summary: "Error",
            detail: err.error ? err.error.message : "Ups! ocurrio un error",
          });
        },
        complete: () => {
          this.loadingRejectSsta = false;
        },
      });
  }

  paginate(event) {
    this.page = event.page + 1;
    this.size = event.rows;
    this.getAlertas();
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }
}
