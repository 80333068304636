import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { PopUpCambiarRolComponent } from './shared/pop-up-cambiar-rol/pop-up-cambiar-rol.component';
import { RolesComponent } from './pages/list-roles/roles.component';



@NgModule({
  declarations: [
    RolesComponent,
    PopUpCambiarRolComponent
  ],
  imports: [
    CommonModule,PrimengModule
  ],
  exports: [
    RolesComponent,PopUpCambiarRolComponent
  ]
})
export class RolesModule { }
