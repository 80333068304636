<li class="nav-item text-left">
  <a href="/tablero-transportista">
    <i class="bi bi-house"></i>
    <span class="links_name">Home</span>
  </a>
  <span class="tooltip">Home</span>
</li>
<li class="nav-item text-left">
  <a href="/alertas">
    <i class="bi bi-bell"></i>
    <span class="links_name">Alertas</span>
  </a>
</li>
<li class="nav-item text-left">
  <a href="/solicitudes-asignadas">
    <i class="bi bi-file-check"></i>
    <span class="links_name">SSTA Asignadas</span>
  </a>
  <span class="tooltip"></span>
</li>
<li class="nav-item text-left">
  <a href="/informacion-de-transporte">
    <i class="bi bi-info-circle"></i>
    <span class="links_name"
      >Información <br />
      Transporte</span
    >
  </a>
  <span class="tooltip"></span>
</li>
