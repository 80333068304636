<li class="nav-item text-left">
    <a href="/tablero-transportista">
        <i class="bi bi-house"></i>
        <span class="links_name">Home</span>
    </a>
    <span class="tooltip">Home</span>
</li>
<li class="nav-item text-left">
    <a href="/alertas">
        <i class="bi bi-bell"></i>
        <span class="links_name">Alertas</span>
    </a>
    <span class="tooltip"></span>
</li>
<li class="nav-item text-left">
    <a href="/solicitudes">
        <i class="bi bi-file-earmark-text"></i>
        <span class="links_name">SST</span>
    </a>
    <span class="tooltip"></span>
</li>
<li class="nav-item text-left">
    <a href="/solicitudes-asignadas">
        <i class="bi bi-file-check"></i>
        <span class="links_name">SSTA </span>
    </a>
    <span class="tooltip"></span>
</li>
<li class="nav-item text-left">
    <a href="/transportistas">
        <i class="bi bi-truck"></i>
        <span class="links_name">Transportistas</span>
    </a>
    <span class="tooltip"></span>
</li>
<li class="nav-item text-left">
    <a href="/reportes">
        <i class="bi bi-file-text"></i>
        <span class="links_name">Reportes</span>
    </a>
    <span class="tooltip"></span>
</li>

